/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {hrefUtils} from '@illumio-shared/utils';
import createCachedSelector from 're-reselect';
import SettingsReducers from './Settings/SettingsState';
import * as GridUtils from 'components/Grid/GridUtils';
import {getMergedOrgs} from './UserUtils';
import {generalUtils} from '@illumio-shared/utils/shared';
import stringify from 'safe-stable-stringify';

import JSONBig from 'json-bigint-keep-object-prototype-methods';

const JSONBigIntNative = JSONBig({useNativeBigInt: true, objectProto: true});

export const roleNamesMap = () => ({
  owner: 'Global Organization Owner',
  admin: 'Global Administrator',
  read_only: 'Global Read Only',
  global_object_provisioner: 'Global Policy Object Provisioner',
  workload_manager: 'Workload Manager',
  ruleset_manager: 'Full Ruleset Manager',
  ruleset_provisioner: 'Ruleset Provisioner',
  ruleset_viewer: 'Ruleset Viewer',
  limited_ruleset_manager: 'Limited Ruleset Manager',
});

export const anyRoles = ['Anyone', 'Any Authenticated User', 'This Authenticated User'];
export const scopedRoles = new Set([
  'ruleset_manager',
  'limited_ruleset_manager',
  'ruleset_provisioner',
  'ruleset_viewer',
  'workload_manager',
]);
export const globalRoles = new Set(['owner', 'admin', 'read_only', 'global_object_provisioner']);

/**
 * REDUCER common for user and user pages
 * user
 *    |- login
 *    |- settings
 * users
 *    |- all
 *    |- count
 *    |- remove
 * **/
export default {
  user: combineReducers({
    ...SettingsReducers,
    login: (function () {
      const userFields = [
        'auth_username',
        'full_name',
        'href',
        'login_url',
        'last_login_ip_address',
        'last_login_on',
        'flow_analytics_enabled',
        'health_dashboard_enabled',
        'org_display_name',
        'org_id',
        'orgs',
        'session_token',
        'start',
        'time_zone',
        'type',
        'pce_cluster_type',
        'certificate',
        'is_msp_owner',
        'msp_org_url',
        'support_products',
        'chargebee_url',
        'chargebee_site_name',
        'org_uuid',
      ];

      return (state = {}, {type, data}) => {
        let user = state;

        if (type === 'USER_LOGIN_SUCCESS') {
          user = {..._.pick(data, userFields), id: hrefUtils.getIdNumber(data.href)};
        } else if (type === 'USER_GET_ORG_SUCCESS' && data.userId === user.id) {
          const mergedOrgs = getMergedOrgs(user.orgs, data.orgs);

          if (!_.isEqual(user.orgs, mergedOrgs)) {
            user = {...user, orgs: mergedOrgs};
          }
        } else if (type === 'USER_UPDATE_PROFILE') {
          user = {...user, ..._.pick(data, userFields)};
        }

        if (user !== state) {
          // For sharing user between tessereact<->legacy page. #USERJUMP
          // update window object for legacy and tesse react
          // window object is used by JumpToNew and JumpToOld
          window['--JUMPS_USER--'] = {...(window['--JUMPS_USER--'] || data), ...user};
        }

        return user;
      };
    })(),
    grids(state = {origin: {}, settings: {}, seenColIds: {}}, {type, data}) {
      if (type === 'GRID_GET_SETTINGS') {
        const newGridSettings = {};
        const userCustomSettings = GridUtils.getCustomUserSettings(data.settings);

        if (stringify(userCustomSettings) !== stringify(state.settings[data.key])) {
          newGridSettings.settings = {...state.settings, [data.key]: userCustomSettings};
        }

        if (
          generalUtils.sortAndStringifyArray(state.seenColIds[data.key]) !==
          generalUtils.sortAndStringifyArray(data.settings.seenColumnIds)
        ) {
          newGridSettings.seenColIds = {...state.seenColIds, [data.key]: data.settings.seenColumnIds};
        }

        if (_.isEmpty(newGridSettings)) {
          return state;
        }

        return {
          ...state,
          origin: {...state.origin, [data.key]: data.settings},
          settings: newGridSettings.settings || state.settings,
          seenColIds: newGridSettings.seenColIds || state.seenColIds,
        };
      }

      return state;
    },
    selectorHistory(state = {}, {type, data}) {
      if (type === 'SELECTOR_GET_HISTORY') {
        return data;
      }

      return state;
    },
  }),
  users: combineReducers({
    // Array of all users
    all(state = [], {type, data}) {
      if (type === 'USER_GET_ALL') {
        return data;
      }

      /* 1) Used for MyProfile page, need to use data.username for MyProfile email/username display.
       * 2) When type === 'USER_GET_INSTANCE' append a user to the users list
       * Note: state.user.login with login/ API doesn't have username
       */
      if (type === 'USER_UPDATE_PROFILE' || type === 'USER_GET_INSTANCE') {
        // If we get user instance, insert it into list, but keep orgs if user already in list
        const userIndex = state.findIndex(user => user.id === data.id);

        // 'orgs' is legacy, keep orgs when in the list
        if (userIndex >= 0) {
          return state
            .slice(0, userIndex)
            .concat(state[userIndex].orgs ? {...data, orgs: state[userIndex].orgs} : data)
            .concat(state.slice(userIndex + 1));
        }

        return [...state, data];
      }

      return state;
    },
    // Total count of all users
    count: (state = 0, {type, data}) => (type === 'USER_GET_ALL' ? data.length : state),
    orgPermissions(state = {}, action) {
      switch (action.type) {
        case 'USER_GET_ORG_PERMISSIONS':
          const {userId, orgPermissions} = action.data;

          return {...state, [userId]: orgPermissions};
        default:
          return state;
      }
    },
  }),
};

/** SELECTORS common for users pages **/
export const getUser = state => state.user.login;
export const getAllUsers = state => state.users.all;
export const getUserOrgPermissions = state => state.users.orgPermissions;
export const getUserGridSettings = (state, key) => state.user.grids.settings[key];
export const getUserGridSeenColumns = (state, key) => state.user.grids.seenColIds[key];
export const getUserGridOriginSettings = (state, key) => state.user.grids.origin[key];
export const getUserAllGridSeenColumns = state => state.user.grids.seenColIds;
export const getUserSelectorHistory = state => state.user.selectorHistory;

export const getAllUsersMap = createSelector(getAllUsers, (users = []) =>
  users.reduce((map, user) => map.set(user.href, user), new Map()),
);
export const getAllUsersByIdMap = createSelector(getAllUsers, (users = []) =>
  users.reduce((map, user) => map.set(JSONBigIntNative.parse(user.id), user), new Map()),
);
export const getAllUsersByUsernameMap = createSelector(getAllUsers, (users = []) =>
  users.reduce((map, user) => map.set(user.username, user), new Map()),
);

// Selector to get user object from all users and optionally filter it by type ('external'/'local')
export const getUserById = createCachedSelector(
  getAllUsersByIdMap,
  (state, id) => id,
  (state, userId, type) => type,
  (users, id, type) => {
    const user = users.get(id);

    if (user !== undefined && (!type || user.type === type)) {
      return user;
    }
  },
)((state, id, type) => (type ? id + type : id.toString()));

export const getUsernameToUserMap = createSelector(getAllUsers, (users = []) =>
  users.reduce((res, user) => ({...res, [user.username]: user}), {}),
);

export const getAllUsersCount = state => state.users.count;

export const getUserOrgs = createSelector(getUser, user => user.orgs);

export const getOrg = createSelector(getUserOrgs, (orgs = []) => {
  // With the new MSSP feature (EYE-78598), a user can have access to multiple organizations,
  // and the first organization in the orgs list is the organization the user is currently accessing.
  return orgs[0];
});

// The organization this user belongs to
export const getMemberOrgId = createSelector(getUser, user => user.org_id);

export const getMemberOrgName = createSelector(getUser, user => user.org_display_name);

export const getOrgId = createSelector(getOrg, (org = {}) => hrefUtils.getId(org.href));

export const getOrgName = createSelector(getOrg, org => org?.display_name);

export const getSuperclusterType = createSelector(getUser, user => user.pce_cluster_type);

export const isUserExternal = createSelector(getUser, user => user.type === 'external');

export const isSuperclusterUser = createSelector(getSuperclusterType, type => Boolean(type));

export const isSuperclusterLeader = createSelector(getSuperclusterType, type => type === 'leader');

export const isSuperclusterMember = createSelector(getSuperclusterType, type => type === 'member');

export const getExtUsers = createSelector(getAllUsers, (users = []) => users.filter(user => user.type === 'external'));

export const getLocalUsers = createSelector(getAllUsers, (users = []) => users.filter(user => user.type === 'local'));

export const getLocalUsersUsernameSet = createSelector(
  getLocalUsers,
  (localUsers = []) => new Set(localUsers.map(user => user.username.toLowerCase())),
);

export const getUserId = createSelector(getUser, user => user.id);

export const getUserName = createSelector(getUser, user => user.full_name || user.auth_username);

export const getUserPermissions = createSelector(getOrg, (org = {}) => org.permissions);

export const getUserRoles = createSelector(getUserPermissions, (permissions = []) => [
  ...new Set(permissions.map(permission => hrefUtils.getId(permission.role.href))),
]);

export const getRoleNames = createSelector(getUserRoles, userRoles => {
  const roleNames = new Set(anyRoles);

  userRoles.forEach(userRole => {
    if (roleNamesMap()[userRole]) {
      roleNames.add(roleNamesMap()[userRole]);
    }
  });

  return roleNames;
});

export const isUserAdmin = createSelector([getUserRoles], roles => roles.includes('admin'));

export const isUserOwner = createSelector([getUserRoles], roles => roles.includes('owner'));

export const isUserGlobalReadOnly = createSelector([getUserRoles], roles => roles.includes('read_only'));

// User might have limited Admin access to narrow scopes, but is effectively read only for entire UI
export const isUserReadOnlyClusterInsensitive = createSelector(
  [isUserAdmin, isUserOwner],
  (userIsAdmin, userIsOwner) => !userIsAdmin && !userIsOwner,
);

export const getIsSuperClusterReadOnlyMode = createSelector(
  [isSuperclusterMember],
  superclusterMember => superclusterMember,
);

export const isUserReadOnly = createSelector(
  [isUserReadOnlyClusterInsensitive, getIsSuperClusterReadOnlyMode],
  (userIsReadOnly, isSuperClusterReadOnlyMode) => isSuperClusterReadOnlyMode || userIsReadOnly,
);

// User is either read only or only has provisioner specific roles
export const isUserReadOnlyOrProvisionerClusterInsensitive = createSelector(
  [getUserRoles, isUserAdmin, isUserOwner],
  (roles, userIsAdmin, userIsOwner) =>
    !userIsAdmin &&
    !userIsOwner &&
    roles.includes('read_only') &&
    !roles.includes('ruleset_manager') &&
    !roles.includes('limited_ruleset_manager'),
);
export const isUserReadOnlyOrProvisioner = createSelector(
  [isUserReadOnlyOrProvisionerClusterInsensitive, getIsSuperClusterReadOnlyMode],
  (userIsReadOnlyOrProvisioner, isSuperClusterReadOnlyMode) =>
    isSuperClusterReadOnlyMode || userIsReadOnlyOrProvisioner,
);

// User is Read Only-All|All|All and has no other role scopes
// Used where RBAC is applied (rulesets pages, Illumination)
export const isUserReadOnlyAllClusterInsensitive = createSelector(
  [getUserRoles],
  roles => roles.length === 1 && roles[0] === 'read_only',
);

export const isUserReadOnlyAll = createSelector(
  [isUserReadOnlyAllClusterInsensitive, getIsSuperClusterReadOnlyMode],
  (userIsReadOnlyAll, isSuperClusterReadOnlyMode) => isSuperClusterReadOnlyMode || userIsReadOnlyAll,
);

export const isUserRulesetProvisioner = createSelector(
  [getUserRoles, isUserAdmin, isUserOwner],
  (roles, userIsAdmin, userIsOwner) => !userIsAdmin && !userIsOwner && roles.includes('ruleset_provisioner'),
);

export const isUserGlobalObjectProvisioner = createSelector([getUserRoles], roles =>
  roles.includes('global_object_provisioner'),
);

export const isUserWorkloadManager = createSelector([getUserRoles], roles => roles.includes('workload_manager'));

export const isUserWorkloadManagerOnly = createSelector(getUserRoles, roles =>
  roles.every(role => role === 'workload_manager'),
);

export const isUserScoped = createSelector(getUserRoles, roles => roles.every(role => scopedRoles.has(role)));

// Tells if user has permission to provision.
export const canUserProvision = createSelector(
  [isUserRulesetProvisioner, isUserGlobalObjectProvisioner, isUserAdmin, isUserOwner],
  (userRulesetProvisioner, userGlobalObjectProvisioner, userAdmin, userOwner) =>
    userRulesetProvisioner || userGlobalObjectProvisioner || userAdmin || userOwner,
);

export const canUserViewEnforcementBoundaries = createSelector(
  [isUserGlobalObjectProvisioner, isUserAdmin, isUserOwner, isUserGlobalReadOnly],
  (userGlobalObjectProvisioner, userAdmin, userOwner, userIsGlobalReadOnly) => {
    return userGlobalObjectProvisioner || userAdmin || userOwner || userIsGlobalReadOnly;
  },
);

export const canUserProvisionGlobalObject = createSelector(
  [isUserGlobalObjectProvisioner, isUserAdmin, isUserOwner],
  (userIsGlobalObjectProvisioner, userIsAdmin, userIsOwner) =>
    userIsGlobalObjectProvisioner || userIsAdmin || userIsOwner,
);

export const doesUserHaveGlobalObjectPermissionsClusterInsensitive = createSelector(
  [getUserRoles, isUserAdmin, isUserOwner],
  (roles, userIsAdmin, userIsOwner) => userIsAdmin || userIsOwner || roles.includes('global_object_provisioner'),
);
export const doesUserHaveGlobalObjectPermissions = createSelector(
  [doesUserHaveGlobalObjectPermissionsClusterInsensitive, getIsSuperClusterReadOnlyMode],
  (UserHasGlobalObjectPermissions, isSuperClusterReadOnlyMode) =>
    isSuperClusterReadOnlyMode || UserHasGlobalObjectPermissions,
);

export const getCert = createSelector(getUser, user => user.certificate);

export const getCertExpiration = createSelector(getCert, certificate => certificate?.expiration);

export const getCertType = createSelector(getCert, certificate => certificate?.generated);

export const isHealthEnabled = createSelector(getUser, user => user.health_dashboard_enabled || false);

export const isFlowAnalyticsEnabled = createSelector(getUser, user => user.flow_analytics_enabled || false);

export const isIlluminationMapEnabled = createSelector(isUserScoped, userIsScoped => !userIsScoped);

export const isSegmentationTemplatesEnabled = createSelector(isUserScoped, userIsScoped => !userIsScoped);

export const isUserMSPOwner = createSelector(getUser, user => user.is_msp_owner || false);
export const getMspOrgUrl = createSelector(getUser, user => user.msp_org_url);
export const getSupportProducts = createSelector(getUser, user => user.support_products || []);
export const getChargebeeUrl = createSelector(getUser, user => user.chargebee_url);
export const getChargebeeSiteName = createSelector(getUser, user => user.chargebee_site_name);
export const getOrgUUID = createSelector(getUser, user => user.org_uuid);

/*
 * Starting with release 20.1, the following roles will be scoped:
 * 'ruleset_manager', 'limited_ruleset_manager','ruleset_provisioner', 'ruleset_viewer', 'workload_manager'.
 * However, some scoped roles, such as Workload Manager, have narrower scope than others.
 * This method will tell if a user has a reduced scope.
 */
export const isUserWithReducedScope = createSelector(
  getUserRoles,
  roles => roles.includes('workload_manager') && roles.length === 1,
);

export const isUserWithRulesetRole = createSelector(
  getUserRoles,
  roles =>
    roles.includes('ruleset_viewer') || roles.includes('ruleset_manager') || roles.includes('ruleset_provisioner'),
);

// export const getRBACAbilities = createSelector(
//   getUserRoles,
//   roles => getEffectiveAbilitiesTable([...roles])
// );

/* Check to determine if the User's permission is a not a workload_manager. When they are
 * Admin or Owner they will still have workload manager rights.
 * e.g. roles - e.g. ['workload_manager', 'ruleset_manager'] permission from backend
 * Note: isUserReadOnly : admin or owner are never read only
 */
export const isWorkloadManagerReadOnly = createSelector([getUserRoles, isUserReadOnly], (roles, userIsReadOnly) =>
  roles.includes('workload_manager') ? false : userIsReadOnly,
);

export const isWorkloadManagerReadOnlyClusterInsensitive = createSelector(
  [getUserRoles, isUserReadOnlyClusterInsensitive],
  (roles, userIsReadOnly) => (roles.includes('workload_manager') ? false : userIsReadOnly),
);

/* Check for user with only workload manager roles
 */
export const isUserRulesetViewer = createSelector([getUserRoles], roles => roles.includes('ruleset_viewer'));

export const isUserRulesetManager = createSelector(
  [getUserRoles],
  roles => roles.includes('ruleset_manager') || roles.includes('limited_ruleset_manager'),
);

export const isTrafficEnabled = createSelector(
  [isUserAdmin, isUserOwner, isUserGlobalReadOnly, isUserRulesetViewer, isUserRulesetManager, isUserRulesetProvisioner],
  (
    userIsAdmin,
    userIsOwner,
    userIsGlobalReadOnly,
    userIsRulesetViewer,
    userIsRulesetManager,
    userIsRulesetProvisioner,
  ) =>
    userIsAdmin ||
    userIsOwner ||
    userIsGlobalReadOnly ||
    userIsRulesetViewer ||
    userIsRulesetManager ||
    userIsRulesetProvisioner,
);

export const isExplorerEnabled = createSelector([isFlowAnalyticsEnabled], flowAnalyticsEnabled => flowAnalyticsEnabled);

export const isSaveAndProvisionDisabled = createSelector(
  [isUserRulesetManager, isUserRulesetProvisioner],
  (userIsRulesetManager, userIsRulesetProvisioner) => userIsRulesetManager && !userIsRulesetProvisioner,
);

export const isRuleWritingDisabled = createSelector(
  [isUserScoped, isUserRulesetManager, isUserReadOnlyAll],
  (userIsScoped, userIsRulesetManager, userIsReadOnly) => userIsReadOnly || (userIsScoped && !userIsRulesetManager),
);

/* Check to determine if User is workload manager and not an owner or admin.
 * All owner and admin can choose any labels: app, loc, env but workload manager that is not admin or owner
 * can only choose the scope they belong to.
 */
export const isWorkloadManagerScopeSpecific = createSelector(
  [isWorkloadManagerReadOnly, isUserReadOnly],
  (workloadManagerReadOnly, userIsReadOnly) => !workloadManagerReadOnly && userIsReadOnly,
);

export const truncateText = str => {
  if (typeof str === 'string' && str.length) {
    let returnVal;

    if (str.includes('...')) {
      returnVal = `${str.slice(0, Math.abs(Math.ceil(str.length / 2) - Math.ceil(str.length / 9)))}...${str.slice(
        Math.abs(Math.ceil(str.length / 2) + Math.ceil(str.length / 6)),
        str.length,
      )}`;
    } else {
      returnVal = `${str.slice(0, Math.floor(str.length / 2) - 4)}...${str.slice(
        Math.ceil(str.length / 2) + 1,
        str.length,
      )}`;
    }

    return returnVal;
  }
};

export const trimLabels = name => name.split('|').map(label => label.trim());

export const truncateAppGroupName = (appGroupName, totalCharacters, numberOfCharactersPerLabel) => {
  //number of characters per label should be an array of numbers for app, env, loc labels
  if (appGroupName && appGroupName.length > totalCharacters) {
    const labels = trimLabels(appGroupName);

    labels.forEach((label, index) => {
      let prevLabel;

      // Ensure no infinite loop
      while (label.length > numberOfCharactersPerLabel[index] && prevLabel !== label) {
        prevLabel = label;
        label = truncateText(label);
      }

      labels[index] = label;
    });

    return labels.join(' | ');
  }

  return appGroupName;
};

export const truncateUsername = (username, maxLength) => {
  const truncatedUsername = username.split('@');

  // if it is not an email address, has 0 or multiple @ symbols, just truncate as a string
  if (truncatedUsername.length !== 2) {
    return truncateAppGroupName(truncatedUsername.join('@'), maxLength, [maxLength]);
  }

  if (truncatedUsername[1].length < 15) {
    // If username is an email address, remove the length of the domain from the total length
    maxLength -= truncatedUsername[1].length;
  } else {
    // If the domain length is ALSO very long, compose the email of equal parts truncated-username@truncated-domain
    maxLength = maxLength / 2;
    truncatedUsername[1] = truncateAppGroupName(truncatedUsername[1], maxLength, [maxLength]);
  }

  truncatedUsername[0] = truncateAppGroupName(truncatedUsername[0], maxLength, [maxLength]);

  return truncatedUsername.join('@');
};
