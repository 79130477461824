/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';

export const calcAppGroupName = labels =>
  labels
    .sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
    .map(label => label.value)
    .join(' | ');

export const calcAppGroupMemberCount = appGroup => {
  return (
    (appGroup?.num_workloads || 0) +
    (appGroup?.num_virtual_services || 0) +
    (appGroup?.num_virtual_servers || 0) +
    (appGroup?.num_container_workloads || 0)
  );
};

const getEnforcementString = type => {
  const policyStates = {
    enforced: intl('Workloads.Full'),
    idle: intl('Common.Idle'),
    mixed: intl('Policy.Mixed'),
    selective: intl('Workloads.Selective'),
    unknown: intl('Common.Unknown'),
    unmanaged: intl('Common.Unmanaged'),
    visibility: intl('Common.VisibilityOnly'),
  };

  return policyStates[type];
};

export const calcAppGroupEnforcement = (appGroup, modeKeys) => {
  const {mode = [], container_workload_mode = []} = appGroup || {};

  const enforcedMode = mode.reduce((enforced, m, i) => {
    enforced[i] = m + container_workload_mode[i];

    return enforced;
  }, []);
  const enforcementDetails = (modeKeys || []).reduce(
    (result, key, index) => ({...result, [key]: enforcedMode[index]}),
    {},
  );

  let enforcement = '';
  const modeCountList = Object.values(enforcementDetails).filter(value => value > 0);

  if (modeCountList.length > 1) {
    enforcement = 'mixed';
  } else {
    enforcement = Object.keys(enforcementDetails).find(key => enforcementDetails[key] > 0);
  }

  return getEnforcementString(enforcement);
};

/**
 * Parses app group nodes and assigns the appropriate labels to each one
 * @param nodes - array of app groups
 * @param labels - labels object
 * @returns [] - array of app group nodes
 */
export const parseAppGroupNodes = (nodes, labels) => {
  return nodes.map(node => {
    return {
      ...node,
      labels: node.label_ids.map(id => ({
        ...labels[id].label,
        id,
      })),
    };
  });
};
