/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {call, select, put, retry} from 'redux-saga/effects';
import {getLabels, getLabelsCount, getGridSettings} from './LabelListState';
import {filterMap} from './LabelListConfig';
import gridSaga from 'components/Grid/GridSaga';

import {errorUtils} from '@illumio-shared/utils';
import {cachedResponses} from 'api/apiCache';

export function* fetchLabels({filter, usage = false, force = false} = {}) {
  const query = {max_results: 500, usage, includeDeleted: false};

  if (filter) {
    Object.entries(filter).forEach(([name, [value]]) => {
      query[name] = value.value ?? value;
    });
  }

  return yield call(apiSaga, 'labels.get_collection', {
    query,
    cache: !force,
    *onDone({data: list, count}) {
      if (force || list !== (yield select(getLabels)) || count !== (yield select(getLabelsCount))) {
        yield put({type: 'LABEL_GET_LIST', data: {list, count, usage}});
      }

      return {list, count};
    },
  });
}

export function* removeLabels({hrefs}) {
  yield call(apiSaga, 'labels.delete', {
    data: {labels: hrefs.map(href => ({href}))},
    hrefs,
    *onDone({options: {hrefs}}) {
      yield put({type: 'LABELS_REMOVE', data: hrefs});

      cachedResponses.removeByMethodName('label_dimensions.get_collection');
    },
  });
}

export function* fetchLabelList(route, refetch = false) {
  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap,
    *onSaga({filterParams}) {
      const {list} = yield call(fetchLabels, {
        force: refetch,
        usage: true,
        filter: filterParams.isEmpty ? undefined : filterParams.valid,
      });

      return list.length;
    },
  });
}

export function* fetchLabelMatches({query, params, retries = 2}) {
  let errorMessage;

  try {
    // Call to get the matching query name
    const matches = yield retry(retries, 0, apiSaga, 'labels.autocomplete', {params, query});

    return matches;
  } catch (error) {
    errorMessage = error;
  }

  // Throw error message when request fails
  throw new errorUtils.RequestError({
    message: errorMessage,
  });
}
