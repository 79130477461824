/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {PureComponent, createElement, createRef, type RefObject, type MutableRefObject} from 'react';
import {AppContext} from 'containers/App/AppUtils';
import {reactUtils} from '@illumio-shared/utils';
import Modal from '../Modal';
import {Button, type ButtonProps} from 'components';
import type {FormikProps} from 'formik';
import type {ModalHeaderProps} from '../ModalHeader';
import type {ModalContentProps} from '../ModalContent';

const defaultModalProps = {
  stretch: true,
  minWidth: '70%',
  dontRestrainChildren: true,
};

const defaultDoneButtonProps = {
  tid: 'ok',
  text: intl('Common.Close'),
};

const defaultSaveButtonProps = {
  tid: 'save',
  text: intl('Common.Save'),
  icon: 'save' as const,
};

const defaultCancelButtonProps = {
  tid: 'cancel',
  text: intl('Common.Cancel'),
  noFill: true,
};

// List of props specific to Alert modal, all extra props will be passed down to rendered Modal as is
interface PageInvokerProps<P extends Record<string, unknown>>
  extends Omit<ModalHeaderProps, 'children'>,
    Omit<ModalContentProps, 'children'> {
  // Props for Container page
  edit: boolean;
  //container: PropTypes.any;
  container: React.ComponentType<P>;
  containerProps: P;
  unsavedWarningData: Record<string, unknown>;

  // Props for <Modal.Header>
  // Object with custom props for a button, will be merged with defaultButtonProps and onClose (if passed)
  doneButtonProps: ButtonProps;
  // Object with custom props for a button, will be merged with defaultButtonProps and onClose (if passed)
  saveButtonProps: ButtonProps;
  // Object with custom props for a button, will be merged with defaultButtonProps and onClose (if passed)
  cancelButtonProps: ButtonProps;
  // Shortcut to set progress state to the button if buttonProps is not specified
  isInProgress: boolean;

  // Pass onDone callback to receive created object and to handle next actions
  onDone: (...arg: unknown[]) => void;
  // Pass onSave when parent handles API call, when onSave is not passed then API call is made by container
  onSave?: (payload: unknown) => void;
  onClose: (evt?: React.MouseEvent<HTMLElement>) => void;

  noFooter: boolean;
  noModalFooter: boolean;
  // passed down from selector to prevent selector from closing on mousedown within modal
  pageInvokerRef?: RefObject<Modal>;
  children: (arg: {handleOpen: PageInvokerModal<P>['handleOpen']}) => void;
}

interface PageInvokerState<P extends Record<string, unknown>> {
  showModal: boolean;
  valid: boolean;
  saveDisabled: boolean;
  saving: boolean;
  parentFormDirtyFlag?: boolean;
  additionalProps?: P & Pick<ModalHeaderProps, 'title'>;
  onSaveDone?: (value: unknown) => void;
}

interface Container {
  getPayload: () => unknown;
  handleSave: (evt: React.MouseEvent<HTMLElement>) => void;
  formik: FormikProps<Record<string, unknown>>;
}

export default class PageInvokerModal<P extends Record<string, unknown>> extends PureComponent<
  PageInvokerProps<P>,
  PageInvokerState<P>
> {
  static contextType = AppContext;

  // eslint-disable-next-line react/static-property-placement
  declare context: React.ContextType<typeof AppContext>;
  private container?: Container;

  containerRef: MutableRefObject<HTMLElement | null>;

  constructor(props: PageInvokerProps<P>) {
    super(props);

    this.state = {
      showModal: false,
      valid: false,
      saveDisabled: true,
      saving: false,
    };

    this.containerRef = createRef();

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
  }

  handleOpen(_event: MouseEvent, additionalProps: P & Pick<ModalHeaderProps, 'title'>): void {
    if (additionalProps) {
      this.setState({additionalProps});
    }

    this.setState({showModal: true, parentFormDirtyFlag: this.context.store.prefetcher.formIsDirty});
    PubSub.publish('FORM.DIRTY', {dirty: false}, {immediate: true});
  }

  handleClose(evt?: React.MouseEvent<HTMLElement>): void {
    // if there were not changes in the parent but this page in modal has some changes,
    // then publish dirty event to pubsub to make parent form dirty
    if (this.container?.formik?.dirty && this.state.parentFormDirtyFlag === undefined) {
      PubSub.publish('FORM.DIRTY', {dirty: true}, {immediate: true});

      // otherwise set the previous value of parent form state
    } else {
      PubSub.publish('FORM.DIRTY', {dirty: this.state.parentFormDirtyFlag}, {immediate: true});
    }

    this.setState({showModal: false});

    this.props.onClose?.(evt);
  }

  handleFormReset(): void {
    const {resetForm} = this.context.store.prefetcher;

    if (typeof resetForm === 'function') {
      resetForm();
    } else {
      // Reset formIsDirty on submit to prevent displaying confirm leave page warning
      PubSub.publish('FORM.DIRTY', {dirty: false}, {immediate: true});
    }
  }

  async handleSave(evt: React.MouseEvent<HTMLElement>): Promise<void> {
    if (this.props.onSave) {
      const payload = this.container?.getPayload();

      this.props.onSave(payload);

      this.handleFormReset(); // Reset Form before closing to prevent cancel leave confirmation from prefetcher on navigation
      this.handleClose();

      return;
    }

    await reactUtils.setStateAsync({saving: true}, this);
    this.container?.handleSave?.(evt);
  }

  async handleDone(...args: unknown[]): Promise<void> {
    // Wait for progress on save button to finish
    await new Promise(onSaveDone => this.setState({onSaveDone, saving: false}));

    this.handleClose();

    this.props.onDone?.(...args);
  }

  handleValidation(saveDisabled: boolean): void {
    this.setState({saveDisabled});
  }

  async handleCancel(): Promise<void> {
    const {formIsDirty} = this.context.store.prefetcher;

    if (formIsDirty) {
      // Cancel confirmation is mounted if form has changes
      const answer = await new Promise(resolve =>
        PubSub.publish('UNSAVED.WARNING', {resolve, ...this.props.unsavedWarningData}),
      );

      if (answer === 'cancel') {
        return;
      }

      this.handleFormReset();
    }

    this.handleClose();
  }

  render() {
    const {
      props: {
        children,
        doneButtonProps,
        saveButtonProps,
        cancelButtonProps,
        isInProgress,
        edit,
        // Destructure <Modal.Header> props
        title,
        noCloseIcon,
        // Destructure <Modal.Content> props
        insensitive,
        notScrollable,
        noPaddings,
        gap,
        container,
        containerProps,
        noFooter,
        noModalFooter,
        pageInvokerRef, // passed down from selector to prevent selector from closing on mousedown within modal
        onSave,
        ...modalProps
      },
      state: {showModal, saveDisabled, additionalProps, saving, onSaveDone},
    } = this;
    let headerProps = {title, noCloseIcon};
    const contentProps = {insensitive, notScrollable, noPaddings, gap};
    const modal = {...defaultModalProps, ...modalProps, onClose: this.handleCancel};

    const content = createElement(container, {
      ref: (containerInstance: Container) => {
        this.container = containerInstance;
      },
      onValid: this.handleValidation,
      ...containerProps,
      ...additionalProps,
      ...(onSave ? {} : {onDone: this.handleDone}),
    });

    if (additionalProps) {
      headerProps = {...headerProps, title: additionalProps.title};
    }

    return (
      <>
        {children({handleOpen: this.handleOpen})}
        {showModal && (
          <Modal ref={pageInvokerRef} {...modal}>
            <Modal.Header {...headerProps} />
            <Modal.Content {...contentProps}>{content}</Modal.Content>
            <Modal.Footer>
              {edit ? (
                <>
                  <Button onClick={this.handleCancel} {...defaultCancelButtonProps} {...cancelButtonProps} />
                  <Button
                    onClick={this.handleSave}
                    progress={isInProgress || saving}
                    onProgressDone={onSaveDone}
                    disabled={saveDisabled}
                    {...defaultSaveButtonProps}
                    {...saveButtonProps}
                  />
                </>
              ) : (
                <Button onClick={this.handleClose} {...defaultDoneButtonProps} {...doneButtonProps} />
              )}
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}
