/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import '../MapGraph.css';
import {domUtils} from '@illumio-shared/utils';
import {getIconDataUri} from 'components/Icon/IconUtils';
import {getEndpointTypeName, getEnforcementMode} from '../../Utils/MapTrafficQueryResponseUtils';
import {DELETED_COMBO_ID, isDeletedId, truncateString} from './MapGraphUtils';
import {getComboGroupTypeFromId, getComboId, getComboLabelIdsFromId, isChildCombo} from './MapGraphComboUtils';
import type {CombinedPolicyDecision, PolicyVersion} from '../../MapPolicyUtils';
import type {Donut, Glyph, Item, Node, NodeLabelContainer} from 'regraph';
import type {MapMode} from '../../MapTypes';
import type {
  ColorBlindType,
  ComboColorOptions,
  ComboId,
  ComboItems,
  ComboLabelId,
  ComboMapping,
  ComboParent,
  ComboState,
  GraphCombo,
  GraphCombosAndManagedEndpoints,
  GraphContentType,
  GraphLinks,
  GraphManagedEndpoint,
  GraphSelection,
  GraphUnmanagedEndpoint,
  GraphUnmanagedEndpoints,
  LabelType,
  Position,
  RegraphCombine,
  TypedLink,
  TypedNode,
  UnmanagedAddress,
  UnmanagedAddresses,
  UnmanagedEndpointPositions,
  UnmanagedFQDNs,
  UnmanagedIpLists,
  LabelHrefToServerRoleMap,
  VulnerabilityOccurence,
  VulnerabilityData,
} from '../MapGraphTypes';
import {getProtectionSchemaIcon} from 'antman/containers/Servers/ServerLabeling/ServersLabelingUtils';
import {colorUtils} from '@illumio-shared/utils/shared';

export const nodeSizeBase = 0.8;
export const comboNodeSizeBase = 0.8;
export const countGlyphSize = 2;
export const comboSuperAppGroupSize = 2.4;
export const comboNodeBorderWidth = 1.5;
export const comboNodeDonutWidth = 6;
export const managedNodeSizeBase = 0.8;
export const managedNodeBorderWidth = 4;
export const unmanagedNodeSizeBase = 0.8;
export const unmanagedNodeSizeMin = 0.8;
export const unmanagedNodeSizeMax = 3;
export const unmanagedNodeBorderWidth = 15;
export const labelTruncationSize = 20;
export const labelComboTruncationSize = 22;

export const maxLinkWidth = 100;
export const closedLinkFactor = 2;
export const openLinkFactor = 3;
export const defaultLinkWidth = 1;

export const getLinkWidth = (type: string, zoom: number): number => {
  return _.clamp(
    (type === 'open' ? openLinkFactor : closedLinkFactor) / Math.sqrt(zoom),
    defaultLinkWidth,
    maxLinkWidth,
  );
};

// Load color variables from CSS via document object
export const getGraphCSSVariables = (allCssVariables: Record<string, string>, prefix = ''): Record<string, string> => {
  const cssVariables = {} as Record<string, string>;

  for (const [key, value] of Object.entries(allCssVariables)) {
    if (key.startsWith(`--${prefix}`)) {
      cssVariables[key] = value.startsWith('rgb') ? colorUtils.convertSpaceRGBToComma(value) : value;
    }
  }

  return cssVariables;
};

export const colorsMap = getGraphCSSVariables(domUtils.getCSSVariables());

const comboColors: Record<string, ComboColorOptions> = {
  app: {
    closed: colorsMap['--map-combo-app-closed'],
    open: colorsMap['--map-combo-app-open'],
    appGroupMapClosed: colorsMap['--map-combo-app-closed'],
    appGroupMapOpen: colorsMap['--map-combo-app-open'],
  },
  role: {
    closed: colorsMap['--map-combo-role-closed'],
    open: colorsMap['--map-combo-role-open'],
    appGroupMapClosed: colorsMap['--map-combo-role-closed'],
    appGroupMapOpen: colorsMap['--map-combo-role-open'],
  },
  env: {
    closed: colorsMap['--map-combo-env-closed'],
    open: colorsMap['--map-combo-env-open'],
    appGroupMapClosed: colorsMap['--map-combo-env-closed'],
    appGroupMapOpen: colorsMap['--map-combo-env-open'],
  },
  loc: {
    closed: colorsMap['--map-combo-loc-closed'],
    open: colorsMap['--map-combo-loc-open'],
    appGroupMapClosed: colorsMap['--map-combo-loc-closed'],
    appGroupMapOpen: colorsMap['--map-combo-loc-open'],
  },
  nodes: {
    closed: colorsMap['--map-combo-nodes-closed'],
    open: colorsMap['--map-combo-nodes-open'],
    appGroupMapOpen: colorsMap['--map-combo-nodes-appGroupMap-open'],
    appGroupMapClosed: colorsMap['--map-combo-nodes-appGroupMap-closed'],
  },
  appGroup: {
    closed: colorsMap['--map-combo-appGroup-closed'],
    open: colorsMap['--map-combo-appGroup-open'],
    appGroupMapOpen: colorsMap['--map-combo-appGroup-appGroupMap'],
    appGroupMapClosed: colorsMap['--map-combo-appGroup-appGroupMap'],
  },
};

const dropdownIconColor = colorsMap['--map-black-background'];

// Load Icon URI statically
export const icons: Record<string, string> = {
  dropdownDataUri: getIconDataUri('down', dropdownIconColor),
  selectLinkGlyphUri: getIconDataUri('selected', ''),
  cloudGlyphUri: getIconDataUri('internet', colorsMap['--lightning--blue-gray-600']),
  syncGlyphUri: getIconDataUri('syncing', colorsMap['--lightning--blue-gray-600']),
};

export const getLinkColor = (
  policyDecision: CombinedPolicyDecision,
  colorBlind: ColorBlindType,
  isFlowNA = false,
): string => {
  if (isFlowNA) {
    return colorsMap['--map-map-link-default'];
  }

  switch (policyDecision) {
    case 'blocked':
    case 'blockedByBoundary':
      return colorBlind === 'normal'
        ? colorsMap['--map-map-link-blocked']
        : colorsMap['--map-map-link-deficiency-blocked'];
    case 'potentiallyBlocked':
    case 'potentiallyBlockedByBoundary':
      return colorsMap['--map-map-link-potentially-blocked'];
    case 'allowed':
    case 'allowedAcrossBoundary':
      return colorBlind === 'normal'
        ? colorsMap['--map-map-link-allowed']
        : colorsMap['--map-map-link-deficiency-allowed'];
    case 'vulnerable':
      return colorsMap['--map-map-link-vulnerable'];
    case 'potentiallyBlockedVulnerable':
      return colorsMap['--map-map-link-potentiallyBlockedVulnerable'];
    case 'loading':
      return 'white';
    case 'notVulnerable':
    default:
      return colorsMap['--map-map-link-default'];
  }
};

export const getVulnerabilityColor = (vulnerability: string | undefined): string => {
  switch (vulnerability) {
    case 'critical':
      return colorsMap['--map-combo-vulnerability-critical'];
    case 'high':
      return colorsMap['--map-combo-vulnerability-high'];
    case 'medium':
      return colorsMap['--map-combo-vulnerability-medium'];
    case 'low':
      return colorsMap['--map-combo-vulnerability-low'];
    case 'info':
      return colorsMap['--map-combo-vulnerability-info'];
    case 'none':
    default:
      return colorsMap['--map-combo-vulnerability-none'];
  }
};

export const getVulnerabilityDonut = (vulnerabilityOccurence: VulnerabilityOccurence): Donut | undefined => {
  const segments = Object.entries(vulnerabilityOccurence || {}).map(([key, value]) => ({
    color: getVulnerabilityColor(key),
    size: value,
  }));
  const vulnerabilityDonutWidth = Object.keys(vulnerabilityOccurence || {}).length === 0 ? 0 : comboNodeDonutWidth;
  const vulnerabilityDonut =
    (segments.length !== 0 && {segments, width: vulnerabilityDonutWidth, border: {width: comboNodeBorderWidth}}) ||
    undefined;

  return vulnerabilityDonut;
};

export const getShowVESScore = (vulnerabilityOccurence: VulnerabilityOccurence): string => {
  const vulnerabilityColorKey = vulnerabilityOccurence && Object.keys(vulnerabilityOccurence)[0];

  return vulnerabilityColorKey;
};

export const getVESScoreTextOrIcon = (
  vulnerabilityData: VulnerabilityData,
):
  | {fontIcon: {fontFamily: string; text: string; color: string}}
  | {text: number | string; color: string}
  | undefined => {
  const vulnerabilityComputationState = vulnerabilityData.computationState;
  const vulnerabilityOccurence = vulnerabilityData.vulnerabilityScore;
  const colorKey = vulnerabilityOccurence && getShowVESScore(vulnerabilityOccurence);

  if (colorKey && Object.keys(vulnerabilityOccurence).length !== 0) {
    switch (vulnerabilityComputationState) {
      case 'in_sync':
        return {text: vulnerabilityData.vesScore, color: colorsMap['--lightning--white']};
      case 'not_applicable':
        return {text: intl('Common.NA'), color: colorsMap['--lightning--white']};
      case 'syncing':
        return {fontIcon: {fontFamily: 'sync-icon', text: '\u{E800}', color: colorsMap['--lightning--white']}};
    }
  }

  return undefined;
};

export const getFontFromZoom = (zoom: number): number => {
  // xpress product prefers smaller font size with untruncated labels; upper bound isn't modified preserve scaling behavior
  const base = zoom > 1 ? 8 : 9;
  const exponent = 1 / Math.sqrt(Math.sqrt(zoom));
  const minFontSize = __ANTMAN__ ? 14 : 0;
  const maxFontSize = 36;

  return _.clamp(base ** exponent, minFontSize, maxFontSize);
};

// Styles for the Managed Endpoints
export const getManagedEndpointStyle = (
  endpoint: GraphManagedEndpoint,
  basicMode: boolean,
  isCSFrame: boolean,
  mapMode: MapMode,
  image?: string,
): Node => {
  const endpointStyle: Node = {};
  const endpointLabel = endpoint.name;
  const iconColor = colorsMap['--map-endpoint-workload'];
  const nodeColor = colorsMap['--map-endpoint-workload-background'];
  const vulnerabilityData = endpoint?.vulnerabilityData;
  const endpointVulnerabilityScore = vulnerabilityData?.vulnerabilityScore;
  const showVESScore =
    mapMode === 'vulnerability' && endpointVulnerabilityScore && getShowVESScore(endpointVulnerabilityScore);
  const colorKey = endpointVulnerabilityScore && getShowVESScore(endpointVulnerabilityScore);

  endpointStyle.color = nodeColor;
  endpointStyle.size = managedNodeSizeBase;

  const truncateLength = labelComboTruncationSize * 1.5;
  const text = truncateString(endpointLabel, truncateLength);

  const minWidth = 20 * text.length;
  const minHeight = 100;

  const endpointTextLabel = {
    text,
    fontSize: 'auto',
    minWidth,
    minHeight,
    maxWidth: 800,
    textWrap: 'normal',
    position: 's',
    padding: {
      top: 2,
      right: 4,
      bottom: showVESScore ? 20 : 2,
      left: 4,
    },
    border: {
      radius: 3,
    },
    backgroundColor: colorsMap['--map-transparent-background'],
  };

  const vesScoreLabel = {
    bold: true,
    fontSize: 16,
    minWidth: 40,
    maxWidth: 75,
    margin: {
      top: -20,
      right: 10,
      bottom: 1,
      left: 10,
    },
    padding: {
      top: 1,
      right: 0,
      bottom: 1,
      left: 0,
    },
    border: {
      radius: 10,
    },
    backgroundColor: getVulnerabilityColor(colorKey) || colorsMap['--map-transparent-background'],
  };

  if (showVESScore && getVESScoreTextOrIcon(vulnerabilityData)) {
    Object.assign(vesScoreLabel, getVESScoreTextOrIcon(vulnerabilityData));
    endpointStyle.label = [endpointTextLabel, vesScoreLabel] as NodeLabelContainer[];
  } else {
    endpointStyle.label = endpointTextLabel as NodeLabelContainer;
  }

  if (endpoint.managedType === 'workload') {
    endpointStyle.glyphs = [
      {
        image: getIconDataUri('workload', iconColor),
        size: 1.8,
        radius: 0,
        angle: 0,
      },
    ];

    // Add style for container workload/ idle workload/ unmanaged workload
    if (endpoint.managedType === 'workload') {
      switch (endpoint.subType) {
        case 'idle':
          endpointStyle.glyphs[0].image = getIconDataUri('idle', iconColor);
          break;
        case 'unmanaged':
          endpointStyle.glyphs[0].image = getIconDataUri('unmanaged', iconColor);
          break;
        case 'containerWorkload':
          endpointStyle.glyphs[0].image = getIconDataUri('container-workload', iconColor);
          break;
        case 'kubernetesWorkload':
          endpointStyle.glyphs[0].image = getIconDataUri('kubernetes-workload', iconColor);
          break;
        case 'deleted':
          endpointStyle.glyphs[0].image = getIconDataUri('delete', iconColor);
          break;
      }

      // Add style for vulnerability
      if (mapMode === 'vulnerability' && !['unmanaged', 'deleted'].includes(endpoint.subType)) {
        endpointStyle.donut = getVulnerabilityDonut(
          endpoint.vulnerabilityData?.vulnerabilityScore as VulnerabilityOccurence,
        );

        if (endpoint.vulnerabilityData?.internetExposure) {
          endpointStyle.glyphs[1] = {
            size: 2,
            radius: 50,
            angle: 50,
            image: getIconDataUri('internet', colorsMap['--lightning--blue-gray-600']),
          };
        }
      }

      // Add style for enforcement state
      // Do not apply enforcement mode border style for the unmanaged workload
      if (endpoint.subType !== 'unmanaged' && mapMode === 'policy') {
        switch (endpoint.mode) {
          case 'visibility_only':
            endpointStyle.border = {
              width: managedNodeBorderWidth,
              color: basicMode ? nodeColor : colorsMap['--map-combo-mode-visibility-only'],
            };
            break;

          case 'selective':
            endpointStyle.border = {
              width: managedNodeBorderWidth,
              color: basicMode ? nodeColor : colorsMap['--map-combo-mode-selective'],
            };
            break;

          case 'full':
            endpointStyle.border = {
              width: managedNodeBorderWidth,
              color: basicMode ? nodeColor : colorsMap['--map-combo-mode-full'],
            };
            break;
        }
      }

      // CloudSecure has its own icon for unmanaged workloads (all workloads are unmanaged by now)
      if (endpoint.subType === 'unmanaged' && isCSFrame) {
        endpointStyle.glyphs[0].image = getIconDataUri('cloud-workload', iconColor);
      }

      if (__ANTMAN__ && image) {
        endpointStyle.glyphs[0].image = image;
      }
    }
  } else if (endpoint.managedType === 'virtualService') {
    endpointStyle.glyphs = [
      {
        image: getIconDataUri('virtual-service', iconColor),
        size: 2,
        radius: 0,
        angle: 0,
      },
    ];
  } else if (endpoint.managedType === 'virtualServer') {
    endpointStyle.glyphs = [
      {
        image: getIconDataUri('virtual-server', iconColor),
        size: 2,
        radius: 0,
        angle: 0,
      },
    ];
  }

  return endpointStyle;
};

export const getComboLabelColor = (
  grouping: string,
  labelTypes: LabelType[],
  isDeleted: boolean,
): string | undefined => {
  const labelType = labelTypes.find(type => type?.key === grouping);

  if (isDeleted) {
    return colorsMap['--lightning--white'];
  }

  // Use user-defined foreground color for group label
  if (!labelType) {
    return;
  }

  return labelType.display_info?.foreground_color_rgb;
};

export const getComboIcon = (grouping: string, labelTypes: LabelType[]): string | undefined => {
  if (grouping === 'appGroup') {
    return 'illumination';
  }

  const labelType = labelTypes.find(type => type?.key === grouping);

  // Use user-defined foreground color for group label
  if (!labelType) {
    return;
  }

  return labelType.display_info?.icon;
};

export const getComboInitial = (grouping: string, labelTypes: LabelType[]): string | undefined => {
  const labelType = labelTypes.find(type => type?.key === grouping);

  // Use user-defined foreground color for group label
  if (!labelType) {
    return;
  }

  return labelType.display_info?.initial;
};

// Styles for the Combos
export const getComboColor = (
  grouping: string,
  state: ComboState = 'closed',
  labelTypes: LabelType[],
): string | undefined => {
  const labelType = labelTypes.find(type => type?.key === grouping);

  // Apply the group style to customer defined label type
  if (labelType?.display_info) {
    return labelType.display_info?.background_color;
  }

  // // Apply the group style to the following label type: app, env, loc and app
  if (comboColors[grouping]) {
    return comboColors[grouping][state];
  }

  return colorsMap['--map-combo-default'];
};

export const getComboMode = (endpoints: Record<string, GraphManagedEndpoint>): string[] => {
  const endpointDetails = Object.values(endpoints);
  const modeArray = endpointDetails.map(endpointDetail => {
    if (endpointDetail.subType !== 'unmanaged') {
      return endpointDetail.mode;
    }

    return null;
  });

  return _.compact(modeArray);
};

export const getComboModeColor = (enforcementMode: string): string => {
  switch (enforcementMode) {
    case 'visibility_only':
      return colorsMap['--map-combo-mode-visibility-only'];
    case 'selective':
      return colorsMap['--map-combo-mode-selective'];
    case 'full':
      return colorsMap['--map-combo-mode-full'];
    case 'idle':
    default:
      return colorsMap['--map-mode-default'];
  }
};

export const getComboModeWidth = (modeArray: string[]): number => {
  //virtual services will have no donut
  if (modeArray.length === 0) {
    return 0;
  }

  return comboNodeDonutWidth;
};

export const getComboModeDonut = (nodes: Record<string, GraphManagedEndpoint>): Donut => {
  const modeArray = getComboMode(nodes);
  const modeOrder = ['idle', 'visibility_only', 'selective', 'full'];

  modeArray.sort((a, b) => modeOrder.indexOf(a) - modeOrder.indexOf(b));

  const modeDonutWidth = getComboModeWidth(modeArray);
  const modeOccurrence = _.countBy(modeArray);
  const segments = Object.entries(modeOccurrence).map(([key, value]) => ({color: getComboModeColor(key), size: value}));
  const modeDonut = {segments, width: modeDonutWidth, border: {width: comboNodeBorderWidth}};

  return modeDonut;
};

export const getComboIds = (node: string): string[] | string => {
  const comboMatch = /^_.*_/;

  if (!node.match(comboMatch)?.length) {
    return node;
  }

  const nodeIds = node.replace(comboMatch, '');

  return nodeIds.split(',').map(label => {
    const [key, id] = label.split(':');

    return id === 'discovered' ? key : id;
  });
};

export const getGroupTypeName = (groupType: string, labelTypes: LabelType[]): string => {
  switch (groupType) {
    case 'nodes':
      return intl('IlluminationMap.LabelSet');
    case 'appGroup':
      return intl('Common.AppGroup');
    default:
      return labelTypes.find(type => type.key === groupType)?.display_name || '';
  }
};

export const getInnerComboType = (focusComboId: ComboId, combine: RegraphCombine): string => {
  const focusType = getComboGroupTypeFromId(focusComboId);
  const focusIndex = combine.properties.indexOf(focusType);

  return combine.properties[focusIndex - 1];
};

export const getInnerComboCount = (comboIdsArray: string[], focusComboId: ComboId, combine: RegraphCombine): number => {
  const focusIds = getComboLabelIdsFromId(focusComboId).split(',');
  const innerComboType = getInnerComboType(focusComboId, combine);

  const matchingCombos = comboIdsArray.filter(comboId => {
    return (Array.isArray(focusIds) ? focusIds : []).every(id => {
      const focusLabelIdRegex = new RegExp(`${id}\\b`);

      return comboId.includes(`_${innerComboType}_`) && comboId.match(focusLabelIdRegex);
    });
  });

  return matchingCombos.length;
};

export const getClosedComboInnerLabel = (
  isSuperAppGroup: boolean,
  isNode: boolean,
  isDeleted: boolean,
  numOfEndpoints: number,
  mapMode: MapMode,
  isInternetExposure: boolean,
  glyphLabelColor?: string | undefined,
  glyphIcon?: string | undefined,
  glyphInitial?: string | undefined,
  labelStyle?: string | undefined,
  image?: string,
): Glyph[] => {
  let newGlyphStyle: Glyph[] = [];

  if (isNode || isDeleted || isSuperAppGroup) {
    newGlyphStyle = [
      {
        size: numOfEndpoints < 1000 ? 3 : 2,
        angle: 0,
        radius: 0,
        color: colorsMap['--map-transparent-background'],
        ...(image && {image}),
        ...(!image && {
          label: {
            text: String(numOfEndpoints),
            color:
              isSuperAppGroup || mapMode === 'vulnerability'
                ? colorsMap['--map-combo-nodes-closed']
                : colorsMap['--map-white-background'],
          },
        }),
      },
    ];
  } else {
    const glyphColor = glyphLabelColor || colorsMap['--map-white-background'];
    const glyphStyle: Glyph = {
      size: 2.5,
      angle: 180,
      radius: 0,
      color: colorsMap['--map-transparent-background'],
    };

    if (glyphIcon && labelStyle !== 'initial') {
      glyphStyle.image = getIconDataUri(glyphIcon, glyphColor);
    } else {
      glyphStyle.label = {
        text: glyphInitial ?? String(numOfEndpoints),
        color: glyphColor,
      };
    }

    const cloudGlyphStyle = {
      size: 2,
      angle: 50,
      radius: 50,
      image: getIconDataUri('internet', colorsMap['--lightning--blue-gray-600']),
    };

    newGlyphStyle = [glyphStyle];

    if (mapMode === 'vulnerability' && isInternetExposure) {
      newGlyphStyle.push(cloudGlyphStyle);
    }
  }

  return newGlyphStyle;
};

export const getUpdatedGlyphs = (
  combo: GraphCombo,
  labelTypes: LabelType[],
  labelStyle: string,
  mapMode: MapMode,
  image?: string,
): Glyph[] => {
  const isSuperAppGroup = combo.id.includes('_superAppGroups_');
  const comboGrouping = getComboGroupTypeFromId(combo.id);
  const isNode = comboGrouping === 'nodes';
  const isDeleted = isDeletedId(combo.id);
  const countValue = isSuperAppGroup ? combo.appGroupCount : combo.endpointCount;
  const isInternetExposure = (mapMode === 'vulnerability' && combo.vulnerabilityData?.internetExposure) || false;
  const glyphLabelColor =
    mapMode === 'vulnerability' && !isDeleted
      ? colorsMap['--lightning--blue-gray-600']
      : getComboLabelColor(comboGrouping, labelTypes, isDeleted);
  const glyphIcon = getComboIcon(comboGrouping, labelTypes);
  const glyphInitial = getComboInitial(comboGrouping, labelTypes);
  const updatedImage = isDeleted ? getIconDataUri('delete', glyphLabelColor) : image;

  return getClosedComboInnerLabel(
    isSuperAppGroup,
    isNode,
    isDeleted,
    countValue || 0,
    mapMode,
    isInternetExposure,
    glyphLabelColor,
    glyphIcon,
    glyphInitial,
    labelStyle,
    updatedImage,
  );
};

export const getComboDonut = (mapMode: MapMode, combo: GraphCombo): Donut | undefined => {
  if (mapMode === 'vulnerability' && !isDeletedId(combo.id) && combo.vulnerabilityData?.vulnerabilityScore) {
    return getVulnerabilityDonut(combo.vulnerabilityData?.vulnerabilityScore);
  }

  if (mapMode === 'policy') {
    return getComboModeDonut(combo.endpoints.workload || ({} as Record<string, GraphManagedEndpoint>));
  }

  return undefined;
};

export const getTruncatedComboName = (
  comboData: GraphCombo,
  truncationSize: number = labelComboTruncationSize,
): string => {
  if (comboData?.id?.includes('_appGroup_') || comboData?.id?.includes('_nodes_')) {
    return comboData.name;
  }

  return truncateString(comboData?.fullName, truncationSize);
};

export const getComboTextLabel = (
  comboData: GraphCombo,
  isClicked: boolean,
  isHovered: boolean,
  font: number,
  showVESScore?: string | false,
): NodeLabelContainer => {
  const isSuperAppGroup = (comboData?.id || '').includes('superAppGroup');

  // Break each line in the hovered tex into multiple lines at 20 characters
  let fullName = isHovered
    ? comboData?.fullName
        .split('\n')
        .map(line =>
          _.chunk([...(line || '')], 20)
            .map(group => group.join(''))
            .join('\n'),
        )
        .join('\n')
    : comboData?.fullName;

  if (isSuperAppGroup) {
    fullName = comboData?.fullName.replace(' App Groups', '\nApp Groups');
  }

  // Only truncate the non-hovered case. Increase the truncation length as we zoome
  const text =
    isHovered || isSuperAppGroup
      ? fullName
      : getTruncatedComboName(comboData, Math.max((labelComboTruncationSize * 3) / Math.sqrt(font), 15));

  let textLength;

  if (isSuperAppGroup) {
    textLength = 5;
  } else {
    textLength =
      text.split('\n').reduce((result, line) => {
        return Math.max(result, line.length);
      }, 0) || 0;
  }

  // Reduce the super app group font, make the hovered font larger
  const minHeightFactor = isSuperAppGroup ? Math.sqrt(font) / 2 : isHovered ? 3 * Math.sqrt(font) : 2 * Math.sqrt(font);
  // For multi-line labels give enough height for each line, but make them each slightly smaller
  const minHeight = fullName?.includes('\n') ? fullName.split('\n').length * minHeightFactor * 4 : minHeightFactor * 6;

  // Hovered text will be broken into 20 character lines, allow more width for each character up to the max of 20
  const minWidth = isHovered ? (textLength < 20 ? textLength * 1.5 : 30) * font : textLength * 4.5 * Math.sqrt(font);

  return {
    text,
    fontSize: 'auto',
    minWidth,
    maxWidth: 1000,
    minHeight,
    textWrap: 'normal',
    position: 's',
    padding: {
      top: showVESScore ? -5 : 2,
      right: 4,
      bottom: showVESScore ? 20 : 2,
      left: 4,
    },
    border: {
      radius: 3,
    },
    backgroundColor: isClicked
      ? colorsMap['--map-interaction-select']
      : isHovered
      ? 'white'
      : colorsMap['--map-transparent-background'],
    color: colorsMap['--map-combo-label-color'],
  };
};

export const getCombinedComboLabel = (
  comboData: GraphCombo,
  isClicked: boolean,
  isHovered: boolean,
  comboVulnerabilityData: VulnerabilityData | undefined,
  font: number,
  mapMode: MapMode,
): NodeLabelContainer | NodeLabelContainer[] => {
  const isCombo = comboData?.type === 'combo';
  const showVESScore =
    mapMode === 'vulnerability' && comboVulnerabilityData && getShowVESScore(comboVulnerabilityData.vulnerabilityScore);
  const textLabel = getComboTextLabel(comboData, isClicked, isHovered, font, showVESScore);
  const colorKey =
    comboVulnerabilityData?.vulnerabilityScore && getShowVESScore(comboVulnerabilityData.vulnerabilityScore);
  const vesScoreLabel = {
    bold: true,
    fontSize: isCombo ? 15 : 35,
    minWidth: isCombo ? 40 : 90,
    maxWidth: isCombo ? 65 : 105,
    margin: {top: -25, bottom: 2},
    padding: {
      top: 2,
      right: 0,
      bottom: 2,
      left: 0,
    },
    border: {
      radius: isCombo ? 10 : 20,
    },
    backgroundColor: getVulnerabilityColor(colorKey) || colorsMap['--map-transparent-background'],
  };

  if (showVESScore && getVESScoreTextOrIcon(comboVulnerabilityData)) {
    Object.assign(vesScoreLabel, getVESScoreTextOrIcon(comboVulnerabilityData));

    return [textLabel, vesScoreLabel];
  }

  return textLabel;
};

export const getComboStyle = (
  combo: GraphCombo,
  labelTypes: LabelType[],
  labelStyle: string,
  zoom: number,
  mapMode: MapMode,
  image?: string,
): Node => {
  const grouping = getComboGroupTypeFromId(combo.id);
  const isNode = grouping === 'nodes';
  const isSuperAppGroup = combo.id.includes('_superAppGroups_');
  let size = isNode ? nodeSizeBase : comboNodeSizeBase;
  const nodeColor = getComboColor(grouping, 'closed', labelTypes);

  if (isSuperAppGroup) {
    size = (1.5 * size) / zoom;
  }

  if (combo.id === DELETED_COMBO_ID) {
    size = 1.5;
  }

  return {
    size,
    color: mapMode === 'vulnerability' && !isDeletedId(combo.id) ? colorsMap['--map-white-background'] : nodeColor,
    donut: getComboDonut(mapMode, combo),
    glyphs: getUpdatedGlyphs(combo, labelTypes, labelStyle, mapMode, image),
    label: getCombinedComboLabel(combo, false, false, undefined, getFontFromZoom(zoom), 'policy'),
  };
};

// Styles for the Unmanaged Endpoints
export const getUnmanagedEndpointStyle = (endpoint: GraphUnmanagedEndpoint): Node => {
  const endpointStyle: Node = {};
  const iconColor = colorsMap['--map-endpoint-unmanagedWorkload'];
  const nodeColor = colorsMap['--lightning--blue-gray-600'];

  if (Object.keys(endpoint.items).length === 0) {
    return endpointStyle;
  }

  const name = getEndpointTypeName[endpoint.type] || '';

  endpointStyle.label = {
    text: name,
    fontSize: 'auto',
    minWidth: 75,
    maxWidth: 800,
    textWrap: 'normal',
    position: 's',
    padding: {
      top: 2,
      right: 4,
      bottom: 2,
      left: 4,
    },
    border: {
      radius: 3,
    },
    backgroundColor: colorsMap['--map-transparent-background'],
  };

  endpointStyle.color = nodeColor;

  endpointStyle.border = {color: colorsMap['--map-endpoint-unmanagedWorkload-border'], width: unmanagedNodeBorderWidth};
  endpointStyle.size = 1.5;

  endpointStyle.glyphs = [
    {
      size: 1.8,
      radius: 0,
      angle: 0,
    },
  ];

  switch (endpoint.type) {
    case 'ipList':
      endpointStyle.glyphs[0].image = getIconDataUri('ip-lists', iconColor);
      break;

    case 'fqdn':
      endpointStyle.glyphs[0].image = getIconDataUri('map', iconColor);
      break;

    case 'internet':
      endpointStyle.glyphs[0].image = getIconDataUri('internet', iconColor);
      break;

    case 'privateAddress':
      endpointStyle.glyphs[0].image = getIconDataUri('private-address', iconColor);
      break;
  }

  return endpointStyle;
};

const getPositionX = (startX: number, endX: number, length: number, index: number): number => {
  const absDifferenceInX = Math.abs(endX - startX);

  if (absDifferenceInX < 200) {
    const centerX = (endX - startX) / 2;

    endX = centerX + 200;
    startX = centerX - 200;
  }

  const step = (endX - startX) / (length - 1);

  return startX + step * index;
};

export const getUnmanagedEndpointPositions = (
  positionsObject: Record<string, Position>,
): UnmanagedEndpointPositions => {
  const unmanagedEndpointOrder = ['ipList', 'fqdn', 'privateAddress', 'internet'] as const;
  const availableUnmanaged = unmanagedEndpointOrder.filter(endpoint => positionsObject[endpoint]);
  const managedPositionsObject = {...positionsObject};

  availableUnmanaged.forEach(unmanaged => delete managedPositionsObject[unmanaged]);

  const positionsXY = Object.values(managedPositionsObject);
  const startY = Math.min(...positionsXY.map(position => position.y)) - 200;
  const startX = Math.min(...positionsXY.map(position => position.x));
  const endX = Math.max(...positionsXY.map(position => position.x));
  const unManagedLength = availableUnmanaged.length;

  const unmanagedFixedPosition = availableUnmanaged.reduce((result, endpoint, index) => {
    if (unManagedLength === 1) {
      result[endpoint] = {x: (startX + endX) / 2, y: startY};
    } else {
      const newX = getPositionX(startX, endX, unManagedLength, index);

      result[endpoint] = {x: newX, y: startY};
    }

    return result;
  }, {} as UnmanagedEndpointPositions);

  return unmanagedFixedPosition;
};

// Selection Styles
export const getSelectionStyle = (
  type: string,
  zoom: number,
  hidden: boolean,
  id: string,
  chartItemDetails: Node | undefined,
): object => {
  let selectStyles = {};

  if (
    (type === 'combo' && id.includes('superAppGroup')) ||
    type === 'managedEndpoint' ||
    type === 'unmanagedEndpoint'
  ) {
    if (type === 'managedEndpoint' && chartItemDetails && chartItemDetails.donut) {
      const textLabel = chartItemDetails?.label as NodeLabelContainer[];

      textLabel[0].backgroundColor = colorsMap['--map-interaction-select'];
      textLabel[0].border = {
        color: colorsMap['--map-interaction-select'],
        radius: 3,
      };
      textLabel[0].fontSize = 14;

      selectStyles = {
        ...chartItemDetails,
        label: chartItemDetails.label,
        halos: [
          {
            color: colorsMap['--map-interaction-select'],
            radius: 34,
            width: 20,
          },
        ],
      };
    } else {
      selectStyles = {
        label: {
          backgroundColor: colorsMap['--map-interaction-select'],
          border: {
            color: colorsMap['--map-interaction-select'],
            radius: 3,
          },
        },
        halos: [
          {
            color: colorsMap['--map-interaction-select'],
            radius: 34,
            width: type === 'unmanagedEndpoint' ? 15 : 5,
          },
        ],
      };
    }
  } else if (type === 'link' && !hidden) {
    selectStyles = {
      glyphs: [
        {
          size: _.clamp(0.7 / zoom, 0.7, 5),
          image: icons.selectLinkGlyphUri,
        },
      ],
    };
  }

  return selectStyles;
};

export function getUnmanagedNodeSize(zoom?: number): number {
  return _.clamp(zoom ? 0.6 / zoom : unmanagedNodeSizeBase, unmanagedNodeSizeMin, unmanagedNodeSizeMax);
}

export const appGroupOrders = {
  // Unmanaged endpoint's sequence will start from 0
  unmanagedEndpoint: {hierarchy: 1},
  manangedEndpointSource: {hierarchy: 2, sequence: 2},
  managedEndpointTarget: {hierarchy: 2, sequence: 1},
  // Focused group's sequence will start from 1
  openGroupSource: {hierarchy: 2, sequence: 1},
  openGroupTarget: {hierarchy: 2, sequence: 3},
  consumerOrProviderGroup: {hierarchy: 3},
  consumerGroup: {sequence: 3},
  providerGroup: {sequence: 1},

  focusedGroup: {hierarchy: 2, sequence: 2},
};

export const getManagedEndpointChartItems = (
  endpointItems: GraphCombosAndManagedEndpoints,
  zoom: number,
  labelTypes: LabelType[],
  labelStyle: string,
  isAppGroupMap: boolean,
  basicMode: boolean,
  isCSFrame: boolean,
  labelHrefToServerRoleMap: LabelHrefToServerRoleMap,
  mapMode: MapMode,
): Record<string, TypedNode> => {
  return Object.keys(endpointItems).reduce(
    (result: Record<string, TypedNode>, key: string): Record<string, TypedNode> => {
      const endpointOrCombo = endpointItems[key];
      let style = {};

      let isSuperAppGroup;
      let superAppGroupType;

      if (endpointOrCombo.type !== 'managedEndpoint') {
        isSuperAppGroup = endpointOrCombo.id.includes('_superAppGroups_');

        if (endpointOrCombo.id.includes('source')) {
          superAppGroupType = 'source';
        }

        if (endpointOrCombo.id.includes('target')) {
          superAppGroupType = 'target';
        }
      }

      let image;

      if (__ANTMAN__) {
        // Check to see if label href in workload exists in server roles labels hrefs. Returns server role.
        const labelIcon = endpointOrCombo.labels.find(label => labelHrefToServerRoleMap.get(label?.href));

        if (labelIcon) {
          // Get icon from static icon mapping
          const protectionSchemaIcon = getProtectionSchemaIcon(labelHrefToServerRoleMap.get(labelIcon.href));

          if (protectionSchemaIcon !== 'lock') {
            image = getIconDataUri(protectionSchemaIcon);
          }
        }
      }

      if (endpointOrCombo.type === 'managedEndpoint') {
        style = getManagedEndpointStyle(endpointOrCombo, basicMode, isCSFrame, mapMode, image);
      } else {
        style = getComboStyle(endpointOrCombo, labelTypes, labelStyle, zoom, mapMode, image);
      }

      // For an open group, or a managed workload that belongs to an open group
      // Add source and target level and sequence to data
      result[key] = {
        type: endpointOrCombo.type,
        data:
          isAppGroupMap && !isSuperAppGroup
            ? superAppGroupType === 'source' || endpointOrCombo.data.endType === 'source'
              ? {...endpointOrCombo.data, ...appGroupOrders.manangedEndpointSource}
              : {...endpointOrCombo.data, ...appGroupOrders.managedEndpointTarget}
            : endpointOrCombo.data,
        ...style,
      };

      if (isSuperAppGroup) {
        result[key].label = {
          ...result[key].label,
          text: endpointOrCombo.name,
          fontSize: 'auto',
          minWidth: 120,
          maxWidth: 800,
          textWrap: 'normal',
          position: 's',
          padding: {
            top: 2,
            right: 4,
            bottom: 2,
            left: 4,
          },
          border: {
            radius: 3,
          },
          color: colorsMap['--map-combo-label-color'],
        };
      }

      return result;
    },
    {},
  );
};

export const getUnmanagedEndpointChartItems = (endpointItems: GraphUnmanagedEndpoints): Record<string, TypedNode> => {
  return Object.keys(endpointItems).reduce(
    (result: Record<string, TypedNode>, key: string, index: number): Record<string, TypedNode> => {
      const endpoint = endpointItems[key];

      const style = getUnmanagedEndpointStyle(endpoint);

      // TBD need to add: getUnmanagedEndpointStyle
      result[key] = {
        type: 'unmanagedEndpoint',
        data: {
          ...appGroupOrders.unmanagedEndpoint,
          sequence: index,
        },
        ...style,
      };

      return result;
    },
    {},
  );
};

export const getLinkChartItems = (
  links: GraphLinks,
  policyVersion: PolicyVersion,
  clickedId: string | null,
  selection: GraphSelection,
  combos: ComboMapping,
  colorBlind: ColorBlindType,
  zoom: number,
  isCSFrame: boolean,
  mapMode?: MapMode,
): Record<string, TypedLink> => {
  // Add Link style such as width/label font size
  return Object.keys(links).reduce((result: Record<string, TypedLink>, key: string): Record<string, TypedLink> => {
    const isFlowNA = isCSFrame && links[key].policy.reported?.decision === 'unknown';
    const policyDecision =
      mapMode === 'vulnerability'
        ? links[key].policy[policyVersion]?.vulnerabilityDecision
        : links[key].policy[policyVersion]?.decision;

    result[key] = {...links[key].data, type: 'link'};
    result[key].end2 = {arrow: true};
    result[key].width = getLinkWidth('closed', zoom) * 2;
    result[key].fade = true;
    result[key].color = getLinkColor(policyDecision || 'loading', colorBlind, isFlowNA);

    if (result[key].color === 'white') {
      result[key].end1 = {color: colorsMap['--map-background']};
      result[key].end2 = {arrow: true, color: colorsMap['--map-map-link-default']};
    }

    if (clickedId) {
      result[key].width = (result[key]?.width || 1) / 2;

      const id1 = result[key].id1;
      const id2 = result[key].id2;
      const wasClicked = key === clickedId;

      const connected = clickedId === id1 || clickedId === id2;
      const comboClicked = clickedId && combos.combos[clickedId as ComboId];
      const selectedComboLink = selection?.comboLink?.[0];
      let parentSelected;

      if (selectedComboLink) {
        // This logic highlights the link between an newly opened connected app group and the focused group
        const selectedIds = selectedComboLink.replace('_bidirectional', '').split(';');

        parentSelected =
          isChildCombo(id1 as ComboId, selectedIds[0] as ComboId, combos) &&
          isChildCombo(id2 as ComboId, selectedIds[1] as ComboId, combos);
      }

      let childClicked = false;
      let parentClicked = false;

      if (comboClicked) {
        childClicked =
          isChildCombo(clickedId as ComboId, id1 as ComboId, combos) ||
          isChildCombo(clickedId as ComboId, id2 as ComboId, combos);
        parentClicked =
          !childClicked &&
          (isChildCombo(id1 as ComboId, clickedId as ComboId, combos) ||
            isChildCombo(id2 as ComboId, clickedId as ComboId, combos));
      }

      // Don't fade the clicked, connected, parent, child links
      if (childClicked || wasClicked || connected || parentClicked || parentSelected) {
        result[key].fade = false;
      }
    }

    return result;
  }, {});
};

export const getChartItems = (
  comboItems: ComboItems,
  combos: ComboMapping,
  policyVersion: PolicyVersion,
  zoom: number,
  clickedId: string | null,
  selection: GraphSelection,
  labelTypes: LabelType[],
  labelStyle: string,
  colorBlind: ColorBlindType,
  isAppGroupMap: boolean,
  isCSFrame: boolean,
  basicMode: boolean,
  labelHrefToServerRoleMap: LabelHrefToServerRoleMap,
  mapMode: MapMode,
): Record<string, Item> => {
  return {
    ...getManagedEndpointChartItems(
      comboItems.managedEndpoints,
      zoom,
      labelTypes,
      labelStyle,
      isAppGroupMap,
      basicMode,
      isCSFrame,
      labelHrefToServerRoleMap,
      mapMode,
    ),
    ...getUnmanagedEndpointChartItems(comboItems.unmanagedEndpoints),
    ...getLinkChartItems(
      comboItems.links,
      policyVersion,
      clickedId,
      selection,
      combos,
      colorBlind,
      zoom,
      isCSFrame,
      mapMode,
    ),
  };
};

// Check hovered item's type
export const getItemType = (id: string | null): GraphContentType | '' => {
  if (!id) {
    return '';
  }

  const regexp = /_node*/g;
  const linkIdMatchLength = Array.from(id.matchAll(regexp), m => m[0]).length;

  if (id.includes('_superAppGroups_')) {
    return 'superAppGroups';
  }

  if (id.startsWith('_node') && !id.includes(';') && linkIdMatchLength === 1) {
    return 'node';
  }

  // unmanaged workload-node/ node-unmanaged workload link
  if (id.includes(';')) {
    return 'link';
  }

  // node-node link
  if (id.startsWith('_node') && id.includes(';') && linkIdMatchLength === 2) {
    return 'link';
  }

  if (id.startsWith('_combolink')) {
    return 'comboLink';
  }

  if (id === 'ipList' || id === 'fqdn' || id === 'privateAddress' || id === 'internet') {
    return 'unmanagedEndpoint';
  }

  if (id.startsWith('_href_') || id.startsWith('/')) {
    return 'managedEndpoint';
  }

  if (id.startsWith('_')) {
    return 'combo';
  }

  return '';
};

// Check hovered item's id to see if it needs a tooltip
export const shouldShowTooltip = (id: ComboId, combo?: GraphCombo): boolean => {
  // If a hovered combo / node doesn't have the combo data
  const type = getItemType(id);

  // Workload, unmanaged workload, comboLink and link won't need combo data
  if (!combo && type !== 'managedEndpoint' && type !== 'unmanagedEndpoint' && type !== 'comboLink' && type !== 'link') {
    return false;
  }

  return (
    type === 'combo' ||
    type === 'node' ||
    type === 'managedEndpoint' ||
    type === 'unmanagedEndpoint' ||
    type === 'superAppGroups' ||
    type === 'comboLink' ||
    type === 'link'
  );
};

export const getEnforcementModeList = (nodes: {[id: string]: GraphManagedEndpoint} | undefined): string[] => {
  const enforcementModeList = [] as string[];

  if (!nodes) {
    return enforcementModeList;
  }

  const enforcementMode = _.countBy(getComboMode(nodes));

  for (const [enforcement, count] of Object.entries(enforcementMode)) {
    enforcementModeList.push(intl('Tooltip.EnforcementCount', {type: getEnforcementMode(enforcement, true), count}));
  }

  return enforcementModeList;
};

// Styles for the Links
export const getArrowEnd = (
  comboMapping: ComboMapping,
  combo: ComboLabelId,
  linkEndPoint: ComboId,
  comboEnd: ComboId,
  unmanagedEndpoints: GraphUnmanagedEndpoints,
  combine: RegraphCombine,
): boolean => {
  if (unmanagedEndpoints[linkEndPoint] && unmanagedEndpoints[comboEnd]) {
    return true;
  }

  if (combo) {
    const comboId: ComboId = getComboId(combo, combine);

    if (comboMapping.combos[linkEndPoint]?.id === comboId) {
      return true;
    }

    if (
      Object.values(comboMapping.combos[linkEndPoint]?.parents || {}).some(
        (parent: ComboParent) => parent.id === comboId,
      ) ||
      comboMapping.endpoints[linkEndPoint]?.includes(comboId)
    ) {
      // If  target of the content link is in the combo of the summary link show the arrow;
      return true;
    }
  }

  return false;
};

export const getConcatedList = (valueList: string[], limit: number): string[] => {
  let concatedList = [] as string[];

  if (valueList.length > 0 && valueList.length <= limit) {
    return valueList;
  }

  concatedList = valueList.slice(0, limit);
  concatedList.push(`... ${valueList.length - limit} More`);

  return concatedList;
};

export const getConcatNamesList = (
  unmanagedEndpointObject: UnmanagedFQDNs | UnmanagedIpLists,
  limit: number,
): string[] => {
  const NamesList = [];

  if (unmanagedEndpointObject) {
    for (const value of Object.values(unmanagedEndpointObject)) {
      NamesList.push(value.name);
    }
  }

  return getConcatedList(NamesList, limit);
};

export const getConcatAddressesList = (unmanagedEndpointObject: UnmanagedAddresses, limit: number): string[] => {
  const Addresses = new Set();
  const endpointValuesList: UnmanagedAddress[] = unmanagedEndpointObject ? Object.values(unmanagedEndpointObject) : [];

  endpointValuesList.forEach(value => {
    if (value.addresses.size > 0) {
      Array.from(value.addresses).forEach(address => {
        Addresses.add(address);
      });
    }
  });

  return getConcatedList([...Addresses] as string[], limit);
};
