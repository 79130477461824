/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {createSelector} from 'reselect';
import {isAPIAvailable, schemaAutocompleteMethodsMap} from 'api/apiUtils';
import {routesMap} from '../router/routes';
import {isEventsEnabled} from './Events/EventsState';
import {
  canUserViewEnforcementBoundaries,
  isExplorerEnabled,
  isIlluminationMapEnabled,
  isSegmentationTemplatesEnabled,
  isTrafficEnabled,
  isUserOwner,
  isUserWithReducedScope,
} from 'containers/User/UserState';
import {getVenLibraries} from './Ven/Library/VenLibraryState';
import {isVenLibraryEnabled} from './Ven/VenState';
import {isPolicySettingsEnabled} from './PolicySettings/PolicySettingsState';
import {isSupportReportsEnabled} from './SupportBundles/VENSupportReports/SupportReportsState';
import {isNetworkEnabled} from './Network/NetworkState';
import {
  isClasEnabled,
  isCoreServicesEnabled,
  isHealthEnabled,
  isIlluminationApiEnabled,
  isIPForwardingEnabled,
  isKubernetesSupported,
  isLabelSettingsPageEnabled,
  isNetworkEnforcementNodeEnabled,
  isRansomwareReadinessEnabled,
  isReportingEnabled,
} from './App/AppState';
import {getCoreServiceSettings} from './CoreServices/Settings/CoreServicesSettingsState';
import {isEventsConfigEnabled} from './Events/Config/EventsConfigState';
import {isAuthenticationSettingsEnabled} from './AuthenticationSettings/AuthenticationSettingsState';
import {isOfflineTimersEnabled} from './OfflineTimers/OfflineTimersState';
import {isPairingProfilesEnabled} from './PairingProfile/PairingProfileState';
import {isSecureGatewayEnabled} from './SecureGateway/SecureGatewayState';
import {isAccessRestrictionEnabled} from './RBAC/AccessRestriction/AccessRestrictionState';
import {isContainerClustersEnabled} from './ContainerCluster/ContainerClusterState';
import {isLoadBalancerEnabled} from './LoadBalancer/LoadBalancerState';
import {isSwitchEnabled} from './Switch/SwitchState';
import {isSecuritySettingsEnabled} from './SecuritySettings/SecuritySettingsState';
import {isPCESupportBundlesEnabled} from 'containers/SupportBundles/PCESupportBundles/PCESupportBundlesState';
import {isMyManagedTenantsEnabled} from 'msp/containers/MyManagedTenants/MyManagedTenantsState';
import {isVenOperationsEnabled} from './VenOperations/VenOperationsState';
import {isFlowCollectionEnabled} from './FlowCollectionFilters/FlowCollectionFiltersState';
import {hrefUtils, webStorageUtils} from '@illumio-shared/utils';
import {getLastMapRouteParams} from 'containers/IlluminationMap/MapState';
import {isAPIKeySettingsEnabled} from './APIKeySettings/APIKeySettingsState';
import {call} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {
  getXpressFeaturesMode,
  isClassicExplorerEnabled,
  isClassicIlluminationEnabled,
  isClassicAppGroupMapEnabled,
} from 'containers/User/Settings/SettingsState';

const isSettingsEnabled = createSelector(
  [
    isEventsConfigEnabled,
    isSecuritySettingsEnabled,
    isVenOperationsEnabled,
    isAuthenticationSettingsEnabled,
    isPolicySettingsEnabled,
    isOfflineTimersEnabled,
    isFlowCollectionEnabled,
    isCoreServicesEnabled,
    isAPIKeySettingsEnabled,
    isLabelSettingsPageEnabled,
  ],
  (
    eventsConfigEnabled,
    securitySettingsEnabled,
    venOperationsEnabled,
    authenticationSettingsEnabled,
    policySettingsEnabled,
    offlineTimersEnabled,
    flowCollectionEnabled,
    coreServicesEnabled,
    apiKeySettingsEnabled,
    labelSettingsPageEnabled,
  ) => {
    return (
      !__MSP__ &&
      [
        eventsConfigEnabled,
        securitySettingsEnabled,
        venOperationsEnabled,
        authenticationSettingsEnabled,
        policySettingsEnabled,
        offlineTimersEnabled,
        flowCollectionEnabled,
        isAPIAvailable('settings_core_services.update') && coreServicesEnabled,
        apiKeySettingsEnabled,
        labelSettingsPageEnabled,
      ].some(isEnabled => isEnabled)
    );
  },
);

const isInfrastructureEnabled = createSelector(
  [isLoadBalancerEnabled, isContainerClustersEnabled, isSecureGatewayEnabled, isNetworkEnabled, isSwitchEnabled],
  (loadBalancerEnabled, containerClustersEnabled, secureGatewayEnabled, networkEnabled, switchEnabled) => {
    return [loadBalancerEnabled, containerClustersEnabled, secureGatewayEnabled, networkEnabled, switchEnabled].some(
      isEnabled => isEnabled,
    );
  },
);

function getCollectionFactory(collectionName) {
  return function* getCollection(request) {
    const {query} = request;
    const params = schemaMethodHasPversion(collectionName);

    try {
      const {data} = yield call(apiSaga, `${collectionName}.autocomplete`, {
        query,
        params,
      });

      return data;
    } catch (error) {
      const token = _.get(error, 'data[0].token');
      const errorMessage = (token && intl(`ErrorsAPI.err:${token}`)) || _.get(error, 'data.error', error.message);

      return {error: errorMessage};
    }
  };
}

function schemaMethodHasPversion(collectionName) {
  return schemaAutocompleteMethodsMap.get(collectionName).params.includes('pversion') ? {pversion: 'draft'} : {};
}

export const getContainersPropertiesMap = createSelector(
  [
    isEventsEnabled,
    isExplorerEnabled,
    isTrafficEnabled,
    isIlluminationMapEnabled,
    isIlluminationApiEnabled,
    isUserWithReducedScope,
    isSegmentationTemplatesEnabled,
    isUserOwner,
    canUserViewEnforcementBoundaries,
    getVenLibraries,
    isVenLibraryEnabled,
    isPolicySettingsEnabled,
    isSupportReportsEnabled,
    isNetworkEnabled,
    isHealthEnabled,
    isNetworkEnforcementNodeEnabled,
    isRansomwareReadinessEnabled,
    isReportingEnabled,
    isIPForwardingEnabled,
    isCoreServicesEnabled,
    isLabelSettingsPageEnabled,
    getCoreServiceSettings,
    isEventsConfigEnabled,
    isAuthenticationSettingsEnabled,
    isOfflineTimersEnabled,
    isPairingProfilesEnabled,
    isSecureGatewayEnabled,
    isAccessRestrictionEnabled,
    isContainerClustersEnabled,
    isLoadBalancerEnabled,
    isSwitchEnabled,
    isSecuritySettingsEnabled,
    isPCESupportBundlesEnabled,
    isMyManagedTenantsEnabled,
    isVenOperationsEnabled,
    isFlowCollectionEnabled,
    getLastMapRouteParams,
    isSettingsEnabled,
    isInfrastructureEnabled,
    getXpressFeaturesMode,
    isClassicIlluminationEnabled,
    isClassicExplorerEnabled,
    isClassicAppGroupMapEnabled,
    isClasEnabled,
  ],
  (
    eventsEnabled,
    explorerEnabled,
    trafficEnabled,
    illuminationMapEnabled,
    illuminationApiEnabled,
    userWithReducedScope,
    segmentationTemplatesEnabled,
    userIsOwner,
    userCanViewEnforcementBoundaries,
    vens,
    venLibraryEnabled,
    policySettingsEnabled,
    supportReportsEnabled,
    networkEnabled,
    healthEnabled,
    networkEnforcementNodeEnabled,
    ransomwareReadinessEnabled,
    reportingEnabled,
    iPForwardingEnabled,
    coreServicesEnabled,
    labelSettingsPageEnabled,
    coreServiceSettings,
    eventsConfigEnabled,
    authenticationSettingsEnabled,
    offlineTimersEnabled,
    pairingProfilesEnabled,
    secureGatewayEnabled,
    accessRestrictionEnabled,
    containerClustersEnabled,
    loadBalancerEnabled,
    switchEnabled,
    securitySettingsEnabled,
    pceSupportBundlesEnabled,
    myManagedTenantsEnabled,
    venOperationsEnabled,
    flowCollectionEnabled,
    mapRouteParams,
    isSettingsEnabled,
    infrastructureEnabled,
    xpressFeatures,
    classicIlluminationEnabled,
    classicExplorerEnabled,
    classicAppGroupMapEnabled,
    clasIsEnabled,
  ) => {
    const venLibraryIsAvailable = venLibraryEnabled && vens?.length > 0;
    const isXpressFeatures = xpressFeatures === 'advanced';

    return new Map([
      [
        'Landing',
        {
          viewName: intl('Common.Home'),
          isAvailable: __ANTMAN__,
          linkProps: {to: 'landing'},
          icon: 'dashboard',
        },
      ],
      [
        'EventsList',
        {
          viewName: intl('Common.Events'),
          isAvailable: eventsEnabled,
          linkProps: {to: 'events'},
          params: {
            section: 'landing',
          },
        },
      ],
      [
        'WorkloadList',
        {
          viewName: __ANTMAN__ ? intl('Workloads.All') : intl('Common.Workloads'),
          isAvailable: !__MSP__,
          getCollection: getCollectionFactory('workloads'),
          getItemLinkProps: dest => ({
            id: hrefUtils.getId(dest.href),
            ...schemaMethodHasPversion('workloads'),
          }),
          linkProps: {to: 'workloads'},
        },
      ],
      [
        'ProvisionVersionList',
        {
          viewName: intl('Provision.Versions', {multiple: true}),
          isAvailable: !__MSP__ && !userWithReducedScope,
          linkProps: {to: 'versions'},
        },
      ],
      [
        'VenLibrary',
        {
          viewName: intl('VEN.Library'),
          isAvailable: venLibraryIsAvailable,
          linkProps: {to: 'venLibrary'},
        },
      ],
      [
        'ScopeList',
        {
          viewName: intl('Common.Scopes'),
          isAvailable: !__ANTMAN__ && userIsOwner && !__MSP__,
          linkProps: {to: 'rbac.roles.scope'},
        },
      ],
      [
        'MyRolesList',
        {
          viewName: intl('Users.MyRoles'),
          linkProps: {to: 'myroles'},
        },
      ],
      [
        'Provisioning',
        {
          viewName: intl('Provision.ProvisioningStatus'),
          linkProps: {to: 'provisioning'},
        },
      ],
      [
        'PendingList',
        {
          viewName: intl('Policy.DraftsAndPolicyVersions'),
          isAvailable: !userWithReducedScope,
          linkProps: {to: 'pending'},
        },
      ],
      [
        'PolicySettings',
        {
          viewName: intl('Policy.Settings'),
          isAvailable: policySettingsEnabled,
          linkProps: {to: 'policySettings'},
        },
      ],
      [
        'IPListList',
        {
          viewName: intl('Common.IPLists'),
          isAvailable: __ANTMAN__ ? isXpressFeatures : !__MSP__,
          getCollection: getCollectionFactory('ip_lists'),
          getItemLinkProps: dest => ({
            id: hrefUtils.getId(dest.href),
            ...schemaMethodHasPversion('ip_lists'),
          }),
          linkProps: {to: 'iplists'},
        },
      ],
      [
        'SupportReportsList',
        {
          viewName: intl('SupportReports.PageName'),
          isAvailable: !__MSP__ && supportReportsEnabled,
          linkProps: {to: 'supportBundles.ven'},
        },
      ],
      [
        'PCESupportBundlesList',
        {
          viewName: intl('PCESupportBundles.PageName'),
          isAvailable: false,
          linkProps: {to: 'supportBundles.pce'},
        },
      ],
      [
        'NetworkList',
        {
          viewName: intl('Menu.Networks', {multiple: true}),
          isAvailable: __ANTMAN__ ? isXpressFeatures : networkEnabled,
          linkProps: {to: 'networks'},
        },
      ],
      [
        'LabelGroupList', // TODO: This container has a duplicate file name
        {
          viewName: intl('Labels.Groups'),
          getCollection: getCollectionFactory('label_groups'),
          getItemLinkProps: dest => ({
            id: hrefUtils.getId(dest.href),
            ...schemaMethodHasPversion('label_groups'),
          }),
          isAvailable: __ANTMAN__ ? isXpressFeatures : !__MSP__,
          linkProps: {to: 'labelGroups'},
        },
      ],
      [
        'LabelList',
        {
          viewName: intl('Common.Labels'),
          getCollection: getCollectionFactory('labels'),
          getItemLinkProps: dest => ({
            id: hrefUtils.getId(dest.href),
            ...schemaMethodHasPversion('labels'),
          }),
          isAvailable: __ANTMAN__ ? isXpressFeatures : !__MSP__,
          linkProps: {to: 'labels'},
        },
      ],
      [
        'EventsConfig',
        {
          viewName: intl('Common.EventSettings'),
          isAvailable: healthEnabled && eventsConfigEnabled,
          linkProps: {to: 'events.config'},
        },
      ],
      [
        'APIKeysList',
        {
          viewName: intl('Users.APIKeys.Name'),
          isAvailable: true,
          linkProps: {to: 'apikeys'},
        },
      ],
      [
        'VenList',
        {
          viewName: intl('Common.VENS'),
          getCollection: getCollectionFactory('vens'),
          getItemLinkProps: dest => ({
            id: hrefUtils.getId(dest.href),
            ...schemaMethodHasPversion('vens'),
          }),
          isAvailable: true,
          linkProps: {to: 'workloads.vens'},
        },
      ],
      [
        'AuthenticationSettings',
        {
          viewName: intl('Common.Authentication'),
          isAvailable: authenticationSettingsEnabled,
          linkProps: {to: 'authenticationSettings'},
        },
      ],
      [
        'HealthList',
        {
          viewName: intl('Health.PCEHealth'),
          isAvailable: healthEnabled,
          linkProps: {to: 'health'},
        },
      ],
      [
        'OfflineTimers',
        {
          viewName: intl('OfflineTimers.OfflineTimers'),
          isAvailable: offlineTimersEnabled,
          linkProps: {to: 'offlineTimers'},
        },
      ],
      [
        'MyProfile',
        {
          viewName: intl('Users.MyProfile'),
          linkProps: {to: 'myprofile'},
        },
      ],
      [
        'MyManagedTenantsList',
        {
          viewName: intl('MSP.ManagedTenants'),
          isAvailable: myManagedTenantsEnabled,
          linkProps: {to: 'mymanagedtenants.list'},
          icon: 'managed-tenants',
        },
      ],
      [
        'ServiceList',
        {
          viewName: intl('Common.Services'),
          isAvailable: __ANTMAN__ ? isXpressFeatures : !__MSP__,
          getCollection: getCollectionFactory('services'),
          getItemLinkProps: dest => ({
            id: hrefUtils.getId(dest.href),
            ...schemaMethodHasPversion('services'),
          }),
          linkProps: {to: 'services'},
        },
      ],
      [
        'PairingProfileList',
        {
          viewName: intl('PairingProfiles.Profiles'),
          isAvailable: __ANTMAN__ ? isXpressFeatures : pairingProfilesEnabled,
          linkProps: {to: 'pairingProfiles'},
        },
      ],
      [
        'ContainerWorkloadList',
        {
          viewName: intl('Common.ContainerWorkloads'),
          getCollection: getCollectionFactory('container_workloads'),
          getItemLinkProps: dest => ({
            id: hrefUtils.getId(dest.href),
            ...schemaMethodHasPversion('container_workloads'),
          }),
          isAvailable: isKubernetesSupported,
          linkProps: {to: 'workloads.containers'},
        },
      ],
      [
        'KubernetesWorkloadList',
        {
          viewName: intl('Common.KubernetesWorkloads'),
          collectionName: 'kubernetes_workloads',
          isAvailable: isKubernetesSupported && clasIsEnabled,
          linkProps: {to: 'workloads.kubernetes'},
        },
      ],
      [
        'SecureGatewayList',
        {
          viewName: intl('SecureGateway.SecureConnect'),
          isAvailable: !__ANTMAN__ && secureGatewayEnabled,
          linkProps: {to: 'secureGateways'},
        },
      ],
      [
        'UserGroupList',
        {
          viewName: intl('Common.UserGroups'),
          isAvailable: !__MSP__,
          linkProps: {to: 'userGroups'},
        },
      ],
      [
        'FlowCollectionFilters',
        {
          viewName: intl('Common.FlowCollectionFilters'),
          isAvailable: flowCollectionEnabled,
          linkProps: {to: 'flowCollectionFilters'},
        },
      ],
      [
        'RulesetList',
        {
          viewName: intl('Rulesets.AndRulesSideNav'),
          isAvailable: !__MSP__ && !userWithReducedScope,
          linkProps: {to: 'rulesets'},
          aliases: [
            {
              viewName: `${intl('Rulesets.AndRulesSideNav')} - ${intl('Common.RuleSearch')}`,
              linkProps: {to: 'app.rulesets.ruleSearch'},
              isAvailable: !userWithReducedScope,
            },
          ],
        },
      ],
      [
        'AccessRestrictionList',
        {
          viewName: intl('AccessRestriction.AccessRestrictionTitle'),
          isAvailable: !__ANTMAN__ && accessRestrictionEnabled && !__MSP__,
          linkProps: {to: 'accessRestrictions'},
        },
      ],
      [
        'ContainerClusterList',
        {
          viewName: intl('Menu.ContainerClusters', {multiple: true}),
          getCollection: getCollectionFactory('container_clusters'),
          getItemLinkProps: dest => ({
            id: hrefUtils.getId(dest.href),
            ...schemaMethodHasPversion('container_clusters'),
          }),
          isAvailable: containerClustersEnabled,
          linkProps: {to: 'containerClusters'},
        },
      ],
      [
        'LoadBalancerList',
        {
          viewName: intl('Menu.LoadBalancers'),
          isAvailable: !__ANTMAN__ && loadBalancerEnabled,
          linkProps: {to: 'loadBalancers'},
        },
      ],
      [
        'TrustedProxyList',
        {
          viewName: intl('TrustedProxy.TrustedProxy'),
          isAvailable: isAPIAvailable('trusted_proxy_ips.get'),
          linkProps: {to: 'trustedproxy'},
        },
      ],
      [
        'UserActivityList',
        {
          viewName: intl('RBAC.UsersActivity.UserActivity'),
          isAvailable: userIsOwner,
          linkProps: {to: 'rbac.users.activity'},
        },
      ],
      [
        'ExternalUserList',
        {
          viewName: intl('Users.ExternalUsers'),
          isAvailable: userIsOwner,
          linkProps: {to: 'rbac.users.external.list'},
        },
      ],
      [
        'ExternalGroupList',
        {
          viewName: intl('Users.ExternalGroups'),
          isAvailable: userIsOwner,
          linkProps: {to: 'rbac.users.external.groups'},
        },
      ],
      [
        'GlobalRoleList',
        {
          viewName: intl('RBAC.GlobalRoles'),
          isAvailable: !__ANTMAN__ && userIsOwner,
          linkProps: {to: 'rbac.roles.global'},
        },
      ],
      [
        'ServiceAccountList',
        {
          viewName: intl('Common.ServiceAccounts'),
          isAvailable: userIsOwner && !__MSP__,
          linkProps: {to: 'rbac.serviceAccounts'},
        },
      ],
      [
        'APIKeySettingsView',
        {
          viewName: intl('Users.APIKeys.Name'),
          isAvailable: userIsOwner,
          linkProps: {to: 'apiKeySettings'},
        },
      ],
      [
        'ReportsList',
        {
          viewName: intl('Exports.PageName'),
          isAvailable: !__MSP__,
          linkProps: {to: 'reports'},
        },
      ],
      [
        'LabelSettings',
        {
          viewName: intl('Common.LabelSettings'),
          isAvailable: labelSettingsPageEnabled,
          linkProps: {to: 'labelsettings'},
        },
      ],
      [
        'CoreServicesList',
        {
          viewName: intl('CoreServices.CoreServices'),
          isAvailable: coreServicesEnabled && isAPIAvailable('settings_core_services.update'),
          linkProps: {to: 'coreservices'},
        },
      ],
      [
        'CoreServicesSettings',
        {
          viewName: intl('CoreServices.CoreServiceSettings'),
          isAvailable: coreServicesEnabled && isAPIAvailable('settings_core_services.update'),
          linkProps: {to: 'coreservicesettings'},
        },
      ],
      [
        'SwitchList',
        {
          viewName: intl('Menu.Switches'),
          isAvailable: switchEnabled && networkEnforcementNodeEnabled,
          linkProps: {to: 'switches'},
        },
      ],
      [
        'LocalUserList',
        {
          viewName: intl('Users.LocalUsers'),
          isAvailable: userIsOwner,
          linkProps: {to: 'rbac.users.local'},
        },
      ],
      [
        'EssentialServiceRules',
        {
          viewName: intl('Settings.EssentialServiceRules'),
          isAvailable: isAPIAvailable('essential_service_rules.get'),
          linkProps: {to: 'essentialservicerules'},
        },
      ],
      [
        'VenOperations',
        {
          viewName: intl('VEN.VENOperation'),
          isAvailable: venOperationsEnabled,
          linkProps: {to: 'venoperations'},
        },
      ],
      [
        'EnforcementBoundariesMain',
        {
          viewName: intl('Rulesets.DenyRules'),
          isAvailable: !__ANTMAN__ && userCanViewEnforcementBoundaries,
          linkProps: {to: 'boundaries'},
        },
      ],
      [
        'EnforcementBoundariesList',
        {
          viewName: intl('Rulesets.DenyRules'),
          isAvailable: !__ANTMAN__ && userCanViewEnforcementBoundaries,
          linkProps: {to: 'boundaries'},
        },
      ],
      [
        'VirtualServiceList',
        {
          viewName: intl('Common.VirtualServices'),
          getCollection: getCollectionFactory('virtual_services'),
          getItemLinkProps: dest => ({
            id: hrefUtils.getId(dest.href),
            ...schemaMethodHasPversion('virtual_services'),
          }),
          isAvailable: isKubernetesSupported && !__MSP__,
          linkProps: {to: 'virtualServices'},
        },
      ],
      [
        'VirtualServerList',
        {
          viewName: intl('Common.VirtualServers'),
          getCollection: getCollectionFactory('virtual_servers'),
          getItemLinkProps: dest => ({
            id: hrefUtils.getId(dest.href),
            ...schemaMethodHasPversion('virtual_servers'),
          }),
          isAvailable: !__ANTMAN__ && !__MSP__,
          linkProps: {to: 'virtualServers'},
        },
      ],
      [
        'CorporateIPList',
        {
          viewName: intl('GlobalNetwork.GlobalIPs'),
          isAvailable: securitySettingsEnabled,
          linkProps: {to: 'corporatepublicips'},
        },
      ],
      [
        'SecuritySettings',
        {
          viewName: intl('Common.Security'),
          isAvailable: securitySettingsEnabled,
          aliases: [
            {
              viewName: `${intl('Common.Security')} - ${intl('Common.StaticPolicy')}`,
              params: {section: 'staticscopes'},
              isAvailable: !__ANTMAN__ && securitySettingsEnabled,
            },
            {
              viewName: `${intl('Common.Security')} - ${intl('Firewall.Coexistence.Title')}`,
              params: {section: 'firewallcoexistence'},
              isAvailable: securitySettingsEnabled,
            },
            {
              viewName: `${intl('Common.Security')} - ${intl('Common.BlockAction')}`,
              params: {section: 'blockaction'},
              isAvailable: !__ANTMAN__ && securitySettingsEnabled,
            },
            {
              viewName: `${intl('Common.Security')} - ${intl('Settings.LoopbackInterfaces.Title')}`,
              params: {section: 'loopbackinterfaces'},
              isAvailable: !__ANTMAN__ && securitySettingsEnabled,
            },
            {
              viewName: `${intl('Common.Security')} - ${intl('Settings.Containers.ContainersPolicy')}`,
              params: {section: 'containers'},
              isAvailable: !__ANTMAN__ && securitySettingsEnabled,
            },
            {
              viewName: `${intl('Common.Security')} - ${intl('Settings.IPForwarding.Title')}`,
              params: {section: 'ipforwarding'},
              isAvailable: !__ANTMAN__ && securitySettingsEnabled && iPForwardingEnabled,
            },
            {
              viewName: `${intl('Common.Security')} - ${intl('Settings.SecureConnect')}`,
              params: {section: 'secureconnect'},
              isAvailable: securitySettingsEnabled,
            },
          ],
          linkProps: {to: 'securitysettings'},
        },
      ],
      [
        'OutboundPolicy',
        {
          viewName: intl('Policy.Organizational'),
          isAvailable: false,
        },
      ],
      [
        'GroupDashboard',
        {
          viewName: intl('Common.Groups'),
          isAvailable: false,
        },
      ],
      [
        'Reporting',
        {
          viewName: intl('Common.Reports'),
          isAvailable: isAPIAvailable('reports.get_collection') && reportingEnabled,
          linkProps: {to: 'reporting'},
        },
      ],
      [
        'Dashboard',
        {
          viewName: intl('Common.Dashboard'),
          isAvailable: (__ANTMAN__ || __TARGET__ === 'core') && !userWithReducedScope,
          icon: 'dashboard',
        },
      ],
      [
        'VenStatistics',
        {
          viewName: __ANTMAN__ ? intl('Common.VenDashboard') : intl('Common.ServersAndEndpoints'),
          isAvailable: (__ANTMAN__ || __TARGET__ === 'core') && !userWithReducedScope,
          linkProps: {to: 'dashboard'},
        },
      ],
      [
        'RansomwareDashboard',
        {
          viewName: intl('RansomwareDashboard.RansomwareProtection'),
          isAvailable:
            ransomwareReadinessEnabled && isAPIAvailable('reports.time_series_statistics') && !userWithReducedScope,
          linkProps: {to: 'ransomwareDashboard'},
        },
      ],
      [
        'Illumination',
        {
          viewName: intl('Common.IlluminationPlus'),
          isAvailable: false,
          linkProps: (() => {
            const mapParams = mapRouteParams ||
              webStorageUtils.getSessionItem('mapRouteParams') || {
                id: undefined,
                consuming: undefined,
                providing: undefined,
                offset: undefined,
              };

            return {to: 'illumination', params: mapParams};
          })(),
          // We use Illumination in multiple places so this will let
          // us specify where it should link to in the routes tree
          linkToRoute: ({routeName} = {}) => routeName === 'app.illumination',
        },
      ],
      [
        'IlluminationMap',
        {
          name: 'app.illumination',
          viewName: intl('Common.Map'),
          isAvailable: explorerEnabled && trafficEnabled && !__MSP__,
          linkProps: (() => {
            const mapParams = mapRouteParams ||
              webStorageUtils.getSessionItem('mapRouteParams') || {
                id: undefined,
                consuming: undefined,
                providing: undefined,
                offset: undefined,
              };

            return {to: 'illumination', params: {...mapParams, display: 'map'}};
          })(),
        },
      ],
      [
        'IlluminationTraffic',
        {
          name: 'app.illumination',
          viewName: intl('Common.Traffic'),
          isAvailable: explorerEnabled && trafficEnabled && !__MSP__,
          linkProps: (() => {
            const mapParams = mapRouteParams ||
              webStorageUtils.getSessionItem('mapRouteParams') || {
                id: undefined,
                consuming: undefined,
                providing: undefined,
                offset: undefined,
              };

            return {to: 'illumination', params: {...mapParams, display: 'table'}};
          })(),
        },
      ],
      [
        'IlluminationMesh',
        {
          name: 'app.illumination',
          viewName: intl('IlluminationMap.Mesh'),
          isAvailable: explorerEnabled && trafficEnabled && !__MSP__,
          linkProps: (() => {
            const mapParams = mapRouteParams ||
              webStorageUtils.getSessionItem('mapRouteParams') || {
                id: undefined,
                consuming: undefined,
                providing: undefined,
                offset: undefined,
              };

            return {to: 'illumination', params: {...mapParams, display: 'mesh'}};
          })(),
        },
      ],
      [
        'XpressDashboard',
        {
          viewName: intl('Common.Dashboard'),
          isAvailable: __ANTMAN__,
          linkProps: {to: 'xpressdashboard'},
          icon: 'dashboard',
        },
      ],
      [
        'EndpointPairing',
        {
          viewName: intl('Antman.Endpoints.Add'),
          isAvailable: __ANTMAN__,
          linkProps: {to: 'endpoints.pairing'},
        },
      ],
      [
        'EndpointEnforcementList',
        {
          viewName: intl('Antman.Endpoints.Enforcement.Title'),
          isAvailable: __ANTMAN__,
          linkProps: {to: 'endpoints.enforcement'},
        },
      ],
      [
        'ServerPairing',
        {
          viewName: intl('Antman.Servers.Add'),
          isAvailable: __ANTMAN__,
          linkProps: {to: 'servers.pairing'},
        },
      ],
      [
        'ServerEnforcementList',
        {
          viewName: intl('Antman.Servers.Enforcement.Title'),
          isAvailable: __ANTMAN__,
          linkProps: {to: 'servers.enforcement'},
        },
      ],
      [
        'Explore',
        {
          viewName: intl('Common.Explore'),
          isAvailable: explorerEnabled && !__MSP__,
          icon: 'explore',
        },
      ],
      [
        'Policy',
        {
          viewName: intl('Explorer.Policy'),
          isAvailable: !__MSP__ && !userWithReducedScope,
          icon: 'policy',
        },
      ],
      [
        'ServersAndEndpoints',
        {
          viewName: intl('Common.ServersAndEndpoints'),
          isAvailable: !__MSP__ && canUserViewEnforcementBoundaries,
          icon: 'servers',
        },
      ],
      [
        'Workloads',
        {
          viewName: intl('Common.Workloads'),
          icon: 'servers',
          isAvailable: __ANTMAN__,
        },
      ],
      [
        'ServerList',
        {
          viewName: intl('Common.Servers'),
          isAvailable: __ANTMAN__,
          collectionName: 'workloads',
          linkProps: {
            to: 'workloads',
            params: {
              type: 'server',
            },
          },
        },
      ],
      [
        'EndpointList',
        {
          viewName: intl('Antman.Endpoints.Endpoints'),
          isAvailable: __ANTMAN__,
          collectionName: 'workloads',
          linkProps: {
            to: 'workloads',
            params: {
              type: 'endpoint',
            },
          },
        },
      ],
      [
        'Protect',
        {
          viewName: intl('Common.Protect'),
          isAvailable: __ANTMAN__,
          linkProps: {to: 'protect'},
        },
      ],
      [
        'PolicyObjects',
        {
          viewName: intl('Policy.Objects'),
          isAvailable: !__MSP__,
          icon: 'policyobj',
        },
      ],
      [
        'AdvancedPolicy',
        {
          viewName: intl('Policy.AdvancedPolicy'),
          isAvailable: true,
          icon: 'policyobj',
        },
      ],
      [
        'Access',
        {
          viewName: intl('RuleSearch.Access'),
          isAvailable: userIsOwner,
          icon: 'access',
        },
      ],
      [
        'Infrastructure',
        {
          viewName: intl('Menu.Infrastructure'),
          isAvailable: !__MSP__ && !__ANTMAN__ && infrastructureEnabled,
          icon: 'infrastructure',
        },
      ],
      [
        'Settings',
        {
          viewName: intl('Common.Settings'),
          isAvailable: isSettingsEnabled,
          icon: 'settings',
        },
      ],
      [
        'Troubleshoot',
        {
          viewName: intl('Common.Troubleshoot'),
          isAvailable: true,
          icon: 'troubleshoot',
        },
      ],
      [
        'Support',
        {
          viewName: intl('Common.Support'),
          isAvailable: true,
          icon: 'info',
        },
      ],
      [
        'CloudSecure',
        {
          viewName: intl('Common.CloudSecure'),
          isAvailable: false,
          linkProps: {href: 'https://lumos1.illum.io', target: '_blank'},
        },
      ],
      [
        'SupportPortal',
        {
          viewName: intl('Common.SupportPortal'),
          isAvailable: true,
          linkProps: {href: `https://portal.illumio.com/`, target: '_blank'},
        },
      ],
      [
        'ProductVersion',
        {
          viewName: intl('Common.ProductVersion'),
          isAvailable: true,
          linkProps: {params: {showVersion: true}, replace: true},
        },
      ],
      [
        'AppGroupList',
        {
          viewName: intl('Common.AppGroups'),
          linkProps: {to: 'appGroupsList'},
          isAvailable: illuminationApiEnabled && !__MSP__,
          icon: 'appgroup',
        },
      ],
      [
        'AppGroups',
        {
          viewName: intl('Common.AppGroups'),
          linkProps: {to: 'appGroupsList'},
          isAvailable: illuminationApiEnabled && !__MSP__,
          icon: 'appgroup',
        },
      ],
      [
        'PolicyCheck',
        {
          viewName: intl('Policy.Check'),
          isAvailable: !__MSP__ && !userWithReducedScope,
          linkProps: {to: 'connectivity'},
          legacy: true,
        },
      ],
      [
        'Explorer',
        {
          viewName: intl('Common.Explorer'),
          isAvailable: classicExplorerEnabled,
          linkProps: {to: 'explorer'},
          legacy: true,
        },
      ],
      [
        'ClassicIllumination',
        {
          viewName: intl('Common.ClassicIllumination'),
          isAvailable: classicIlluminationEnabled,
          linkProps: {to: 'map'},
          legacy: true,
        },
      ],
      [
        'app.map',
        {
          viewName: intl('Common.IlluminationMap'),
          isAvailable: illuminationMapEnabled,
          linkProps: {to: 'map'},
        },
      ],
      [
        'app.explorer',
        {
          viewName: intl('Common.Explorer'),
          isAvailable: explorerEnabled,
          linkProps: {to: 'explorer'},
        },
      ],
      [
        'app.appMap',
        {
          viewName: intl('Common.AppGroupMap'),
          isAvailable: trafficEnabled && classicAppGroupMapEnabled,
          linkProps: {to: 'appMap'},
        },
      ],
      [
        'app.appGroupsList',
        {
          viewName: intl('Common.AppGroups'),
          isAvailable: true,
          linkProps: {to: 'appGroupsList'},
          icon: 'appgroup',
        },
      ],
      [
        'app.policygenerator',
        {
          viewName: intl('PolicyGenerator.PolicyGenerator'),
          isAvailable: trafficEnabled,
          linkProps: {to: 'policygenerator'},
        },
      ],
      [
        'app.connectivity',
        {
          viewName: intl('Policy.Check'),
          isAvailable: !userWithReducedScope,
          linkProps: {to: 'connectivity'},
        },
      ],
      [
        'SegmentationTemplates',
        {
          viewName: intl('SegmentationTemplates.SegmentationTemplates'),
          isAvailable: segmentationTemplatesEnabled,
          linkProps: {to: 'segmentationTemplates'},
          legacy: true,
        },
      ],
    ]);
  },
);

/**
 * Map of routes in legacy and their respective viewNames.
 *
 * This is done since we do not access to the container or
 * components in legacy, so this map simulates that.
 *
 * All role checking is done as well to emulate the main menu behavior.
 */
export const getLegacyRoutesDisplayNamesMap = state => {
  const legacyRoutesDisplayNameMap = {};

  if (isIlluminationMapEnabled(state)) {
    legacyRoutesDisplayNameMap['app.map'] = {
      viewName: () => intl('Common.ClassicIllumination'),
      isAvailable: isClassicIlluminationEnabled(state),
    };
  }

  const explorerIsEnabled = isExplorerEnabled(state);

  if (explorerIsEnabled) {
    legacyRoutesDisplayNameMap['app.explorer'] = {
      viewName: () => intl('Common.Explorer'),
      isAvailable: isClassicExplorerEnabled(state),
    };
  }

  legacyRoutesDisplayNameMap['app.appGroupsList'] = {
    viewName: () => intl('Common.AppGroups'),
  };

  if (isTrafficEnabled(state)) {
    legacyRoutesDisplayNameMap['app.appMap'] = {
      viewName: () => intl('Common.AppGroupMap'),
      isAvailable: false,
    };

    legacyRoutesDisplayNameMap['app.policygenerator'] = {
      viewName: () => intl('PolicyGenerator.PolicyGenerator'),
      isAvailable: false,
    };
  }

  if (!isUserWithReducedScope(state)) {
    legacyRoutesDisplayNameMap['app.connectivity'] = {
      viewName: () => intl('Policy.Check'),
    };
  }

  if (isSegmentationTemplatesEnabled(state)) {
    legacyRoutesDisplayNameMap['app.segmentationTemplates.list'] = {
      viewName: () => intl('SegmentationTemplates.SegmentationTemplates'),
    };
  }

  return legacyRoutesDisplayNameMap;
};

export const getContainerPropertiesByRouteMap = createSelector(getContainersPropertiesMap, containersPropertiesMap => {
  const routeNamesMap = new Map();

  for (const [routeName, routeProperties] of routesMap) {
    routeNamesMap.set(routeName, {...routeProperties, ...containersPropertiesMap.get(routeProperties.container)});
  }

  return routeNamesMap;
});

export default getContainersPropertiesMap;
