/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */

export default {
  "api_prefix": "/api/v2",
  "classes": {
    "datafiles": {
      "collection": true,
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/datafiles",
          "summary": "add a file",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "interservice",
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/datafiles/:uuid",
          "summary": "get a file",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "uuid of file resource",
              "required": true
            },
            {
              "dataType": "string",
              "name": "filename",
              "description": "Filename to save as",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "support_reports": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/datafiles/support_reports",
          "summary": "add support report",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "expose_to": [
            "agent",
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": [
            "any_agent",
            "any_kubelink"
          ],
          "auditable": true
        },
        "existing_iptable_rules": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/datafiles/existing_iptable_rules",
          "summary": "add agent existing ip table rules file",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "expose_to": [
            "agent"
          ],
          "operation_type": "write",
          "authz": "any_agent",
          "auditable": true
        }
      }
    },
    "datafile": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/datafiles/:uuid",
          "summary": "delete a file",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "uuid of file resource",
              "required": true
            }
          ],
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "interservice",
          "auditable": false
        }
      }
    },
    "network_endpoints": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/network_devices/:nd_uuid/network_endpoints",
          "summary": "get network_endpoint list",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "nd_uuid",
              "description": "uuid of device resource",
              "required": true
            }
          ],
          "schema": "network_devices_network_endpoints_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/network_devices/:nd_uuid/network_endpoints",
          "summary": "add a network_endpoint",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "nd_uuid",
              "description": "uuid of device resource",
              "required": true
            }
          ],
          "schema": "network_devices_network_endpoints_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/network_devices/:nd_uuid/network_endpoints/:ep_uuid",
          "summary": "get a network_endpoint",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "nd_uuid",
              "description": "uuid of device resource",
              "required": true
            },
            {
              "dataType": "string",
              "name": "ep_uuid",
              "description": "uuid of network_endpoint resource",
              "required": true
            }
          ],
          "schema": "network_devices_network_endpoints_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "network_endpoint": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/network_devices/:nd_uuid/network_endpoints/:ep_uuid",
          "summary": "update a network_endpoint",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "nd_uuid",
              "description": "uuid of device resource",
              "required": true
            },
            {
              "dataType": "string",
              "name": "ep_uuid",
              "description": "uuid of network_endpoint resource",
              "required": true
            }
          ],
          "schema": "network_devices_network_endpoints_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/network_devices/:nd_uuid/network_endpoints/:ep_uuid",
          "summary": "delete a network_endpoint",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "nd_uuid",
              "description": "uuid of device resource",
              "required": true
            },
            {
              "dataType": "string",
              "name": "ep_uuid",
              "description": "uuid of network_endpoint resource",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        }
      }
    },
    "network_devices": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/network_devices",
          "summary": "get network device list",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "device_type",
              "description": "Type of supported device",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "manufacturer",
              "description": "Device manufacturer",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "model",
              "description": "Device model",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "device name",
              "query_param": true
            }
          ],
          "schema": "network_devices_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "multi_enforcement_instructions_request": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/network_devices/multi_enforcement_instructions_request",
          "summary": "generate enforcement instructions for multiple network devices",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "network_devices_multi_enforcement_instructions_request_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_provisioning",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Ruleset Provisioner"
          ],
          "auditable": false
        },
        "multi_enforcement_instructions_applied": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/network_devices/multi_enforcement_instructions_applied",
          "summary": "acknowledge enforcement instructions applied for multiple network devices",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "network_devices_multi_enforcement_instructions_applied_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_provisioning",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Ruleset Provisioner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/network_devices/:nd_uuid",
          "summary": "get a device",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "nd_uuid",
              "description": "uuid of device resource",
              "required": true
            }
          ],
          "schema": "network_devices_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/network_enforcement_nodes/:uuid/network_devices",
          "summary": "add a network device",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Network enforcement node uuid",
              "required": true
            }
          ],
          "schema": "network_enforcement_nodes_network_devices_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        }
      }
    },
    "network_device": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/network_devices/:nd_uuid",
          "summary": "update a device",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "nd_uuid",
              "description": "uuid of device resource",
              "required": true
            }
          ],
          "schema": "network_devices_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/network_devices/:nd_uuid",
          "summary": "delete a device",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "nd_uuid",
              "description": "uuid of device resource",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "enforcement_instructions_request": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/network_devices/:nd_uuid/enforcement_instructions_request",
          "summary": "request enforcement instructions",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "nd_uuid",
              "description": "uuid of device resource",
              "required": true
            }
          ],
          "schema": "network_devices_enforcement_instructions_request_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_provisioning",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Ruleset Provisioner"
          ],
          "auditable": false
        },
        "enforcement_instructions_applied": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/network_devices/:nd_uuid/enforcement_instructions_applied",
          "summary": "acknowledge enforcement instructions applied",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "nd_uuid",
              "description": "uuid of device resource",
              "required": true
            }
          ],
          "schema": "network_devices_enforcement_instructions_applied_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_provisioning",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Ruleset Provisioner"
          ],
          "auditable": true
        }
      }
    },
    "network_enforcement_nodes": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/network_enforcement_nodes",
          "summary": "Get list of network enforcement nodes",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "device_type",
              "description": "Type of supported device",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "manufacturer",
              "description": "Device manufacturer",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "model",
              "description": "Device model",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "health",
              "description": "The overall health (condition) of the Network Enforcement Node",
              "enum": [
                "healthy",
                "unhealthy",
                "error",
                "warning"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "condition",
              "description": "A specific error condition to filter by",
              "enum": [
                "network_enforcement_node.missed_heartbeats",
                "network_enforcement_node.degraded"
              ],
              "query_param": true
            }
          ],
          "schema": "network_enforcement_nodes_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "clear_conditions": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/network_enforcement_nodes/clear_conditions",
          "summary": "Clear a condition from a list of NetworkEnforcementNodes",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "network_enforcement_nodes_clear_conditions_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/network_enforcement_nodes/:uuid",
          "summary": "get a network enforcement node",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Network enforcement node uuid",
              "required": true
            }
          ],
          "schema": "network_enforcement_nodes_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "virtual_server_discovery_jobs": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/network_enforcement_nodes/virtual_server_discovery_jobs/:job_uuid",
          "summary": "get a virtual server discovery request",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "job_uuid",
              "description": "uuid of virtual server discovery job",
              "required": true
            }
          ],
          "schema": "network_enforcement_nodes_virtual_server_discovery_jobs_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "network_enforcement_node": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/network_enforcement_nodes/:uuid",
          "summary": "Update the target PCE of the Network Enforcement Node",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Network enforcement node uuid",
              "required": true
            }
          ],
          "schema": "network_enforcement_nodes_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "login_proxy_user": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/login_proxy_user",
          "summary": "Get user(s)",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "FQDN of the PCE cluster",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "working_org_id",
              "description": "Working Org ID on the PCE cluster",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "username",
              "description": "Email address of user to retrieve",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "user_id",
              "description": "User ID of user to retrieve",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "group_name",
              "description": "Group name to filter",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "type",
              "description": "User type which to filter, (e.g. local or external)",
              "query_param": true
            },
            {
              "dataType": "[Integer]",
              "name": "ids",
              "description": "IDs which to filter",
              "query_param": true
            },
            {
              "dataType": "[String]",
              "name": "usernames",
              "description": "Usernames which to filter",
              "query_param": true
            },
            {
              "dataType": "[String]",
              "name": "group_names",
              "description": "Group names to filter",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true
            }
          ],
          "schema": "login_proxy_user_get",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "read",
          "authz": "login_interservice",
          "auditable": false
        },
        "get_or_put": {
          "http_method": "PUT",
          "path": "/login_proxy_user/get_or_put",
          "summary": "Get user or create the user if it doesn't exist",
          "params": [
            {
              "dataType": "string",
              "name": "username",
              "description": "Email address of user to get or create",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "working_org_id",
              "description": "Working Org ID on the PCE cluster",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "login_proxy_user_get_or_put_put",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "invite": {
          "http_method": "PUT",
          "path": "/login_proxy_user/invite",
          "summary": "Invite local user or re-invite an existing local user",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "FQDN of the PCE cluster",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "working_org_id",
              "description": "Working Org ID on the PCE cluster",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "username",
              "description": "Email address of user to invite",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "user_id",
              "description": "User ID of user to reinvite",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "full_name",
              "description": "Full name of domain user",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "time_zone",
              "description": "Time zone (e.g. \"America/Los_Angeles\")",
              "query_param": true
            }
          ],
          "schema": "login_proxy_user_invite_put",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "update": {
          "http_method": "PUT",
          "path": "/login_proxy_user/update",
          "summary": "Update user",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "user_id",
              "description": "User ID of user to update",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "FQDN of the PCE cluster",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "working_org_id",
              "description": "Working Org ID on the PCE cluster",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "full_name",
              "description": "Full name",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "time_zone",
              "description": "Time zone (e.g. \"America/Los_Angeles\")",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "type",
              "description": "User type, (e.g. local or external)",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "locked",
              "description": "Flag to indicate whether account is locked",
              "query_param": true
            }
          ],
          "schema": "login_proxy_user_update_put",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "reset_password": {
          "http_method": "PUT",
          "path": "/login_proxy_user/reset_password",
          "summary": "Reset password for local user",
          "params": [
            {
              "dataType": "string",
              "name": "user_id",
              "description": "User ID of user to reset password",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "login_proxy_user_reset_password_put",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        }
      },
      "singleton": true
    },
    "login_proxy_saml_config": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/login_proxy_saml_configs",
          "summary": "Get domain SAML configuration(s)",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "FQDN of the PCE cluster",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "working_org_id",
              "description": "Working Org ID on the PCE cluster",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "saml_config_uuid",
              "description": "SamlConfig UUID",
              "query_param": true
            }
          ],
          "schema": "login_proxy_saml_configs_get",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "read",
          "authz": "login_interservice",
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/login_proxy_saml_configs/update",
          "summary": "Update domain SAML configuration",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "saml_config_uuid",
              "description": "SamlConfig UUID",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "login_proxy_saml_configs_update_put",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "pce_signing_cert": {
          "http_method": "POST",
          "path": "/login_proxy_saml_configs/pce_signing_cert",
          "summary": "Generate a new cert for signing SAML AuthN requests",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "saml_config_uuid",
              "description": "SamlConfig UUID",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "login_proxy_saml_configs_pce_signing_cert_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        }
      },
      "singleton": true
    },
    "login_proxy_radius_config": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/login_proxy_radius_configs",
          "summary": "Get domain RADIUS configuration(s)",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "radius_config_uuid",
              "description": "RadiusConfig UUID",
              "query_param": true
            }
          ],
          "schema": "login_proxy_radius_configs_get",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "read",
          "authz": "login_interservice",
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/login_proxy_radius_configs",
          "summary": "Create domain RADIUS configuration",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "login_proxy_radius_configs_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "update": {
          "http_method": "PUT",
          "path": "/login_proxy_radius_configs/update",
          "summary": "Update domain RADIUS configuration",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "radius_config_uuid",
              "description": "RadiusConfig UUID",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "login_proxy_radius_configs_update_put",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/login_proxy_radius_configs",
          "summary": "Delete domain RADIUS configuration",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "radius_config_uuid",
              "description": "RadiusConfig UUID",
              "required": true,
              "query_param": true
            }
          ],
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "verify_shared_secret": {
          "http_method": "POST",
          "path": "/login_proxy_radius_configs/verify_shared_secret",
          "summary": "Verify RADIUS shared secret",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "radius_config_uuid",
              "description": "RadiusConfig UUID",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "login_proxy_radius_configs_verify_shared_secret_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        }
      },
      "singleton": true
    },
    "login_proxy_ldap_config": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/login_proxy_ldap_configs",
          "summary": "Get domain LDAP configuration(s)",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "ldap_config_uuid",
              "description": "LdapConfig UUID",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "FQDN of the PCE cluster",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "working_org_id",
              "description": "Working Org ID on the PCE cluster",
              "query_param": true
            }
          ],
          "schema": "login_proxy_ldap_configs_get",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "read",
          "authz": "login_interservice",
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/login_proxy_ldap_configs",
          "summary": "Create domain LDAP configuration",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "FQDN of the PCE cluster",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "working_org_id",
              "description": "Working Org ID on the PCE cluster",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "disable_tls_cert_verification",
              "description": "Specifies whether to verify the server certificate when establishing an SSL connection to the LDAP server",
              "query_param": true
            }
          ],
          "schema": "login_proxy_ldap_configs_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "update": {
          "http_method": "PUT",
          "path": "/login_proxy_ldap_configs/update",
          "summary": "Update domain LDAP configuration",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "ldap_config_uuid",
              "description": "LdapConfig UUID",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "disable_tls_cert_verification",
              "description": "Specifies whether to verify the server certificate when establishing an SSL connection to the LDAP server",
              "query_param": true
            }
          ],
          "schema": "login_proxy_ldap_configs_update_put",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/login_proxy_ldap_configs",
          "summary": "Delete domain LDAP configuration",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "ldap_config_uuid",
              "description": "LdapConfig UUID",
              "required": true,
              "query_param": true
            }
          ],
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "verify_connection": {
          "http_method": "POST",
          "path": "/login_proxy_ldap_configs/verify_connection",
          "summary": "Verify LDAP server connection",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "ldap_config_uuid",
              "description": "LdapConfig UUID",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "login_proxy_ldap_configs_verify_connection_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        }
      },
      "singleton": true
    },
    "login_proxy_password_policy": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/login_proxy_password_policy",
          "summary": "Get domain password policy for local user",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "FQDN of the PCE cluster",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "working_org_id",
              "description": "Working Org ID on the PCE cluster",
              "query_param": true
            }
          ],
          "schema": "login_proxy_password_policy_get",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "read",
          "authz": "login_interservice",
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/login_proxy_password_policy/update",
          "summary": "Update domain password policy for local user",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "FQDN of the PCE cluster",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "working_org_id",
              "description": "Working Org ID on the PCE cluster",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "require_type_number",
              "description": "Flag to indicate whether password must contain numerical digit",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "require_type_lowercase",
              "description": "Flag to indicate whether password must contain lowercase letter",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "require_type_uppercase",
              "description": "Flag to indicate whether password must contain uppercase letter",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "require_type_symbol",
              "description": "Flag to indicate whether password must contain symbol",
              "query_param": true
            }
          ],
          "schema": "login_proxy_password_policy_update_put",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        }
      },
      "singleton": true
    },
    "login_proxy_auth_settings": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/login_proxy_auth_settings",
          "summary": "Get domain authentication settings",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "FQDN of the PCE cluster",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "working_org_id",
              "description": "Working Org ID on the PCE cluster",
              "query_param": true
            }
          ],
          "schema": "login_proxy_auth_settings_get",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "read",
          "authz": "login_interservice",
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/login_proxy_auth_settings/update",
          "summary": "Update domain authentication settings",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "FQDN of the PCE cluster",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "working_org_id",
              "description": "Working Org ID on the PCE cluster",
              "query_param": true
            }
          ],
          "schema": "login_proxy_auth_settings_update_put",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        }
      },
      "singleton": true
    },
    "login_proxy_msp_tenants": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/login_proxy_msp_tenants",
          "summary": "Get MSP tenant(s)",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "The MSP/MSSP org owner User ID",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "resource_uuid",
              "description": "The tenant resource UUID",
              "query_param": true
            }
          ],
          "schema": "login_proxy_msp_tenants_get",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "read",
          "authz": "login_interservice",
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/login_proxy_msp_tenants",
          "summary": "Create a new MSP tenant",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "FQDN of the PCE cluster",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "login_proxy_msp_tenants_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "update": {
          "http_method": "PUT",
          "path": "/login_proxy_msp_tenants/update",
          "summary": "Update MSP tenant info",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "resource_uuid",
              "description": "The tenant resource UUID",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "login_proxy_msp_tenants_update_put",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/login_proxy_msp_tenants",
          "summary": "Delete MSP tenant",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "User ID of org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "resource_uuid",
              "description": "The tenant resource UUID",
              "required": true,
              "query_param": true
            }
          ],
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        }
      },
      "singleton": true
    },
    "login_proxy_orgs": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/login_proxy_orgs",
          "summary": "Get org(s)",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "The User ID of the managing org owner",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "org_uuid",
              "description": "The org_uuid of the managed resource",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation of the response",
              "query_param": true
            }
          ],
          "schema": "login_proxy_orgs_get",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "read",
          "authz": "login_interservice",
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/login_proxy_orgs",
          "summary": "Create a new managed org",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "The User ID of the managing org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "FQDN of the PCE cluster",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "login_proxy_orgs_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "update": {
          "http_method": "PUT",
          "path": "/login_proxy_orgs/update",
          "summary": "Update MSP tenant info",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "The User ID of the managing org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "org_uuid",
              "description": "The org_uuid of the tenant resource",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "login_proxy_orgs_update_put",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/login_proxy_orgs",
          "summary": "Delete MSP tenant",
          "params": [
            {
              "dataType": "integer",
              "name": "authz_user_id",
              "description": "The User ID of the managing org owner",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "org_uuid",
              "description": "The tenant resource UUID",
              "required": true,
              "query_param": true
            }
          ],
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        }
      },
      "singleton": true
    },
    "login_users": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/login_users",
          "summary": "List users in domain",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "Fully qualified name of PCE",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "org_id",
              "description": "Org ID",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "inactive_days",
              "description": "local users with last_sign_in_at more than inactive_days ago",
              "query_param": true
            }
          ],
          "schema": "login_users_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": [
            "login_interservice",
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "authenticate": {
          "http_method": "POST",
          "path": "/login_users/authenticate",
          "summary": "Sign in a local user and return auth_token for specified PCE",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "Fully qualified name of PCE",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "login_users_authenticate_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "authenticated_local_user",
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/login_users/:user_uuid",
          "summary": "Get user",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "user_uuid",
              "description": "User UUID; use 'me' for the currently authenticated user",
              "required": true
            }
          ],
          "schema": "login_users_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": [
            "authenticated_unified_console",
            "this_user_or_login_admin"
          ],
          "auditable": false
        }
      }
    },
    "login_user": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/login_users/:user_uuid",
          "summary": "Destroy a user",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "user_uuid",
              "description": "User UUID; use 'me' for the currently authenticated user",
              "required": true
            }
          ],
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/login_users/:user_uuid",
          "summary": "Update user",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "user_uuid",
              "description": "User UUID; use 'me' for the currently authenticated user",
              "required": true
            }
          ],
          "schema": "login_users_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "this_user_or_login_admin"
          ],
          "auditable": false
        },
        "password": {
          "http_method": "PUT",
          "path": "/login_users/:user_uuid/password",
          "summary": "Change password for a local user",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "user_uuid",
              "description": "User UUID; use 'me' for the currently authenticated user",
              "required": true
            }
          ],
          "schema": "login_users_password_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "authenticated_local_user",
          "auditable": true
        },
        "forgot_password": {
          "http_method": "PUT",
          "path": "/login_users/:user_uuid/forgot_password",
          "summary": "Send forgot password email for local user",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "user_uuid",
              "description": "User UUID; use 'me' for the currently authenticated user",
              "required": true
            }
          ],
          "schema": "login_users_forgot_password_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "lock": {
          "http_method": "PUT",
          "path": "/login_users/:user_uuid/lock",
          "summary": "Lock user",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "user_uuid",
              "description": "User UUID; use 'me' for the currently authenticated user",
              "required": true
            }
          ],
          "schema": "login_users_lock_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "unlock": {
          "http_method": "PUT",
          "path": "/login_users/:user_uuid/unlock",
          "summary": "Unlock user",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "user_uuid",
              "description": "User UUID; use 'me' for the currently authenticated user",
              "required": true
            }
          ],
          "schema": "login_users_unlock_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        }
      }
    },
    "login_clusters": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/login_clusters",
          "summary": "Return list of paired clusters",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            }
          ],
          "schema": "login_clusters_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/login_clusters",
          "summary": "Create and pair PCE cluster",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            }
          ],
          "schema": "login_clusters_post",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/login_clusters/:cluster_uuid",
          "summary": "Return paired cluster",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "cluster_uuid",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "login_clusters_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        }
      }
    },
    "login_cluster": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/login_clusters/:cluster_uuid",
          "summary": "Destroy a cluster",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "cluster_uuid",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/login_clusters/:cluster_uuid",
          "summary": "Update cluster",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "cluster_uuid",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "login_clusters_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "resources": {
          "http_method": "GET",
          "path": "/login_clusters/:cluster_uuid/resources",
          "summary": "Return list of resources associated with cluster",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "cluster_uuid",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "login_clusters_resources_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        }
      }
    },
    "login_domains": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/login_domains",
          "summary": "List all domains",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            }
          ],
          "schema": "login_domains_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/login_domains",
          "summary": "Create domain",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            }
          ],
          "schema": "login_domains_post",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/login_domains/:domain_uuid",
          "summary": "List domain",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "domain_uuid",
              "description": "Domain UUID",
              "required": true
            }
          ],
          "schema": "login_domains_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        }
      }
    },
    "login_domain": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/login_domains/:domain_uuid",
          "summary": "Destroy a domain",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "domain_uuid",
              "description": "Domain UUID",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "force",
              "description": "Force deletes the domain by deleting the associated users and resources",
              "query_param": true
            }
          ],
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/login_domains/:domain_uuid",
          "summary": "Update domain",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "domain_uuid",
              "description": "Domain UUID",
              "required": true
            }
          ],
          "schema": "login_domains_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "resources": {
          "http_method": "GET",
          "path": "/login_domains/:domain_uuid/resources",
          "summary": "Return resources assigned to domain",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "domain_uuid",
              "description": "Domain UUID",
              "required": true
            }
          ],
          "schema": "login_domains_resources_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "users": {
          "http_method": "GET",
          "path": "/login_domains/:domain_uuid/users",
          "summary": "List users in domain",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "domain_uuid",
              "description": "Domain UUID",
              "required": true
            }
          ],
          "schema": "login_domains_users_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "invite": {
          "http_method": "POST",
          "path": "/login_domains/:domain_uuid/invite",
          "summary": "Invite user to a domain",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "domain_uuid",
              "description": "Domain UUID",
              "required": true
            }
          ],
          "schema": "login_domains_invite_post",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "accept_invitation": {
          "http_method": "PUT",
          "path": "/login_domains/:domain_uuid/accept_invitation",
          "summary": "Accept a user invitation to a domain",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "domain_uuid",
              "description": "Domain UUID",
              "required": true
            }
          ],
          "schema": "login_domains_accept_invitation_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        }
      }
    },
    "login_resources": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/login_resources",
          "summary": "Return list of resources",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            }
          ],
          "schema": "login_resources_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/login_resources",
          "summary": "Create resource on cluster",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            }
          ],
          "schema": "login_resources_post",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/login_resources/:resource_uuid",
          "summary": "List resource",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "resource_uuid",
              "description": "Resource uuid",
              "required": true
            }
          ],
          "schema": "login_resources_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        }
      }
    },
    "login_resource": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/login_resources/:resource_uuid",
          "summary": "Update resource",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "resource_uuid",
              "description": "Resource uuid",
              "required": true
            }
          ],
          "schema": "login_resources_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "invite": {
          "http_method": "POST",
          "path": "/login_resources/:resource_uuid/invite",
          "summary": "Invite user to a resource",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "resource_uuid",
              "description": "Resource uuid",
              "required": true
            }
          ],
          "schema": "login_resources_invite_post",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/login_resources/:resource_uuid",
          "summary": "Destroy a resource",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "resource_uuid",
              "description": "Resource uuid",
              "required": true
            }
          ],
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        },
        "unmanage": {
          "http_method": "PUT",
          "path": "/login_resources/:resource_uuid/unmanage",
          "summary": "Remove a resource from being managed by a MSSP",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "resource_uuid",
              "description": "Resource uuid",
              "required": true
            }
          ],
          "schema": "login_resources_unmanage_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "authenticated_unified_console",
            "authenticated_login_admin"
          ],
          "auditable": false
        }
      }
    },
    "login_saml_sp_configs": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/login_saml_sp_configs",
          "summary": "Return list of SAML SP configurations",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            }
          ],
          "schema": "login_saml_sp_configs_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "authenticated_login_admin",
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/login_saml_sp_configs",
          "summary": "Create SAML SP configuration",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            }
          ],
          "schema": "login_saml_sp_configs_post",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "authenticated_login_admin",
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/login_saml_sp_configs/:saml_sp_config_uuid",
          "summary": "Return SAML SP configuration",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "saml_sp_config_uuid",
              "description": "SAML SP configuration UUID",
              "required": true
            }
          ],
          "schema": "login_saml_sp_configs_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "authenticated_login_admin",
          "auditable": false
        }
      }
    },
    "login_saml_sp_config": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/login_saml_sp_configs/:saml_sp_config_uuid",
          "summary": "Destroy a SAML SP configuration",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "saml_sp_config_uuid",
              "description": "SAML SP configuration UUID",
              "required": true
            }
          ],
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "authenticated_login_admin",
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/login_saml_sp_configs/:saml_sp_config_uuid",
          "summary": "Update a SAML SP configuration",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "saml_sp_config_uuid",
              "description": "SAML SP configuration UUID",
              "required": true
            }
          ],
          "schema": "login_saml_sp_configs_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "authenticated_login_admin",
          "auditable": false
        }
      }
    },
    "login_auths": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/login_auths",
          "summary": "Returns a JWT token wrapper that includes cluster-specific tokens, as well as product name, org_uuid, and org_id",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            }
          ],
          "schema": "login_auths_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "authenticated_unified_console",
          "auditable": false
        },
        "tokens": {
          "http_method": "GET",
          "path": "/login_auths/tokens",
          "summary": "Returns a JWT token wrapper that includes cluster-specific tokens, as well as product name, org_uuid, and org_id",
          "params": [
            {
              "dataType": "string",
              "name": "one_time_password",
              "description": "The time-based one-time password for two-factor authentication.",
              "query_param": true
            }
          ],
          "schema": "login_auths_tokens_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "authenticated_unified_console",
          "auditable": false
        }
      }
    },
    "login_auth": {
      "methods": {
      }
    },
    "traffic_flows_database_metrics": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/traffic_flows/database_metrics",
          "summary": "return organization database usage metrics",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "traffic_flows_database_metrics_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "traffic_flows_async_queries": {
      "collection": true,
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/traffic_flows/async_queries",
          "summary": "Create asynchronous traffic flow query",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "traffic_flows_async_queries_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/traffic_flows/async_queries",
          "summary": "Get collection of asynchronous traffic flow query status",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "traffic_flows_async_queries_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/traffic_flows/async_queries/:uuid",
          "summary": "Get asynchronous traffic flow query status",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "async query uuid",
              "required": true
            }
          ],
          "schema": "traffic_flows_async_queries_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "download": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/traffic_flows/async_queries/:uuid/download",
          "summary": "Download results of a completed asynchronous traffic flow query",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "async query uuid",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "limit",
              "description": "Optional limit the number of results returned.",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "integer",
              "name": "offset",
              "description": "Optional offset of 1st result (ordered by last seen).",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "draft_policy_decision",
              "description": "List of draft policy decisions, encoded as a JSON string",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "boolean",
              "name": "include_draft_policy_in_csv",
              "description": "Indicate whether to include draft_policy_decision column in the downloaded csv file",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "reported_policy_decision_set",
              "description": "List of reported policy decision filters, encoded as a JSON string",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "transmission_set",
              "description": "List of transmission filters, encoded as a JSON string",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "ip_property_set",
              "description": "List of ip_property filters, encoded as a JSON string",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "traffic_flows_async_queries_download_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "update_rules": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/traffic_flows/async_queries/:uuid/update_rules",
          "summary": "Update rule coverage for a completed asynchronous traffic flow query",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "async query uuid",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "limit",
              "description": "Limit the number of flows updated.",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "integer",
              "name": "offset",
              "description": "Offset of 1st flow updated (ordered by last seen).",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "boolean",
              "name": "label_based_rules",
              "description": "Calculate label-based rule coverage",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "traffic_flows_async_queries_update_rules_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/traffic_flows/async_queries/:uuid",
          "summary": "Request cancellation of an executing asynchronous traffic flow query",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "async query uuid",
              "required": true
            }
          ],
          "schema": "traffic_flows_async_queries_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/traffic_flows/async_queries/:uuid",
          "summary": "Delete status and results for an asynchronous traffic flow query",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "async query uuid",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      }
    },
    "traffic_flows": {
      "collection": true,
      "methods": {
        "traffic_analysis_queries": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/traffic_flows/traffic_analysis_queries",
          "summary": "DEPRECATED AND REPLACED (USE traffic_flows/async_queries INSTEAD)",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "traffic_flows_traffic_analysis_queries_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      }
    },
    "traffic_flow": {
      "methods": {
      }
    },
    "groups": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/groups/autocomplete",
          "summary": "return groups autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "admin",
              "description": "Filter by admin group",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/groups/facets",
          "summary": "return groups filter facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/groups",
          "summary": "Return the set of groups",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name on which to filter. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "include_deleted",
              "description": "Include deleted groups",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of Groups to return.",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "admin",
              "description": "Filter by admin group",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "update_type",
              "description": "Rule set update type (create, update, delete)",
              "query_param": true
            }
          ],
          "schema": "groups_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/groups",
          "summary": "Create a group",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "groups_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/groups/:group_id",
          "summary": "Get group by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "group_id",
              "description": "Group ID",
              "required": true
            }
          ],
          "schema": "groups_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "group": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/groups/:group_id",
          "summary": "Modify a group by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "group_id",
              "description": "Group ID",
              "required": true
            }
          ],
          "schema": "groups_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/groups/:group_id",
          "summary": "Delete a group by Id",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "group_id",
              "description": "Group ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "workloads": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/workloads/autocomplete",
          "summary": "return workloads autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "selected_scope",
              "description": "Scope objects containing key and href indicating which user scope to use. Defined in org_scope.schema.json",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/workloads/facets",
          "summary": "return workloads filter facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "enforcement_mode",
              "description": "Mode",
              "enum": [
                "visibility_only",
                "full",
                "idle",
                "selective"
              ],
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "log_traffic",
              "description": "Whether we want to log traffic events from this workload",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "hostname",
              "description": "Hostname",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "os_id",
              "description": "OS ID",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "online",
              "description": "Online",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "ip_address",
              "description": "IP Address",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "policy_health",
              "description": "Policy of health of workload(s) to return. Valid values: active, warning, error",
              "enum": [
                "error",
                "warning",
                "active"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Container cluster UUID",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "selected_scope",
              "description": "Scope objects containing key and href indicating which user scope to use. Defined in org_scope.schema.json",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": [
            "this_org_user",
            "interservice"
          ],
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/workloads",
          "summary": "Used to create a workload",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "workloads_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/workloads",
          "summary": "Get the workload information",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "labels",
              "description": "List of lists of label URIs, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "include_deleted",
              "description": "Include deleted workloads",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "soft_deleted",
              "description": "DEPRECATED WITH NO REPLACEMENT: Only soft-deleted workloads",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "ip_address",
              "description": "IP address of workload(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_set",
              "description": "The data source from which a resource originates",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_reference",
              "description": "A unique identifier within the external data source",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "security_policy_update_mode",
              "description": "Advanced search option for workload based on security policy update mode",
              "enum": [
                "static",
                "adaptive"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "security_policy_sync_state",
              "description": "Advanced search option for workload based on policy sync state",
              "enum": [
                "staged"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "mode",
              "description": "Management mode of workload(s) to return. DEPRECATED AND REPLACED (Use enforcement_mode)",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "enforcement_mode",
              "description": "Enforcement mode of workload(s) to return",
              "enum": [
                "visibility_only",
                "full",
                "idle",
                "selective"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "enforcement_modes",
              "description": "List of enforcement modes, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "visibility_level",
              "description": "Filter by visibility level",
              "enum": [
                "flow_full_detail",
                "flow_summary",
                "flow_drops",
                "flow_off",
                "enhanced_data_collection"
              ],
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "log_traffic",
              "description": "Whether we want to log traffic events from this workload",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "online",
              "description": "Return online/offline workloads using this filter",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "hostname",
              "description": "Hostname of workload(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of workload(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description of workload(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "os_id",
              "description": "Operating System of workload(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "policy_health",
              "description": "Policy of health of workload(s) to return. Valid values: active, warning, error, suspended",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "risk_summary.ransomware.workload_exposure_severity",
              "description": "The ransomware severity of the workload. Valid values: low, medium, high, critical, fully_protected",
              "query_param": true
            },
            {
              "dataType": "Hash",
              "name": "security_policy_applied_at",
              "description": "Security policy applied at timestamps of workload(s) to return",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": null,
              "name": "security_policy_applied_at[gte]",
              "description": "Greater than or equal to value for security policy applied at timestamp",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": null,
              "name": "security_policy_applied_at[lte]",
              "description": "Less than or equal to value for security policy applied at timestamp",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "Hash",
              "name": "security_policy_received_at",
              "description": "Security policy received at timestamps of workload(s) to return",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": null,
              "name": "security_policy_received_at[gte]",
              "description": "Greater than or equal to value for security policy received at timestamp",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": null,
              "name": "security_policy_received_at[lte]",
              "description": "Less than or equal to value for security policy received at timestamp",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "boolean",
              "name": "managed",
              "description": "Return managed or unmanaged workloads using this filter",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of workloads to return.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "advanced_search_name",
              "description": "Advanced search option for workload name",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "advanced_search_hostname",
              "description": "Advanced search option for workload hostname",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "ven.ven_type",
              "description": "Return only Workloads with VENs of the specific type: server, endpoint, or containerized",
              "enum": [
                "server",
                "endpoint",
                "containerized"
              ],
              "query_param": true,
              "expose_to": [
                "end_user_experimental"
              ]
            },
            {
              "dataType": "Hash",
              "name": "last_heartbeat_on",
              "description": "Last heartbeat on timestamp for the workload(s) to return",
              "query_param": true,
              "expose_to": [
                "end_user_experimental"
              ]
            },
            {
              "dataType": null,
              "name": "last_heartbeat_on[gte]",
              "description": "Greater than or equal to value for last heartbeat on timestamp",
              "query_param": true
            },
            {
              "dataType": null,
              "name": "last_heartbeat_on[lte]",
              "description": "Less than or equal to value for last heartbeat on timestamp",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "agent.active_pce_fqdn",
              "description": "FQDN of the PCE",
              "query_param": true,
              "expose_to": [
                "end_user_experimental"
              ]
            },
            {
              "dataType": "Hash",
              "name": "vulnerability_summary.vulnerability_exposure_score",
              "description": "Vulnerability exposure score for the workload(s) to return",
              "query_param": true,
              "expose_to": [
                "end_user_experimental"
              ]
            },
            {
              "dataType": "integer",
              "name": "vulnerability_summary.vulnerability_exposure_score[gte]",
              "description": "Greater than or equal to value for vulnerability_exposure_score",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "vulnerability_summary.vulnerability_exposure_score[lte]",
              "description": "Less than or equal to value for vulnerability_exposure_score",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "container_clusters",
              "description": "List  of container cluster URIs, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "include_ventual_interfaces",
              "description": "Include ventual interfaces",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "ven",
              "description": "URI of VEN to filter by.",
              "query_param": true,
              "expose_to": [
                "end_user_experimental"
              ]
            },
            {
              "dataType": "string",
              "name": "selective_enforcement_rule",
              "description": "URI of Selective enforcement rule to filter by",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "workloads_get",
          "expose_to": [
            "end_user_public",
            "interservice"
          ],
          "operation_type": "read",
          "authz": [
            "this_org_user",
            "interservice",
            "any_kubelink"
          ],
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "unpair": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/workloads/unpair",
          "summary": "DEPRECATED AND REPLACED (USE /orgs/:xorg_id/vens/unpair INSTEAD)",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "workloads_unpair_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/workloads/update",
          "summary": "Sent by the user to update the mode on multiple workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "workloads_update_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "set_labels": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/workloads/set_labels",
          "summary": "Sent by the user to set the label on multiple workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "workloads_set_labels_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "remove_labels": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/workloads/remove_labels",
          "summary": "Sent by the user to remove a label from multiple workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "workloads_remove_labels_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "set_flow_reporting_frequency": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/workloads/set_flow_reporting_frequency",
          "summary": "Set the flow reporting frequency for multiple workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "workloads_set_flow_reporting_frequency_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "bulk_create": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/workloads/bulk_create",
          "summary": "Bulk create workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "workloads_bulk_create_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": [
            "this_org_user_for_workloads",
            "any_kubelink"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "bulk_import": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/workloads/bulk_import",
          "summary": "Bulk update workloads using csv file",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "create_labels",
              "description": "When updating labels via csv, create new labels if they do not exist",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "delete_token",
              "description": "delete token is used to specify the value in the csv which indicates the deletion of a label",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "dry_run",
              "description": "in draft mode, api will only return the statistics of the potential changes but not making any changes",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "bulk_update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/workloads/bulk_update",
          "summary": "Bulk update workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "create_labels",
              "description": "When updating labels via csv, create new labels if they do not exist",
              "query_param": true,
              "expose_to": [
                "end_user_private_transitional"
              ]
            }
          ],
          "schema": "workloads_bulk_update_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": [
            "this_org_user_for_workloads",
            "any_kubelink"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "bulk_delete": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/workloads/bulk_delete",
          "summary": "Bulk delete workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "workloads_bulk_delete_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": [
            "this_org_user_for_workloads",
            "any_kubelink"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "apply_policy": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/workloads/apply_policy",
          "summary": "Apply policy on static workloads that have pending policy",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "workloads_apply_policy_post",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "verify_caps": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/workloads/verify_caps",
          "summary": "Verify permission caps for a workload with the requested labels for the logged-in user",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "workloads_verify_caps_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "detailed_vulnerabilities": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/workloads/detailed_vulnerabilities",
          "summary": "Get the list of workloads with the detailed vulnerability data",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "workloads_detailed_vulnerabilities_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": [
            "this_org_user"
          ],
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/workloads/:workload_id",
          "summary": "Get the workload information",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "boolean",
              "name": "include_ventual_interfaces",
              "description": "Include ventual interfaces",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "workloads_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "workload": {
      "methods": {
        "risk_details": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/workloads/:workload_id/risk_details",
          "summary": "Get ransomware details for this workload",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            }
          ],
          "schema": "workloads_risk_details_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": [
            "this_org_user"
          ],
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/workloads/:workload_id",
          "summary": "Update the workload information",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            }
          ],
          "schema": "workloads_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/workloads/:workload_id",
          "summary": "Delete the workload record",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "recalc_rules": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/workloads/:workload_id/recalc_rules",
          "summary": "Force policy recalc for single workload",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "event_log": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/workloads/:workload_id/event/log",
          "summary": "DEPRECATED WITH NO REPLACEMENT: Send lightning bolt to agent",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "message",
              "description": "Message to send to agent",
              "query_param": true
            }
          ],
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": false
        },
        "gen_support_report": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/workloads/:workload_id/gen_support_report",
          "summary": "DEPRECATED WITH NO REPLACEMENT.",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            }
          ],
          "schema": "workloads_gen_support_report_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": false
        },
        "redetect_network": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/workloads/:workload_id/redetect_network",
          "summary": "Redetect the network for this workload",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            }
          ],
          "schema": "workloads_redetect_network_put",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "service_reports": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/workloads/:workload_id/service_reports",
          "summary": "Post a new service report",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "service_reports_latest": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/workloads/:workload_id/service_reports/latest",
          "summary": "Get the latest service report",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            }
          ],
          "schema": "workloads_service_reports_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "labels": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/labels/autocomplete",
          "summary": "return labels autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "key",
              "description": "Key",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "label_group",
              "description": "URI of Label Group that will be the parent of any chosen Label. Used for filtering existing Labels from results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "selected_scope",
              "description": "Scope objects containing key and href indicating which user scope to use. Defined in org_scope.schema.json",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "resource_type",
              "description": "Resource type with which labels are associated",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/labels/facets",
          "summary": "return labels filter facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "key",
              "description": "Key",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "value",
              "description": "Value",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/labels",
          "summary": "Return the set of labels",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "key",
              "description": "Key by which to filter",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "usage",
              "description": "Include label usage flags as well",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "include_deleted",
              "description": "Include deleted labels",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_set",
              "description": "The data source from which a resource originates",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_reference",
              "description": "A unique identifier within the external data source",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "value",
              "description": "Value on which to filter. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of Labels to return.",
              "query_param": true
            }
          ],
          "schema": "labels_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": [
            "this_org_user",
            "any_kubelink"
          ],
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/labels",
          "summary": "Create a label",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "labels_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/labels/delete",
          "summary": "Delete multiple labels",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "labels_delete_put",
          "expose_to": [
            "end_user_experimental_ms_only"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/labels/:label_id",
          "summary": "Get label by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "label_id",
              "description": "Label ID",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "usage",
              "description": "Include label usage flags as well",
              "query_param": true
            }
          ],
          "schema": "labels_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "label": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/labels/:label_id",
          "summary": "Modify a label by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "label_id",
              "description": "Label ID",
              "required": true
            }
          ],
          "schema": "labels_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/labels/:label_id",
          "summary": "Delete a label by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "label_id",
              "description": "Label ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "security_principals": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/security_principals/autocomplete",
          "summary": "return security principal autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/security_principals/facets",
          "summary": "return security principal facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "sid",
              "description": "SID",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/security_principals",
          "summary": "Get all security principals for an org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of security principal to filter by",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "sid",
              "description": "SID of security principal to filter by",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of entries to return",
              "query_param": true
            }
          ],
          "schema": "security_principals_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/security_principals",
          "summary": "Create a new security principal for an org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "security_principals_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "bulk_create": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/security_principals/bulk_create",
          "summary": "Bulk create security principals for an org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "security_principals_bulk_create_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/security_principals/:sid",
          "summary": "Get the info for one SID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "sid",
              "description": "Security principal SID",
              "required": true
            }
          ],
          "schema": "security_principals_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "security_principal": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/security_principals/:sid",
          "summary": "Modify the security principal for one SID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "sid",
              "description": "Security principal SID",
              "required": true
            }
          ],
          "schema": "security_principals_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/security_principals/:sid",
          "summary": "Delete a specific security principal",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "sid",
              "description": "Security principal SID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "pairing_profiles": {
      "collection": true,
      "methods": {
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/pairing_profiles/facets",
          "summary": "return pairing profile facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "selected_scope",
              "description": "Scope objects containing key and href indicating which user scope to use. Defined in org_scope.schema.json",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "this_org_workload_manager"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/pairing_profiles",
          "summary": "Return the set of pairing profiles",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "labels",
              "description": "List of lists of label URIs, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of Pairing Profile(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description of Pairing Profile(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of Pairing Profiles to return.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_set",
              "description": "The data source from which a resource originates",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_reference",
              "description": "A unique identifier within the external data source",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "agent_software_release",
              "description": "The agent software release for pairing profiles",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "ven_type",
              "description": "Specifies the pairing profile by the VEN type: server, endpoint, or specified_during_activation",
              "enum": [
                "specified_during_activation",
                "server",
                "endpoint"
              ],
              "query_param": true
            }
          ],
          "schema": "pairing_profiles_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "this_org_workload_manager"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/pairing_profiles",
          "summary": "Create a pairing profile",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "pairing_profiles_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/pairing_profiles/delete",
          "summary": "Delete multiple pairing profiles",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "pairing_profiles_delete_put",
          "expose_to": [
            "end_user_experimental_ms_only"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/pairing_profiles/:pairing_profile_id",
          "summary": "Get pairing profile by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "pairing_profile_id",
              "description": "Pairing Profile ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "pairing_profiles_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "this_org_workload_manager"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "pairing_profile": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/pairing_profiles/:pairing_profile_id",
          "summary": "Modify a pairing profile by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "pairing_profile_id",
              "description": "Pairing Profile ID",
              "required": true
            }
          ],
          "schema": "pairing_profiles_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "pairing_key": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/pairing_profiles/:pairing_profile_id/pairing_key",
          "summary": "Generate a pairing key for a pairing profile by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "pairing_profile_id",
              "description": "Pairing Profile ID",
              "required": true
            }
          ],
          "schema": "pairing_profiles_pairing_key_post",
          "expose_to": [
            "end_user_public"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/pairing_profiles/:pairing_profile_id",
          "summary": "Delete a pairing profile by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "pairing_profile_id",
              "description": "Pairing Profile ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "pairing_keys": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/pairing_profiles/:pairing_profile_id/pairing_keys",
          "summary": "Delete all pairing keys of a pairing profile by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "pairing_profile_id",
              "description": "Pairing Profile ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        }
      }
    },
    "networks": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/networks/autocomplete",
          "summary": "return network autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/networks/facets",
          "summary": "return network filter facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "data_center",
              "description": "Data Center",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/networks",
          "summary": "Get the list of networks",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of Networks to return. **Supports partial matches**",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description of Networks to return. **Supports partial matches**",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "data_center",
              "description": "Data center name by which to filter Networks. **Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of Networks to return.",
              "query_param": true
            }
          ],
          "schema": "networks_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/networks",
          "summary": "Create a new network",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "networks_post",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/networks/:network_id",
          "summary": "Get a network by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "network_id",
              "description": "Network UUID",
              "required": true
            }
          ],
          "schema": "networks_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        }
      }
    },
    "network": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/networks/:network_id",
          "summary": "Update a network",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "network_id",
              "description": "Network UUID",
              "required": true
            }
          ],
          "schema": "networks_put",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/networks/:network_id",
          "summary": "Delete a network",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "network_id",
              "description": "Network UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "enforcement_boundaries": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/enforcement_boundaries/autocomplete",
          "summary": "enforcement boundaries autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/enforcement_boundaries/facets",
          "summary": "enforcement boundaries facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "service_ports.proto",
              "description": "Protocol to filter on",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "service_ports.port",
              "description": "Specify port or port range to filter results. The range is from -1 to 65535.",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/enforcement_boundaries",
          "summary": "Get collection of enforcement boundaries",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "labels",
              "description": "List of lists of label URIs, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Filter by name supports partial matching",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "service",
              "description": "Service URI",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "service_ports.proto",
              "description": "Protocol to filter on",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "service_ports.port",
              "description": "Specify port or port range to filter results. The range is from -1 to 65535.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "network_type",
              "description": "Filter by network type",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "update_type",
              "description": "Update type (create, update, delete)",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of enforcement boundaries to return.",
              "query_param": true
            }
          ],
          "schema": "sec_policy_enforcement_boundaries_get",
          "expose_to": [
            "end_user_experimental",
            "interservice"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "interservice"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/enforcement_boundaries",
          "summary": "Create a new enforcement boundary",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_enforcement_boundaries_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "has_any": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/enforcement_boundaries/has_any",
          "summary": "Get if there are any enforcement boundaries",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/enforcement_boundaries/:enforcement_boundary_id",
          "summary": "Get enforcement boundary by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "enforcement_boundary_id",
              "description": "Enforcement boundary ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "sec_policy_enforcement_boundaries_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        }
      }
    },
    "enforcement_boundary": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/enforcement_boundaries/:enforcement_boundary_id",
          "summary": "Modify enforcement boundary",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "enforcement_boundary_id",
              "description": "Enforcement boundary ID",
              "required": true
            }
          ],
          "schema": "sec_policy_enforcement_boundaries_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/enforcement_boundaries/:enforcement_boundary_id",
          "summary": "Delete enforcement boundary",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "enforcement_boundary_id",
              "description": "Enforcement boundary ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "virtual_services": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_services/autocomplete",
          "summary": "return virtual services autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "selected_scope",
              "description": "Scope objects containing key and href indicating which user scope to use. Defined in org_scope.schema.json",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_services/facets",
          "summary": "virtual services filter facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "service_address.ip",
              "description": "IP address configured under service_address property, supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "service_address.fqdn",
              "description": "FQDN configured under service_address property, supports partial matches",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "service_ports.proto",
              "description": "Protocol to filter on",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "service_ports.port",
              "description": "Specify port or port range to filter results. The range is from -1 to 65535.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "selected_scope",
              "description": "Scope objects containing key and href indicating which user scope to use. Defined in org_scope.schema.json",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_services",
          "summary": "Return the set of Virtual Services",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "labels",
              "description": "List of lists of label URIs, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "usage",
              "description": "Include Virtual Service usage flags",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_set",
              "description": "The data source from which a resource originates",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_reference",
              "description": "A unique identifier within the external data source",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name on which to filter. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description on which to filter. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of Virtual Services to return.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "service_address.ip",
              "description": "IP address configured under service_address property, supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "service_address.fqdn",
              "description": "FQDN configured under service_address property, supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "service",
              "description": "Service URI",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "service_ports.proto",
              "description": "Protocol to filter on",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "service_ports.port",
              "description": "Specify port or port range to filter results. The range is from -1 to 65535.",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "deleted_container_cluster",
              "description": "Show Virtual Services created by container clusters which are now deleted",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "created_by_container_clusters",
              "description": "List of container cluster URIs, encoded as a JSON string",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "sec_policy_virtual_services_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_services",
          "summary": "Create a virtual service",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_virtual_services_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "bulk_create": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_services/bulk_create",
          "summary": "Bulk create virtual services",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_virtual_services_bulk_create_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "bulk_update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_services/bulk_update",
          "summary": "Bulk update virtual services",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_virtual_services_bulk_update_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_services/:virtual_service_id",
          "summary": "Get Virtual Service by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "virtual_service_id",
              "description": "Virtual Service ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "boolean",
              "name": "usage",
              "description": "Include Virtual Service usage flags",
              "query_param": true
            }
          ],
          "schema": "sec_policy_virtual_services_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "virtual_service": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_services/:virtual_service_id",
          "summary": "Modify a Virtual Service by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "virtual_service_id",
              "description": "Virtual Service ID",
              "required": true
            }
          ],
          "schema": "sec_policy_virtual_services_put",
          "expose_to": [
            "end_user_public"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_services/:virtual_service_id",
          "summary": "Delete a Virtual Service by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "virtual_service_id",
              "description": "Virtual Service ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "ip_lists": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/ip_lists/autocomplete",
          "summary": "return IP lists autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/ip_lists/facets",
          "summary": "return IP lists filter facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "ip_address",
              "description": "IP address matching IP list(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "update_type",
              "description": "Update type",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "fqdn",
              "description": "IP lists matching FQDN. Supports partial matches",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/ip_lists",
          "summary": "Get the collection of IP lists",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "external_data_set",
              "description": "The data source from which a resource originates",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_reference",
              "description": "A unique identifier within the external data source",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of IP list(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description of IP list(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "ip_address",
              "description": "IP address matching IP list(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "update_type",
              "description": "Update type (create, update, delete)",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of IP Lists to return.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "fqdn",
              "description": "IP lists matching FQDN. Supports partial matches",
              "query_param": true
            }
          ],
          "schema": "sec_policy_ip_lists_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/ip_lists",
          "summary": "Create new IP List",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_ip_lists_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/ip_lists/delete",
          "summary": "Delete multiple IP Lists",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_ip_lists_delete_put",
          "expose_to": [
            "end_user_experimental_ms_only"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/ip_lists/:ip_list_id",
          "summary": "Get IP list by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "ip_list_id",
              "description": "IP list ID",
              "required": true
            }
          ],
          "schema": "sec_policy_ip_lists_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "ip_list": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/ip_lists/:ip_list_id",
          "summary": "Modify IP list",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "ip_list_id",
              "description": "IP list ID",
              "required": true
            }
          ],
          "schema": "sec_policy_ip_lists_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/ip_lists/:ip_list_id",
          "summary": "Delete IP list",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "ip_list_id",
              "description": "IP list ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "label_groups": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/label_groups/autocomplete",
          "summary": "return label groups autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "key",
              "description": "Key",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "label_group",
              "description": "URI of Label Group that will be the parent of any chosen Label Group. It and any of its ancestors will be filtered from results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "selected_scope",
              "description": "Scope objects containing key and href indicating which user scope to use. Defined in org_scope.schema.json",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "resource_type",
              "description": "Resource type with which label groups are associated",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/label_groups/facets",
          "summary": "return label groups filter facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "key",
              "description": "Key",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "update_type",
              "description": "Update type",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/label_groups",
          "summary": "Create label group",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_label_groups_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/label_groups",
          "summary": "Get the label groups",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "usage",
              "description": "Include label usage flags as well",
              "query_param": true,
              "expose_to": [
                "end_user_experimental"
              ]
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of Label Group(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description of Label Group(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "key",
              "description": "Key by which to filter",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "update_type",
              "description": "Update type (create, update, delete)",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of Labels to return.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_set",
              "description": "The data source from which a resource originates",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_reference",
              "description": "A unique identifier within the external data source",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "sec_policy_label_groups_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/label_groups/:label_group_id",
          "summary": "Get label group",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "label_group_id",
              "description": "Label Group UUID",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "usage",
              "description": "Include label usage flags as well",
              "query_param": true
            }
          ],
          "schema": "sec_policy_label_groups_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "label_group": {
      "methods": {
        "member_of": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/label_groups/:label_group_id/member_of",
          "summary": "Get the parents of this label group",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "label_group_id",
              "description": "Label Group UUID",
              "required": true
            }
          ],
          "schema": "sec_policy_label_groups_member_of_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "all_labels": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/label_groups/:label_group_id/all_labels",
          "summary": "Get all labels in this label group, including it's and sub-groups",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "label_group_id",
              "description": "Label Group UUID",
              "required": true
            }
          ],
          "schema": "labels_summary",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/label_groups/:label_group_id",
          "summary": "Update the label group",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "label_group_id",
              "description": "Label Group UUID",
              "required": true
            }
          ],
          "schema": "sec_policy_label_groups_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/label_groups/:label_group_id",
          "summary": "Delete label group",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "label_group_id",
              "description": "Label Group UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "services": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/services/autocomplete",
          "summary": "return services autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "type",
              "description": "Service type to filter on",
              "enum": [
                "windows",
                "all"
              ],
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/services/facets",
          "summary": "return services filter facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "port",
              "description": "Port or Port range (Example of range representation => 1058-1099)",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "protocol",
              "description": "Protocol",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "update_type",
              "description": "Update type",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/services",
          "summary": "Get the collection of Services",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "exclude_all_svc",
              "description": "Exclude 'All Services' service",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "external_data_set",
              "description": "The data source from which a resource originates",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_reference",
              "description": "A unique identifier within the external data source",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of Service(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description of Service(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "update_type",
              "description": "Update type (create, update, delete)",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "port",
              "description": "Specify port or port range to filter results. The range is from -1 to 65535 (0 is not supported).",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "proto",
              "description": "Protocol to filter on",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of Services to return.",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "is_ransomware",
              "description": "Services associated with ransomware.",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "include_ransomware_protection_percent",
              "description": "Include average protection percent in ransomware object.",
              "query_param": true
            }
          ],
          "schema": "sec_policy_services_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/services",
          "summary": "Create new Service",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_services_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/services/delete",
          "summary": "Delete multiple services",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_services_delete_put",
          "expose_to": [
            "end_user_experimental_ms_only"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/services/:service_id",
          "summary": "Get Service by id",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "service_id",
              "description": "Service ID",
              "required": true
            }
          ],
          "schema": "sec_policy_services_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "service": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/services/:service_id",
          "summary": "Modify Service",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "service_id",
              "description": "Service ID",
              "required": true
            }
          ],
          "schema": "sec_policy_services_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/services/:service_id",
          "summary": "Delete Service",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "service_id",
              "description": "Service ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "virtual_servers": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_servers/autocomplete",
          "summary": "return virtual server autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_servers/facets",
          "summary": "return virtual servers filter facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "mode",
              "description": "Mode",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_servers",
          "summary": "Create a virtual server",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_virtual_servers_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_servers",
          "summary": "Get the virtual servers",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "external_data_set",
              "description": "The data source from which a resource originates",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_reference",
              "description": "A unique identifier within the external data source",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of virtual server(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "slb",
              "description": "URI of SLB object to filter virtual server(s)",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "vip",
              "description": "Frontend (VIP) address of the virtual server(s). Supports suffix-wildcard matches",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "vip_proto",
              "description": "Protocol of frontend VIP of the virtual server(s)",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "vip_port",
              "description": "Port of frontend VIP of the virtual server(s)",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "labels",
              "description": "2D array of label URIs, encoded as a JSON string. Filter by virtual server labels.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "mode",
              "description": "Mode of the virtual server(s) to return",
              "enum": [
                "unmanaged",
                "enforced"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "active_pce_fqdn",
              "description": "FQDN of the PCE",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "network_enforcement_node.hostname",
              "description": "Hostname of NEN object to filter virtual server(s)",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "discovered_virtual_server",
              "description": "URI of discovered virtual server to filter by",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of discovered virtual servers to return",
              "query_param": true
            }
          ],
          "schema": "sec_policy_virtual_servers_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_servers/:virtual_server_id",
          "summary": "Get the virtual server",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "virtual_server_id",
              "description": "Virtual server UUID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "sec_policy_virtual_servers_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "virtual_server": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_servers/:virtual_server_id",
          "summary": "Update the virtual server",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "virtual_server_id",
              "description": "Virtual server UUID",
              "required": true
            }
          ],
          "schema": "sec_policy_virtual_servers_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/virtual_servers/:virtual_server_id",
          "summary": "Delete the virtual server",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "virtual_server_id",
              "description": "Virtual server UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "rule_sets": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/autocomplete",
          "summary": "return rule sets autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/facets",
          "summary": "return rule sets filter facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "update_type",
              "description": "Update type",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "selected_scope",
              "description": "Scope objects containing key and href indicating which user scope to use. Defined in org_scope.schema.json",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets",
          "summary": "Get the collection of Rule Sets",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "labels",
              "description": "List of lists of label URIs, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "labels_scope",
              "description": "List of label URIs, encoded as a JSON string, used as short hand for queries consisting of all permutations of valid label uris and placeholders.",
              "query_param": true,
              "expose_to": [
                "end_user_private_transitional"
              ]
            },
            {
              "dataType": "string",
              "name": "external_data_set",
              "description": "The data source from which a resource originates",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_reference",
              "description": "A unique identifier within the external data source",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of Rule Set(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description of Rule Set(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "update_type",
              "description": "Update type (create, update, delete)",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of Rule Sets to return.",
              "query_param": true
            }
          ],
          "schema": "sec_policy_rule_sets_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets",
          "summary": "Create new Rule Set",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Limited Ruleset Manager"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/delete",
          "summary": "Delete multiple Rule Sets",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_delete_put",
          "expose_to": [
            "end_user_experimental_ms_only"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Limited Ruleset Manager"
          ],
          "auditable": true
        },
        "projected_ves": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/projected_ves",
          "summary": "Get vulnerability scores for potential new policy",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_projected_ves_post",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "outbound_allow_ruleset": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/outbound_allow_ruleset",
          "summary": "Get the Edge outbound allow ruleset href",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_outbound_allow_ruleset_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id",
          "summary": "Get Rule Set by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "sec_policy_rule_sets_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      }
    },
    "rule_set": {
      "methods": {
        "projected_ves": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/projected_ves",
          "summary": "Get vulnerability scores for potential new policy",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_projected_ves_existing_post",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id",
          "summary": "Modify Rule Set",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Limited Ruleset Manager"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id",
          "summary": "Delete Rule Set",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Limited Ruleset Manager"
          ],
          "auditable": true
        },
        "connectivity": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/connectivity",
          "summary": "Get rule connectivity information for all rules in this rule set",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "max_most_connected_scoped_rules",
              "description": "The maximum number of scoped rules to return. Defaults to 5; maximum 500.",
              "enum": "0..500",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_workload_statistical_intervals",
              "description": "The number of statistical intervals to group the workloads into. Defaults to 3; maximum 500.",
              "enum": "0..500",
              "query_param": true
            }
          ],
          "schema": "sec_policy_rule_sets_connectivity_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      }
    },
    "container_workloads": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_workloads/autocomplete",
          "summary": "return container workloads auto complete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "selected_scope",
              "description": "Scope objects containing key and href indicating which user scope to use. Defined in org_scope.schema.json",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_workloads/facets",
          "summary": "return container workloads filter facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "enforcement_mode",
              "description": "Mode",
              "enum": [
                "visibility_only",
                "full",
                "idle",
                "selective"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "ip_address",
              "description": "IP Address",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Container cluster UUID",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "selected_scope",
              "description": "Scope objects containing key and href indicating which user scope to use. Defined in org_scope.schema.json",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "namespace",
              "description": "namespace the container belong to",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": [
            "this_org_user",
            "interservice"
          ],
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_workloads",
          "summary": "Get all container workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "labels",
              "description": "List of lists of label URIs, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "ip_address",
              "description": "IP address of container workload(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "container_hosts",
              "description": "List of workload URIs, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "all",
              "description": "Include non-active container workloads",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "include_ventual_interfaces",
              "description": "Include ventual interfaces",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": null,
              "name": "external_data_reference",
              "description": "Query by an external id",
              "query_param": true
            },
            {
              "dataType": null,
              "name": "external_data_set",
              "description": "Query by an external data set",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "container_clusters",
              "description": "List  of container cluster URIs, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of container workload(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "enforcement_mode",
              "description": "Enforcement mode of container workload(s) to return",
              "enum": [
                "visibility_only",
                "full",
                "idle",
                "selective"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "visibility_level",
              "description": "Filter by visibility level",
              "enum": [
                "flow_full_detail",
                "flow_summary",
                "flow_drops",
                "flow_off",
                "enhanced_data_collection"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "namespace",
              "description": "Namespace of container workload(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of container workloads to return.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "PCE FQDN for this container workload. Used in Supercluster only",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "ven",
              "description": "URI of VEN to filter by.",
              "query_param": true,
              "expose_to": [
                "end_user_experimental"
              ]
            }
          ],
          "schema": "container_workloads_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_workloads/:container_workload_id",
          "summary": "Get the info for one container workload",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_workload_id",
              "description": "ContainerWorkload uuid",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "include_ventual_interfaces",
              "description": "Include ventual interfaces",
              "query_param": true
            }
          ],
          "schema": "container_workloads_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "container_workload": {
      "methods": {
        "running_containers": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_workloads/:container_workload_id/running_containers",
          "summary": "Containers running on a ContainerWorkload",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_workload_id",
              "description": "ContainerWorkload uuid",
              "required": true
            }
          ],
          "schema": "container_workloads_running_containers_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "container_workload_profiles": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/container_workload_profiles/autocomplete",
          "summary": "return container workload profiles autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/container_workload_profiles/facets",
          "summary": "ontainer workload profiles facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "namespace",
              "description": "Name",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/container_workload_profiles",
          "summary": "Get collection of container workload profiles",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "namespace",
              "description": "Namespace string to match.Supports partial matches.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name string to match.Supports partial matches.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "enforcement_mode",
              "description": "Filter by enforcement mode.",
              "enum": [
                "visibility_only",
                "full",
                "idle",
                "selective"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "visibility_level",
              "description": "Filter by visibility level",
              "enum": [
                "flow_full_detail",
                "flow_summary",
                "flow_drops",
                "flow_off",
                "enhanced_data_collection"
              ],
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "linked",
              "description": "Filter by linked container workload profiles.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "assign_labels",
              "description": "List of lists of label URIs, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of container workloads to return.",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "managed",
              "description": "Filter by managed state",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "boolean",
              "name": "skip_default",
              "description": "Filter out default profile from results",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "container_clusters_container_workload_profiles_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "this_kubelink"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/container_workload_profiles",
          "summary": "Create a container workload profile",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_container_workload_profiles_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/container_workload_profiles/update",
          "summary": "Bulk update container workload profiles",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_container_workload_profiles_update_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/container_workload_profiles/:container_workload_profile_id",
          "summary": "Get a container workload profile by id",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_workload_profile_id",
              "description": "Container workload profile UUID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "container_clusters_container_workload_profiles_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "this_kubelink"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        }
      }
    },
    "container_workload_profile": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/container_workload_profiles/:container_workload_profile_id",
          "summary": "Update a container workload profile by id",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_workload_profile_id",
              "description": "Container workload profile UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_container_workload_profiles_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/container_workload_profiles/:container_workload_profile_id",
          "summary": "Delete a container workload profile by id",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_workload_profile_id",
              "description": "Container workload profile UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "container_clusters": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_clusters/autocomplete",
          "summary": "return container clusters autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_clusters/facets",
          "summary": "Container clusters facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_clusters",
          "summary": "Return the set of container_clusters",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pce_fqdn",
              "description": "PCE FQDN for this container cluster. Used in Supercluster only",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of the container cluster(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "clas_mode",
              "description": "CLAS Mode of container cluster(s) to return",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of container clusters to return",
              "query_param": true
            }
          ],
          "schema": "container_clusters_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/container_clusters",
          "summary": "Create a new container cluster",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "container_clusters_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id",
          "summary": "Return the container_cluster instance",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        }
      }
    },
    "container_cluster": {
      "methods": {
        "label_map": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/label_map",
          "summary": "Modify all label mappings at once",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_label_map_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_kubelink",
          "auditable": true
        },
        "put_from_cluster": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/put_from_cluster",
          "summary": "Update cluster by kubelink",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_put_from_cluster_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_kubelink",
          "auditable": true
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id",
          "summary": "Update a cluster",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id",
          "summary": "Delete a cluster",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "services": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/services",
          "summary": "Update the cluster services",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "refresh",
              "description": "Refresh all data",
              "query_param": true
            }
          ],
          "schema": "container_clusters_services_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_kubelink",
          "auditable": true
        },
        "services_delete": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/services/delete",
          "summary": "",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_services_delete_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_kubelink",
          "auditable": true
        },
        "service_backends": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/service_backends",
          "summary": "",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_service_backends_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "support_report_info": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/support_report_info",
          "summary": "PCE contributions to container cluster support report",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_support_report_info_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_kubelink",
          "auditable": false
        },
        "authenticate_kubelink": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/authenticate_kubelink",
          "summary": "Authenticate Kubelink",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_authenticate_kubelink_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "interservice",
          "auditable": false
        },
        "clas_auth_public_key": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/clas_auth_public_key",
          "summary": "Get CLAS RSA public key for specific cluster",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_kubelink",
          "auditable": false
        },
        "policies": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/policies",
          "summary": "Get security policy for all workloads under container cluster",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "force",
              "description": "Forces full recalculation of policy when enabled",
              "query_param": true
            }
          ],
          "schema": "sec_policy_policy_view_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_kubelink",
          "auditable": false
        },
        "security_policy_acks": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/security_policy_acks",
          "summary": "",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_security_policy_acks_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": [
            "this_kubelink"
          ],
          "auditable": true
        },
        "kubernetes_workloads": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/kubernetes_workloads",
          "summary": "",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "kubernetes_workloads_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": [
            "this_kubelink"
          ],
          "auditable": false
        },
        "kubernetes_workloads_bulk_create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/kubernetes_workloads/bulk_create",
          "summary": "Bulk create Kubernetes workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_kubernetes_workloads_bulk_create_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": [
            "this_kubelink"
          ],
          "auditable": true
        },
        "kubernetes_workloads_bulk_update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/kubernetes_workloads/bulk_update",
          "summary": "Bulk update of Kubernetes workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_kubernetes_workloads_bulk_update_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": [
            "this_kubelink"
          ],
          "auditable": true
        },
        "kubernetes_workloads_bulk_delete": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/container_clusters/:container_cluster_id/kubernetes_workloads/bulk_delete",
          "summary": "Bulk delete of Kubernetes workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Cluster UUID",
              "required": true
            }
          ],
          "schema": "container_clusters_kubernetes_workloads_bulk_delete_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": [
            "this_kubelink"
          ],
          "auditable": true
        }
      }
    },
    "vens": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/vens/autocomplete",
          "summary": "Return VEN autocomplete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "selected_scope",
              "description": "Scope objects containing key and href indicating which user scope to use. Defined in org_scope.schema.json",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/vens/facets",
          "summary": "VEN facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Container cluster UUID",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "VEN name to filter facet results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "hostname",
              "description": "VEN hostname to filter facet results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "ip_address",
              "description": "VEN ip_address to filter facet results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "os_id",
              "description": "VEN os_id to filter facet results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "version",
              "description": "VEN version to filter facet results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "status",
              "description": "VEN status to filter facet results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "VEN description to filter facet results",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "selected_scope",
              "description": "Scope objects containing key and href indicating which user scope to use. Defined in org_scope.schema.json",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/vens",
          "summary": "Get all VENs",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of VEN(s) to return. Supports partial matches.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "hostname",
              "description": "Hostname of VEN(s) to return. Supports partial matches.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description of VEN(s) to return. Supports partial matches.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "os",
              "description": "Operating System of VEN(s) to return. Supports partial matches.",
              "query_param": true
            },
            {
              "dataType": "Hash",
              "name": "version",
              "description": "The minimum and maximum VEN software version. Supports partial versions (e.g. 19.2).",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "version[gte]",
              "description": "Greater than or equal to value for version",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "version[lte]",
              "description": "Less than or equal to value for version",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "status",
              "description": "The current status of the VEN",
              "enum": [
                "active",
                "suspended",
                "stopped",
                "uninstalled"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "health",
              "description": "The overall health (condition) of the VEN",
              "enum": [
                "healthy",
                "unhealthy",
                "error",
                "warning"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "condition",
              "description": "A specific error condition to filter by",
              "enum": [
                "agent.upgrade_time_out",
                "agent.missing_heartbeats_after_upgrade",
                "agent.clone_detected",
                "agent.missed_heartbeats",
                "agent.too_many_interfaces_reported",
                "agent.authentication_recovery",
                "agent.self_signed_certificate_in_grace_period",
                "agent.self_signed_certificate_expired",
                "agent.unauthenticated_clone_detected"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "activation_type",
              "description": "The method in which the VEN was activated",
              "enum": [
                "pairing_key",
                "kerberos",
                "ca_signed_certificate",
                "self_signed_certificate"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "active_pce_fqdn",
              "description": "FQDN of the PCE",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "labels",
              "description": "2D Array of label URIs, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "ip_address",
              "description": "IP address of VEN(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "DateTime",
              "name": "disconnected_before",
              "description": "Return VENs that have been disconnected since the given time",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "container_clusters",
              "description": "Array of container cluster URIs, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "upgrade_pending",
              "description": "Only return VENs with/without a pending upgrade",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "authentication_recovery",
              "description": "Return VENs in or not in authentication recovery",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "ven_type",
              "description": "Return only VENs of the specific type: server, endpoint, or containerized",
              "enum": [
                "server",
                "endpoint",
                "containerized"
              ],
              "query_param": true
            },
            {
              "dataType": "Hash",
              "name": "last_heartbeat_at",
              "description": "Timestamp of the VENs last heartbeat. Using this filter will result in region-specific VENs only.",
              "query_param": true
            },
            {
              "dataType": null,
              "name": "last_heartbeat_at[gte]",
              "description": "Greater than or equal to value for last heartbeat timestamp",
              "query_param": true
            },
            {
              "dataType": null,
              "name": "last_heartbeat_at[lte]",
              "description": "Less than or equal to value for last heartbeat timestamp",
              "query_param": true
            },
            {
              "dataType": "Hash",
              "name": "last_goodbye_at",
              "description": "Timestamp of the VENs last goodbye",
              "query_param": true
            },
            {
              "dataType": null,
              "name": "last_goodbye_at[gte]",
              "description": "Greater than or equal to value for last goodbye at timestamp",
              "query_param": true
            },
            {
              "dataType": null,
              "name": "last_goodbye_at[lte]",
              "description": "Less than or equal to value for last goodbye at timestamp",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of VENs to return.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "vens_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "unpair": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/vens/unpair",
          "summary": "Sent by the user to unpair multiple agents",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "vens_unpair_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "upgrade": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/vens/upgrade",
          "summary": "Sent by the user to upgrade VENs.",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "vens_upgrade_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "authentication_recovery": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/vens/authentication_recovery",
          "summary": "Enable or disable authentication recovery for a list of VENs",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "vens_authentication_recovery_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "clear_conditions": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/vens/clear_conditions",
          "summary": "Clear a condition from a list of VENs",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "vens_clear_conditions_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "statistics": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/vens/statistics",
          "summary": "",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "vens_statistics_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "generate_maintenance_token": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/vens/generate_maintenance_token",
          "summary": "Generate and return maintenance token that can be consumed by any VEN in org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "vens_generate_maintenance_token_post",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/vens/:ven_id",
          "summary": "Get one VEN",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "ven_id",
              "description": "VEN id",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "vens_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "ven": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/vens/:ven_id",
          "summary": "Update VENs name, description, or target PCE FQDN",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "ven_id",
              "description": "VEN id",
              "required": true
            }
          ],
          "schema": "vens_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "compatibility_report": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/vens/:ven_id/compatibility_report",
          "summary": "Get the latest compatibility check report for this workload",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "ven_id",
              "description": "VEN id",
              "required": true
            }
          ],
          "schema": "vens_compatibility_report_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "org_permissions": {
      "collection": true,
      "methods": {
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/permissions/facets",
          "summary": "return permissions facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "scoped_roles_only",
              "description": "Scope role permissions only",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/permissions",
          "summary": "Get all permissions for this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "scope",
              "description": "Scope to filter on, where scope is in the format defined in org_scope.schema.json",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "auth_security_principal",
              "description": "Auth_security_principal URI (JSON-encoded string) to filter on",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "role",
              "description": "Role URI (JSON-encoded string) to filter on",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "scoped_roles_only",
              "description": "Scope role permissions only",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "orgs_permissions_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "scope_roles": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/permissions/scope_roles",
          "summary": "Get the list of permissions as unique scopes and corresponding roles in this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "scope",
              "description": "Scope to filter on, where scope is in the format defined in org_scope.schema.json",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "partial_scope",
              "description": "Partial scope to filter on, where partial scope is in the format defined by org_scope.schema.json",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "auth_security_principal_name",
              "description": "Name of the auth_security_principal to filter on",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "role",
              "description": "Role URI (JSON-encoded string) to filter on",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of Permissions to return.",
              "query_param": true
            }
          ],
          "schema": "orgs_permissions_scope_roles_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/permissions",
          "summary": "Create a permission in this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "orgs_permissions_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/permissions/:permission_id",
          "summary": "Get details of this permission in this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "permission_id",
              "description": "UUID of the permission",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details with details of auth_security_principal and scope for permission",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "orgs_permissions_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        }
      }
    },
    "org_permission": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/permissions/:permission_id",
          "summary": "Update this permission in this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "permission_id",
              "description": "UUID of the permission",
              "required": true
            }
          ],
          "schema": "orgs_permissions_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/permissions/:permission_id",
          "summary": "Delete this permission in this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "permission_id",
              "description": "UUID of the permission",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "slbs": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/slbs/autocomplete",
          "summary": "SLBs autocomplete",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "has_virtual_server",
              "description": "Filter only for SLBs which have a policy virtual server",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/slbs/facets",
          "summary": "return SLB filter facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "device_type",
              "description": "Device type",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/slbs",
          "summary": "Create a server load balancer",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "slbs_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/slbs",
          "summary": "Get the server load balancers",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of SLBs to return.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name on which to filter. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "has_virtual_server",
              "description": "Filtering criteria -  if the SLB has a virtual server",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "description",
              "description": "Description of the SLB to filter. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "num_devices",
              "description": "Number of devices to filter",
              "enum": [
                1,
                2
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "device_type",
              "description": "Device type to filter",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "status",
              "description": "SLB status to filter",
              "enum": [
                "active",
                "pending",
                "error"
              ],
              "query_param": true
            }
          ],
          "schema": "slbs_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/slbs/:slb_id",
          "summary": "Get the server load balancer information",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "slb_id",
              "description": "Slb UUID",
              "required": true
            }
          ],
          "schema": "slbs_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "slb": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/slbs/:slb_id",
          "summary": "Update the server load balancer information",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "slb_id",
              "description": "Slb UUID",
              "required": true
            }
          ],
          "schema": "slbs_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/slbs/:slb_id",
          "summary": "Delete the server load balancer",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "slb_id",
              "description": "Slb UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "discovered_virtual_servers": {
      "collection": true,
      "methods": {
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/discovered_virtual_servers/facets",
          "summary": "Discovered virtual server facets",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Discovered virtual server facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Discovered virtual server name to filter by. Supports partial matches.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "vip",
              "description": "Discovered virtual server VIP to filter by. Supports suffix-wildcard matches.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "slb",
              "description": "href of an SLB to filter by.",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "vip_proto",
              "description": "Discovered virtual server protocol to filter by. Use integer form of protocol.",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "vip_port",
              "description": "Discovered virtual server VIP port to filter by",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "has_virtual_server",
              "description": "Filter only for discovered virtual servers with corresponding policy virtual server",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/discovered_virtual_servers",
          "summary": "Get the discovered virtual servers",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of discovered virtual server(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "slb",
              "description": "URI of SLB object to filter discovered virtual server(s)",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "vip",
              "description": "Frontend (VIP) address of the discovered virtual server(s). Supports suffix-wildcard matches",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "vip_proto",
              "description": "Protocol of frontend VIP of the discovered virtual server(s)",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "vip_port",
              "description": "Port of frontend VIP of the discovered virtual server(s)",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "has_virtual_server",
              "description": "Filter discovered virtual server(s) by whether they are managed by a virtual server object",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "virtual_server_mode",
              "description": "Filter discovered virtual server(s) by virtual server mode. Requires usage of has_virtual_server: true",
              "enum": [
                "unmanaged",
                "enforced"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "virtual_server_labels",
              "description": "2D array of label URIs, encoded as a JSON string. Filter by virtual server labels. Requires usage of has_virtual_server: true",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "active_pce_fqdn",
              "description": "FQDN of the PCE",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "network_enforcement_node.hostname",
              "description": "Hostname of NEN object to filter discovered virtual server(s)",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "virtual_server",
              "description": "URI of virtual server to filter discovered virtual server(s)",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of discovered virtual servers to return",
              "query_param": true
            }
          ],
          "schema": "discovered_virtual_servers_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/discovered_virtual_servers/:discovered_virtual_server_id",
          "summary": "Get the discovered virtual servers",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "discovered_virtual_server_id",
              "description": "Discovered virtual servers UUID",
              "required": true
            }
          ],
          "schema": "discovered_virtual_servers_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "discovered_virtual_server": {
      "methods": {
      }
    },
    "service_accounts": {
      "collection": true,
      "methods": {
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/service_accounts/facets",
          "summary": "",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Service Account facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/service_accounts",
          "summary": "Create a new service account",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "service_accounts_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/service_accounts",
          "summary": "Get service accounts",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of service account to filter by",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "role",
              "description": "Role URI (JSON-encoded string) to filter on",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of service accounts to return.",
              "query_param": true
            }
          ],
          "schema": "service_accounts_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/service_accounts/:service_account_id",
          "summary": "Read Service Account Info",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "service_account_id",
              "description": "Service account UUID",
              "required": true
            }
          ],
          "schema": "service_accounts_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        }
      }
    },
    "service_account": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/service_accounts/:service_account_id",
          "summary": "Update service account info",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "service_account_id",
              "description": "Service account UUID",
              "required": true
            }
          ],
          "schema": "service_accounts_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/service_accounts/:service_account_id",
          "summary": "Delete service account",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "service_account_id",
              "description": "Service account UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "auth_data": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/service_accounts/:service_account_id/auth_data",
          "summary": "Obtain auth_data in order to create service account session",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "service_account_id",
              "description": "Service account UUID",
              "required": true
            }
          ],
          "schema": "service_accounts_auth_data_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "interservice",
          "auditable": false
        }
      }
    },
    "kubernetes_workloads": {
      "collection": true,
      "methods": {
        "autocomplete": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/kubernetes_workloads/autocomplete",
          "summary": "return kubernetes workloads auto complete results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "selected_scope",
              "description": "Scope objects containing key and href indicating which user scope to use. Defined in org_scope.schema.json",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "facets": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/kubernetes_workloads/facets",
          "summary": "return kubernetes workloads filter facet results",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "facet",
              "description": "Facet name",
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "query",
              "description": "Query",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Max results",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "namespace",
              "description": "namespace the k8s container belong to",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "kind",
              "description": "Kind of k8s resource",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "enforcement_mode",
              "description": "Mode",
              "enum": [
                "visibility_only",
                "full",
                "idle",
                "selective"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "visibility_level",
              "description": "Visibility Level",
              "enum": [
                "flow_full_detail",
                "flow_summary",
                "flow_drops",
                "flow_off",
                "enhanced_data_collection"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "container_cluster_id",
              "description": "Container cluster UUID",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "selected_scope",
              "description": "Scope objects containing key and href indicating which user scope to use. Defined in org_scope.schema.json",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": [
            "this_org_user",
            "interservice"
          ],
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/kubernetes_workloads",
          "summary": "Get all kubernetes workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of kubernetes workload(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "namespace",
              "description": "Namespace of kubernetes workload(s) to return. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "kind",
              "description": "k8s resource kind (Deployment, ReplicaSet, etc.)",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "labels",
              "description": "List of label URIs to include, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "no_labels",
              "description": "List of label URIs to NOT include, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "container_clusters",
              "description": "List  of container cluster URIs, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "enforcement_mode",
              "description": "Enforcement mode of kubernetes workload(s) to return",
              "enum": [
                "visibility_only",
                "full",
                "idle",
                "selective"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "visibility_level",
              "description": "Filter by visibility level",
              "enum": [
                "flow_full_detail",
                "flow_summary",
                "flow_drops",
                "flow_off",
                "enhanced_data_collection"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "security_policy_sync_state",
              "description": "Search option based on policy sync state",
              "enum": [
                "syncing",
                "applied"
              ],
              "query_param": true
            },
            {
              "dataType": "Hash",
              "name": "security_policy_applied_at",
              "description": "Security policy applied at timestamps of kubernetes workload(s) to return",
              "query_param": true
            },
            {
              "dataType": null,
              "name": "security_policy_applied_at[gte]",
              "description": "Greater than or equal to value for security policy applied at timestamp",
              "query_param": true
            },
            {
              "dataType": null,
              "name": "security_policy_applied_at[lte]",
              "description": "Less than or equal to value for security policy applied at timestamp",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of kubernetes workloads to return",
              "query_param": true
            }
          ],
          "schema": "kubernetes_workloads_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/kubernetes_workloads/:kubernetes_workload_id",
          "summary": "Get the info for one kubernetes workload",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "kubernetes_workload_id",
              "description": "KubernetesWorkload uuid",
              "required": true
            }
          ],
          "schema": "kubernetes_workloads_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "kubernetes_workload": {
      "methods": {
      }
    },
    "enforcement_points": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/enforcement_points",
          "summary": "Get the enforcement points collection",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of enforcement points to return.",
              "query_param": true
            }
          ],
          "schema": "enforcement_points_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/enforcement_points",
          "summary": "Create an enforcement point",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "enforcement_points_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "set": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/enforcement_points/set",
          "summary": "Update the entire enforcement point collection",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "enforcement_points_set_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/enforcement_points/:uuid",
          "summary": "Get Enforcement Point",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Enforcement Point UUID",
              "required": true
            }
          ],
          "schema": "enforcement_points_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        }
      }
    },
    "enforcement_point": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/enforcement_points/:uuid",
          "summary": "Update Enforcement Point",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Enforcement Point UUID",
              "required": true
            }
          ],
          "schema": "enforcement_points_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/enforcement_points/:uuid",
          "summary": "Delete Enforcement Point",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Enforcement Point UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "policy": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/enforcement_points/:uuid/policy",
          "summary": "Get Policy for Enforcement Point",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Enforcement Point UUID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "rule_digest",
              "description": "previous rule digest",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "set_digest",
              "description": "previous set digest",
              "query_param": true
            }
          ],
          "schema": "enforcement_points_policy_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        }
      }
    },
    "ven_software_images": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/software/ven/releases/:release/images",
          "summary": "Get the list of VEN software images for a given release",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "release",
              "description": "Release identifier",
              "required": true
            }
          ],
          "schema": "software_ven_releases_images_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "this_org_workload_manager"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/software/ven/releases/:release/images/:filename",
          "summary": "Get a requested VEN software image",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "release",
              "description": "Release identifier",
              "required": true
            },
            {
              "dataType": "string",
              "name": "filename",
              "description": "Image filename",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "this_org_workload_manager"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "ven_software_image": {
      "methods": {
      }
    },
    "ven_software_releases": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/software/ven/releases",
          "summary": "Get available releases for org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "ven_type",
              "description": "Return releases that support a specific VEN type (server or endpoint) or releases that only support both servers and endpoints (all)",
              "enum": [
                "server",
                "endpoint",
                "all"
              ],
              "query_param": true
            }
          ],
          "schema": "software_ven_releases_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "this_org_workload_manager"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Workload Manager"
          ],
          "auditable": false
        },
        "default": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/software/ven/releases/default",
          "summary": "DEPRECATED. Set the default release for this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "software_ven_releases_default_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": [
            "this_global_org_user",
            "this_org_workload_manager"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "defaults": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/software/ven/releases/defaults",
          "summary": "Set the default release for this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "software_ven_releases_defaults_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": [
            "this_global_org_user",
            "this_org_workload_manager"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/software/ven/releases/:release",
          "summary": "Get details about a VEN release",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "release",
              "description": "Release identifier",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "software_ven_releases_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "this_org_workload_manager"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "ven_software_release": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/software/ven/releases/:release",
          "summary": "Remove a release from the org/repo",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "release",
              "description": "Release identifier",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "ven_softwares": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/software/ven/releases",
          "summary": "List all releases in the system across all orgs",
          "params": [
            {
              "dataType": "string",
              "name": "ven_type",
              "description": "Return releases that support a specific VEN type (server or endpoint) or releases that only support both servers and endpoints (all)",
              "enum": [
                "server",
                "endpoint",
                "all"
              ],
              "query_param": true
            }
          ],
          "schema": "ven_software_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "root",
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/software/ven/releases",
          "summary": "Make a VEN release that is already in the VEN repo available to a set of orgs",
          "params": null,
          "schema": "ven_software_post",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/software/ven/releases/:release",
          "summary": "Get global usage details about a VEN release",
          "params": [
            {
              "dataType": "string",
              "name": "release",
              "description": "Release identifier",
              "required": true
            }
          ],
          "schema": "ven_software_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "root",
          "auditable": false
        },
        "deploy": {
          "http_method": "POST",
          "path": "/software/ven/releases/deploy",
          "summary": "Receive and deploy VEN release image file to local ven repo",
          "params": null,
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "interservice",
          "auditable": true
        }
      }
    },
    "ven_software": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/software/ven/releases/:release",
          "summary": "Remove a release from all orgs",
          "params": [
            {
              "dataType": "string",
              "name": "release",
              "description": "Release identifier",
              "required": true
            }
          ],
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": [
            "root",
            "interservice"
          ],
          "auditable": false
        }
      }
    },
    "agents": {
      "collection": true,
      "methods": {
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/agents/:agent_id",
          "summary": "DEPRECATED AND REPLACED (USE /orgs/:xorg_id/vens/:ven_uuid INSTEAD)",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "agent_id",
              "description": "Agent ID",
              "required": true
            }
          ],
          "schema": "agents_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_agent_or_user",
          "auditable": false
        }
      }
    },
    "agent": {
      "methods": {
        "event_log": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/agents/:agent_id/event/log",
          "summary": "Send lightning bolt to agent",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "agent_id",
              "description": "Agent ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "message",
              "description": "Message to send to agent",
              "query_param": true
            }
          ],
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": false
        },
        "gen_support_report": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/agents/:agent_id/gen_support_report",
          "summary": "",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "agent_id",
              "description": "Agent ID",
              "required": true
            }
          ],
          "schema": "agents_gen_support_report_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": false
        },
        "authenticate": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/agents/:agent_id/authenticate",
          "summary": "Authenticate Agent",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "agent_id",
              "description": "Agent ID",
              "required": true
            }
          ],
          "schema": "agents_authenticate_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "interservice",
          "auditable": false
        },
        "compatibility_report": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/agents/:agent_id/compatibility_report",
          "summary": "DEPRECATED AND REPLACED (USE /orgs/:xorg_id/vens/:ven_uuid/compatibility_report INSTEAD)",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "agent_id",
              "description": "Agent ID",
              "required": true
            }
          ],
          "schema": "agents_compatibility_report_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/agents/:agent_id/update",
          "summary": "DEPRECATED AND REPLACED (USE PUT /orgs/:xorg_id/vens/:ven_uuid INSTEAD)",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "agent_id",
              "description": "Agent ID",
              "required": true
            }
          ],
          "schema": "agents_update_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "vulnerabilities": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/vulnerabilities",
          "summary": "Get the vulnerabilities collection",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of vulnerabilities to return.",
              "query_param": true
            }
          ],
          "schema": "vulnerabilities_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/vulnerabilities",
          "summary": "Create or update the vulnerabilities in bulk",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "vulnerabilities_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/vulnerabilities/:reference_id",
          "summary": "Get Vulnerability by reference ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "reference_id",
              "description": "Vulnerability Reference ID",
              "required": true
            }
          ],
          "schema": "vulnerabilities_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "vulnerability": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/vulnerabilities/:reference_id",
          "summary": "Create or update the vulnerability",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "reference_id",
              "description": "Vulnerability Reference ID",
              "required": true
            }
          ],
          "schema": "vulnerabilities_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/vulnerabilities/:reference_id",
          "summary": "Delete Vulnerability by reference ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "reference_id",
              "description": "Vulnerability Reference ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "vulnerability_reports": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/vulnerability_reports",
          "summary": "Get the vulnerability_report collection",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of vulnerability_reports to return.",
              "query_param": true
            }
          ],
          "schema": "vulnerability_reports_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/vulnerability_reports/:reference_id",
          "summary": "Get Vulnerability Report by reference ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "reference_id",
              "description": "Vulnerability Report Reference ID",
              "required": true
            }
          ],
          "schema": "vulnerability_reports_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "vulnerability_report": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/vulnerability_reports/:reference_id",
          "summary": "Create a vulnerability_report",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "reference_id",
              "description": "Vulnerability Report Reference ID",
              "required": true
            }
          ],
          "schema": "vulnerability_reports_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/vulnerability_reports/:reference_id",
          "summary": "Delete a vulnerability report by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "reference_id",
              "description": "Vulnerability Report Reference ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "aggregated_detected_vulnerabilities": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/aggregated_detected_vulnerabilities",
          "summary": "Get the aggregated detected vulnerabilities collection",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of vulnerabilities to return.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "workload_labels",
              "description": "List of label URIs, encoded as a JSON string",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "aggregated_detected_vulnerabilities_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "aggregated_detected_vulnerability": {
      "methods": {
      }
    },
    "ransomware_timeseries_statistics": {
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/reports/risk_summary/ransomware_timeseries_statistics",
          "summary": "Get the risk summary time series statistics",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "reports_risk_summary_ransomware_timeseries_statistics_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "risk_summary": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/reports/risk_summary",
          "summary": "Get the org risk_summary",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "reports_risk_summary_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "detected_vulnerabilities": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/workloads/:workload_id/detected_vulnerabilities",
          "summary": "Get the list of detected vulnerabilities",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            }
          ],
          "schema": "workloads_detected_vulnerabilities_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "detected_vulnerability": {
      "methods": {
      }
    },
    "app_groups": {
      "collection": true,
      "methods": {
        "risk_summary": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/app_groups/risk_summary",
          "summary": "Get the risk summary for all application groups",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "app_groups_risk_summary_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/app_groups",
          "summary": "Get the app_groups collection",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of app groups to return.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "sort_by",
              "description": "Sort by option based on ves, es or vs",
              "query_param": true
            }
          ],
          "schema": "app_groups_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "risk_details": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/app_groups/:app_group_id/risk_details",
          "summary": "Get the risk_details for a specific application group",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "app_group_id",
              "description": "app group resource id",
              "required": true
            }
          ],
          "schema": "app_groups_risk_details_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "observed_rule_coverage": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/app_groups/observed_rule_coverage",
          "summary": "Get the label-based observed rule coverage summaries for all application groups",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "app_groups_observed_rule_coverage_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      }
    },
    "workload_interfaces": {
      "collection": true,
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/workloads/:workload_id/interfaces",
          "summary": "DEPRECATED WITH NO REPLACEMENT. Create an interface",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            }
          ],
          "schema": "workloads_interfaces_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/workloads/:workload_id/interfaces",
          "summary": "DEPRECATED WITH NO REPLACEMENT. Get the interface statuses (all interfaces)",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of interface(s) to return. Supports partial matches",
              "query_param": true
            }
          ],
          "schema": "workloads_interfaces_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "delete": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/workloads/:workload_id/interfaces/delete",
          "summary": "Bulk delete workload interfaces",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            }
          ],
          "schema": "workloads_interfaces_delete_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/workloads/:workload_id/interfaces/:name",
          "summary": "DEPRECATED WITH NO REPLACEMENT. Get the interface status (single interface). Use get_collection API filtering by name.",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Interface Name",
              "required": true
            }
          ],
          "schema": "workloads_interfaces_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "workload_interface": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/workloads/:workload_id/interfaces/:name",
          "summary": "DEPRECATED WITH NO REPLACEMENT. Delete an interface. Use the PUT /interfaces/delete to delete multiple interfaces.",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Interface Name",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        }
      }
    },
    "ignored_interfaces": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/workloads/:workload_id/ignored_interfaces",
          "summary": "Get the list of ignored interface names",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            }
          ],
          "schema": "workloads_ignored_interfaces_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/workloads/:workload_id/ignored_interfaces",
          "summary": "Update list of ignored interface names",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload_id",
              "description": "Workload UUID",
              "required": true
            }
          ],
          "schema": "workloads_ignored_interfaces_put",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "dashboards": {
      "collection": true,
      "methods": {
        "rule_calc_progress": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/dashboard/rule_calc_progress",
          "summary": "Rule calculation / configuration progress",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "dashboard_rule_calc_progress_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "dashboard": {
      "methods": {
      }
    },
    "admins": {
      "collection": true,
      "methods": {
        "recalc_rules": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/admin/recalc_rules",
          "summary": "Force rule recalc for an org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "policy_sanity_check": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/admin/policy_sanity_check",
          "summary": "Sanity check for policy (SetServer vs DB)",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": false
        }
      }
    },
    "admin": {
      "methods": {
      }
    },
    "label_dimensions": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/label_dimensions",
          "summary": "Return the set of label dimensions",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "key",
              "description": "Key by which to filter. Supports partial matches.",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "include_deleted",
              "description": "Include deleted labels.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_set",
              "description": "The data source from which a resource originates.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_reference",
              "description": "A unique identifier within the external data source.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "display_name",
              "description": "Name on which to filter. Supports partial matches.",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of Labels to return.",
              "query_param": true
            }
          ],
          "schema": "label_dimensions_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/label_dimensions",
          "summary": "Create a label dimension",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "label_dimensions_post",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "obj_limits": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/label_dimensions/obj_limits",
          "summary": "Return the label dimension object limits",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "label_dimensions_obj_limits_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/label_dimensions/:label_dimension_id",
          "summary": "Get label dimension by UUID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "label_dimension_id",
              "description": "LabelDimension ID",
              "required": true
            }
          ],
          "schema": "label_dimensions_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "label_dimension": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/label_dimensions/:label_dimension_id",
          "summary": "Delete a label dimension",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "label_dimension_id",
              "description": "LabelDimension ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/label_dimensions/:label_dimension_id",
          "summary": "Update editable fields in a label dimension",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "label_dimension_id",
              "description": "LabelDimension ID",
              "required": true
            }
          ],
          "schema": "label_dimensions_put",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "service_bindings": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/service_bindings",
          "summary": "Return all virtual service bindings",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "virtual_service",
              "description": "Virtual service URI",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "workload",
              "description": "Workload URI",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_set",
              "description": "The data source from which a resource originates",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_reference",
              "description": "A unique identifier within the external data source",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of virtual service bindings to return.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "service_bindings_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/service_bindings",
          "summary": "Create a bound service binding",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "service_bindings_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/service_bindings/delete",
          "summary": "Delete multiple bound service bindings",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "service_bindings_delete_put",
          "expose_to": [
            "end_user_experimental_ms_only"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/service_bindings/:service_binding_id",
          "summary": "Get service binding by id",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "service_binding_id",
              "description": "Service Binding ID",
              "required": true
            }
          ],
          "schema": "service_bindings_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "service_binding": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/service_bindings/:service_binding_id",
          "summary": "Delete a service binding by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "service_binding_id",
              "description": "Service Binding ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "sec_rules": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/sec_rules",
          "summary": "Get the collection of rules",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "external_data_set",
              "description": "The data source from which a resource originates",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "external_data_reference",
              "description": "A unique identifier within the external data source",
              "query_param": true
            }
          ],
          "schema": "sec_policy_rule_sets_sec_rules_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/sec_rules",
          "summary": "Create new rule",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_sec_rules_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Limited Ruleset Manager"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/sec_rules/delete",
          "summary": "Delete rules in a rule set",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_sec_rules_delete_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Limited Ruleset Manager"
          ],
          "auditable": true
        },
        "impact": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/sec_rules/impact",
          "summary": "Analyze the impact of creating a new rule",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_sec_rules_impact_post",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/sec_rules/:sec_rule_id",
          "summary": "Get Rule by ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "sec_rule_id",
              "description": "Rule ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_sec_rules_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      }
    },
    "sec_rule": {
      "methods": {
        "connectivity": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/sec_rules/:sec_rule_id/connectivity",
          "summary": "Get connectivity information for this rule",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "sec_rule_id",
              "description": "Rule ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "max_most_connected_scoped_rules",
              "description": "The maximum number of scoped rules to return. Defaults to 5; maximum 500.",
              "enum": "0..500",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_workload_statistical_intervals",
              "description": "The number of statistical intervals to group the workloads into. Defaults to 3; maximum 500.",
              "enum": "0..500",
              "query_param": true
            }
          ],
          "schema": "sec_policy_rule_sets_sec_rules_connectivity_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/sec_rules/:sec_rule_id",
          "summary": "Modify Rule",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "sec_rule_id",
              "description": "Rule ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_sec_rules_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Limited Ruleset Manager"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/sec_rules/:sec_rule_id",
          "summary": "Delete Rule",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "sec_rule_id",
              "description": "Rule ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Limited Ruleset Manager"
          ],
          "auditable": true
        },
        "impact": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/sec_rules/:sec_rule_id/impact",
          "summary": "Analyze the impact of an existing rule",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "sec_rule_id",
              "description": "Rule ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_sec_rules_impact_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      }
    },
    "deny_rules": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/deny_rules",
          "summary": "Get the collection of deny rules in the ruleset",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_deny_rules_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/deny_rules",
          "summary": "",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_deny_rules_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/deny_rules/:deny_rule_id",
          "summary": "Get deny rule by ID and ruleset ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "deny_rule_id",
              "description": "deny rule ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_deny_rules_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        }
      }
    },
    "deny_rule": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/deny_rules/:deny_rule_id",
          "summary": "Modify deny rule by ID and ruleset ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "deny_rule_id",
              "description": "deny rule ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_deny_rules_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/deny_rules/:deny_rule_id",
          "summary": "Delete deny rule by ID and deny rule ID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "deny_rule_id",
              "description": "deny rule ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "ip_tables_rules": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/ip_tables_rules",
          "summary": "Get the collection of iptables rules for a ruleset",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_ip_tables_rules_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/ip_tables_rules",
          "summary": "Create an iptables rule in a ruleset",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_ip_tables_rules_post",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Limited Ruleset Manager"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/ip_tables_rules/:ip_tables_rule_id",
          "summary": "Get an iptables rule by id",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "ip_tables_rule_id",
              "description": "IP Tables rule ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_ip_tables_rules_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "ip_tables_rule": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/ip_tables_rules/:ip_tables_rule_id",
          "summary": "Modify an iptables rule",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "ip_tables_rule_id",
              "description": "IP Tables rule ID",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_sets_ip_tables_rules_put",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Limited Ruleset Manager"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_sets/:rule_set_id/ip_tables_rules/:ip_tables_rule_id",
          "summary": "Delete an iptables rule",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "rule_set_id",
              "description": "Rule Set ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "ip_tables_rule_id",
              "description": "IP Tables rule ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Limited Ruleset Manager"
          ],
          "auditable": true
        }
      }
    },
    "firewall_settings": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/firewall_settings",
          "summary": "Get the firewall settings object",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "sec_policy_firewall_settings_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/firewall_settings",
          "summary": "Modify firewall settings object",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_firewall_settings_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "essential_service_rules": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/essential_service_rules",
          "summary": "Get the essential service rules",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_essential_service_rules_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/essential_service_rules",
          "summary": "Modify essential service rules",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_essential_service_rules_put",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "secure_connect_gateways": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/secure_connect_gateways",
          "summary": "Get the list of secure connect gateway objects with their secure networks",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_secure_connect_gateways_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/secure_connect_gateways",
          "summary": "Create a secure connect gateway object with secure networks",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_secure_connect_gateways_post",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/secure_connect_gateways/:secure_connect_gateway_id",
          "summary": "Get a single secure connect gateway object with secure networks",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "secure_connect_gateway_id",
              "description": "Gateway ID",
              "required": true
            }
          ],
          "schema": "sec_policy_secure_connect_gateways_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        }
      }
    },
    "secure_connect_gateway": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/secure_connect_gateways/:secure_connect_gateway_id",
          "summary": "Modify a secure connect gateway",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "secure_connect_gateway_id",
              "description": "Gateway ID",
              "required": true
            }
          ],
          "schema": "sec_policy_secure_connect_gateways_put",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/secure_connect_gateways/:secure_connect_gateway_id",
          "summary": "Delete a secure connect gateway",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "secure_connect_gateway_id",
              "description": "Gateway ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "sec_policy_pending": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/pending",
          "summary": "Get the collection of pending uncommitted security policy objects.",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of policy objects to return (per type)",
              "query_param": true
            }
          ],
          "schema": "sec_policy_pending_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/sec_policy/pending",
          "summary": "Revert the collection of pending uncommitted security policy objects.",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "sec_policy_policy_templates": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/policy_templates",
          "summary": "Retrieve the list of policy templates for rule_sets",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "use_existing",
              "description": "When true, replaces the default templates with the ruleset from the database",
              "query_param": true
            }
          ],
          "schema": "sec_policy_policy_templates_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "sec_policy_rule_view": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_view",
          "summary": "Get a flattened view of the rules applying to a virtual server",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "virtual_server",
              "description": "Virtual Server URI",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "sec_policy_rule_view_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "sec_policy_allows": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/allow",
          "summary": "Determine which rule(s) allow communication, if any",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "src_external_ip",
              "description": "The external IP of the source workload",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "src_workload",
              "description": "The URI of the source workload",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "src_container_workload",
              "description": "The URI of the source container workload",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "dst_external_ip",
              "description": "The external IP of the destination workload",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "dst_workload",
              "description": "The URI of the destination workload",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "dst_container_workload",
              "description": "The URI of the destination container workload",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "service",
              "description": "The specific service to check",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "port",
              "description": "The specific port number to check",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "protocol",
              "description": "The specific protocol number to check",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "network_type",
              "description": "Type of network to check",
              "enum": [
                "brn",
                "non_brn"
              ],
              "query_param": true
            }
          ],
          "schema": "sec_policy_allow_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      }
    },
    "sec_policy_allow": {
      "methods": {
      }
    },
    "sec_policy_rule_coverage": {
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_coverage",
          "summary": "Determine whether specified edges are covered by rules",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "include_deny_rules",
              "description": "Include deny rules",
              "query_param": true
            }
          ],
          "schema": "sec_policy_rule_coverage_post",
          "expose_to": [
            "end_user_private_perm",
            "interservice",
            "publish_private_schema_flag"
          ],
          "operation_type": "read",
          "authz": [
            "this_org_user",
            "interservice"
          ],
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "sec_policy_rule_search": {
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/rule_search",
          "summary": "Get the collection of Rules",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_rule_search_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "sec_policy_enforcement_boundary_search": {
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/enforcement_boundary_search",
          "summary": "",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_enforcement_boundary_search_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "sec_policy_overlapping_rule_search": {
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/overlapping_rule_search",
          "summary": "",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_overlapping_rule_search_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "sec_policies": {
      "collection": true,
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy",
          "summary": "Commit current draft. If a list of entities is supplied explicitly, only the entities in that list would be committed. The request would however be rejected if the list does not constitute a dependency closure.",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "sec_policy_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_provisioning",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Ruleset Provisioner"
          ],
          "auditable": true
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy",
          "summary": "Get the collection of committed security policies",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "sec_policy_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "delete": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/sec_policy/delete",
          "summary": "Revert the given collection of pending uncommitted security policy objects. The request is rejected if the list does not constitute a dependency closure.",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "sec_policy_delete_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "impact": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/impact",
          "summary": "Calculate the impact of a policy",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "sec_policy_impact_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_provisioning",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion",
          "summary": "Get security policy by version",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "sec_policy": {
      "methods": {
        "dependencies": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/dependencies",
          "summary": "Get dependency info for a set of objects. The set can consist of any pending (i.e., updated but not yet committed) policy objects.",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_dependencies_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "restore": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/restore",
          "summary": "Restore security policy to this version",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_restore_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "modified_objects": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/modified_objects",
          "summary": "Get the collection of modified objects in the given version of the policy",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            }
          ],
          "schema": "sec_policy_modified_objects_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "connectivity": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/connectivity",
          "summary": "Get rule connectivity information for the entire organization",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "max_most_connected_scoped_rules",
              "description": "The maximum number of scoped rules to return. Defaults to 5; maximum 500.",
              "enum": "0..500",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_workload_statistical_intervals",
              "description": "The number of statistical intervals to group the workloads into. Defaults to 3; maximum 500.",
              "enum": "0..500",
              "query_param": true
            }
          ],
          "schema": "sec_policy_connectivity_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "redundant_rules": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/redundant_rules",
          "summary": "Get a list of redundant rules",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "max_equivalent_redundant_rules",
              "description": "Maximum number of equivalent redundant",
              "enum": "0..500",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_fully_overlapped_rules",
              "description": "Maximum number of fully overlapped rules",
              "enum": "0..500",
              "query_param": true
            }
          ],
          "schema": "sec_policy_redundant_rules_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "policy_view": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/sec_policy/:pversion/policy_view",
          "summary": "Get policy applying to a workload",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "pversion",
              "description": "Security Policy Version",
              "required": true
            },
            {
              "dataType": "string",
              "name": "workload",
              "description": "Workload URI",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "container_workload",
              "description": "Container workload URI",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "kubernetes_workload",
              "description": "Kubernetes workload URI",
              "query_param": true
            }
          ],
          "schema": "sec_policy_policy_view_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "workload_os_families": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/workload_os_families",
          "summary": "Return the array of workload_os_families",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "pairing_profile_id",
              "description": "The pairing profile id for the pairing lines",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "pairing_profile_name",
              "description": "The pairing profile name for the pairing lines",
              "query_param": true
            }
          ],
          "schema": "workload_os_families_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "workload_os_family": {
      "methods": {
      }
    },
    "protection_schemas": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/protection_schemas",
          "summary": "Return the set of all protection_schemas",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "protection_schemas_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/protection_schemas",
          "summary": "Create a protection schema",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "protection_schemas_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "groupings": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/protection_schemas/groupings",
          "summary": "Create groupings using a set of protection schemas",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "protection_schemas_groupings_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/protection_schemas/:protection_schema_id",
          "summary": "Get protection schema instance",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "protection_schema_id",
              "description": "ProtectionSchema ID",
              "required": true
            }
          ],
          "schema": "protection_schemas_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "protection_schema": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/protection_schemas/:protection_schema_id",
          "summary": "Update protection schema instance",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "protection_schema_id",
              "description": "ProtectionSchema ID",
              "required": true
            }
          ],
          "schema": "protection_schemas_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/protection_schemas/:protection_schema_id",
          "summary": "Delete protection schema instance",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "protection_schema_id",
              "description": "ProtectionSchema ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "management_ports": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/management_ports",
          "summary": "Return the array of management ports",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "management_ports_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "management_port": {
      "methods": {
      }
    },
    "support_bundle_requests": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/support_bundle_requests",
          "summary": "Return the collection of PCE support bundle requests",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "support_bundle_requests_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/support_bundle_requests",
          "summary": "Create a PCE support bundle request",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "include_logs",
              "description": "Indicate whether to include logs",
              "query_param": true,
              "expose_to": [
                "end_user_experimental"
              ]
            },
            {
              "dataType": "string",
              "name": "starting_at",
              "description": "Time from which to include entries",
              "query_param": true,
              "expose_to": [
                "end_user_experimental"
              ]
            },
            {
              "dataType": "string",
              "name": "ending_at",
              "description": "Time at which to exclude entries",
              "query_param": true,
              "expose_to": [
                "end_user_experimental"
              ]
            }
          ],
          "schema": "support_bundle_requests_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/support_bundle_requests/:uuid",
          "summary": "Return a specific PCE support bundle request",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Support bundle request UUID",
              "required": true
            }
          ],
          "schema": "support_bundle_requests_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        }
      }
    },
    "support_bundle_request": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/support_bundle_requests/:uuid",
          "summary": "Delete a PCE support bundle request",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Support bundle request UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "support_report_requests": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/support_report_requests",
          "summary": "Return the collection of support report requests",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            },
            {
              "dataType": "string",
              "name": "ven",
              "description": "Limit returned reports to those associated with this VEN href.",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "container_cluster",
              "description": "Limit returned reports to those associated with this container cluster href.",
              "query_param": true
            }
          ],
          "schema": "support_report_requests_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "this_org_workload_manager"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Workload Manager"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/support_report_requests",
          "summary": "Create a support report request",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "support_report_requests_post",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/support_report_requests/:uuid",
          "summary": "Get a support report request",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Support Report Request ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "support_report_requests_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "this_org_workload_manager"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "support_report_request": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/support_report_requests/:uuid",
          "summary": "Update a support report request",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Support Report Request ID",
              "required": true
            }
          ],
          "schema": "support_report_requests_put",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "interservice",
          "auditable": false
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/support_report_requests/:uuid",
          "summary": "Delete a support report request",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Support Report Request ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_transitional"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_org_user_for_workloads",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Workload Manager"
          ],
          "auditable": true
        }
      }
    },
    "nfcs": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/nfcs",
          "summary": "Get the network function controllers",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "nfcs_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/nfcs/:nfc_id",
          "summary": "Get the network function controller information",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "nfc_id",
              "description": "Nfc UUID",
              "required": true
            }
          ],
          "schema": "nfcs_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "nfc": {
      "methods": {
      }
    },
    "api_keys": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/users/:user_id/api_keys",
          "summary": "Get API Key",
          "params": [
            {
              "dataType": "integer",
              "name": "user_id",
              "description": "User ID",
              "required": true
            }
          ],
          "schema": "users_api_keys_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_user",
          "pretty_authz": [
            "This Authenticated User"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/users/:user_id/api_keys",
          "summary": "Create a new API Key",
          "params": [
            {
              "dataType": "integer",
              "name": "user_id",
              "description": "User ID",
              "required": true
            }
          ],
          "schema": "users_api_keys_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_user",
          "pretty_authz": [
            "This Authenticated User"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/users/:user_id/api_keys/:key_id",
          "summary": "Read API Key Info",
          "params": [
            {
              "dataType": "integer",
              "name": "user_id",
              "description": "User ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "key_id",
              "description": "key_id",
              "required": true
            }
          ],
          "schema": "users_api_keys_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_user",
          "pretty_authz": [
            "This Authenticated User"
          ],
          "auditable": false
        }
      }
    },
    "api_key": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/users/:user_id/api_keys/:key_id",
          "summary": "Update API Key Info",
          "params": [
            {
              "dataType": "integer",
              "name": "user_id",
              "description": "User ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "key_id",
              "description": "key_id",
              "required": true
            }
          ],
          "schema": "users_api_keys_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_user",
          "pretty_authz": [
            "This Authenticated User"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/users/:user_id/api_keys/:key_id",
          "summary": "Delete API Key",
          "params": [
            {
              "dataType": "integer",
              "name": "user_id",
              "description": "User ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "key_id",
              "description": "key_id",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_user",
          "pretty_authz": [
            "This Authenticated User"
          ],
          "auditable": true
        }
      }
    },
    "kvpairs": {
      "collection": true,
      "methods": {
        "get_instance": {
          "http_method": "GET",
          "path": "/users/:user_id/kvpair/:key",
          "summary": "Get a Value associated with a key",
          "params": [
            {
              "dataType": "integer",
              "name": "user_id",
              "description": "User ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "key",
              "description": "Key",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_user",
          "pretty_authz": [
            "This Authenticated User"
          ],
          "auditable": false
        }
      }
    },
    "kvpair": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/users/:user_id/kvpair/:key",
          "summary": "Set the value associated with a key",
          "params": [
            {
              "dataType": "integer",
              "name": "user_id",
              "description": "User ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "key",
              "description": "Key",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_user",
          "pretty_authz": [
            "This Authenticated User"
          ],
          "auditable": false
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/users/:user_id/kvpair/:key",
          "summary": "Remove the value associated with a key",
          "params": [
            {
              "dataType": "integer",
              "name": "user_id",
              "description": "User ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "key",
              "description": "Key",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_user",
          "pretty_authz": [
            "This Authenticated User"
          ],
          "auditable": false
        }
      }
    },
    "api_usage_limit_profiles": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/api_usage_limit_profiles",
          "summary": "Get the API usage limit profiles",
          "params": null,
          "schema": "api_usage_limit_profiles_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "root",
          "auditable": false
        },
        "by_org_id": {
          "http_method": "GET",
          "path": "/api_usage_limit_profiles/by_org_id",
          "summary": "Get the API usage limit profiles by Org ID",
          "params": [
            {
              "dataType": "integer",
              "name": "org_id",
              "description": "ID of the Org",
              "query_param": true
            }
          ],
          "schema": "api_usage_limit_profiles_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "interservice",
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/api_usage_limit_profiles",
          "summary": "Create new API usage limit profile",
          "params": null,
          "schema": "api_usage_limit_profiles_post",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/api_usage_limit_profiles/:profile_id",
          "summary": "Get the API usage limit profile",
          "params": [
            {
              "dataType": "integer",
              "name": "profile_id",
              "description": "Profile ID",
              "required": true
            }
          ],
          "schema": "api_usage_limit_profiles_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "root",
          "auditable": false
        }
      }
    },
    "api_usage_limit_profile": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/api_usage_limit_profiles/:profile_id",
          "summary": "Update the API usage limit profile",
          "params": [
            {
              "dataType": "integer",
              "name": "profile_id",
              "description": "Profile ID",
              "required": true
            }
          ],
          "schema": "api_usage_limit_profiles_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": false
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/api_usage_limit_profiles/:profile_id",
          "summary": "Delete the API usage limit profile",
          "params": [
            {
              "dataType": "integer",
              "name": "profile_id",
              "description": "Profile ID",
              "required": true
            }
          ],
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": false
        }
      }
    },
    "object_limit_profiles": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/object_limit_profiles",
          "summary": "Get the object limit profiles",
          "params": null,
          "schema": "object_limit_profiles_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "root",
          "auditable": false
        },
        "by_org_id": {
          "http_method": "GET",
          "path": "/object_limit_profiles/by_org_id",
          "summary": "Get the object limit profiles by Org ID",
          "params": [
            {
              "dataType": "integer",
              "name": "org_id",
              "description": "ID of the Org",
              "query_param": true
            }
          ],
          "schema": "object_limit_profiles_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "interservice",
          "auditable": false
        },
        "global_only": {
          "http_method": "GET",
          "path": "/object_limit_profiles/global_only",
          "summary": "Get the global object limit profile",
          "params": null,
          "schema": "object_limit_profiles_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "interservice",
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/object_limit_profiles",
          "summary": "Create new object limit profile",
          "params": null,
          "schema": "object_limit_profiles_post",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/object_limit_profiles/:profile_id",
          "summary": "Get the object limit profile",
          "params": [
            {
              "dataType": "integer",
              "name": "profile_id",
              "description": "Profile ID",
              "required": true
            }
          ],
          "schema": "object_limit_profiles_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "root",
          "auditable": false
        }
      }
    },
    "object_limit_profile": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/object_limit_profiles/:profile_id",
          "summary": "Update the object limit profile",
          "params": [
            {
              "dataType": "integer",
              "name": "profile_id",
              "description": "Profile ID",
              "required": true
            }
          ],
          "schema": "object_limit_profiles_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": false
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/object_limit_profiles/:profile_id",
          "summary": "Delete the object limit profile",
          "params": [
            {
              "dataType": "integer",
              "name": "profile_id",
              "description": "Profile ID",
              "required": true
            }
          ],
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": false
        }
      }
    },
    "user_local_profile": {
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/users/:user_id/local_profile",
          "summary": "Create local user profile, i.e. convert an external user to a local user",
          "params": [
            {
              "dataType": "integer",
              "name": "user_id",
              "description": "User ID",
              "required": true
            }
          ],
          "schema": "users_local_profile_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/users/:user_id/local_profile",
          "summary": "Delete local user profile",
          "params": [
            {
              "dataType": "integer",
              "name": "user_id",
              "description": "User ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "password": {
          "http_method": "PUT",
          "path": "/users/:user_id/local_profile/password",
          "summary": "Change local user password",
          "params": [
            {
              "dataType": "integer",
              "name": "user_id",
              "description": "User ID",
              "required": true
            }
          ],
          "schema": "users_local_profile_password_put",
          "expose_to": [
            "end_user_public"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_user",
          "pretty_authz": [
            "This Authenticated User"
          ],
          "auditable": true
        },
        "reinvite": {
          "http_method": "PUT",
          "path": "/users/:user_id/local_profile/reinvite",
          "summary": "Resend invitation email for local user",
          "params": [
            {
              "dataType": "integer",
              "name": "user_id",
              "description": "User ID",
              "required": true
            }
          ],
          "schema": "users_local_profile_reinvite_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "users": {
      "collection": true,
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/users",
          "summary": "Create a new local user",
          "params": null,
          "schema": "users_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/users",
          "summary": "Get all of the users",
          "params": [
            {
              "dataType": "string",
              "name": "type",
              "description": "User type which to filter, (e.g. local or external)",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "users_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "authenticated_user",
          "pretty_authz": [
            "Any Authenticated User"
          ],
          "auditable": false
        },
        "login": {
          "http_method": "GET",
          "path": "/users/login",
          "summary": "Login a specific user, and return a session token. Uses an authentication token. For implementation details see the Authentication section in the Illumio ASP REST API Guide.",
          "params": null,
          "schema": "users_login_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "anybody",
          "pretty_authz": [
            "Anyone"
          ],
          "auditable": true
        },
        "create_session": {
          "http_method": "POST",
          "path": "/users/create_session",
          "summary": "Establish session for a user",
          "params": null,
          "schema": "users_create_session_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "interservice",
          "auditable": true
        },
        "auth_token": {
          "http_method": "POST",
          "path": "/users/auth_token",
          "summary": "Return a auth token for authentication to PCE",
          "params": null,
          "schema": "users_auth_token_post",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": [
            "root",
            "this_org_admin"
          ],
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/users/:id",
          "summary": "Get the user information",
          "params": [
            {
              "dataType": "integer",
              "name": "id",
              "description": "User ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "users_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "authenticated_user",
          "pretty_authz": [
            "Any Authenticated User"
          ],
          "auditable": false
        },
        "create_first_user_for_domain_from_jwt": {
          "http_method": "POST",
          "path": "/users/create_first_user_for_domain_from_jwt",
          "summary": "Assign user as org owner via JWT",
          "params": null,
          "schema": "users_create_first_user_for_domain_from_jwt_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "is_user_authorized_from_jwt": {
          "http_method": "POST",
          "path": "/users/is_user_authorized_from_jwt",
          "summary": "Retrieve information regarding whether the user is authorized to access the PCE via JWT",
          "params": null,
          "schema": "users_is_user_authorized_from_jwt_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": false
        },
        "is_user_granted_role_from_jwt": {
          "http_method": "POST",
          "path": "/users/is_user_granted_role_from_jwt",
          "summary": "Retrieve information regarding whether the user is granted a specified role via JWT",
          "params": null,
          "schema": "users_is_user_granted_role_from_jwt_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": false
        },
        "logout_from_jwt": {
          "http_method": "POST",
          "path": "/users/logout_from_jwt",
          "summary": "Log out the user via JWT",
          "params": null,
          "schema": "users_logout_from_jwt_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        }
      }
    },
    "user": {
      "methods": {
        "logout": {
          "http_method": "PUT",
          "path": "/users/:id/logout",
          "summary": "Logout a specific user and destroy the access token",
          "params": [
            {
              "dataType": "integer",
              "name": "id",
              "description": "User ID",
              "required": true
            }
          ],
          "schema": "users_logout_put",
          "expose_to": [
            "end_user_public"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_user",
          "pretty_authz": [
            "This Authenticated User"
          ],
          "auditable": true
        },
        "orgs": {
          "http_method": "GET",
          "path": "/users/:id/orgs",
          "summary": "Get list of orgs the user has access to",
          "params": [
            {
              "dataType": "integer",
              "name": "id",
              "description": "User ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "users_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_user_or_any_org_owner",
          "pretty_authz": [
            "Global Organization Owner",
            "This Authenticated User"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/users/:id",
          "summary": "Update the user information",
          "params": [
            {
              "dataType": "integer",
              "name": "id",
              "description": "User ID",
              "required": true
            }
          ],
          "schema": "users_put",
          "expose_to": [
            "end_user_public"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_user_or_any_org_owner",
          "pretty_authz": [
            "Global Organization Owner",
            "This Authenticated User"
          ],
          "auditable": true
        },
        "authenticate": {
          "http_method": "POST",
          "path": "/users/:id/authenticate",
          "summary": "Authenticate the session token - for other webservices",
          "params": [
            {
              "dataType": "integer",
              "name": "id",
              "description": "User ID",
              "required": true
            }
          ],
          "schema": "users_authenticate_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "interservice",
          "auditable": false
        },
        "auth_data": {
          "http_method": "GET",
          "path": "/users/:id/auth_data",
          "summary": "Obtain auth_data for reporting webservice",
          "params": [
            {
              "dataType": "integer",
              "name": "id",
              "description": "User ID",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "users_auth_data_get",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "read",
          "authz": "interservice",
          "auditable": false
        }
      }
    },
    "roles": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/roles",
          "summary": "Get the roles in the org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "orgs_roles_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/roles/:role_name",
          "summary": "Get information for this role name",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "role_name",
              "description": "Role Name",
              "required": true
            }
          ],
          "schema": "orgs_roles_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "role": {
      "methods": {
      }
    },
    "org_auth_security_principals": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/auth_security_principals",
          "summary": "Get all auth_security_principals for this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of the auth_security_principal to filter by. Supports partial matches",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "type",
              "description": "Type (user or group) to filter by",
              "query_param": true
            }
          ],
          "schema": "orgs_auth_security_principals_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/auth_security_principals",
          "summary": "Create an auth_security_principal in this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "orgs_auth_security_principals_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/auth_security_principals/:auth_security_principal_id",
          "summary": "Get details of this auth_security_principal in this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "auth_security_principal_id",
              "description": "UUID of the auth_security_principal",
              "required": true
            }
          ],
          "schema": "orgs_auth_security_principals_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        }
      }
    },
    "org_auth_security_principal": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/auth_security_principals/:auth_security_principal_id",
          "summary": "Delete this auth_security_principal in this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "auth_security_principal_id",
              "description": "UUID of the auth_security_principal",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/auth_security_principals/:auth_security_principal_id",
          "summary": "Edit this auth_security_principal in this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "auth_security_principal_id",
              "description": "UUID of the auth_security_principal",
              "required": true
            }
          ],
          "schema": "orgs_auth_security_principals_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "access_restrictions": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/access_restrictions",
          "summary": "Get all Access Restrictions",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "orgs_access_restrictions_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/access_restrictions",
          "summary": "Create a new Access Restriction",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "orgs_access_restrictions_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/access_restrictions/:id",
          "summary": "Read Access Restriction Info",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "id",
              "description": "access restriction id",
              "required": true
            }
          ],
          "schema": "orgs_access_restrictions_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        }
      }
    },
    "access_restriction": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/access_restrictions/:id",
          "summary": "Update Access Restriction Info",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "id",
              "description": "access restriction id",
              "required": true
            }
          ],
          "schema": "orgs_access_restrictions_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/access_restrictions/:id",
          "summary": "Delete Access Restriction",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "id",
              "description": "access restriction id",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "syslog_destinations": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/settings/syslog/destinations",
          "summary": "Get syslog destinations for the org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_syslog_destinations_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/settings/syslog/destinations",
          "summary": "Post a new syslog destinations for org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_syslog_destinations_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/settings/syslog/destinations/:syslog_destination_id",
          "summary": "Get a PCE destination by UUID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "syslog_destination_id",
              "description": "PCE destination UUID",
              "required": true
            }
          ],
          "schema": "settings_syslog_destinations_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        }
      }
    },
    "syslog_destination": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/settings/syslog/destinations/:syslog_destination_id",
          "summary": "Create or Update a syslog destination",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "syslog_destination_id",
              "description": "PCE destination UUID",
              "required": true
            }
          ],
          "schema": "settings_syslog_destinations_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/settings/syslog/destinations/:syslog_destination_id",
          "summary": "Delete destination by UUID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "syslog_destination_id",
              "description": "PCE destination UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "org_setting": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/settings/events",
          "summary": "Get org-settings",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_events_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "interservice"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/settings/events",
          "summary": "Create or Update org-settings",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_events_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "corporate_ips_setting": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/settings/corporate_ips",
          "summary": "Get the corporate IPs of the org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_corporate_ips_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/settings/corporate_ips",
          "summary": "Create new corporate IPs for the org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_corporate_ips_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "default": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/settings/corporate_ips/default",
          "summary": "Update the default corporate IPs instance",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_corporate_ips_default_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/settings/corporate_ips/:corporate_ips_uuid",
          "summary": "Get the corporate IPs instance",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "corporate_ips_uuid",
              "description": "Corporate IPs UUID",
              "required": true
            }
          ],
          "schema": "settings_corporate_ips_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/settings/corporate_ips/:corporate_ips_uuid",
          "summary": "Update the corporate IPs instance",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "corporate_ips_uuid",
              "description": "Corporate IPs UUID",
              "required": true
            }
          ],
          "schema": "settings_corporate_ips_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/settings/corporate_ips/:corporate_ips_uuid",
          "summary": "Delete the corporate IPs instance",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "corporate_ips_uuid",
              "description": "Corporate IPs UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "trusted_proxy_ips": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/settings/trusted_proxy_ips",
          "summary": "Get Trusted Proxy IPs",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "settings_trusted_proxy_ips_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/settings/trusted_proxy_ips",
          "summary": "Create or Update Trusted Proxy IPs",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "settings_trusted_proxy_ips_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "workload_settings": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/settings/workloads",
          "summary": "Get workload setting properties",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_workloads_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/settings/workloads",
          "summary": "Update workload setting properties",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_workloads_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "ven_settings": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/settings/vens",
          "summary": "Get VEN settings",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_vens_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/settings/vens",
          "summary": "Update VEN settings",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_vens_put",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "traffic_collector_settings": {
      "collection": true,
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/settings/traffic_collector",
          "summary": "Post a new traffic_collector setting for org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_traffic_collector_post",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/settings/traffic_collector",
          "summary": "Get traffic_collector settings for the org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_traffic_collector_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/settings/traffic_collector/:traffic_collector_setting_id",
          "summary": "Get a traffic collector setting by UUID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "traffic_collector_setting_id",
              "description": "traffic_collector setting UUID",
              "required": true
            }
          ],
          "schema": "settings_traffic_collector_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        }
      }
    },
    "traffic_collector_setting": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/settings/traffic_collector/:traffic_collector_setting_id",
          "summary": "Update a traffic_collector setting",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "traffic_collector_setting_id",
              "description": "traffic_collector setting UUID",
              "required": true
            }
          ],
          "schema": "settings_traffic_collector_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/settings/traffic_collector/:traffic_collector_setting_id",
          "summary": "Delete a traffic_collector setting by UUID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "traffic_collector_setting_id",
              "description": "traffic_collector setting UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "settings": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/settings",
          "summary": "Get settings for this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": [
            "this_org_user",
            "interservice"
          ],
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/settings",
          "summary": "Update settings for this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "settings_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "service_account_api_keys": {
      "collection": true,
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/service_accounts/:service_account_id/api_keys",
          "summary": "Create a new API key for this service account",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "service_account_id",
              "description": "Service account UUID",
              "required": true
            }
          ],
          "schema": "service_accounts_api_keys_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "service_account_api_key": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/service_accounts/:service_account_id/api_keys/:api_key_id",
          "summary": "Delete Service Account API Key",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "service_account_id",
              "description": "Service account UUID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "api_key_id",
              "description": "API Key ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "org_api_keys": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/api_keys",
          "summary": "Get api keys",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "type",
              "description": "Type of principal - User or Service Account",
              "enum": [
                "user",
                "service_account"
              ],
              "required": true,
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "state",
              "description": "State of api keys - active or expired",
              "enum": [
                "active",
                "expired"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "name",
              "description": "Name of the service account to filter by",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "username",
              "description": "Username of the user to filter by",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "role",
              "description": "Role URI (JSON-encoded string) to filter on",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of api keys to return.",
              "query_param": true
            }
          ],
          "schema": "api_keys_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        }
      }
    },
    "org_api_key": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/api_keys/:api_key_id",
          "summary": "Delete API Key",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            },
            {
              "dataType": "string",
              "name": "api_key_id",
              "description": "API Key ID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "optional_features": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/optional_features",
          "summary": "Get the optional features collection",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "optional_features_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/optional_features",
          "summary": "Set the optional features for an org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "optional_features_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "licenses": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/licenses",
          "summary": "Get the licenses collection",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of licenses to return.",
              "query_param": true
            }
          ],
          "schema": "licenses_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/licenses/:license_identifier",
          "summary": "Get License",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "license_identifier",
              "description": "License UUID",
              "required": true
            }
          ],
          "schema": "licenses_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        }
      }
    },
    "license": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/licenses/:license_identifier",
          "summary": "Create License",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "license_identifier",
              "description": "License UUID",
              "required": true
            }
          ],
          "schema": "licenses_put",
          "expose_to": [
            "end_user_private_perm",
            "publish_private_schema_flag"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/licenses/:license_identifier",
          "summary": "Delete License",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "license_identifier",
              "description": "License UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm",
            "publish_private_schema_flag"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "orgs": {
      "collection": true,
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs",
          "summary": "Create a new org account",
          "params": null,
          "schema": "orgs_post",
          "expose_to": [
            "eng"
          ],
          "operation_type": "write",
          "authz": "system_admin",
          "pretty_authz": [
            "System Administrator"
          ],
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs",
          "summary": "Get the orgs",
          "params": null,
          "schema": "orgs_get",
          "expose_to": [
            "ops",
            "interservice"
          ],
          "operation_type": "read",
          "authz": "interservice",
          "auditable": false
        },
        "ven_count": {
          "http_method": "POST",
          "path": "/orgs/ven_count",
          "summary": "Get active ven count for a list of orgs",
          "params": null,
          "schema": "orgs_ven_count_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id",
          "summary": "Get the org information",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "orgs_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "from_jwt": {
          "http_method": "POST",
          "path": "/orgs/from_jwt",
          "summary": "Create a new org account via JWT",
          "params": null,
          "schema": "orgs_from_jwt_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        },
        "unmanage_from_jwt": {
          "http_method": "POST",
          "path": "/orgs/unmanage_from_jwt",
          "summary": "Remove permissions granted for MSSP security principals on a client org via JWT",
          "params": null,
          "schema": "orgs_unmanage_from_jwt_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "login_interservice",
          "auditable": true
        }
      }
    },
    "org": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id",
          "summary": "Set the org information",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "orgs_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id",
          "summary": "Delete the org record",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "expose_to": [
            "eng"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": false
        },
        "unpair_vens": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/unpair_vens",
          "summary": "Unpair all vens from org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Org ID",
              "required": true
            }
          ],
          "schema": "orgs_unpair_vens_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": true
        },
        "slb_devices": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/slb_devices/:device_id",
          "summary": "Get the SLB device information.",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "device_id",
              "description": "Slb Device UUID",
              "required": true
            }
          ],
          "schema": "slb_devices_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "policy_services_invalidate_set_digests": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/policy_services/invalidate_set_digests",
          "summary": "Invalidate set digests for the list of workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "policy_services_invalidate_set_digests_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": [
            "interservice"
          ],
          "auditable": false
        },
        "out_of_sync_workloads": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/out_of_sync_workloads",
          "summary": "Get all out of sync workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "workload_id",
              "description": "Filter on a single workload",
              "query_param": true
            }
          ],
          "expose_to": [
            "interservice"
          ],
          "operation_type": "read",
          "authz": "interservice",
          "auditable": false
        },
        "last_heartbeat_info": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/last_heartbeat_info",
          "summary": "Get last heartbeat info",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "agent_id",
              "description": "Agent ID",
              "required": true,
              "query_param": true
            }
          ],
          "expose_to": [
            "interservice"
          ],
          "operation_type": "read",
          "authz": "interservice",
          "auditable": false
        }
      }
    },
    "sessions": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/sessions",
          "summary": "Get the session list",
          "params": null,
          "schema": "sessions_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "root",
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/sessions/:id",
          "summary": "Get the session",
          "params": [
            {
              "dataType": "integer",
              "name": "id",
              "description": "Session ID",
              "required": true
            }
          ],
          "schema": "sessions_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "root",
          "auditable": false
        }
      }
    },
    "session": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/sessions/:id",
          "summary": "Delete the Session",
          "params": [
            {
              "dataType": "integer",
              "name": "id",
              "description": "Session ID",
              "required": true
            }
          ],
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": false
        }
      }
    },
    "saml_configs": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/authentication_settings/saml_configs",
          "summary": "Get all SAML configurations",
          "params": null,
          "schema": "authentication_settings_saml_configs_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/authentication_settings/saml_configs/:uuid",
          "summary": "Get SAML configuration",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "SAMLConfig UUID",
              "required": true
            }
          ],
          "schema": "authentication_settings_saml_configs_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        }
      }
    },
    "saml_config": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/authentication_settings/saml_configs/:uuid",
          "summary": "Update SAML configuration",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "SAMLConfig UUID",
              "required": true
            }
          ],
          "schema": "authentication_settings_saml_configs_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "pce_signing_cert": {
          "http_method": "POST",
          "path": "/authentication_settings/saml_configs/:uuid/pce_signing_cert",
          "summary": "Generate a new cert for signing SAML AuthN requests",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "SAMLConfig UUID",
              "required": true
            }
          ],
          "schema": "authentication_settings_saml_configs_pce_signing_cert_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "radius_configs": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/authentication_settings/radius_configs",
          "summary": "Get all RADIUS configurations",
          "params": null,
          "schema": "authentication_settings_radius_configs_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/authentication_settings/radius_configs",
          "summary": "Create RADIUS configuration",
          "params": null,
          "schema": "authentication_settings_radius_configs_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/authentication_settings/radius_configs/:uuid",
          "summary": "Get RADIUS configuration",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "RADIUSConfig UUID",
              "required": true
            }
          ],
          "schema": "authentication_settings_radius_configs_get",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        }
      }
    },
    "radius_config": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/authentication_settings/radius_configs/:uuid",
          "summary": "Update RADIUS configuration",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "RADIUSConfig UUID",
              "required": true
            }
          ],
          "schema": "authentication_settings_radius_configs_put",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/authentication_settings/radius_configs/:uuid",
          "summary": "Delete RADIUS configuration",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "RADIUSConfig UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "verify_shared_secret": {
          "http_method": "POST",
          "path": "/authentication_settings/radius_configs/:uuid/verify_shared_secret",
          "summary": "Verify RADIUS shared secret",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "RADIUSConfig UUID",
              "required": true
            }
          ],
          "schema": "authentication_settings_radius_configs_verify_shared_secret_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "ldap_configs": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/authentication_settings/ldap_configs",
          "summary": "Get all LDAP configurations",
          "params": null,
          "schema": "authentication_settings_ldap_configs_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/authentication_settings/ldap_configs",
          "summary": "Create LDAP configuration",
          "params": null,
          "schema": "authentication_settings_ldap_configs_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/authentication_settings/ldap_configs/:uuid",
          "summary": "Get LDAP configuration",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "LDAPConfig UUID",
              "required": true
            }
          ],
          "schema": "authentication_settings_ldap_configs_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        }
      }
    },
    "ldap_config": {
      "methods": {
        "update": {
          "http_method": "PUT",
          "path": "/authentication_settings/ldap_configs/:uuid",
          "summary": "Update LDAP configuration",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "LDAPConfig UUID",
              "required": true
            }
          ],
          "schema": "authentication_settings_ldap_configs_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/authentication_settings/ldap_configs/:uuid",
          "summary": "Delete LDAP configuration",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "LDAPConfig UUID",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "verify_connection": {
          "http_method": "POST",
          "path": "/authentication_settings/ldap_configs/:uuid/verify_connection",
          "summary": "Verify LDAP server connection",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "LDAPConfig UUID",
              "required": true
            }
          ],
          "schema": "authentication_settings_ldap_configs_verify_connection_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "password_policy": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/authentication_settings/password_policy",
          "summary": "Get password policy for local user",
          "params": null,
          "schema": "authentication_settings_password_policy_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/authentication_settings/password_policy",
          "summary": "Update password policy for local user",
          "params": null,
          "schema": "authentication_settings_password_policy_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "authentication_settings": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/authentication_settings",
          "summary": "Get authentication settings",
          "params": [
            {
              "dataType": "string",
              "name": "representation",
              "description": "Representation details for this resource on the response object",
              "query_param": true,
              "expose_to": [
                "end_user_private_perm"
              ]
            }
          ],
          "schema": "authentication_settings_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/authentication_settings",
          "summary": "Update authentication settings",
          "params": null,
          "schema": "authentication_settings_put",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": "any_org_owner",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "health": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/health",
          "summary": "Get the PCE Health",
          "params": null,
          "schema": "health_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "system_admin"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "System Administrator"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "location_summary": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/location_summary",
          "summary": "return location summary to UI",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "include_stale",
              "description": "calculate and compare cached summary signature",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "app_group_summary": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/app_group_summary",
          "summary": "return app_group summary to UI",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "include_stale",
              "description": "calculate and compare cached summary signature",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "network_traffic": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/network_traffic",
          "summary": "return network traffic data to UI",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "labels",
              "description": "List of label URIs to be filtered, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "workloads",
              "description": "List of workload URIs, encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "include_private",
              "description": "include all private addresses in full graph",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_ports",
              "description": "maximum ports (flows) to return per workload",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_nodes",
              "description": "maximum graph nodes",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_ip_lists",
              "description": "maximum ip_list matches to return per ip address",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "clusters",
              "description": "collapse workloads by cluster",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "roles",
              "description": "collapse workloads by role",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "role_keys",
              "description": "List of role keys describing workload sets [role key e.g => '1x2x3-4'], encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "cluster_keys",
              "description": "List of cluster keys describing workload sets [cluster key e.g => '1x2x3'], encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "app_group_keys",
              "description": "List of app_group keys describing workload sets [app_group key e.g => '2x3'], encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "boolean",
              "name": "include_stale",
              "description": "calculate and compare cached graph signature",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "traffic_class",
              "description": "traffic class: one of [unicast, multicast, broadcast, core_service]",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/network_traffic",
          "summary": "delete traffic for workloads in specified org assigned all specified labels",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "labels",
              "description": "List of label URIs to be filtered, encoded as a JSON string",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "write",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Limited Ruleset Manager"
          ],
          "auditable": true
        },
        "interservice_delete": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/network_traffic/interservice_delete",
          "summary": "delete traffic for workloads in specified org assigned all specified labels",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "labels",
              "description": "List of label URIs to be filtered, encoded as a JSON string",
              "query_param": true
            }
          ],
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "interservice",
          "auditable": false
        },
        "update_subgraphs": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/network_traffic/update_subgraphs",
          "summary": "Sync subgraphs from other regions to the leader PCE",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "caps": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/network_traffic/caps",
          "summary": "return network traffic data to UI",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "cluster_keys",
              "description": "List of cluster keys describing workload sets [cluster key e.g => '1x2x3'], encoded as a JSON string",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "app_group_keys",
              "description": "List of app_group keys describing workload sets [app_group key e.g => '2x3'], encoded as a JSON string",
              "query_param": true
            }
          ],
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "app_group": {
      "methods": {
        "observed_rule_coverage": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/app_groups/:app_group_id/observed_rule_coverage",
          "summary": "Get the label-based observed rule coverage summary for a specific application group",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "app_group_id",
              "description": "app group resource id",
              "required": true
            }
          ],
          "schema": "app_groups_observed_rule_coverage_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      }
    },
    "subgraphs": {
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/subgraphs",
          "summary": "return list of current subgraphs",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "interservice",
          "auditable": false
        },
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/subgraphs",
          "summary": "return list of subgraph update timestamps",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "expose_to": [
            "interservice"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "read",
          "authz": "interservice",
          "auditable": false
        }
      },
      "singleton": true
    },
    "dns_reverse_lookup": {
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/dns_reverse_lookup",
          "summary": "Reverse DNS resolution (ip2name)",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "dns_reverse_lookup_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "dns_lookup": {
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/dns_lookup",
          "summary": "DNS resolution (name2ip)",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "dns_lookup_post",
          "expose_to": [
            "end_user_private_perm"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "bulk_traffic_flows": {
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/agents/bulk_traffic_flows",
          "summary": "Bulk load traffic flows from unmanaged workloads",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "traffic_data": {
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/traffic_data",
          "summary": "Protocol buffer encoded traffic data from Network Flow Ingest Analyzer Application",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_transitional"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": [
            "this_org_user",
            "any_kubelink"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "flowlink": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/traffic_data/flowlink",
          "summary": "Protocol buffer encoded traffic data from the Flowlink client",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_transitional"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": [
            "this_org_user"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "kubelink": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/traffic_data/kubelink",
          "summary": "Protocol buffer encoded traffic data from the Kubelink client",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_private_transitional"
          ],
          "allowed_on_non_leader": true,
          "operation_type": "write",
          "authz": [
            "any_kubelink"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "detected_core_services_summaries": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/detected_core_services_summary",
          "summary": "Get detected core service summary details",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "detected_core_services_summary_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "detected_core_services_summary": {
      "methods": {
      }
    },
    "detected_core_services": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/detected_core_services",
          "summary": "Fetches all detected core services for this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "the maximum results to be returned",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "action",
              "description": "the action taken on the detected core services",
              "enum": [
                "skip",
                "reject",
                "accept",
                "recommended"
              ],
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "core_service_type",
              "description": "get all detected core services for a particular core service type",
              "query_param": true
            }
          ],
          "schema": "detected_core_services_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/detected_core_services/:detected_core_service_id",
          "summary": "Get a detected core service by UUID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "detected_core_service_id",
              "description": "UUID of the detected core service",
              "required": true
            }
          ],
          "schema": "detected_core_services_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/detected_core_services/:detected_core_service_id",
          "summary": "",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "detected_core_service_id",
              "description": "UUID of the detected core service",
              "required": true
            }
          ],
          "schema": "detected_core_services_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "core_service_types": {
      "collection": true,
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/core_service_types",
          "summary": "Create a new core service type",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "core_service_types_post",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/core_service_types",
          "summary": "Fetches all core service types for this org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "core_service_types_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/core_service_types/:core_service_type_id",
          "summary": "Fetches core service type by UUID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "core_service_type_id",
              "description": "UUID of the core service type",
              "required": true
            }
          ],
          "schema": "core_service_types_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/core_service_types/:core_service_type_id",
          "summary": "",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "core_service_type_id",
              "description": "UUID of the core service type",
              "required": true
            }
          ],
          "schema": "core_service_types_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "settings_core_services": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/settings/core_services",
          "summary": "Get core services setting for an org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "settings_core_services_get",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "read",
          "authz": [
            "this_org_user_for_rule_sets",
            "interservice"
          ],
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/settings/core_services",
          "summary": "Update core services setting for an org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "settings_core_services_put",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": "this_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "events": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/events",
          "summary": "Get a list of organizational events",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "created_by",
              "description": "User, agent, or system that created the event",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "event_type",
              "description": "Type of event",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "status",
              "description": "Status of event",
              "enum": [
                "success",
                "failure",
                "nil"
              ],
              "query_param": true
            },
            {
              "dataType": "Hash",
              "name": "timestamp",
              "description": null,
              "query_param": true
            },
            {
              "dataType": null,
              "name": "timestamp[gte]",
              "description": "Earliest event date to return (RFC 3339 format)",
              "query_param": true
            },
            {
              "dataType": null,
              "name": "timestamp[lte]",
              "description": "Latest event date to return (RFC 3339 format)",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "severity",
              "description": "Severity of event",
              "enum": [
                "emerg",
                "alert",
                "crit",
                "warning",
                "err",
                "notice",
                "info",
                "debug"
              ],
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of results to return",
              "query_param": true
            }
          ],
          "schema": "events_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "root",
            "system_admin"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "System Administrator"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/events/:composite_log_event_id",
          "summary": "Get an organizational event by id",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "composite_log_event_id",
              "description": "UUID of event to return",
              "required": true
            }
          ],
          "schema": "events_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": [
            "this_global_org_user",
            "root",
            "system_admin"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "System Administrator"
          ],
          "auditable": false
        }
      }
    },
    "event": {
      "methods": {
      }
    },
    "system_events": {
      "collection": true,
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/system_events",
          "summary": "Log system events written to syslog by other PCE services",
          "params": null,
          "schema": "system_events_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": "interservice",
          "auditable": false
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/system_events",
          "summary": "Get a list of system events",
          "params": [
            {
              "dataType": "string",
              "name": "created_by",
              "description": "User, agent, or system that created the event",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "event_type",
              "description": "Type of event",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "status",
              "description": "Status of event",
              "enum": [
                "success",
                "failure"
              ],
              "query_param": true
            },
            {
              "dataType": "Hash",
              "name": "timestamp",
              "description": null,
              "query_param": true
            },
            {
              "dataType": null,
              "name": "timestamp[gte]",
              "description": "Earliest event date to return (RFC 3339 format)",
              "query_param": true
            },
            {
              "dataType": null,
              "name": "timestamp[lte]",
              "description": "Latest event date to return (RFC 3339 format)",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "severity",
              "description": "Severity of event",
              "enum": [
                "emerg",
                "alert",
                "crit",
                "warning",
                "err",
                "notice",
                "info",
                "debug"
              ],
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of results to return",
              "query_param": true
            }
          ],
          "schema": "system_events_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": [
            "this_org_user",
            "root",
            "system_admin"
          ],
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "System Administrator",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/system_events/:composite_log_event_id",
          "summary": "Get a system event by id",
          "params": [
            {
              "dataType": "string",
              "name": "composite_log_event_id",
              "description": "UUID of system event to return",
              "required": true
            }
          ],
          "schema": "system_events_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": [
            "authenticated_user",
            "root",
            "system_admin"
          ],
          "pretty_authz": [
            "Any Authenticated User",
            "System Administrator"
          ],
          "auditable": false
        }
      }
    },
    "system_event": {
      "methods": {
      }
    },
    "setup_active_pce": {
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/standby/setup_active_pce",
          "summary": "API to setup and update active PCE in PCE standby",
          "params": null,
          "schema": "standby_setup_active_pce_post",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "write",
          "authz": [
            "interservice"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "setup_standby_pce": {
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/standby/setup_standby_pce",
          "summary": "API to setup PCE standby",
          "params": null,
          "schema": "standby_setup_standby_pce_post",
          "expose_to": [
            "end_user_private_transitional"
          ],
          "operation_type": "write",
          "authz": [
            "root",
            "this_org_admin"
          ],
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "pce_info": {
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/standby/pce_info",
          "summary": "PCE config information used for setting PCE standby",
          "params": null,
          "schema": "standby_pce_info_post",
          "expose_to": [
            "ops",
            "interservice"
          ],
          "operation_type": "read",
          "authz": [
            "root",
            "this_org_admin",
            "interservice"
          ],
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "replication_stats": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/standby/replication_stats",
          "summary": "API to fetch replication stats from standby PCE",
          "params": null,
          "schema": "standby_replication_stats_get",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "read",
          "authz": [
            "interservice"
          ],
          "auditable": false
        }
      },
      "singleton": true
    },
    "jobs": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/jobs",
          "summary": "Gets the job list.",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "job_type",
              "description": "The job-type value to use for filtering the results.",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "max_results",
              "description": "Maximum number of jobs to return.",
              "query_param": true
            }
          ],
          "schema": "jobs_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/jobs/:job_id",
          "summary": "Gets information about the specified job.",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "job_id",
              "description": "ID of a job.",
              "required": true
            }
          ],
          "schema": "jobs_get",
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "read",
          "authz": "this_org_user",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        }
      }
    },
    "job": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/jobs/:job_id",
          "summary": "Deletes the specified job record",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "job_id",
              "description": "ID of a job.",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_public"
          ],
          "operation_type": "write",
          "authz": [
            "this_org_user",
            "this_job_user"
          ],
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "This Job User"
          ],
          "auditable": true
        }
      }
    },
    "report_templates": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/report_templates",
          "summary": "Fetches collection of all available report templates for this user and org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "report_templates_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/report_templates/:template_type",
          "summary": "Get a report template by template type",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "template_type",
              "description": "Template type of the report",
              "required": true
            }
          ],
          "schema": "report_templates_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/report_templates/:template_type",
          "summary": "Update report template for an org by template type",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "template_type",
              "description": "Template type of the report",
              "required": true
            }
          ],
          "schema": "report_templates_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      }
    },
    "report_schedules": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/report_schedules",
          "summary": "Fetches collection of all report schedules for this user and org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "report_schedules_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/report_schedules",
          "summary": "Creates a new report schedule",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "report_schedules_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/report_schedules/:report_schedule_id",
          "summary": "Get a report schedule by UUID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "report_schedule_id",
              "description": "UUID of the report schedule",
              "required": true
            }
          ],
          "schema": "report_schedules_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/report_schedules/:report_schedule_id",
          "summary": "Update a report schedule by UUID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "report_schedule_id",
              "description": "UUID of the report schedule",
              "required": true
            }
          ],
          "schema": "report_schedules_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        },
        "delete": {
          "http_method": "DELETE",
          "path": "/orgs/:xorg_id/report_schedules/:report_schedule_id",
          "summary": "Delete a report schedule by UUID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "report_schedule_id",
              "description": "UUID of the report schedule",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_user_for_rule_sets",
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer"
          ],
          "auditable": false
        }
      }
    },
    "reports": {
      "collection": true,
      "methods": {
        "get_collection": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/reports",
          "summary": "Fetches a collection of all reports for this org and user",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "reports_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "time_series_statistics": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/reports/time_series_statistics",
          "summary": "Get time series data",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "reports_time_series_statistics_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_global_org_user",
          "pretty_authz": [
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only"
          ],
          "auditable": false
        },
        "create": {
          "http_method": "POST",
          "path": "/orgs/:xorg_id/reports",
          "summary": "Create a new on-demand report",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "reports_post",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/reports/:report_id",
          "summary": "Get a report by UUID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "report_id",
              "description": "UUID of the report",
              "required": true
            }
          ],
          "schema": "reports_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/reports/:report_id",
          "summary": "Updates a report by UUID",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "report_id",
              "description": "UUID of the report",
              "required": true
            }
          ],
          "schema": "reports_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        },
        "download": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/reports/:report_id/download",
          "summary": "Download a report",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "report_id",
              "description": "UUID of the report",
              "required": true
            }
          ],
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": false
        }
      }
    },
    "settings_reports": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/settings/reports",
          "summary": "Get report settings for an org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "settings_reports_get",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "read",
          "authz": [
            "this_org_user",
            "interservice"
          ],
          "pretty_authz": [
            "Full Ruleset Manager",
            "Global Administrator",
            "Global Organization Owner",
            "Global Policy Object Provisioner",
            "Global Read Only",
            "Limited Ruleset Manager",
            "Ruleset Provisioner",
            "Ruleset Viewer",
            "Workload Manager"
          ],
          "auditable": false
        },
        "update": {
          "http_method": "PUT",
          "path": "/orgs/:xorg_id/settings/reports",
          "summary": "Update report settings for an org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            }
          ],
          "schema": "settings_reports_put",
          "expose_to": [
            "end_user_experimental"
          ],
          "operation_type": "write",
          "authz": "this_org_admin",
          "pretty_authz": [
            "Global Organization Owner"
          ],
          "auditable": true
        }
      },
      "singleton": true
    },
    "traffic_flows_distinct_address_count": {
      "methods": {
        "get": {
          "http_method": "GET",
          "path": "/orgs/:xorg_id/traffic_flows/distinct_address_count",
          "summary": "Get total address space for an org",
          "params": [
            {
              "dataType": "integer",
              "name": "xorg_id",
              "description": "Organization",
              "required": true
            },
            {
              "dataType": "string",
              "name": "start_date",
              "description": "Start date for the query in RFC3339 format",
              "query_param": true
            },
            {
              "dataType": "string",
              "name": "end_date",
              "description": "End date for the query in RFC3339 format",
              "query_param": true
            }
          ],
          "schema": "traffic_flows_distinct_address_count_get",
          "expose_to": [
            "interservice"
          ],
          "operation_type": "read",
          "authz": "interservice",
          "auditable": false
        }
      },
      "singleton": true
    },
    "stats": {
      "methods": {
        "memcached": {
          "http_method": "GET",
          "path": "/stats/memcached",
          "summary": "Do basic memcached health check",
          "params": null,
          "schema": "stats_memcached_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "root",
          "auditable": false
        },
        "load": {
          "http_method": "GET",
          "path": "/stats/load",
          "summary": "Load Stats",
          "params": [
            {
              "dataType": "string",
              "name": "start_time_bucket",
              "description": "What Date report time to retrieve (Default: Latest)",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "count",
              "description": "The number of entries to return (Default:24)",
              "query_param": true
            },
            {
              "dataType": "integer",
              "name": "interval_minutes",
              "description": "The bucket interval 5 or 60 (1hrs)",
              "required": true,
              "query_param": true
            }
          ],
          "schema": "stats_load_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "root",
          "auditable": false
        }
      },
      "singleton": true
    },
    "org_extracts": {
      "collection": true,
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/org_extracts",
          "summary": "Create a new org extract",
          "params": null,
          "schema": "org_extracts_post",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": true
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/org_extracts",
          "summary": "Get the org extracts",
          "params": null,
          "schema": "org_extracts_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "root",
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/org_extracts/:uuid",
          "summary": "Get org extract record",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Org Extract UUID",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "include_workloads_waiting_for_pce_addresses",
              "description": "Return list of workloads that still have not received the necessary PCE addresses",
              "query_param": true
            }
          ],
          "schema": "org_extracts_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "root",
          "auditable": false
        }
      }
    },
    "org_extract": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/org_extracts/:uuid",
          "summary": "Delete org extract record",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Org Extract UUID",
              "required": true
            }
          ],
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": true
        },
        "update": {
          "http_method": "PUT",
          "path": "/org_extracts/:uuid",
          "summary": "Update org extract record",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Org Extract UUID",
              "required": true
            }
          ],
          "schema": "org_extracts_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": true
        }
      }
    },
    "org_loads": {
      "collection": true,
      "methods": {
        "create": {
          "http_method": "POST",
          "path": "/org_loads",
          "summary": "Create a new org load",
          "params": null,
          "schema": "org_loads_post",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": true
        },
        "get_collection": {
          "http_method": "GET",
          "path": "/org_loads",
          "summary": "Get the org loads",
          "params": null,
          "schema": "org_loads_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "root",
          "auditable": false
        },
        "get_instance": {
          "http_method": "GET",
          "path": "/org_loads/:uuid",
          "summary": "Get org load record",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Org Load UUID",
              "required": true
            },
            {
              "dataType": "boolean",
              "name": "include_workloads_that_have_not_migrated",
              "description": "Return list of workloads that still have not migrated to this PCE",
              "query_param": true
            }
          ],
          "schema": "org_loads_get",
          "expose_to": [
            "ops"
          ],
          "operation_type": "read",
          "authz": "root",
          "auditable": false
        }
      }
    },
    "org_load": {
      "methods": {
        "delete": {
          "http_method": "DELETE",
          "path": "/org_loads/:uuid",
          "summary": "Delete org load record",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Org Load UUID",
              "required": true
            }
          ],
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": true
        },
        "update": {
          "http_method": "PUT",
          "path": "/org_loads/:uuid",
          "summary": "Update org load record",
          "params": [
            {
              "dataType": "string",
              "name": "uuid",
              "description": "Org Load UUID",
              "required": true
            }
          ],
          "schema": "org_loads_put",
          "expose_to": [
            "ops"
          ],
          "operation_type": "write",
          "authz": "root",
          "auditable": true
        }
      }
    }
  },
  "methods": {
    "node_available": {
      "http_method": "GET",
      "path": "/node_available",
      "summary": "Get node health status",
      "params": null,
      "expose_to": [
        "end_user_public"
      ]
    },
    "supercluster_leader": {
      "http_method": "GET",
      "path": "/supercluster/leader",
      "summary": "Determine if node is supercluster leader",
      "params": null,
      "expose_to": [
        "end_user_public"
      ]
    },
    "masterconfig_health_check": {
      "http_method": "GET",
      "path": "/masterconfig_health_check",
      "summary": "Do basic health check for masterconfig service",
      "params": null,
      "expose_to": [
        "ops"
      ]
    },
    "fileserver_health_check": {
      "http_method": "GET",
      "path": "/fileserver_health_check",
      "summary": "Do basic health check (no DB)",
      "params": null,
      "schema": "fileserver_health_check_get",
      "expose_to": [
        "interservice"
      ],
      "operation_type": "read",
      "authz": "interservice",
      "auditable": false
    },
    "fileserver_sync_files": {
      "http_method": "POST",
      "path": "/fileserver_sync_files",
      "summary": "sync master to slave",
      "params": null,
      "schema": "fileserver_sync_files_post",
      "expose_to": [
        "interservice"
      ],
      "operation_type": "write",
      "authz": "interservice",
      "auditable": false
    },
    "network_device_health_check": {
      "http_method": "GET",
      "path": "/network_device_health_check",
      "summary": "Do basic health check (no DB)",
      "params": null,
      "schema": "network_device_health_check_get",
      "expose_to": [
        "interservice"
      ],
      "operation_type": "read",
      "authz": "interservice",
      "auditable": false
    },
    "login_health_check": {
      "http_method": "GET",
      "path": "/login_health_check",
      "summary": "Do basic health check (no DB)",
      "params": null,
      "schema": "login_health_check_get",
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "login_health_check_db": {
      "http_method": "GET",
      "path": "/login_health_check_db",
      "summary": "Do basic health check (fetch data from DB)",
      "params": null,
      "schema": "login_health_check_db_get",
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "login_health_check_ext": {
      "http_method": "GET",
      "path": "/login_health_check_ext",
      "summary": "Do extended health check (DB, memcached)",
      "params": null,
      "schema": "login_health_check_ext_get",
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "orgs_:xorg_id_traffic_query_health_check": {
      "http_method": "GET",
      "path": "/orgs/:xorg_id/traffic_query/health_check",
      "summary": "return traffic query health status",
      "params": [
        {
          "dataType": "integer",
          "name": "xorg_id",
          "description": "Organization",
          "required": true
        }
      ],
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "api_key_authenticate": {
      "http_method": "POST",
      "path": "/api_key_authenticate",
      "summary": "Authenticate the API KEY - for other webservices",
      "params": null,
      "schema": "api_key_authenticate_post",
      "expose_to": [
        "interservice"
      ],
      "operation_type": "write",
      "authz": "interservice",
      "auditable": false
    },
    "clusters": {
      "http_method": "GET",
      "path": "/clusters",
      "summary": "Retrieve cluster-specific parameters, for pairing to login server",
      "params": null,
      "schema": "clusters_get",
      "expose_to": [
        "interservice"
      ],
      "operation_type": "read",
      "authz": "login_interservice",
      "auditable": false
    },
    "trusted_proxy_ips": {
      "http_method": "GET",
      "path": "/trusted_proxy_ips",
      "summary": "Interservice API for fetching Trusted Proxy IPs for a given Org",
      "params": [
        {
          "dataType": "integer",
          "name": "org_id",
          "description": "Org ID",
          "required": true,
          "query_param": true
        }
      ],
      "schema": "trusted_proxy_ips_get",
      "expose_to": [
        "interservice"
      ],
      "operation_type": "read",
      "authz": "interservice",
      "auditable": false
    },
    "agent_health_check": {
      "http_method": "GET",
      "path": "/agent_health_check",
      "summary": "Do basic health check (no DB)",
      "params": null,
      "schema": "agent_health_check_get",
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "agent_health_check_ext": {
      "http_method": "GET",
      "path": "/agent_health_check_ext",
      "summary": "Do extended health check (DB, Redis, memcached, Rabbit etc...)",
      "params": null,
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "agent_health_check_db": {
      "http_method": "GET",
      "path": "/agent_health_check_db",
      "summary": "Do basic health check (just fetch data from DB)",
      "params": null,
      "schema": "agent_health_check_db_get",
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "agent_test_crash": {
      "http_method": "GET",
      "path": "/agent_test_crash",
      "summary": "Test rails crash - used to test that crash email is working",
      "params": null,
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "product_version": {
      "http_method": "GET",
      "path": "/product_version",
      "summary": "Product Version Details",
      "params": null,
      "schema": "product_version_get",
      "expose_to": [
        "end_user_public",
        "interservice"
      ],
      "operation_type": "read",
      "authz": [
        "authenticated_user",
        "login_interservice"
      ],
      "pretty_authz": [
        "Any Authenticated User"
      ],
      "auditable": false
    },
    "agent_alert_check": {
      "http_method": "GET",
      "path": "/agent_alert_check",
      "summary": "Write something to the alert log",
      "params": null,
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "agent_exception_check": {
      "http_method": "GET",
      "path": "/agent_exception_check",
      "summary": "Raise an exception",
      "params": null,
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "noop": {
      "http_method": "GET",
      "path": "/noop",
      "summary": "Noop API. Useful for things like resetting the session expiration timer, session validation and API key validation",
      "params": null,
      "schema": "noop_get",
      "expose_to": [
        "end_user_public"
      ],
      "operation_type": "read",
      "authz": "authenticated_user",
      "pretty_authz": [
        "Any Authenticated User"
      ],
      "auditable": false
    },
    "components_version": {
      "http_method": "GET",
      "path": "/components_version",
      "summary": "Version details for PCE and its components",
      "params": null,
      "schema": "components_version_get",
      "expose_to": [
        "end_user_private_perm"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "passenger_memory_monitor_test": {
      "http_method": "GET",
      "path": "/passenger_memory_monitor_test",
      "summary": "",
      "params": null,
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "agent_traffic_health_check": {
      "http_method": "GET",
      "path": "/agent_traffic_health_check",
      "summary": "",
      "params": null,
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "collector_health_check": {
      "http_method": "GET",
      "path": "/collector_health_check",
      "summary": "Do basic health check",
      "params": null,
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "collector_health_check_ext": {
      "http_method": "GET",
      "path": "/collector_health_check_ext",
      "summary": "Do extended health check (DB, Redis, memcached, Rabbit etc...)",
      "params": null,
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "core_services_health_check": {
      "http_method": "GET",
      "path": "/core_services_health_check",
      "summary": "Do basic health check (no DB)",
      "params": null,
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "events_health_check": {
      "http_method": "GET",
      "path": "/events_health_check",
      "summary": "Do basic health check (no DB)",
      "params": null,
      "schema": "events_health_check_get",
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "events_health_check_ext": {
      "http_method": "GET",
      "path": "/events_health_check_ext",
      "summary": "Do extended health check (db)",
      "params": null,
      "schema": "events_health_check_ext_get",
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "software_ven_releases_deploy": {
      "http_method": "POST",
      "path": "/software/ven/releases/deploy",
      "summary": "Receive and deploy VEN release image file to local ven repo",
      "params": null,
      "expose_to": [
        "interservice"
      ],
      "operation_type": "write",
      "authz": "interservice",
      "auditable": true
    },
    "reporting_management_health_check": {
      "http_method": "GET",
      "path": "/reporting_management_health_check",
      "summary": "Do basic health check (no DB)",
      "params": null,
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "ops_health_check": {
      "http_method": "GET",
      "path": "/ops_health_check",
      "summary": "Do basic health check (no DB)",
      "params": null,
      "schema": "ops_health_check_get",
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    },
    "ops_health_check_db": {
      "http_method": "GET",
      "path": "/ops_health_check_db",
      "summary": "Do basic health check (fetch data from DB)",
      "params": null,
      "schema": "ops_health_check_db_get",
      "expose_to": [
        "ops"
      ],
      "operation_type": "read",
      "authz": "root",
      "auditable": false
    }
  }
};
