/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createElement, useEffect, useCallback, useContext, useRef, useMemo} from 'react';
import FocusLock from 'react-focus-lock';
import {reactUtils} from '@illumio-shared/utils';
import {AppContext} from 'containers/App/AppUtils';
import {prepareContainerProps} from 'containers/Selector/SelectorUtils';

export default function ContainerResource(props) {
  const {
    theme,
    resource: {
      id,
      container,
      enableFocusLock,
      dontBackOnDone,
      template,
      container: {prefetch},
    },
    resource,
    query,
    keyword,
    onOptionSelect,
    onBack,
    focusLockGroupName,
    onReturnFocusToInput,
    isGridArea,
    setCategories,
    initialLoadParams,
  } = props;

  const {
    fetcher,
    store: {prefetcher},
  } = useContext(AppContext);
  const routeOptions = reactUtils.useDeepCompareMemo(prefetcher.options, [prefetcher.options]);
  const fetchTask = useRef(null);
  const {onInitialLoadDone, onInitialLoadReject} = reactUtils.useDeepCompareMemo(initialLoadParams ?? {});

  const handleDone = useCallback(
    (evt, value) => {
      onOptionSelect(evt, {resourceId: resource.id, value});

      if (dontBackOnDone) {
        return;
      }

      onBack(evt);
    },
    [resource, dontBackOnDone, onOptionSelect, onBack],
  );

  const containerProps = useMemo(
    () => prepareContainerProps({query, keyword, onDone: handleDone, onCancel: onBack, setCategories, resource}),
    [query, keyword, resource, handleDone, onBack, setCategories],
  );

  useEffect(() => {
    const containerLoadedTimeout = setTimeout(() => {
      onInitialLoadDone?.();
    });

    return () => {
      clearTimeout(containerLoadedTimeout);
      onInitialLoadReject?.();
    };
  }, [onInitialLoadDone, onInitialLoadReject]);

  useEffect(() => {
    if (!prefetch) {
      return;
    }

    fetchTask.current = fetcher.fork(prefetch, routeOptions);

    return () => {
      if (fetchTask.current) {
        fetcher.cancel(fetchTask.current);
        fetchTask.current = null;
      }
    };
  }, [prefetch, fetcher, routeOptions]);

  const style = {};

  if (template) {
    style.display = 'grid';
    style.gridTemplate = template;
  }

  if (isGridArea) {
    style.gridArea = id;
  }

  const content = createElement(container, containerProps);

  if (enableFocusLock) {
    return (
      <FocusLock
        group={focusLockGroupName}
        className={theme.containerResource}
        autoFocus={false}
        onDeactivation={onReturnFocusToInput}
        lockProps={{style}}
      >
        {content}
      </FocusLock>
    );
  }

  return (
    <div className={theme.containerResource} style={style}>
      {content}
    </div>
  );
}
