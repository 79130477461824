/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {webStorageUtils} from '@illumio-shared/utils';
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {undoable} from 'containers/IlluminationMap/Graph/Utils/MapGraphStateUtils';

export default {
  graph: combineReducers({
    openCombos: undoable(
      (state = {}, action) => {
        switch (action.type) {
          case 'MAP_OPEN_COMBOS_SAVE':
            return action.data;
          case 'MAP_SET_GRAPH_STATE':
            return action.data?.openCombos || state;
          case 'MAP_CLEAR_GRAPH_STATE':
            return {};
          default:
            return state;
        }
      },
      {
        undoActionType: 'MAP_OPEN_COMBOS_UNDO',
        redoActionType: 'MAP_OPEN_COMBOS_REDO',
        clearHistoryActionTypes: [
          'MAP_OPEN_COMBOS_CLEAR_HISTORY',
          'MAP_SET_GRAPH_STATE',
          'MAP_CLEAR_GRAPH_STATE',
          'MAP_SET_AUTO_GROUPING_SETTINGS',
        ],
      },
    ),
    positions(state = {}, action) {
      switch (action.type) {
        case 'MAP_GRAPH_CHART_CHANGED':
          return action.data;
        default:
          return state;
      }
    },
    showAllMembers(state = webStorageUtils.getItem('mapShowAllMembers') ?? false, action) {
      switch (action.type) {
        case 'MAP_SHOW_ALL_MEMBERS':
          webStorageUtils.setItem('mapShowAllMembers', action.data);

          return action.data;
        default:
          return state;
      }
    },
  }),
  protectionSchemas(state = [], action) {
    switch (action.type) {
      case 'SET_PROTECTION_SCHEMAS':
        return action.data;
      default:
        return state;
    }
  },
};

export const getOpenCombos = state => state.map?.graph?.openCombos?.present;
export const getPositions = state => state.map?.graph?.positions ?? null;
export const getShowAllMembers = state => state.map?.graph?.showAllMembers;
const getProtectionSchemas = state => state.protectionSchemas;
const getOpenCombosHistory = state => state.map?.graph?.openCombos;

// This should be replaced by an API eventually
export const getLabelTypes = createSelector([], () => [
  {display_name: intl('Common.Location'), key: 'loc'},
  {display_name: intl('Common.Environment'), key: 'env'},
  {display_name: intl('Common.Application'), key: 'app'},
  {display_name: intl('Common.Role'), key: 'role'},
]);

// Converts server roles to href -> server role mapping e.g. {"/orgs/1/labels/xyz" => "AD-Domain-Services"}
export const getIconLabelMappingFromProtectionSchemas = createSelector([getProtectionSchemas], protectionSchemas =>
  (protectionSchemas ?? []).reduce((result, protectionSchema) => {
    protectionSchema.label_groups?.forEach(labelGroup => {
      if (labelGroup?.href && !result.get(labelGroup.href)) {
        result.set(labelGroup.href, protectionSchema.rule_set_external_data_reference);
      }
    });

    return result;
  }, new Map()),
);

export const getOpenCombosCanUndo = createSelector(
  [getOpenCombosHistory],
  openCombosHistory => openCombosHistory.past.length > 0,
);
export const getOpenCombosCanRedo = createSelector(
  [getOpenCombosHistory],
  openCombosHistory => openCombosHistory.future.length > 0,
);
