/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {combineReducers} from 'redux';
import {isUserScoped} from 'containers/User/UserState';
import {getRouteParams} from 'containers/App/AppState';
import {calcAppGroupName} from './AppGroupUtils';
import vulnerabilitiesReducers from './Vulnerabilities/AppGroupVulnerabilitiesState';

export default {
  appGroup: combineReducers({
    ...vulnerabilitiesReducers,
    appGroups(state = [], action) {
      switch (action.type) {
        case 'APPGROUPSUMMARY_GET_LIST':
          return action.data.nodes;
        default:
          return state;
      }
    },
    appGroupSummary(state = [], action) {
      switch (action.type) {
        case 'APPGROUPSUMMARY_GET_DETAILS':
          return action.data;
        default:
          return state;
      }
    },
    appGroupLabels(state = [], action) {
      switch (action.type) {
        case 'APPGROUPSUMMARY_GET_LIST':
          return action.data.labels;
        default:
          return state;
      }
    },
    appGroupRuleCoverage(state = [], action) {
      switch (action.type) {
        case 'APPGROUP_RULE_COVERAGE_GET_LIST':
          return action.data;
        case 'APPGROUP_RULE_COVERAGE_GET':
          return state.map(item => (item.href === action.id ? action.data : item));
        default:
          return state;
      }
    },
    appGroupListVulnerabilities(state = {}, action) {
      switch (action.type) {
        case 'APPGROUP_LIST_GET_VULNERABILITIES':
          return action.data;
        default:
          return state;
      }
    },
    appGroupRuleCoverageLoading(state = {}, action) {
      switch (action.type) {
        case 'APPGROUP_RULE_COVERAGE_GET_LOADING':
          return {...state, [action.id]: true};
        case 'APPGROUP_RULE_COVERAGE_GET':
          delete state[action.id];

          return {...state};
        default:
          return state;
      }
    },
  }),
};

export const getAppGroups = state => state.appGroup.appGroups;
export const getAppGroupSummary = state => state.appGroup.appGroupSummary;
export const getAppGroupLabels = state => state.appGroup.appGroupLabels;
export const getAppGroupRuleCoverage = state => state.appGroup.appGroupRuleCoverage;
export const getAppGroupListVulnerabilities = state => state.appGroup.appGroupListVulnerabilities;
export const getAppGroupRuleCoverageLoading = state => state.appGroup.appGroupRuleCoverageLoading;
export const isAppGroupsEnabled = createSelector([getAppGroups, isUserScoped], (appGroups, isUserScoped) => {
  return !isUserScoped || Object.keys(appGroups).length;
});

export const getAppGroupCaps = createSelector([getAppGroups, getRouteParams], (appGroups, routeParams) => {
  const id = routeParams.previd || routeParams.id;

  return appGroups.find(appGroup => appGroup.href === id)?.caps || {workloads: [], rule_sets: []};
});

export const isTrafficAvailableForAppGroup = createSelector([getAppGroupCaps], caps => caps.rule_sets.includes('read'));

export const getAppGroupName = createSelector(
  [getAppGroups, getAppGroupLabels, getRouteParams],
  (appGroups, labels, routeParams) => {
    const id = routeParams.previd || routeParams.id;
    const appGroupLabelIds = appGroups.find(appGroup => appGroup.href === id)?.label_ids;

    return appGroupLabelIds && calcAppGroupName(appGroupLabelIds?.map(id => labels[id]?.label));
  },
);

export const getAppGroupParams = createSelector(
  [getAppGroups, getAppGroupLabels, getRouteParams],
  (appGroups, labels, routeParams) => {
    const id = routeParams.previd || routeParams.id;
    const appGroupLabelIds = appGroups.find(appGroup => appGroup.href === id)?.label_ids;

    const appGroupHrefs = appGroupLabelIds?.map(id => labels[id]?.label);

    return appGroupLabelIds && appGroupHrefs;
  },
);

export const getAppGroupsWithRuleCoverage = createSelector([getAppGroupRuleCoverage], appGroups => {
  return appGroups
    .map(appGroup => {
      const name = appGroup.labels
        .sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
        .map(label => label.value)
        .join(' | ');
      const {intra_group, inter_group, ip_list, num_workloads} = appGroup;
      const totalServices =
        (intra_group?.num_services || 0) + (inter_group?.num_services || 0) + (ip_list?.num_services || 0);
      const totalRules = (intra_group?.num_rules || 0) + (inter_group?.num_rules || 0) + (ip_list?.num_rules || 0);
      const coverage = totalServices ? totalRules / totalServices : 0;
      const members = num_workloads;

      return {name, coverage, members, href: appGroup.href, totalServices, totalRules};
    })
    .sort((a, b) => (a.members < b.members ? 1 : a.members > b.members ? -1 : 0));
});
