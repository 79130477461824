/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {isValidProcessName, lookupRegexPortProtocol} from 'containers/Service/ServiceUtils';
import _, {isEqual} from 'lodash';
import {ipUtils, portUtils} from '@illumio-shared/utils';
import intl from '@illumio-shared/utils/intl';
import {EmptyFilters, mapQueryNamePrefix} from 'containers/IlluminationMap/Filter/MapFilterConstants';
import {generalUtils} from '@illumio-shared/utils/shared';
import {createDefaultName, getAutoSavedFilters} from 'containers/IlluminationMap/Filter/MapFilterApiUtils';

export const saveFiltersSettings = settings => {
  const transformed = Object.entries(settings ?? {}).reduce((settings, [key, value]) => {
    if (value !== undefined) {
      settings[key] = value;
    }

    return settings;
  }, {});
  let serialized;

  try {
    serialized = JSON.stringify(transformed);
  } catch (error) {
    console.error(error);

    return;
  }

  localStorage.setItem('mapFilterSettings', serialized);
};

export const loadFiltersSettings = () => {
  const serialized = localStorage.getItem('mapFilterSettings');
  let deserialized = {};

  try {
    deserialized = JSON.parse(serialized || '{}');
  } catch (error) {
    console.error(error);

    return deserialized;
  }

  return deserialized;
};

export const removeBasicModeSetting = () => {
  const filterSettings = loadFiltersSettings();

  saveFiltersSettings({...filterSettings, basicMode: undefined});
};

export const generateFavoriteFilterPayload = ({filters, label, queryId, date, queryName}) => {
  const defaultName = createDefaultName(filters);

  return {
    date: date ?? new Date().toISOString().slice(),
    filters,
    label: label ?? queryName ?? defaultName,
    queryId: queryId ?? generalUtils.randomString(10),
    queryName: `${mapQueryNamePrefix}${queryName ?? label ?? defaultName}`,
  };
};

/**
---------------------    Serialize and De-Serialize State ------------------
This is Filter State. This state is converted to JSON string using `serializeState` then stored in sessionStorage. In the Filter constructor, the stored information is taken back
from sessionStorage and has to be deserialized using `deserializeState` function before setting to state. If there is no value in sessionStorage, then the DefaultState is used.

const DefaultState = {
  orView: false,
  summaryView: false,
  filters: {
            time: {value: ..., }
            policyDecisions: new Map(),
            providerInclude: new Map(),
            providerExclude: new Map(),
            consumerInclude: new Map(),
            consumerExclude: new Map(),
            consumerOrProviderInclude: new Map(),
            consumerOrProviderExclude: new Map(),
            servicesInclude: new Map(),
            servicesExclude: new Map(),
          }
};

 */

/**
  Take a filter object with  keys of type string and values of  type Map, return an object with keys of type string and values of  type object.
  @param filters : Object with {key : Map() , ...}
  @return object : Object with {key : Object(), ...}
 */
export const convertFiltersValuesToObject = filters => {
  return Object.entries(filters ?? {}).reduce((serialized, [key, value]) => {
    if (
      [
        'consumerInclude',
        'providerInclude',
        'servicesInclude',
        'consumerOrProviderInclude',
        'consumerExclude',
        'providerExclude',
        'servicesExclude',
        'consumerOrProviderExclude',
        'policyDecisions',
      ].includes(key) &&
      value instanceof Map
    ) {
      serialized[key] = Object.fromEntries(value);
    } else {
      serialized[key] = value;
    }

    return serialized;
  }, {});
};

/**
  Take a filter object with keys of type string and values of  type object., return an object with keys of type string and values of  type Map.
  @param filter : Object with {key : Object(), ...}
  @return  object : Object with {key : Map() , ...}
 */
export const convertFiltersValuesToMap = filters => {
  return Object.entries(filters ?? {}).reduce((deserializedFilters, [key, value]) => {
    if (
      [
        'consumerInclude',
        'providerInclude',
        'servicesInclude',
        'consumerOrProviderInclude',
        'consumerExclude',
        'providerExclude',
        'servicesExclude',
        'consumerOrProviderExclude',
        'policyDecisions',
      ].includes(key) &&
      _.isPlainObject(value)
    ) {
      deserializedFilters[key] = new Map(Object.entries(value));
    } else {
      deserializedFilters[key] = value;
    }

    return deserializedFilters;
  }, {});
};

/**
Convert all Map() values of Filter in the Filter State to Object values
 */
export const convertAllFieldInStateToObject = state => {
  return Object.entries(state ?? {}).reduce((newState, [key, value]) => {
    if (key === 'filters') {
      newState[key] = convertFiltersValuesToObject(value);
    } else {
      newState[key] = value;
    }

    return newState;
  }, {});
};

/**
Convert iterative values values of Filter  in the Filter State to Map() values
 */
export const convertSomeFieldInStateToMap = jsonObject => {
  return Object.entries(jsonObject ?? {}).reduce((newState, [key, value]) => {
    if (key === 'filters') {
      newState[key] = convertFiltersValuesToMap(value);
    } else if (key === 'queryId' || key === 'queryName' || key === 'date' || key === 'label') {
      newState[key] = value;
    }

    return newState;
  }, {});
};

export const isEmptyFilter = filters => {
  return _.isEqual(convertFiltersValuesToObject(filters), convertFiltersValuesToObject(EmptyFilters));
};

export function swapFilters(consumer, provider) {
  consumer ??= new Map();
  provider ??= new Map();

  const newProvider = new Map(consumer);

  // Preserve the transmission entries on the provider side
  if (provider.get('transmission')) {
    newProvider.set('transmission', provider.get('transmission'));
  }

  // Preserve the FQDN entries on the provider side
  if (provider.get('fqdn')) {
    newProvider.set('fqdn', provider.get('fqdn'));
  }

  const newConsumer = new Map(provider);

  // Remove Tranmission and FQDN entries on the consumer side
  newConsumer.delete('transmission');
  newConsumer.delete('fqdn');

  return {newConsumer, newProvider};
}

const protocolRegexMap = {
  gre: {proto: 47, text: intl('Protocol.GRE'), regex: /^(g|gr|gre)$/i},
  icmp: {proto: 1, text: intl('Protocol.ICMP'), regex: /^(i|ic|icm|icmp)$/i},
  icmpv6: {proto: 58, text: intl('Protocol.ICMPv6'), regex: /^(i|ic|icm|icmp|icmpv|icmpv6)$/i},
  igmp: {proto: 2, text: intl('Protocol.IGMP'), regex: /^(i|ig|igm|igmp)$/i},
  ipip: {proto: 94, text: intl('Protocol.IPIP'), regex: /^(i|ip|ipi|ipip)$/i},
  ipv4: {proto: 4, text: intl('Protocol.IPv4'), regex: /^(i|ip|ipv|ipv4)$/i},
  ipv6: {proto: 41, text: intl('Protocol.IPv6'), regex: /^(i|ip|ipv|ipv6)$/i},
  tcp: {proto: 6, text: intl('Protocol.TCP'), regex: /^(t|tc|tcp)$/i},
  udp: {proto: 17, text: intl('Protocol.UDP'), regex: /^(u|ud|udp)$/i},
};

/**
 * validate acceptable strings that represent protocol without port.
 * @param key
 * @param query
 */
const validateProtocolOnly = (key, query) => {
  return Object.values(protocolRegexMap).reduce((result, {proto, text, regex}) => {
    if (regex.test(query)) {
      result.push({
        value: text,
        detail: {proto},
      });
    }

    return result;
  }, []);
};

/**
 *
 * Accept query string and will return an array of range : ['89-90', '89-90 TCP', '80-90 UDP']
 *
 * @param {*} key : unused
 * @param {*} query : String of query in form of ' # - # protocol', 'protocol #-#', '#-#'
 * @returns array of object in shape of { value: /string/ } and return [] if no match
 */
const validatePortRangeSearch = (key, query) => {
  // Make sure query not just empty space or has urgly non-word
  if (/(^ *$)|[^\s\w-]/.test(query)) {
    return [];
  }

  const result = [];

  let ranges;

  //  Try extract #-# or similar pattern.
  try {
    ranges = query
      .match(/\d+\s*-+\s*\d+/)
      .shift()
      .split(/\s*-+\s*/)
      .sort((a, b) => a - b);

    // No more that 2 numbers
    if (query.match(/\d+/g).length > 2) {
      return [];
    }
  } catch {
    // If no match, null,  return []
    return [];
  }

  //  Extract protocol
  const protocol = query.split(/[^A-Za-z]+/).filter(Boolean);

  // if numbers are valid port and range
  const valueStart = Number(ranges[0]) < Number(ranges[1]) ? Number(ranges[0]) : Number(ranges[1]);
  const valueEnd = Number(ranges[0]) > Number(ranges[1]) ? Number(ranges[0]) : Number(ranges[1]);

  if (valueStart < valueEnd && portUtils.isValidPort(ranges[0]) && portUtils.isValidPort(ranges[1])) {
    // Query string contains only port and no protocol,
    if (_.isEmpty(protocol)) {
      const range = `${valueStart}-${valueEnd}`;

      // In this case add both UDP and TCP protocol options in dropdown
      result.push(
        {
          value: range,
          detail: {port: valueStart, to_port: valueEnd},
        },
        {
          value: `${range} ${portUtils.portProtocolMap().tcp.text}`,
          detail: {proto: portUtils.portProtocolMap().tcp.value, port: valueStart, to_port: valueEnd},
        },
        {
          value: `${range} ${portUtils.portProtocolMap().udp.text}`,
          detail: {proto: portUtils.portProtocolMap().udp.value, port: valueStart, to_port: valueEnd},
        },
      );

      return result;
    }

    // Query string contains port with one type of protocol chars, in this case add the matching protocol option to drop down list
    if (protocol.length === 1) {
      // check for TCP
      if (portUtils.portProtocolRegex.tcpPortProtocol.test(`${valueEnd} ${protocol[0]}`)) {
        result.push({
          value: `${valueStart}-${valueEnd} ${portUtils.portProtocolMap().tcp.text}`,
          detail: {proto: portUtils.portProtocolMap().tcp.value, port: valueStart, to_port: valueEnd},
        });
      } else if (portUtils.portProtocolRegex.udpPortProtocol.test(`${valueEnd} ${protocol[0]}`)) {
        result.push({
          value: `${valueStart}-${valueEnd} ${portUtils.portProtocolMap().udp.text}`,
          detail: {proto: portUtils.portProtocolMap().udp.value, port: valueStart, to_port: valueEnd},
        });
      }
    }
  }

  return result;
};

/**
 *
 * Accept query string and search for port with available protocol or port with protocol if protocol is specified
 *
 * @param {*} key : unused
 * @param {*} query : query string in shape of '#', "# protocol", 'protocol #'
 * @returns array of object in shape of  {value: /string/, detail: {proto: /number/, port: /number/}} and return [] if no match
 */
const validatePortSearch = (key, query) => {
  //  Make sure query not just empty space or contains urgly non-words
  if (/(^ *$)|[^\s\w/]/.test(query)) {
    return [];
  }

  // Extract Port [] and Protocol []
  const port = query.match(/\d+/g);
  const protocol = query.split(/[^A-Za-z]+/).filter(Boolean);

  //-------     CASE 1: port number in ## format : 0-255 -------------------------------

  // Must be one digit number in range 0-65535
  if (port && port.length === 1 && portUtils.isValidPort(port[0])) {
    // ProtocolMap
    const protocolName = portUtils.ProtocolMap[Number(port[0])];
    const protocolValue = protocolName ? `Protocol ${port[0]} (${protocolName})` : `Protocol ${port[0]}`;

    // No Spefic protocol
    if (_.isEmpty(protocol)) {
      const result = [
        {value: `Port ${port[0]}`, detail: {port: Number(port[0])}},
        {
          value: `${port[0]} ${portUtils.portProtocolMap().tcp.text}`,
          detail: {proto: portUtils.portProtocolMap().tcp.value, port: Number(port[0])},
        },
        {
          value: `${port[0]} ${portUtils.portProtocolMap().udp.text}`,
          detail: {proto: portUtils.portProtocolMap().udp.value, port: Number(port[0])},
        },
        {value: protocolValue, detail: {proto: Number(port[0])}},
      ];

      return result;
    }

    //  Only one protocol allow at a time for specificity
    if (protocol.length === 1) {
      // TCP protocol
      if (portUtils.portProtocolRegex.tcpPortProtocol.test(`${port[0]} ${protocol[0]}`)) {
        return [
          {
            value: `${port[0]} ${portUtils.portProtocolMap().tcp.text}`,
            detail: {proto: portUtils.portProtocolMap().tcp.value, port: Number(port[0])},
          },
        ];
      }

      // UDP protocol
      if (portUtils.portProtocolRegex.udpPortProtocol.test(`${port[0]} ${protocol[0]}`)) {
        return [
          {
            value: `${port[0]} ${portUtils.portProtocolMap().udp.text}`,
            detail: {proto: portUtils.portProtocolMap().udp.value, port: Number(port[0])},
          },
        ];
      }
    }
  }

  // ------     CASE 2 : Port is #/# ICMP: 8/9 ICMP  : NOT SUPPORTED BY THE API YET    -------------------------------

  // // Try to Extract #/# pattern
  // try {
  //   const [type, code] = query.match(/\d+\s*\/\s*\d+/g)[0].split(/\s*\/\s*/);

  //   if (portUtils.isValidIcmpTypeCode(code) && portUtils.isValidIcmpTypeCode(type)) {
  //     if (portUtils.portProtocolRegex.icmpv6PortProtocol.test(`${type}${code} ${protocol[0]}`)) {
  //       return [
  //         {
  //           value: `${type}/${code} ${intl('Protocol.ICMPv6')}`,
  //           detail: {proto: portUtils.portProtocolMap().icmpv6.value, icmp_type: Number(type), icmp_code: Number(code)},
  //         },
  //       ];
  //     }

  //     return [
  //       {
  //         value: `${type}/${code} ${intl('Protocol.ICMP')}`,
  //         detail: {proto: portUtils.portProtocolMap().icmp.value, icmp_type: Number(type), icmp_code: Number(code)},
  //       },
  //       {
  //         value: `${type}/${code} ${intl('Protocol.ICMPv6')}`,
  //         detail: {proto: portUtils.portProtocolMap().icmpv6.value, icmp_type: Number(type), icmp_code: Number(code)},
  //       },
  //     ];
  //   }
  // } catch {
  //   return [];
  // }
  return [];
};

/**
 *
 * Accept query string and search for port and/or Porotocol. This uses `validatePortSearch` and `validateProtocolOnly` method.
 *
 * @param {*} key : unused
 * @param {*} query : query string in shape of '#', "# protocol", 'protocol #', 'protocol'
 * @returns array of object in shape of  {value: /string/, detail: {proto: /number/, port: /number/}} and return [] if no match
 */
const validatePortAndOrProtocol = (key, query) => {
  const trimedQuery = query.trim();

  if (lookupRegexPortProtocol('protocolOnlyIncludingTCPUDP').test(_.toUpper(trimedQuery))) {
    return validateProtocolOnly(key, trimedQuery);
  }

  return validatePortSearch(key, trimedQuery);
};

// ------------------------------- Exports ---------------------------------
/**
 *
 * Accept query string of port info and return matching port with protocol. This function is used to generate statics options for
 * Port and/or Protocol categories in Services Selector.
 * @param {*} query string eg. 'icmp', '78 tcp', 'udp 67', 'ipv4', '8/9', 'icmp', '8/7 icmp'
 * @returns object containing array of matches and number of matches.  {matches, num_matches: matches.length}
 */
export const getPortProtoOptions = query => {
  const matches = validatePortAndOrProtocol(undefined, query);

  return {matches, num_matches: matches.length};
};

/**
 *
 * Accept query string of port range info and return matching port range with with TCP or UDP protocol. This function is used to generate statics options for
 * Port Range categories in Services Selector.
 * @param {*} query string eg. '8-9', '7-8 tcp', 'udp 6-7'
 * @returns object containing array of matches and number of matches.  {matches, num_matches: matches.length}
 *
 */
export const getPortRangeOptions = query => {
  const matches = validatePortRangeSearch(undefined, query);

  return {matches, num_matches: matches.length};
};

/**
 *
 * Accept query string of process name and validate it. If query is a valid process name, return query.  This is used to generate statics options for
 * Process categories in Services Selector.
 *
 * @param {*} query string
 * @returns object containing array of matches and number of matches.  {matches, num_matches: matches.length}
 */
export const getProcessOptions = query => {
  if (isValidProcessName(query)) {
    return {matches: [{value: query}], num_matches: 1};
  }

  return {matches: [], num_matches: 0};
};

/**
 * Accept query string of WindowsServices name and validate it. If query is a valid WindowsServices name, return query.  This is used to generate statics options for
 * Windows Services categories in Services Selector.
 * @param {*} query string
 * @returns object containing array of matches and number of matches.  {matches, num_matches: matches.length}
 */
export const getProcessOrWindowsServicesOptions = query => {
  // Does not allow digit/ digits for Windows Services
  if (/\D/.test(query) && query.trim().length < 255) {
    return {matches: [query.trim()], num_matches: 1};
  }

  return {matches: [], num_matches: 0};
};

/**
 * Accept query string of FQDN and validate it. If query is a valid string, return query.  This is used to generate statics options for
 * FQDN categories in Provider/Consumer Selectors.
 * @param {*} query string
 * @returns object containing array of matches and number of matches.  {matches, num_matches: matches.length}
 */
export const getFQDNOptions = query => {
  // Allow user to search all traffic with FQDN
  if (ipUtils.isValidFqdn(query) || query === '*') {
    return {matches: [{value: query}], num_matches: 1};
  }

  return {matches: [], num_matches: 0};
};

/**
 * Accept a query string of either IP (###.###.###.###) or CIDR (###.###.###.###/##)
 * and validate it. If valid IP or CIDR Block, return the query. Used to generate
 * static options for IP address categories in Provider/Consumer Selectors.
 * IP Address: ###.###.###.###
 * CIDR Block: ###.###.###.###/##
 */
export const getIPAddressOrCIDRBlockOptions = query => {
  try {
    ipUtils.parseIP(query);
  } catch (error) {
    if (!(error instanceof TypeError)) {
      console.error(error);
    }

    return {matches: [], num_matches: 0};
  }

  return {matches: [{value: query}], num_matches: 1};
};

export function findSavedOrRecentFilter({queryId, savedFilters = [], recentFilters = [], landingFilter = {}}) {
  const savedFilter = savedFilters.find(filter => filter.queryId === queryId);
  const recentFilter = recentFilters.find(filter => filter.queryId === queryId);

  if (savedFilter) {
    return {filter: savedFilter, type: 'saved'};
  }

  if (recentFilter) {
    return {filter: recentFilter, type: 'recent'};
  }

  if (landingFilter.queryId === queryId) {
    return {filter: landingFilter, type: 'landing'};
  }

  return {};
}

export function getSelectedFavorite({filter, type}) {
  return {
    selectedFilterValues: cloneFilters(filter?.filters ?? {}),
    selectedFilterLabel: filter?.label ?? null,
    selectedFilterQueryId: filter?.queryId ?? null,
    selectedFilterName: (filter?.queryName ?? '').replace(mapQueryNamePrefix, ''),
    selectedFilterType: type ?? null,
  };
}

export const isBasicModeToggleEnabled = () => {
  return Boolean(localStorage.getItem('basicModeToggleEnabled'));
};

export function getSettingsFromFilters(filters) {
  const savedFilterSettings = loadFiltersSettings() ?? {
    orView: false,
    showReportedPolicyDecisionFilter: false,
    showExclusionFilters: false,
    excludeWorkloadsFromIPListQuery: false,
    collapsedView: false,
    basicMode: false,
  };
  const {
    consumerInclude,
    consumerExclude,
    providerInclude,
    providerExclude,
    servicesExclude,
    consumerOrProviderInclude,
    consumerOrProviderExclude,
    policyDecisions,
  } = filters;
  const hasOrFilters = !(_.isEmpty(consumerOrProviderInclude) && _.isEmpty(consumerOrProviderExclude));
  const hasAndFilters = !(
    _.isEmpty(consumerInclude) &&
    _.isEmpty(consumerExclude) &&
    _.isEmpty(providerInclude) &&
    _.isEmpty(providerExclude)
  );
  const hasPolicyDecisionFilters = !_.isEmpty(policyDecisions);
  const hasExclusionFilters = !(
    _.isEmpty(consumerExclude) &&
    _.isEmpty(providerExclude) &&
    _.isEmpty(servicesExclude) &&
    _.isEmpty(consumerOrProviderExclude)
  );

  return {
    orView: (savedFilterSettings.orView && !hasAndFilters) || hasOrFilters,
    showReportedPolicyDecisionFilter: savedFilterSettings.showReportedPolicyDecisionFilter || hasPolicyDecisionFilters,
    showExclusionFilters: savedFilterSettings.showExclusionFilters || hasExclusionFilters,
    excludeWorkloadsFromIPListQuery: savedFilterSettings.excludeWorkloadsFromIPListQuery ?? false,
    collapsedView: savedFilterSettings.collapsedView ?? false,
    basicMode: savedFilterSettings.basicMode ?? false,
  };
}

export function getDefaultAppGroupFilter(appGroup) {
  return appGroup
    ? new Map([['appgroups', [{detail: {...appGroup, sticky: true}, value: appGroup.name, sticky: true}]]])
    : new Map();
}

export function getDefaultFilters({queryId, policyId = null, appGroup, recentFilters = [], savedFilters = []}) {
  const autoSavedFilters = getAutoSavedFilters(policyId);
  const {filter} = findSavedOrRecentFilter({queryId, savedFilters, recentFilters});

  if (filter?.filters) {
    return filter.filters;
  }

  if (!queryId && autoSavedFilters) {
    return autoSavedFilters;
  }

  return {
    ...EmptyFilters,
    consumerOrProviderInclude: getDefaultAppGroupFilter(appGroup),
  };
}

export function cloneFilters(filters) {
  return Object.entries(filters ?? {}).reduce((result, [key, value]) => {
    if (value instanceof Map) {
      result[key] = new Map(Array.from(value.entries()).map(([key, value]) => [key, [...value]]));
    } else if (_.isPlainObject(value)) {
      result[key] = {...value};
    } else {
      result[key] = value;
    }

    return result;
  }, {});
}

export function areFiltersEqual(a, b) {
  return _.isEqual(convertFiltersValuesToObject(a), convertFiltersValuesToObject(b));
}

export function areFiltersModified({filters, selectedFavorite}) {
  if (selectedFavorite.selectedFilterQueryId && !_.isEmpty(selectedFavorite.selectedFilterValues)) {
    return !areFiltersEqual(filters, selectedFavorite.selectedFilterValues);
  }

  return !isEmptyFilter(filters);
}

export const formatDate = date => intl.date(date, intl.formats.date.L_HH_mm);

export const transformStorageMetricsData = (data = {}) => {
  const {
    flows_days: days,
    flows_size_gb: sizeGB,
    flows_days_limit: daysLimit,
    flows_size_gb_limit: sizeGBLimit,
  } = data ?? {};

  return {days, daysLimit, sizeGB, sizeGBLimit};
};

export const parsePortProtoString = (portProtoString = '') => {
  const parts = portProtoString.split(' ').map(s => s.trim());
  const portNumber = parseInt(parts[0], 10);
  let protoNumber = parts[1] ? portUtils.reverseLookupProtocol(parts[1]) : NaN;
  const result = {};

  if (!isNaN(portNumber) && portNumber >= 0 && portNumber <= 65_535) {
    result.port = portNumber;
  }

  if (parts.length === 1 && isNaN(portNumber)) {
    protoNumber = parts[0] ? portUtils.reverseLookupProtocol(parts[0]) : NaN;
  }

  if (!isNaN(protoNumber)) {
    result.proto = protoNumber;
  }

  return result;
};

export const parsePortRangeProtoString = (portRangeProtoString = '') => {
  const parts = portRangeProtoString.split(' ').map(s => s.trim());
  const portRangeParts = (parts[0] ?? '').split('-').map(s => s.trim());
  const fromPortNumber = parseInt(portRangeParts[0], 10);
  const toPortNumber = parseInt(portRangeParts[1], 10);
  const protoNumber = parts[1] ? portUtils.reverseLookupProtocol(parts[1]) : NaN;
  const result = {};

  if (!isNaN(fromPortNumber) && fromPortNumber >= 0 && fromPortNumber <= 65_535) {
    result.port = fromPortNumber;
  }

  if (!isNaN(toPortNumber) && toPortNumber >= 0 && toPortNumber <= 65_535) {
    result.to_port = toPortNumber;
  }

  if (!isNaN(protoNumber)) {
    result.proto = protoNumber;
  }

  return result;
};

export function isDuplicateFilter(mapFilters, filterType, filterCategory, labels) {
  let relatedFilterType;

  if (filterType.endsWith('Include')) {
    relatedFilterType = filterType.replace(/Include$/, 'Exclude');
  } else if (filterType.endsWith('Exclude')) {
    relatedFilterType = filterType.replace(/Exclude$/, 'Include');
  }

  const relatedFilterTypes = [filterType, relatedFilterType];

  const labelsOfRelatedFilterCategories = relatedFilterTypes.flatMap(filterType => {
    if (mapFilters[filterType]?.has(filterCategory)) {
      return mapFilters[filterType].get(filterCategory);
    }

    return [];
  });

  return labels.find(label =>
    Boolean(labelsOfRelatedFilterCategories.some(existingLabel => isEqual(existingLabel, label))),
  );
}
