/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {routesMerger, routesTree as commonRoutes} from '../router/routes';

export default _.mergeWith(
  commonRoutes,
  {
    name: 'app',
    children: [
      {path: '/', forwardTo: 'landing'},
      {name: 'landing', path: '/landing', container: 'Landing', load: () => import(/* webpackChunkName: 'Landing' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Landing/Landing')},
      {
        name: 'rbac',
        path: '/rbac',
        container: 'RBAC', load: () => import(/* webpackChunkName: 'RBAC' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/RBAC'),
        forwardTo: '>roles',
        children: [
          {
            name: 'accesswizard',
            path: '/accesswizard/?:type?:prevRoute?:scopeId?:roles?:authSecPrincipals',
            container: 'AccessWizard', load: () => import(/* webpackChunkName: 'AccessWizard' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/AccessWizard/AccessWizard'),
          },
          {
            name: 'serviceAccounts',
            path: '/serviceaccounts',
            forwardTo: '>list',
            children: [
              {name: 'list', path: '/?:svcacctslist', container: 'ServiceAccountList', load: () => import(/* webpackChunkName: 'ServiceAccountList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/ServiceAccount/List/ServiceAccountList')},
              {name: 'create', path: '/create', container: 'ServiceAccountEdit', load: () => import(/* webpackChunkName: 'ServiceAccountEdit' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/ServiceAccount/Item/Edit/ServiceAccountEdit')},
              {
                name: 'item',
                path: '/:id',
                forwardTo: '>view',
                container: 'ServiceAccountItem', load: () => import(/* webpackChunkName: 'ServiceAccountItem' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/ServiceAccount/Item/ServiceAccountItem'),
                children: [
                  {name: 'edit', path: '/edit', container: 'ServiceAccountEdit', load: () => import(/* webpackChunkName: 'ServiceAccountEdit' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/ServiceAccount/Item/Edit/ServiceAccountEdit')},
                  {name: 'view', path: '/?:apikeys', container: 'ServiceAccountView', load: () => import(/* webpackChunkName: 'ServiceAccountView' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/ServiceAccount/Item/View/ServiceAccountView')},
                ],
              },
            ],
          },
          {
            name: 'roles',
            path: '/roles',
            forwardTo: '>global',
            children: [
              {
                name: 'global',
                path: '/global',
                forwardTo: '>list',
                children: [
                  {name: 'list', path: '/?:globalrolelist', container: 'GlobalRoleList', load: () => import(/* webpackChunkName: 'GlobalRoleList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/Roles/Global/List/GlobalRoleList')},
                  {name: 'detail', path: '/:id?:globalroledetail', container: 'GlobalRoleDetail', load: () => import(/* webpackChunkName: 'GlobalRoleDetail' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/Roles/Global/Detail/GlobalRoleDetail')},
                ],
              },
              {
                name: 'scope',
                path: '/scope',
                forwardTo: '>list',
                children: [
                  {name: 'list', path: '/?:scopelist', container: 'ScopeList', load: () => import(/* webpackChunkName: 'ScopeList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/Roles/Scope/List/ScopeList')},
                  {name: 'detail', path: '/:id?:scopedroledetail', container: 'ScopeDetail', load: () => import(/* webpackChunkName: 'ScopeDetail' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/Roles/Scope/Detail/ScopeDetail')},
                ],
              },
            ],
          },
          {
            name: 'users',
            path: '/users',
            forwardTo: '>local',
            children: [
              {
                name: 'local',
                path: '/local',
                forwardTo: '>list',
                children: [
                  {name: 'list', path: '/?:localuserlist', container: 'LocalUserList', load: () => import(/* webpackChunkName: 'LocalUserList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/UsersAndGroups/LocalUsers/List/LocalUserList')},
                  {name: 'detail', path: '/:id?:localuserdetail', container: 'LocalUserDetail', load: () => import(/* webpackChunkName: 'LocalUserDetail' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/UsersAndGroups/LocalUsers/Detail/LocalUserDetail')},
                ],
              },
              {
                name: 'external',
                path: '/external',
                forwardTo: '>list',
                children: [
                  {name: 'list', path: '/?:externaluserlist', container: 'ExternalUserList', load: () => import(/* webpackChunkName: 'ExternalUserList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/UsersAndGroups/ExternalUsers/List/ExternalUserList')},
                  {name: 'detail', path: '/:id?:externaluserdetail', container: 'ExternalUserDetail', load: () => import(/* webpackChunkName: 'ExternalUserDetail' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/UsersAndGroups/ExternalUsers/Detail/ExternalUserDetail')},
                  {
                    name: 'groups',
                    path: '/groups',
                    forwardTo: '>list',
                    children: [
                      {name: 'list', path: '/?:externalgrouplist', container: 'ExternalGroupList', load: () => import(/* webpackChunkName: 'ExternalGroupList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/UsersAndGroups/ExternalGroups/List/ExternalGroupList')},
                      {name: 'detail', path: '/:id?:externalgroupdetail', container: 'ExternalGroupDetail', load: () => import(/* webpackChunkName: 'ExternalGroupDetail' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/UsersAndGroups/ExternalGroups/Detail/ExternalGroupDetail')},
                    ],
                  },
                ],
              },
              {
                name: 'activity',
                path: '/activity',
                forwardTo: '>list',
                children: [
                  {name: 'list', path: '/?:useractivitylist', container: 'UserActivityList', load: () => import(/* webpackChunkName: 'UserActivityList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/UsersAndGroups/UserActivity/List/UserActivityList')},
                  {name: 'detail', path: '/:id?:useractivitydetail?:tab', container: 'UserActivityDetail', load: () => import(/* webpackChunkName: 'UserActivityDetail' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/RBAC/UsersAndGroups/UserActivity/Detail/UserActivityDetail')},
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'coreservicesettings',
        path: '/coreservicesettings',
        container: 'CoreServicesSettings', load: () => import(/* webpackChunkName: 'CoreServicesSettings' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/CoreServices/Settings/CoreServicesSettings'),
        group: 'Settings',
      },
      {
        name: 'coreservices',
        path: '/coreservices',
        container: 'CoreServicesMain', load: () => import(/* webpackChunkName: 'CoreServicesMain' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/CoreServices/CoreServicesMain'),
        forwardTo: '>list',
        children: [
          {name: 'list', path: '/?:coreserviceslist', container: 'CoreServicesList', load: () => import(/* webpackChunkName: 'CoreServicesList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/CoreServices/List/CoreServicesList'), group: 'Infrastructure'},
          {
            name: 'item',
            path:
              '/:id/:tab' +
              '?:coreServicesItem-recommended' +
              '?:coreServicesItem-accepted' +
              '?:coreServicesItem-rejected',
            container: 'CoreServicesItem', load: () => import(/* webpackChunkName: 'CoreServicesItem' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/CoreServices/Item/CoreServicesItem'),
            defaultParams: {tab: 'recommended'},
          },
        ],
      },
      {
        name: 'workloads',
        path: '/workloads',
        forwardTo: '>list',
        container: 'WorkloadManagement', load: () => import(/* webpackChunkName: 'WorkloadManagement' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/WorkloadManagement/WorkloadManagement'),
        children: [
          {name: 'list', path: '/?:workloadlist', container: 'WorkloadList', load: () => import(/* webpackChunkName: 'WorkloadList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Workload/List/WorkloadList')},
          {name: 'create', path: '/create', container: 'WorkloadEdit', load: () => import(/* webpackChunkName: 'WorkloadEdit' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Workload/Item/Edit/WorkloadEdit')},
          {
            name: 'item',
            path: '/:id',
            forwardTo: '>view',
            container: 'WorkloadItem', load: () => import(/* webpackChunkName: 'WorkloadItem' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Workload/Item/WorkloadItem'),
            children: [
              {name: 'view', path: '/', container: 'WorkloadSummary', load: () => import(/* webpackChunkName: 'WorkloadSummary' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Workload/Item/Summary/WorkloadSummary')},
              {name: 'edit', path: '/edit', container: 'WorkloadEdit', load: () => import(/* webpackChunkName: 'WorkloadEdit' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Workload/Item/Edit/WorkloadEdit')},
              {name: 'virtualServices', path: '/virtualServices?:workloadvs', container: 'WorkloadVirtualServices', load: () => import(/* webpackChunkName: 'WorkloadVirtualServices' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Workload/Item/VirtualServices/WorkloadVirtualServices')},
              {name: 'services', path: '/services?:workloadprocesses', container: 'WorkloadProcesses', load: () => import(/* webpackChunkName: 'WorkloadProcesses' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Workload/Item/Processes/WorkloadProcesses')},
              {name: 'blockedTraffic', path: '/blockedtraffic', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/JumpToOld')},
              {name: 'rules', path: '/rules?:inbound?:outbound?:forward?:customip', container: 'WorkloadRules', load: () => import(/* webpackChunkName: 'WorkloadRules' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Workload/Item/Rules/WorkloadRules')},
              {name: 'report', path: '/report?:compatibilityReport', container: 'WorkloadCompatibilityReport', load: () => import(/* webpackChunkName: 'WorkloadCompatibilityReport' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Workload/Item/Report/WorkloadCompatibilityReport')},
              {
                name: 'vulnerabilities',
                path: '/vulnerabilities?:workloadvulnerability',
                container: 'WorkloadVulnerabilities', load: () => import(/* webpackChunkName: 'WorkloadVulnerabilities' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Workload/Item/Vulnerabilities/WorkloadVulnerabilities'),
              },
            ],
          },
          {
            name: 'containers',
            path: '/containers',
            forwardTo: '>list',
            children: [
              {name: 'list', path: '/?:scope?:cclusterworkloadlist', container: 'ContainerWorkloadList', load: () => import(/* webpackChunkName: 'ContainerWorkloadList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/ContainerWorkload/List/ContainerWorkloadList')},
              {
                name: 'item',
                path: '/:id',
                container: 'ContainerWorkloadItem', load: () => import(/* webpackChunkName: 'ContainerWorkloadItem' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/ContainerWorkload/Item/ContainerWorkloadItem'),
                forwardTo: '>view',
                children: [
                  {name: 'view', path: '/', container: 'ContainerWorkloadSummary', load: () => import(/* webpackChunkName: 'ContainerWorkloadSummary' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/ContainerWorkload/Item/Summary/ContainerWorkloadSummary')},
                  {name: 'containers', path: '/containers', container: 'ContainerWorkloadContainers', load: () => import(/* webpackChunkName: 'ContainerWorkloadContainers' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/ContainerWorkload/Item/Containers/ContainerWorkloadContainers')},
                  {name: 'rules', path: '/rules?:inbound?:outbound?:customip', container: 'ContainerWorkloadRules', load: () => import(/* webpackChunkName: 'ContainerWorkloadRules' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/ContainerWorkload/Item/Rules/ContainerWorkloadRules')},
                ],
              },
            ],
          },
          {
            name: 'vens',
            path: '/vens',
            forwardTo: '>list',
            children: [
              {name: 'list', path: '/?:venslist', container: 'VenList', load: () => import(/* webpackChunkName: 'VenList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Ven/List/VenList')},
              {
                name: 'item',
                path: '/:id',
                forwardTo: '>view',
                container: 'VenItem', load: () => import(/* webpackChunkName: 'VenItem' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Ven/Item/VenItem'),
                children: [
                  {name: 'view', path: '/?:supportreportlist', container: 'VenSummary', load: () => import(/* webpackChunkName: 'VenSummary' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Ven/Item/Summary/VenSummary')},
                  {name: 'edit', path: '/edit', container: 'VenEdit', load: () => import(/* webpackChunkName: 'VenEdit' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Ven/Item/Edit/VenEdit')},
                  {
                    name: 'containerWorkloads',
                    path: '/containerworkloads?:cclusterworkloadlist',
                    container: 'VenContainerWorkloads', load: () => import(/* webpackChunkName: 'VenContainerWorkloads' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_24.1.3/app/containers/Ven/Item/ContainerWorkloads/VenContainerWorkloads'),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'rulesets',
        children: [
          {
            name: 'item',
            path: '/:id/:pversion/:tab',
            defaultParams: {tab: 'intrascope'},
          },
        ],
      },
    ],
  },
  routesMerger,
);
