/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */

/* eslint-disable rulesdir/intl-key-verify */

export default {
  // UI errors
  'err_ui:unknown_error': 'Unknown error encountered. Please try again.',
  'err_ui:session_expired': 'Session has expired. Click OK to log in again.',
  'err_ui:notfound': 'Resource Not Found',
  'err_ui:notfound_info': "The operation couldn't be completed. Please reload the page and try again.",
  'err_ui:exception': 'An exception occurred.',
  'err_ui:connection_error': 'Failed to connect to the server.',
  'err_ui:service_unavailable': 'Service is currently unavailable. Please wait some time and try again.',
  'err_ui:timeout': 'The PCE is currently busy',

  'err_ui:403:title': 'Permission Denied',
  'err_ui:403:message': 'You do not have permission to perform this operation.',

  'err_ui:general': 'An error occurred. Please try again.',
  'err_ui:app_container': 'An error occurred while creating the Group. Please try again.',
  'err_ui:throttled': 'You have made too many requests. Please try again later.',

  'err:enhanced_data_collection_license_required':
    'This feature requires an enhanced data collection license. Contact Illumio support for details',
  'err:enhanced_data_collection_in_use':
    'The enhanced data collection feature is currently in use by a %s and the license cannot be removed',

  // ALL ERROR TOKENS STARTING W/ "err:" MUST ALSO EXIST IN AVENGER
  // misc.
  'err:system_error': 'Unexpected system error',
  'err:invalid_uri': 'Invalid URI', //This is different from 404. Should not happen unless there is a bug.
  'err:name_must_be_unique': 'Name must be unique.',

  // generic HTTP errors for the API
  'err:unauthorized_error': 'Unauthorized',
  'err:forbidden_error': 'Access denied',
  'err:not_found_error': 'Not found',
  'err:method_not_allowed_error': 'Not allowed',
  'err:api_version_conflict_error':
    'Version conflict: Upgrade to a recent API version. For more information, contact your Illumio Support representative',
  'err:not_acceptable_error': 'Not Acceptable. %s',
  'err:too_many_requests_error': 'Too many requests',
  'err:insufficient_storage': 'Insufficient Storage',
  'err:bad_gateway': 'Bad Gateway',
  'err:service_unavailable_error': 'Service Unavailable',
  'err:no_async_header_uncached_result':
    "This API Call must be made with the 'prefer: respond-async' header because the requested object is not already cached. Future calls may be made without this header.",
  'err:unsupported_format': 'Unsupported format: %s',
  'err:conflicting_data': 'Only one of %s or %s is allowed',
  'err:db_query_timeout': 'Query timeout',
  'err:query_too_broad': 'label filters expanded to too many virtual services',

  'err:password_contains_special_character':
    'Password was rejected because it contains at least one restricted character',
  'err:password_expired': 'Your password has expired and needs to be changed',
  'err:password_too_short': 'Password must be a minimum of %s characters',
  'err:password_must_have_digit': 'Password must have at least %s digit(s)',
  'err:password_must_have_lowercase_letter': 'Password must have at least %s lowercase letter(s)',
  'err:password_must_have_uppercase_letter': 'Password must have at least %s uppercase letter(s)',
  'err:password_must_have_symbol': 'Password must have at least %s symbol(s): %s',
  'err:password_characters_changed_required': 'Password must have minimum of %s new character(s)',
  'err:password_min_changed_characters_invalid':
    'Minimum number of changed characters for new passwords must be between 1 and 4',
  'err:password_min_length_invalid': 'Minimum password length must be between 8 and 64',
  'err:password_min_length_too_short':
    'Password minimum length must be greater or equal to number of characters per type times number of types',
  'err:password_min_characters_per_type_invalid':
    'Minimum number of characters for each password character type must be between 1 and 10',
  'err:password_history_count_invalid': 'Number of old passwords to remember must be between 1 and 24',
  'err:password_expire_time_invalid': 'Number of days password expires must be between 0 and 999',
  'err:password_composition_requirement_invalid': 'Password must require three of the four available character types',

  // rbac/label groups
  'err:label_group_referenced_by_user_permissions':
    'Label Group is still referenced by scopes used in user permission(s)',
  'err:restore_policy_deleted_label_group_used_in_permissions':
    'Restore failed because user permissions use the following label groups to be deleted: %s',
  'err:rbac_invalid_org_scope': 'Invalid org scope',
  'err:rbac_cannot_operate_on_extra_scope_rule': 'You do not have sufficient privileges to modify extra scope rules',
  'err:rbac_deleted_label_in_permission': 'Deleted label is used in permission',
  'err:rbac_deleted_label_group_in_permission': 'Deleted label group is used in permission',

  'err:rbac_invalid_org_role': 'Invalid role {%s}',
  'err:rbac_role_id_required': 'Role ID is required',
  'err:rbac_invalid_role_scope': 'Invalid role Scope',
  'err:rbac_missing_role_scope': 'No role Scopes specified for this user',
  'err:rbac_self_edit_attibutes_not_allowed': 'Users cannot edit their own attribute(s): %s',
  'err:rbac_update_only_allowed_for_external': 'Invalid role name',
  'err:rbac_only_one_label_or_label_group_per_key': 'There can only be one Label per key {%s}',
  'err:rbac_user_no_permissions_to_org': 'User does not have any permissions in this Organization',
  'err:rbac_cannot_create_broader_role_scope': 'One or more disallowed role Scopes {%s}',
  'err:rbac_cannot_operate_on_rule_with_disallowed_provider_actors':
    'You do not have sufficient privileges to modify Rules with %s',
  'err:rbac_jwt_org_mismatch': 'The org_id for this user does not match system org_id',
  'err:rbac_no_user': 'User not found in system',
  'err:rbac_no_permission_to_this_org':
    'This user Role cannot access this organization. Contact your Illumio Administrator.',
  'err:rbac_account_org_locked': 'This account has been locked by the Owner',
  'err:rbac_self_edit_role_scope_not_allowed': 'Users cannot edit their own role Scopes',
  'err:rbac_cannot_operate_on_rule_set_with_broader_scope':
    'You cannot modify Rulesets with broader Scope(s) than your permitted Scope(s)',
  'err:rbac_scoped_user_cannot_operate_on_ruleset_with_label_groups':
    'You do not have sufficient privileges to modify Rulesets with Label Groups',
  'err:rbac_cannot_operate_on_rule_with_broader_consumer_scope':
    'You cannot modify Rules with broader source Scope(s) than your permitted Scope(s)',
  'err:rbac_cannot_operate_on_rule_with_disallowed_consumer_actors':
    'You do not have sufficient privileges to modify Rules with %s',
  'err:rbac_cannot_operate_on_rule_with_consumer_security_principals':
    'You do not have sufficient privileges to modify Rules with User Groups',
  'err:rbac_cannot_reinvite_already_active_users': 'You cannot re-invite current users',
  'err:rbac_cannot_operate_on_ip_tables_rule': 'You do not have sufficient privileges to modify Custom iptables Rules',
  'err:rbac_rule_ub_scope_should_be_same_as_scope': "User's scope and source-scope labels cannot be different: %s",
  'err:rbac_cannot_provision_rule_set_with_broader_scope':
    'You cannot provision Rulesets with broader Scope(s) than your permitted Scope(s)',
  'err:rbac_cannot_provision_entity': 'You do not have sufficient privileges to provision %s',
  'err:rbac_limited_ruleset_manager_cannot_have_unrestricted_scope': "Limited Ruleset Manager cannot have Scope 'All'",
  'err:rbac_scope_required': 'Scope is required',
  'err:rbac_this_role_cannot_have_restricted_scope': "Role '%s' can only have Scope 'All'",
  'err:rbac_self_edit_permission_not_allowed': 'Users cannot edit their own permissions',
  'err:rbac_org_auth_security_principal_already_exists': 'This security principal already exists',
  'err:rbac_org_permission_already_exists': 'This permission already exists',
  'err:rbac_permissions_exist_in_another_org': 'This security_principal already has permissions in another org',
  'err:rbac_sp_associated_with_permissions': 'This security_principal is associated with one or more permissions',
  'err:rbac_cannot_edit_default_read_only_permissions': 'The default global read_only permission cannot be edited',
  'err:rbac_cannot_edit_default_asp': 'The default security_principal cannot be edited',
  'err:rbac_cannot_delete_default_asp': 'The default security_principal cannot be deleted',
  'err:rbac_self_delete_permission_not_allowed': 'Users cannot delete their own permissions',
  'err:rbac_the_last_owner_permission_cannot_be_deleted': 'The last owner permission cannot be deleted',
  'err:rbac_the_last_owner_permission_cannot_be_edited': 'The last owner permission cannot be edited',
  'err:rbac_cannot_operate_on_resource_with_broader_scope':
    'You cannot modify resources with broader Scope(s) than your permitted Scope(s)',
  'err:rbac_cannot_operate_on_lock_due_to_scoped_permission':
    'You cannot modify lock on this label type due to your scoped permission',
  'err:rbac_exceeded_max_permissions_per_auth_security_principal':
    'You have exceeded the maximum number of permissions that could be assigned to this user',

  'err:user_already_part_of_different_org':
    "You've invited a user that already belongs to a different Organization. This is currently not supported. Try again with a different email address.",
  'err:user_already_part_of_this_org': 'This user is already a member of your Organization.',
  'err:user_already_exists': 'This user already exists.',
  'err:username_cannot_be_reused': 'This username was deleted and cannot be reused.',
  'err:user_cannot_invite_self': 'You cannot invite yourself',
  'err:invalid_reset_token': 'The reset token provided has expired!',
  'err:account_locked': 'The account is locked.',
  'err:authentication_failure': 'Authentication failure',
  'err:change_password_not_supported': 'Passwords can only be changed through your SAML Identity Provider',
  'err:invalid_time_zone': 'The time zone region string cannot be validated.',
  'err:org_id_required': 'Org_id is required.',
  'err:org_id_must_be_integer': 'Org_id must be an integer.',
  'err:timestamp_required': 'Timestamp is required.',
  'err:ip_version_required': 'IP version is required.',
  'err:ip_version_must_be_integer': 'IP version must be an integer.',
  'err:invalid_ip_version': 'IP version must be 4 or 6',
  'err:protocol_required': 'Protocol is required.',
  'err:protocol_must_be_integer': 'Protocol must be an integer.',
  'err:port_required': 'Port is required.',
  'err:port_must_be_integer': 'Port must be an integer.',
  'err:port_out_of_range': 'Port value out of range.',
  'err:direction_required': 'Direction is required.',
  'err:invalid_direction': 'Invalid direction: %s',
  'err:description_too_long': 'Description is too long.',
  'err:invalid_loc_label': 'Invalid Location Label',
  'err:app_label_required': 'Application Label required.',
  'err:env_label_required': 'Environment Label required.',
  'err:ruleset_required': 'Ruleset required.',
  'err:user_session_required': 'User session required.',
  'err:user_session_timeout_invalid': 'User session inactivity timeout must be between 3 and 30 minutes',
  'err:external_data_too_long': 'External data is too long.',

  'err:external_reference_not_unique': 'External data reference must be unique.',
  'err:external_reference_required_with_set': 'External data reference cannot be null when set is non-null.',
  'err:external_reference_data_json_invalid': 'External data reference JSON is invalid',
  'err:service_principal_name_not_unique': 'Service Principal Name must be unique.',
  'err:cert_auth_id_not_unique': 'VEN to PCE Certificate authentication ID must be unique',
  'err:max_results_invalid': 'Max results must be between 0 and %s.',
  'err:invalid_saml_idp_cert':
    'Invalid SAML Identity Provider certificate. Please ensure it is a PEM encoded X.509 certificate',
  'err:invalid_authn_context': 'Invalid SAML Authentication Context Class URI',
  'err:saml_idp_cert_key_too_short': 'SAML Identity Provider certificate must be at least 2048-bit key length',
  'err:saml_idp_cert_sig_weak': 'SAML Identity Provider certificate is signed using a weak signature algorithm',
  'err:saml_idp_cert_sig_algo_unknown':
    'SAML Identity Provider certificate is signed using an signature algorithm that is not recommended',
  'err:saml_idp_cert_required': 'SAML Identity Provider certificate is required',
  'err:saml_idp_sso_target_url_required': 'SAML Identity Provider Login URL is required',
  'err:sso_user_must_login_to_cluster_leader': 'To access this PCE, you must first login to the supercluster leader',
  'err:radius_shared_secret_invalid': 'RADIUS Server shared secret must be 4 - 128 characters long',
  'err:radius_address_must_be_unique': 'RADIUS Server address must be unique',
  'err:radius_timeout_must_be_integer_in_range': 'RADIUS Server request timeout be an integer between 1 and 60',
  'err:radius_retries_must_be_integer_in_range': 'RADIUS Server retries be an integer between 1 and 5',
  'err:radius_priority_must_be_integer_in_range': 'RADIUS Server priority be an integer between 1 and 8',
  'err:dns_reverse_resolution_failure': 'Fail to resolve IP address to name',
  'err:dns_resolution_failure': 'Fail to resolve name to IP address',
  'err:max_sessions_exceeded':
    'Cannot login because the maximum number of users that can log in to the PCE has been reached. Wait till someone logs out and try again.',

  'err:ip_range_invalid': 'Invalid IP range',
  'err:ip_range_invalid_to_and_from_ip':
    'Must provide From IP and To IP host addresses, or only a From IP address in host or CIDR notation',
  'err:ip_range_invalid_to_ip': 'To IP address must be a host address',
  'err:ip_range_backwards': 'End of IP range must be greater than beginning of IP range',

  'err:svc_icmp_type_invalid': 'ICMP type is invalid',
  'err:svc_icmp_code_invalid': 'ICMP code is invalid',

  // Org migration. Note that these errors are from APIs that used by OPs, and are not wired to the FE app.
  'err:cannot_transition_to_state': 'Cannot set the state to %s. Current state: %s',
  'err:cannot_have_multiple_active_extracts_for_one_org': 'Only one active org extract is allowed per org',
  'err:cannot_find_an_extract_for_org': 'Cannot find an extract for org id: %s from source PCE: %s',
  'err:cannot_have_multiple_active_loads_for_one_org': 'Only one active org load is allowed per org',
  'err:cannot_find_a_matching_org': 'Cannot find an org with id: %s to extract',
  'err:cannot_perform_load_for_org_that_exists': 'Cannot load org: %s as it already exists on the target PCE.',
  'err:pce_versions_do_not_match_between_source_and_target': 'PCE Version of source does not match that of target',

  // msp
  'err:domain_name_taken': 'This domain name is already in use.',
  'err:tenant_already_exists': 'Tenant associated with this domain and product already exists',
  'err:failed_unpairing_active_vens': 'Unable to remove the tenant as it has paired VENs',

  // server and group
  'err:network_name_required': 'Network name is required.',
  'err:network_not_unique': 'Network name must be unique within the data center.',
  'err:network_delete_workload_interface': 'Cannot delete network while referenced by a workload interface',
  'err:network_delete_virtual_service': 'Cannot delete network while referenced by an active or draft virtual service',

  // pairing errors
  'err:pairing_profile_disabled': 'The pairing key used is from a disabled Pairing Profile.',
  'err:pairing_key_expired': 'The pairing key has expired.',
  'err:pairing_key_max_use_exceeded': 'The pairing key has exceeded its maximum number of allowed uses.',
  'err:label_locked': 'The pairing key settings specify that a Label cannot be assigned during pairing.',
  'err:mode_locked': 'The pairing key settings specify that Workload mode cannot be changed during pairing.',
  'err:log_traffic_locked':
    'The pairing key settings specify that Workload log_traffic setting cannot be changed during pairing.',
  'err:visibility_level_locked':
    'The pairing key settings specify that Workload visibility level cannot be changed during pairing.',
  'err:more_than_one_secret_presented': 'More than one secret presented during the activation',
  'err:certificate_pairing_hostname_mismatch':
    'The Common Name(CN) in the certificate does not match the hostname in the activation call',
  'err:common_pairing_error': 'The activation data is invalid',

  // Network Enforcement Node
  'err:network_enforcement_node_hostname_not_unique': 'Network Enforcement Node hostname not unique',
  'err:network_device_type_not_supported': 'Network device type not supported',
  'err:network_device_manufacturer_not_supported': 'Network device manufacturer not supported',
  'err:network_device_model_not_supported': 'Network device model not supported',
  'err:network_device_name_not_unique': 'Network device name not unique for network device',
  'err:network_device_ip_address_not_unique': 'Network device IP address not unique for network device',
  'err:network_device_ip_address_can_not_be_empty':
    'Network device IP address can not be empty when specifying traffic flow id',
  'err:network_device_password_requires_username': 'Network device password requires username',
  'err:no_network_enforcement_node_paired': 'No Network Enforcement Node paired to PCE',
  'err:network_enforcement_node_not_found': 'Network Enforcement Node not found',
  'err:network_enforcement_node_has_network_devices': 'Network Enforcement Node still has associated network device(s)',
  'err:network_device_not_found': 'Network device not found',
  'err:network_device_still_has_associated_network_endpoints':
    'Network device still has an associated network endpoint',
  'err:cannot_change_device_type': 'Cannot change device type',
  'err:network_endpoint_name_not_unique': 'Network endpoint name not unique for network device',
  'err:cannot_change_network_endpoint_type': 'Cannot change type of supported endpoint',
  'err:network_endpoint_traffic_flow_id_must_be_numeric': 'Network endpoint traffic flow identifier must be numeric',
  'err:network_endpoint_traffic_flow_id_not_unique':
    'Network endpoint traffic flow identifier not unique for network device',
  'err:network_endpoint_not_found': 'Network endpoint not found',
  'err:network_endpoint_still_has_associated_workloads': 'Network endpoint still has an associated workload',
  'err:network_endpoint_identifier_not_unique': 'Network endpoint identifier must be unique for this device',
  'err:wrong_network_endpoint_type': 'Wrong network endpoint type for network device type',
  'err:workload_not_found': 'Workload not found uuid: %s',
  'err:workload_already_assigned': 'Workload already assigned to an Network Enforcement Node.',
  'err:workload_label_already_assigned': 'The same label is already assigned to this Workload',
  'err:workload_not_assigned_to_network_enforcement_node': 'Workload not assigned to Network Enforcement Node',
  'err:workload_interface_has_cidr_block': 'Cannot assign workload %s with cidr block interface',

  'err:enforcement_generation_in_progress': 'Enforcement instruction generation in progress for network device: %s',
  'err:network_device_no_workloads': 'No workloads assigned to this network device',
  'err:unable_to_save_credentials': 'Unable to save network device credentials',
  'err:unable_to_read_credentials': 'Unable to read network device credentials',
  'err:incompatible_nen_software_version': 'NEN software is incompatible with PCE software.',
  'err:too_many_network_device_interfaces': 'Too many interfaces for network device.',
  'err:cannot_have_nfc_and_nen': 'NFC and NEN cannot be provided at the same time.',

  // VEN errors
  'err:invalid_ip_value': 'Invalid IP value',
  'err:proposed_ruleset_exists': 'Proposed Ruleset already exists.',
  'err:cannot_change_set_type': 'Change is set_type is not allowed.',
  'err:change_type_required': 'Change type is required',
  'err:invalid_set_type': 'Invalid set_type attribute',
  'err:invalid_change_type': 'Invalid change type: %s',
  'err:rule_set_in_use': 'Another user is already performing an operation with the Ruleset. Please try again later.',
  'err:controller_uid_required': 'Controller UID is required.',
  'err:invalid_ip_table_restore': 'Invalid IP table restore value',
  'err:clone_detected': 'Clone detected.',
  'err:mode_required': 'Mode is required.',
  'err:invalid_mode': 'Invalid mode',
  'err:log_traffic_boolean': 'log_traffic requires a true or false value.',
  'err:visibility_level_required': 'Visibility level is required.',
  'err:invalid_agent_status': 'Invalid VEN status',
  'err:invalid_agent_version': 'Invalid agent version reported',
  'err:invalid_desired_version': 'Invalid desired version reported',
  'err:workload_interface_missing': 'VEN policy requires at least one interface with an IP address',
  'err:scanned_ips_mismatch_dv_ips':
    'Not Acceptable. There are ip addresses present in the detected_vulnerabilities that are not part of the scanned_ips => %s',
  'err:duplicate_detected_vulnerability_row': 'Row %s & %s are ambiguous in detected vulnerabilities',
  'err:input_validation_dv_errors': 'input validation dv errors %s',
  'err:invalid_visibility_level': 'Invalid visibility level',
  'err:invalid_enforcement_mode_and_and_visibility_level':
    'Invalid combination of enforcement mode and visibility level',
  'err:name_too_long': 'Name is too long.',
  'err:integer_too_large': 'Integer input is too large. %s',
  'err:uid_too_long': 'UID is too long.',
  'err:hostname_too_long': 'Hostname is too long.',
  'err:status_too_long': 'Status is too long.',
  'err:agent_version_too_long': 'VEN version is too long.',
  'err:agent_api_version_too_long': 'VEN API version is too long.',
  'err:public_ip_too_long': 'Public IP is too long.',
  'err:country_code_too_long': 'Country code is too long.',
  'err:region_name_too_long': 'Region name is too long.',
  'err:isp_name_too_long': 'ISP name is too long.',
  'err:ip_table_restore_too_long': 'IP table restore is too long.',
  'err:data_center_too_long': 'Data center is too long.',
  'err:data_center_zone_too_long': 'Data center zone is too long.',
  'err:instance_id_too_long': 'Instance ID is too long.',
  'err:ip_tables_digest_too_long': 'IP tables digest is too long.',
  'err:ip_set_digest_too_long': 'IP set digest is too long.',
  'err:os_id_too_long': 'OS ID is too long.',
  'err:os_detail_too_long': 'OS detail is too long.',
  'err:desired_version_too_long': 'Desired version is too long.',
  'err:service_provider_too_long': 'Service destination is too long.',
  'err:policy_update_result_code_too_long': 'Policy update result code is too long.',
  'err:clone_token_too_long': 'Clone token is too long.',
  'err:token_hash_too_long': 'Token hash is too long.',
  'err:salt_too_long': 'Salt is too long.',
  'err:uuid_too_long': 'UUID is too long.',
  'err:service_principal_name_too_long': 'Service principal name (SPN) is too long.',
  'err:service_principal_name_cannot_be_removed': 'Service principal name cannot be removed',
  'err:security_policy_refresh_at_too_long': 'Security policy refresh at is too long.',
  'err:security_policy_refresh_status_too_long': 'Security policy refresh status is too long.',
  'err:invalid_security_policy_refresh_status': 'Invalid security policy refresh status value.',
  'err:tampering_error': 'VEN has been tampered with',
  'err:secure_connect_error': 'There is a secure connection in error state',
  'err:security_policy_refresh_failure': 'Security policy refresh failed',
  'err:kerberos_device_auth_service_name_not_configured':
    'Kerberos service name for device authentication not configured.',
  'err:kerberos_device_auth_keytab_file_not_configured':
    'Kerberos keytab file for device authentication not configured.',
  'err:kerberos_user_auth_service_name_not_configured': 'Kerberos service name for user authentication not configured.',
  'err:kerberos_user_auth_keytab_file_not_configured': 'Kerberos keytab file for user authentication not configured.',
  'err:kerberos_workload_not_found': 'Workload not found for the specified Kerberos SPN. %s',
  'err:kerberos_auth_failed': 'Kerberos authentication failed. %s',
  'err:duplicate_workload_interface': 'Cannot assign the same name and IP address to more than one workload interface',
  'err:invalid_workload_interface': '%s is not a valid workload interface',
  'err:agent_already_suspended': 'VEN was already suspended when reported as suspending',
  'err:agent_not_suspended': 'VEN was not active when reported as suspending, state was not changed',
  'err:agent_already_unsuspended': 'VEN was already active when reported as unsuspending',
  'err:agent_not_unsuspended': 'VEN was not suspended when reported as unsuspending, state was not changed',
  'err:required_processes_not_running': 'At least one required VEN process is down.',
  'err:security_policy_stage_failure': 'Security policy stage failed',
  'err:target_cluster_id_not_numeric': 'Target cluster id must be numeric',
  'err:email_notification_failed': 'Email notification delivery failed. Check mail server configuration',
  'err:invalid_policy_health': 'Invalid policy health',
  'err:invalid_security_policy_applied_at_range':
    'Security policy applied at gte timestamp cannot be before security policy applied lte timestamp',
  'err:invalid_security_policy_received_at_range':
    'Security policy received at gte timestamp cannot be before security policy received lte timestamp',
  'err:modification_network_interface_not_allowed': 'Modification to a network interface is not allowed',
  'err:invalid_workload_disconnect_timer': 'Workload disconnect timer must be at least 300 seconds or -1',
  'err:invalid_workload_disconnect_notification_order':
    'Workload timeout notifications must fulfill this relationship: disconnect_timer > error > warn > info',
  'err:invalid_workload_disconnect_notification_value':
    'Workload timeout notifications must be at least 300 seconds or -1',
  'err:invalid_ven_uninstall_timer': 'VEN uninstall timer must be between 1 and 4440 hours (inclusive)',
  'err:scoped_setting_default_scope_only': 'Setting must have a scope of []',
  'err:duplicate_scoped_setting_scope': 'Duplicate workload setting scope',
  'err:org_setting_invalid_destination_type': 'Invalid destination type',
  'err:org_setting_invalid_format_type': 'Invalid format type',
  'err:org_setting_invalid_input': 'Invalid org-setting request',
  'err:destination_invalid_input': 'Invalid destination input',
  'err:pce_scope_required': 'PCE scope required',
  'err:invalid_pce_fqdn': 'Invalid PCE FQDN: %s',
  'err:hostname_required': 'Hostname required',
  'err:protocol_not_supported': 'Invalid destination type',
  'err:ca_bundle_required': 'CA bundle required',
  'err:invalid_address': 'Invalid address: %s',
  'err:remote_connection_failure': 'Unable to connect to remote syslog server: %s',
  'err:remote_tls_connection_failure': 'Unable to SSL/TLS connect to remote syslog server: %s',
  'err:peer_verification_failure': 'TLS peer verification failure: %s',
  'err:internal_syslog_not_enabled': 'PCE syslog service is not enabled',
  'err:method_not_allowed_on_saas_cluster': 'Configuring PCE syslog service is not allowed on SAAS cluster',
  'err:tls_over_udp_not_supported': 'TLS over udp protocol is not allowed',
  'err:duplicate_syslog_configuration_not_allowed': 'Duplicate syslog configuration is not allowed',
  'err:unexpected_ca_bundle': 'Unexpected CA bundle when TLS is disabled',
  'err:test_syslog_message_failed': 'Failed to write test syslog message to remote server',
  'err:workload_agent_disassociated':
    'Workload has been disassociated from the VEN possibly by another concurrent operation.',
  'err:agent_associated_with_deleted_workload':
    'VEN is associated with a deleted workload. Please uninstall the VEN and activate again',
  'err:api_key_creation_not_allowed': 'API Key can only be created by local users',
  'err:non_unique_machine_id': 'An VEN with this machine ID already exists',
  'err:command_line_label_not_supported':
    'Activation method does not support specifying labels in the activation command',
  'err:invalid_version_string': 'The specified version string is not valid.',
  'err:one_of_network_id_or_profile_required':
    'Either network_id or network_profile must be present for each interface',
  'err:agent_clone_detected':
    'PCE detected a cloned VEN. Resolve the clone issue, or wait for the clone state to be cleared',
  'err:nothing_to_revert': 'No modified object found. Nothing was reverted.',
  'err:ven_upgrade_required':
    'This feature requires upgrading the VEN: Enhanced Data Collection requires VEN version 20.2 or later',
  'err:agent_too_many_interfaces_reported':
    'VEN reported more interfaces than the PCE supports. Please contact Illumio Support.',

  // multi VEN errors
  'err:invalid_ids': 'Invalid IDs',
  'err:single_workload_bulk_operation': 'There cannot be more than one workload bulk operation at the same time.',

  // multi object errors
  'err:objects_not_found': 'Objects not found.',

  'err:invalid_sort_by_field': 'Invalid sort_by field',

  // Ruleset related errors
  'err:rs_invalid_set_type': 'Invalid property set_type',
  'err:rs_commit_message_cant_be_blank': 'Commit message cannot be blank.',
  'err:any_port_invalid': 'Port may not be specified if protocol is \\any protocol\\.',
  'err:changes_in_proposed_only': 'Changes are only allowed in proposed Ruleset.',
  'err:rs_provider_required': 'Rule must specify at least one destination.',
  'err:rs_consumer_required': 'Rule must specify at least one user.',
  'err:rs_duplicate_provider': 'Duplicate destination %s',
  'err:rs_duplicate_consumer': 'Duplicate source %s',
  'err:cant_specify_ip_value_ip_addressable': 'Either ip_value or ip_addressable must be specified but not both.',
  'err:rule_may_not_contain_black_lists_only': 'Rule may not contain only deny lists.',
  'err:cant_specify_ip_value_actor': 'Invalid destination or user',
  'err:service_name_required': 'Service name is required.',
  'err:invalid_svc_type': 'Invalid Service type %s',
  'err:svc_port_required': 'Port is required.',
  'err:svc_protocol_invalid': 'Protocol invalid',
  'err:svc_protocol_required': 'Protocol is required.',
  'err:svc_port_not_numeric': 'Port must be numeric.',
  'err:rule_set_name_required': 'Ruleset name is required.',
  'err:duplicate_service_ports_and_protocols_not_allowed': 'Duplicate port/protocol pairs not allowed.',
  'err:nothing_to_commit': 'No modified object found. Nothing was committed.',
  'err:cant_cross_reference_versions':
    'Supplied payload cross references objects in other than the draft version of security policy. Only draft version is allowed to be updated.',
  'err:service_referenced': 'Cannot delete a Service that is referenced by an existing Ruleset',
  'err:service_referenced_by_virtual_service':
    'Cannot delete service that is referenced by an existing virtual service',
  'err:virtual_server_referenced': 'Cannot delete Virtual Server that is referenced by an existing Rule',
  'err:service_has_associated_vs': 'Service has an associated Virtual Server',
  'err:service_read_only': 'System-created Service cannot be modified or deleted',
  'err:rule_set_name_in_use': 'Ruleset name is already in use',
  'err:ip_list_referenced': 'Can not delete an IP List that is referenced by an existing Rule',
  'err:ip_list_referenced_by_enforcement_boundary':
    'Can not delete Ip List that is referenced by an existing deny rule',
  'err:ip_list_referenced_by_essential_service_rule': 'Cannot delete IP List referenced by an essential service rule',
  'err:ip_list_read_only': 'System created IP List can not be modified or deleted',
  'err:ip_list_address_or_fqdn_required': 'At least one of IP address or FQDN is required',
  'err:service_deleted': 'Can not assign deleted Service',
  'err:workload_deleted': 'Can not assign deleted workload',
  'err:workload_already_deleted': 'Cannot delete a workload that has already been deleted',
  'err:virtual_service_deleted': 'Can not assign deleted Virtual Service',
  'err:label_deleted': 'Can not assign deleted label',
  'err:invalid_rule_actor': 'Invalid Rule actor',
  'err:method_only_allowed_on_draft': 'This method is only allowed on draft policy.',
  'err:invalid_firewall_settings': 'Invalid firewall settings',
  'err:rs_duplicate_rule': 'Duplicate rule %s',
  'err:ruleset_read_only': 'Illumio Edge system created ruleset cannot be modified or deleted',
  'err:use_workload_subnets_invalid_resolve_labels':
    'When workload subnets are used for the destination/source, the corresponding resolve_labels must not contain virtual services',
  'err:use_workload_subnets_must_be_empty':
    'SecureConnect, Machine Auth, and virtual service rules must have not use workload subnets.',

  // Rule Search Errors
  'err:invalid_range': 'Invalid range for %s timestamp',
  'err:too_many_entities_rule_search': 'Too many entities for %s. Only %s entities are allowed.',
  'err:no_port_with_to_port': 'A valid port value is required with to_port',
  'err:port_less_than_to_port': 'port value cannot be more than to_port',
  'err:port_not_allowed': 'A port value is not allowed with this protocol',
  'err:resolve_actors_disabled_rule_search': 'Resolve Actors cannot be used when searching for disabled Rules',
  'err:rule_search_update_type': 'Update type filter can only be used with draft version',
  'err:resolve_actors_requires_rule_actors':
    'Resolve Actors must be used with one of providers, consumers, or providers_or_consumers',
  'err:exact_service_match_requires_ingress_services': 'Exact service match must be used with ingress_services',

  // ip allow/deny lists and groups
  'err:ip_list_name_required': 'List name is required..',
  'err:ip_list_name_not_unique': 'List name must be unique.',
  'err:ip_list_group_name_required': 'Group name is required.',
  'err:ip_list_group_name_not_unique': 'Group name must be unique.',
  'err:ip_list_invalid_exclusion': 'An IP List with an exclusion range must have at least one included range.',
  'err:from_ip_required': 'from_ip property of IpRange is required.',
  'err:input_validation_error': 'Unexpected input validation error',
  'err:may_not_mix_ip_lists': 'Group may not contain both IP Lists and denylists.',
  'err:ip_inconsistent_family': 'IP addresses must be of the same family (IPv4 or IPv6).',
  'err:ip_address_invalid': 'Invalid IP address',
  'err:ip_list_one_address_required': 'At least one IP address is required.',
  'err:ip_address_found_in_opposite_list': 'IP address or range %s is included in the list of the opposite type: %s.',
  'err:description_30_too_long': 'Description cannot be longer than 255 characters.',
  'err:name_25_too_long': 'Name cannot be longer than 25 characters.',
  'err:rule_internal_app_ip_list_pb': 'Workload error with IP List Rule error',
  'err:secure_connect_ip_list': 'Workload error with SecureConnect IP List error',
  'err:rule_ub_must_be_managed': 'Only Managed Workloads are allowed in the Consumers field of Unmanaged Services.',
  'err:rule_pb_only_ip_list_or_all': 'Only IP Lists or All are allowed in the Providers field of Unmanaged Services.',
  'err:provider_not_found': 'Providers for Rule not found',
  'err:consumer_not_found': 'Consumers for Rule not found',

  // Labels
  'err:label_key_required': 'Label must have a key defined.',
  'err:label_value_required': 'Label must have a value defined.',
  'err:label_still_has_associated_agent_info': 'Label still has an associated VEN.',
  'err:label_still_has_associated_pairing_profile': 'Label still has an associated Pairing Profile.',
  'err:label_still_has_associated_rule_set': 'Label still has an associated Ruleset.',
  'err:label_still_has_associated_rule': 'Label still has an associated Ruleset.',
  'err:label_still_has_associated_virtual_service': 'Label still has an associated Virtual Service.',
  'err:label_still_has_associated_vs': 'Label still has an associated Virtual Server.',
  'err:label_key_and_value_must_be_unique':
    'A Label of this type with the same name already exists. Try a different name.',
  'err:label_key_not_supported': 'Label key is not supported.',
  'err:label_key_may_not_be_changed': 'Changing the key of an existing Label is not allowed.',
  'err:label_not_found': 'Label not found',
  'err:label_key_too_long': 'Label key is too long.',
  'err:label_value_too_long': 'Label value is too long.',
  'err:label_still_belongs_to_a_user_scope': 'Label still belongs to a user scope',
  'err:label_still_has_associated_static_scopes': 'Label still has an associated static policy scope',
  'err:label_still_has_associated_secure_connect_scopes':
    'Label still has an associated secure connect certificate scope',
  'err:label_still_has_associated_firewall_coexistence': 'Label is referenced by firewall coexistence scope',
  'err:label_still_has_associated_cip_scopes': 'Label is referenced by containers inherit host policy scope',
  'err:label_still_has_associated_cwp': 'Label is referenced by container workload profiles',
  'err:label_still_has_associated_bcr_scope': 'Label is referenced by blocked connection reject scope',
  'err:label_still_has_associated_loopback_scopes': 'Label is referenced by loopback interfaces in policy scope',
  'err:label_still_has_associated_enforcement_boundary': 'Label is referenced by deny rule',
  'err:label_still_has_associated_enforcement_point': 'Label is referenced by enforcement point',
  'err:label_still_has_associated_rule_hit_count_scopes': 'Label is referenced by rule hit count enabled scope',
  'err:label_still_has_associated_ip_forwarding_enabled_scopes': 'Label is referenced by IP forwarding enabled scope',
  'err:ams_with_label_not_allowed': 'All Workloads cannot be used with labels or label groups in a Deny Rule',
  'err:label_already_deleted': 'Cannot delete a label that has already been deleted',

  // Label Dimensions
  'err:label_dimension_already_deleted': 'Label dimension %s, already deleted.',
  'err:label_dimension_not_editable': 'Label dimension %s, is not modifiable.',
  'err:label_dimension_still_in_use_by_label': 'Label dimension "%s", still referenced by label(s) %s',
  'err:label_dimension_still_in_use_by_label_group': 'Label dimension "%s", still referenced by label group(s) %s',
  'err:label_dimension_key_already_in_use': 'Label dimension key "%s", is already in use',
  'err:label_dimension_key_may_not_be_changed': 'Changing the key of an existing label dimension is not allowed',
  'err:label_dimension_reserved_external_data_set_may_not_be_changed':
    'Changing the reserved external data set or reference is not allowed',

  // Reserved Name misuse
  'err:reserved_name_violation': 'Unauthorized use of reserved name %s, in %s',

  // Label Groups
  'err:label_group_circular_reference': 'Label Group must not contain groups it is a member of.',
  'err:label_group_members_different_keys': 'Members of a Label Group must be of the same type as the Group.',
  'err:label_referenced_by_label_group': 'Can not delete Label that is referenced by a Label Group',
  'err:label_group_asymmetric_scope': 'Label Group may only be used in symmetric (basic) Scope',
  'err:label_group_referenced_by_rules': 'Label Group is still referenced by a Ruleset or a Rule',
  'err:update_type_invalid': "Update Type filter must be one of 'create', 'update', 'delete'",
  'err:label_group_referenced_by_static_scopes': 'Label Group is still referenced by static policy scopes',
  'err:label_group_referenced_by_containers_inherit_host_policy_scopes':
    'Label Group is still referenced by containers inherit host policy scopes',
  'err:label_group_referenced_by_bcr_scopes': 'Label Group is still referenced by blocked connection reject scopes',
  'err:label_group_referenced_by_enforcement_boundaries': 'Label Group is still referenced by a Deny Rule',
  'err:label_group_referenced_by_loopback_policy_scopes':
    'Label Group is still referenced by loopback interfaces in policy scopes',
  'err:label_group_referenced_by_ip_forwarding_enabled_scopes':
    'Label Group is still referenced by IP forwarding enabled scopes',
  'err:label_group_referenced_by_rule_hit_count_scopes':
    'Label Group is still referenced by rule hit count enabled scopes',

  // Enforcement Boundary
  'err:rule_name_in_use': 'Rule name already in use',
  'err:service_referenced_by_enforcement_boundary': 'Cannot delete service that is referenced by an existing deny rule',
  'err:ingress_services_cannot_be_empty': 'Enforced services cannot be empty',
  'err:name_required': 'Name is required',

  // secureconnect status
  'banner:secconnect_error': 'SecureConnect error',
  'status_sc:up': 'SecureConnect enabled',
  'status_sc:down': 'SecureConnect enabled, no connections',
  'status_sc:error': 'SecureConnect error',

  'err_sc:RADIUS_NOT_RESPONDING': 'Radius not responding',
  'err_sc:LOCAL_AUTH_FAILED': 'Local authentication failed.',
  'err_sc:PEER_AUTH_FAILED': 'Peer authentication failed.',
  'err_sc:PARSE_ERROR_HEADER': 'Header parsing error',
  'err_sc:PARSE_ERROR_BODY': 'Body parsing error',
  'err_sc:RETRANSMIT_SEND_TIMEOUT': 'Retransmission timeout',
  'err_sc:HALF_OPEN_TIMEOUT': 'Half opening timeout',
  'err_sc:PROPOSAL_MISMATCH_IKE': 'Proposal mismatch ike',
  'err_sc:PROPOSAL_MISMATCH_CHILD': 'Proposal mismatch child',
  'err_sc:TS_MISMATCH': 'TS mismatch',
  'err_sc:INSTALL_CHILD_SA_FAILED': 'Child SA install failure',
  'err_sc:INSTALL_CHILD_POLICY_FAILED': 'Child policy install failure',
  'err_sc:UNIQUE_REPLACE': 'Unique replace',
  'err_sc:UNIQUE_KEEP': 'Unique keep',
  'err_sc:VIP_FAILURE': 'CIP failure',
  'err_sc:AUTHORIZATION_FAILED': 'Authorization failure',

  // pairing_profiles
  'err:pairing_profile_name_required': 'Name is required.',
  'err:pairing_profile_description_required': 'Description is required.',
  'err:pairing_profile_mode_required': 'Mode is required.',
  'err:pairing_profile_name_too_long': 'Name is too long.',
  'err:pairing_profile_description_too_long': 'Description is too long.',
  'err:pairing_profile_allowed_uses_per_key_numeric': 'Uses per key must be a positive integer.',
  'err:pairing_profile_key_lifespan_numeric': 'Key lifespan must be a positive integer.',
  'err:pairing_profile_ephemeral_boolean': 'Ephemeral requires a true or false value.',
  'err:pairing_profile_enabled_boolean': 'Enabled requires a true or false value.',
  'err:duplicate_label_keys_not_allowed': 'Duplicate Label keys are not allowed.',
  'err:pairing_profile_mode_lock_boolean': 'mode_lock requires a true or false value.',
  'err:pairing_profile_log_traffic_lock_boolean': 'log_traffic_lock requires a true or false value.',
  'err:pairing_profile_visibility_level_lock_boolean': 'visibility_level_lock requires a true or false value.',
  'err:pairing_profile_ven_type_conflict': 'The selected VEN version %s is not supported for the selected %s VEN type.',
  'err:pairing_profile_ven_type_not_allowed': 'ven_type not allowed to be specified in Edge',
  'err:pairing_profile_release_not_in_org': 'The selected agent_software_release %s is not enabled for this org.',

  // policy update codes
  'err:policy_applied_ok': 'Policy configuration was provisioned successfully.',
  'err:policy_illegible': 'Something in your security settings was not configured correctly.',
  'err:policy_firewall_rules_config_failed': 'We were unable to apply the intended firewall Rules to the Workload.',
  'err:policy_firewall_sets_config_failed': 'We were unable to apply the intended firewall Rules to the Workload.',
  'err:policy_ipsec_config_failed': 'We were unable to apply the intended SecureConnect configuration to the Workload.',
  'err:policy_application_failed': 'We were unable to apply the intended policy configuration to the Workload.',
  'err:sec_policy_commit_message_required': 'An update description is required with the policy commit.',

  // Rule visibility API
  'err:bucket_size_seconds_required': 'Bucket size in seconds is required.',
  'err:bucket_size_seconds_must_be_integer': 'Bucket size in seconds must be an integer.',
  'err:rfc3339_time_stamp_required': 'RFC 3339 timestamp is required.',
  'err:bytes_in_required': 'Bytes in is required.',
  'err:bytes_in_must_be_integer': 'Bytes in must be an integer.',
  'err:bytes_out_required': 'Bytes out is required.',
  'err:bytes_out_must_be_integer': 'Bytes out must be an integer.',
  'err:flows_started_required': 'Flows started is required.',
  'err:flows_started_must_be_integer': 'Flows started must be an integer.',
  'err:flows_completed_required': 'Flows completed is required.',
  'err:flows_completed_must_be_integer': 'Flows completed must be an integer.',
  'err:flows_active_required': 'Flows active is required.',
  'err:flows_active_must_be_integer': 'Flows active must be an integer.',
  'err:rule_stat_bucket_id_required': 'Rule stat bucket ID is required.',
  'err:rule_stat_bucket_id_must_be_integer': 'Rule stat bucket ID must be an integer.',

  // Org validations
  'err:uuid_may_not_be_changed': 'UUID was set previously and may not change.',
  'err:invalid_country_code': 'Invalid country code',
  'err:invalid_language_code': 'Invalid language code',
  'err:cs_workload_limit_not_allowed': 'cs_workload_limit can only be set if cs_integration_enabled is set',

  // API usage limit profile validations
  'err:api_usage_limit_profile_name_required': 'API usage limit profile name required.',
  'err:api_usage_limit_profile_name_too_long': 'API usage limit profile name too long.',
  'err:api_usage_limit_profile_max_requests_invalid': 'API usage limit profile max requests must be >= 0.',
  'err:api_specific_limit_api_required': 'API specific limit API name required.',
  'err:api_specific_limit_api_too_long': 'API specific limit API name too long.',
  'err:api_usage_limit_profile_still_has_associated_org':
    'API usage limit profile still has an associated Organization.',
  'err:api_version_deprecated_error':
    'This API version has been deprecated. Please refer to Illumio documentation for information regarding supported API versions.',

  // Object limit profile validations
  'err:object_limit_profile_name_required': 'Object limit profile name required.',
  'err:object_limit_profile_name_too_long': 'Object limit profile name too long.',
  'err:object_limit_profile_edit_name_default_or_global_not_allowed':
    'You cannot modify the name of the Default/Global object limit profile.',
  'err:object_limit_key_required': 'Object limit key required',
  'err:object_limit_key_too_long': 'Object limit key too long',
  'err:object_limit_key_invalid': 'Invalid object limit key',
  'err:object_limit_soft_limit_invalid': 'Object limit soft limit must be >= -1.',
  'err:object_limit_hard_limit_invalid': 'Object limit hard limit must be >= -1.',
  'err:object_limit_profile_still_has_associated_org': 'Object limit profile still has an associated Organization.',
  'err:object_limit_hard_limit_reached': 'Object limit hard limit reached',

  // Audit log event validations
  'err:created_by_id_must_be_integer': 'Created by ID must be an integer.',
  'err:created_by_type_required': 'Created by type is required.',
  'err:event_type_required': 'Event type is required.',
  'err:event_version_too_long': 'Event version is too long',
  'err:severity_required': 'Severity is required.',
  'err:uuid_required': 'UUID is required.',
  'err:uuid_must_be_unique': 'UUID must be unique.',
  'err:created_by_name_too_long': 'Created by name is too long.',
  'err:invalid_created_by_type': 'Invalid created by type: %s',
  'err:invalid_created_by': 'Invalid created by: %s',
  'err:invalid_event_type': 'Invalid event type: %s',
  'err:invalid_severity': 'Invalid severity: %s',
  'err:invalid_time': 'Invalid time: %s',
  'err:invalid_max_results': 'Invalid max_results:  Must be between 1 and %s.',

  // IP secure connection validations
  'err:agent_id_required': 'VEN ID is required.',
  'err:agent_id_must_be_integer': 'VEN ID must be an integer.',
  'err:connection_state_required': 'Connection state is required.',
  'err:rule_id_required': 'Rule ID is required.',
  'err:rule_id_must_be_integer': 'Rule ID must be an integer.',
  'err:remote_ip_required': 'Remote IP is required.',
  'err:error_id_must_be_integer': 'Error ID must be an integer.',
  'err:invalid_connection_state': 'Invalid connection state: %s',
  'err:invalid_remote_ip': 'Invalid remote IP: %s',
  'err:invalid_error_id': 'Invalid error ID: %s',
  'err:remote_agent_id_must_be_integer': 'Remote VEN ID must be an integer.',
  'err:local_ip_required': 'Local IP is required.',
  'err:invalid_local_ip': 'Invalid local IP: %s',

  // Rule validations
  'err:invalid_secure_connect_status': 'Invalid SecureConnect status: %s',
  'err:secure_connect_not_allowed': 'SecureConnect not allowed on Rules for Unmanaged Services.',
  'err:machine_auth_invalid_service': 'All Services or service with all ports not allowed for Machine Auth',
  'err:sec_connect_machine_auth_virtual_service':
    'SecureConnect and Machine Auth rules do not support virtual services',
  'err:stateless_rule_consumer_count_exceeded':
    'Multiple consumers are allowed only if they are Any (0.0.0.0/0 and ::/0) and ams or labels of different types',
  'err:stateless_rule_sec_connect_invalid':
    'Stateless rules cannot be enabled together with secure connect or machine auth',
  'err:invalid_windows_service_provider': 'Windows based services cannot have IP Lists as a destination',
  'err:invalid_windows_service_provider_edge': 'Windows services can only be used in an Inbound Rule',
  'err:secure_connect_aus_invalid': 'SecureConnect cannot be used with Security Principal',
  'err:non_brn_must_use_ip_list':
    'A rule with Network Type "All" or "Non-Corporate (Endpoints Only)" must have only IP lists on consumers or providers',
  'err:network_type_must_be_brn':
    'SecureConnect, Machine Authentication, and Virtual Services rules must have Default(Corporate) Network Type',
  'err:log_flow_invalid_options': 'Rules with log_flow false cannot use stateless, Machine Auth, or SecureConnect.',
  'err:log_flow_invalid_resolve_labels': 'When log_flow is false, resolve_labels cannot include virtual services',

  // Network type related errors
  'err:network_cidr_overlap': 'Network Subnets (CIDRs) must not overlap.',
  'err:cidr_network_detection_disabled':
    'This feature must be enabled via PCE runtime before use. Please contact Illumio support',
  'err:network_cidr_ip_version_mismatch': 'Public IP version does not match the network IP version',
  'err:network_read_only': 'System created networks cannot be modified',
  'err:reserved_network_name': 'Only System networks can be called Private or Internet',
  'err:network_management_immutable': 'Networks cannot change between system and user managed',

  // Traffic events
  'err:src_agent_id_must_be_integer_in_range': 'Source VEN ID must be an integer >= 0.',
  'err:dest_agent_id_required': 'Destination VEN ID is required.',
  'err:dest_agent_id_must_be_integer_in_range': 'Destination VEN ID must be an integer >= 0.',
  'err:dest_port_required': 'Destination port is required.',
  'err:dest_port_must_be_integer_in_range': 'Destination port must be an integer >= 0.',
  'err:dest_protocol_required': 'Destination protocol is required.',
  'err:dest_protocol_must_be_integer_in_range': 'Destination protocol must be an integer >= 0.',
  'err:rounded_start_time_required': 'Rounded start time is required.',
  'err:start_time_required': 'Start time is required.',
  'err:end_time_required': 'End time is required.',
  'err:action_required': 'Action is required.',
  'err:invalid_action': 'Invalid action: %s.',
  'err:bucket_state_required': 'Bucket state is required.',
  'err:invalid_bucket_state': 'Invalid bucket state: %s',
  'err:flow_count_required': 'Flow count is required.',
  'err:flow_count_must_be_integer_in_range': 'Flow count must be an integer >= 1.',

  // Management
  'err:http_proxy_malformed': 'Invalid HTTP proxy URL format',
  'err:email_server_malformed': 'Invalid email server address format',
  'err:email_server_port_invalid': 'Invalid email server port',
  'err:email_domain_malformed': 'Invalid email domain format',
  'err:ntp_server_malformed': 'Invalid NTP server address format',
  'err:deployment_mode_invalid': 'Invalid deployment mode',
  'err:pkcs12_password_missing': 'No PKCS12 password supplied',
  'err:invalid_pkcs12_url': 'Invalid PKCS12 file URL',
  'err:failed_pkcs12_upload': 'Failed to upload PKCS12 file.',
  'err:pkcs12_data_missing': 'No PKCS12 data or file supplied.',
  'err:invalid_pkcs12_data': 'Invalid PKCS12 data',
  'err:invalid_pkcs12_password_or_data': 'Invalid PKCS12 data or password',
  'err:pkcs12_cert_too_soon': 'Certificate chain is not yet valid.',
  'err:pkcs12_cert_expired': 'Certificate chain has expired.',
  'err:pkcs12_cert_error': 'Certificate chain has invalid time range.',
  'err:pkcs12_cert_invalid': 'Unable to verify certificate/missing CAs',
  'err:pkcs12_hostname_invalid': 'Certificate does not match system hostname.',
  'err:pkcs12_bundle_err': 'Unable to match private key against certificate',
  'err:pkcs12_verify_err': 'Unable to verify certificate connectivity',
  'err:interface_not_configured': 'Network interface not configured.',
  'err:excess_dhcp_parameters': 'Excess parameters for DHCP configuration',
  'err:ip_address_required': 'Static interface needs IP address.',
  'err:invalid_ip_address': 'Invalid IP address specified.',
  'err:ip_netmask_required': 'Static interface needs CIDR block.',
  'err:invalid_ip_netmask': 'Invalid CIDR block specified.',
  'err:ip_gateway_required': 'Static interface needs gateway IP address.',
  'err:invalid_ip_gateway': 'Invalid gateway IP address specified.',
  'err:invalid_ip_broadcast': 'Invalid broadcast IP address specified.',
  'err:invalid_ip_metric': 'Invalid metric value specified.',
  'err:invalid_dns_nameservers': 'Invalid DNS name server value(s) specified.',
  'err:dns_nameservers_required': 'Static interface requires DNS name servers.',
  'err:invalid_dns_search': 'Invalid DNS search domain value(s) specified.',
  'err:deleting_last_interface': 'Not allowed to delete the last network interface.',
  'err:interfaces_write_error': 'Unable to write interfaces configuration.',
  'err:email_malformed': 'Invalid email address format',
  'err:missing_authentication_data': 'Missing authentication data',
  'err:syslog_server_malformed': 'Invalid syslog server address',
  'err:fluentd_server_malformed': 'Invalid fluentd server address',
  'err:support_report_in_progress': 'Support Report already in-progress.',
  'err:support_report_failed_to_start': 'Support Report failed to start.',

  // Scoped Services
  'err:labels_cant_be_blank': 'Labels cannot be blank.',
  'err:labels_has_invalid_uri': 'Labels contains an invalid URI.',
  'err:labels_invalid': 'Invalid Labels',
  'err:description_url_too_long': 'Description URL is too long.',

  // OnPrem validations
  'err:user_may_not_edit_own_roles': 'User may not edit own role.',
  'err:user_already_has_role': 'User already has this role.',

  // Server Load Balancer errors
  'err:slb_name_required': 'Server load balancer name is required.',
  'err:slb_device_type_required': 'Server load balancer device type is required.',
  'err:slb_version_too_long': 'Software version is too long.',
  'err:uuid_invalid': 'Invalid UUID format',
  'err:nfc_not_enabled':
    'The SLB cannot be configured because either NFC has not been enabled on the PCE, or NFC is not supported on this PCE configuration. Contact Illumio for assistance.',
  'err:slb_hostname_ip_must_be_unique': 'Server load balancer device Management IP or FQDN address must be unique',

  //These errors are handled by the UI and should never appear
  'err:slb_device_count_error': 'There must be either 1 or 2 devices',
  'err:slb_device_update_error': 'Server Load Balancer device data must include its URI (href) for update',
  'err:slb_device_create_error': 'Server load balancer device data must include config property for create',
  'err:slb_device_check_certificate_boolean': 'Server Load Balancer check_certificate requires a true or false value',
  'err:slb_device_type_not_supported': 'Server load balancer device type not suppported by NFC',

  // Discovered Virtual Server (slb) errors
  'err:virtual_server_name_required': 'Virtual Server name is required.',
  'err:virtual_server_mode_required': 'Virtual Server mode is required.',
  'err:virtual_server_management_mode_required': 'Virtual Server management mode is required.',
  'err:virtual_server_no_service_with_workload': 'Virtual Server Workload destination requires a Service.',
  'err:virtual_server_dvs_in_use': 'Discovered Virtual Server already used by Virtual Server.',
  'err:virtual_server_service_nil': 'Virtual server requires a service',
  'err:virtual_server_non_workload_label_provider': 'Virtual server destination must be workload or label',
  'err:virtual_server_without_dvs':
    'Cannot revert a virtual server when the discovered virtual server has been deleted on the SLB device',
  'err:discovered_virtual_server_identifier_required': 'Virtual Server identifier is required.',
  'err:dvs_virtual_server_filter_required':
    'Filtering discovered_virtual_servers by mode or labels requires has_virtual_server=true',
  'err:invalid_discovered_virtual_server': 'Invalid discovered Virtual Server identifier {%s}',
  'err:slb_required': 'Server load balancer is required.',
  'err:slb_still_has_associated_virtual_servers': 'Server load balancer still has an associated Virtual Server.',
  'err:invalid_slb_for_nfc': 'Server load balancer is not managed by this NFC.',
  'err:unmatched_device_count_for_slb': 'Device count does not match this SLB.',
  'err:invalid_device_for_slb': 'Device is not a member of this SLB.',
  'err:nfc_href_required': 'Need to specify an NFC device',
  'err:nfc_still_has_associated_slbs': 'NFC still has an associated SLB.',
  'err:snat_pool_ips_required': 'Virtual Server SNAT pool IPs required.',
  'err:snat_pool_ips_not_allowed': 'Virtual Server SNAT pool IPs not allowed.',
  'err:snat_type_required': 'Virtual Server SNAT type is required.',
  'err:snat_mode_not_specified': 'Virtual Server SNAT is not configured.',
  'err:protocol_must_be_integer_in_range': 'Protocol must be an integer between 0 and 255.',

  // Virtual Service errors
  'err:virtual_service_used_in_rule': 'Can not delete virtual service. It is being used in rule',
  'err:virtual_service_name_required': 'Name is required.',
  'err:virtual_service_service_required': 'Service is required.',
  'err:virtual_service_invalid_protocol': 'Invalid protocol. Only 6(tcp) or 17(udp) are allowed',
  'err:virtual_service_required': 'Virtual Service must be specified',
  'err:virtual_service_workload_required': 'Workload must be specified',
  'err:virtual_service_still_active': 'Virtual service is still active on a workload',
  'err:virtual_service_apply_to_invalid': 'Virtual service apply_to target is invalid',
  'err:uri_must_reference_active_version': 'The supplied uri must reference an active version of the object',
  'err:port_range_invalid': 'Invalid port range',
  'err:virtual_service_service_or_service_port':
    'Virtual service can be configured with either service or service_ports',
  'err:virtual_service_network_ip_version_mismatch':
    'The IP version of the service_address does not match the IP version of the associated network',
  'err:virtual_service_fqdns_allows_host_only':
    'Virtual service configured with service fqdns is supported for apply to host_only',
  'err:fqdns_invalid': 'Fqdns is invalid',
  'err:virtual_service_link_local_network': 'Virtual service IP addresses cannot be used with a link local network',
  'err:service_binding_invalid_operation': 'Container workload service bindings cannot be deleted',
  'err:virtual_service_service_address_port_all_proto':
    'Virtual service service_addresses may not have ports when the virtual service is configured for all protocols',
  'err:virtual_service_service_address_port_windows':
    'Windows services may not be used on a virtual service when service_addresses have ports',
  'err:virtual_service_service_address_internal_bridge':
    'Virtual service apply_to may not be internal_bridge_network when service_addresses have ports',
  'err:container_cluster_virtual_service_edit':
    'Cannot edit a virtual service created by a container cluster that is now deleted',
  'err:container_cluster_virtual_service_delete':
    'Virtual service created by a container cluster that is now deleted may only be deleted from the leader region',
  'err:svc_info_required_with_node_ports':
    'If a ContainerClusterService has node_ports, it must have external_traffic_policy and service_type.',
  'err:virtual_service_container_specific_fields':
    'Container specific fields cannot be directly set on Virtual Service objects.',
  'err:container_cluster_virtual_service_non_kubelink_delete':
    'Cannot delete a virtual service owned by active container cluster',

  // User Groups errors
  'err:sid_required': 'User Group SID is required',
  'err:sid_must_be_unique': 'User Group SID must be unique',
  'err:sid_too_long': 'User Group SID is too long',
  'err:sid_format_invalid': 'User Group SID format is invalid',

  // Service errors
  'err:non_tcp_udp_port_invalid': "Port may not be specified if protocol is not 'tcp' or 'udp'",
  'err:service_cannot_be_named_all_services': 'Service cannot be named "All Services"',
  'err:invalid_windows_service_process_path': 'Incorrect format for Windows process path',
  'err:invalid_windows_service_name': 'Incorrect format for Windows service name',
  'err:process_name_length_invalid': 'Process name must be nonempty and shorter than 255 characters',
  'err:service_name_length_invalid': 'Service name must be nonempty and shorter than 255 characters',

  // Secure gateway errors
  'err:gw_actor_not_found': 'Gateway entity not found',
  'err:gw_duplicate_actor': 'Duplicated entity',
  'err:gw_actor_must_be_label_or_ams': 'Gateway entity must be a Label or All Workloads',
  'err:gw_either_psk_or_cert': 'Gateway configuration requires pre-shared key (PSK) or certificate info',
  'err:gw_cert_must_specify_ca':
    'Gateway configuration requires certificate authentication ID (CA ID) to use certificate',
  'err:gw_name_required': 'Gateway name required',
  'err:gw_name_not_unique': 'Gateway name must be unique',
  'err:gw_psk_too_long': 'Gateway pre-shared key cannot contain more than 255 characters',
  'err:gw_ca_id_too_long': 'Gateway certificate authentication ID (CA ID) cannot contain more than 255 characters',
  'err:gw_remote_id_too_long': 'Gateway remote ID cannot contain more than 255 characters',
  'err:gw_local_id_too_long': 'Gateway local ID cannot contain more than 255 characters',
  'err:gw_timeout_positive_integer': 'Gateway timeout value must be a positive integer',
  'err:gw_only_one_address': 'Use only one IP address for each Gateway',
  'err:gw_address_too_long': 'Gateway address specified is too long',
  'err:gw_address_format': 'Specified Gateway address contains more than 255 characters',
  'err:gw_ip_list_referenced': 'Cannot delete an IP List that is referenced by an existing SecureConnect Gateway',

  // Service Accounts errors
  'err:api_key_expiration_invalid': 'Invalid api_key expiration',
  'err:service_account_name_length_invalid': 'Service account name must be nonempty and shorter than 255 characters',
  'err:rbac_service_account_already_exists': 'Service account with the same name already exists',
  'err:rbac_cannot_operate_on_service_account': 'Service account cannot perform operations on service accounts',
  'err:rbac_service_account_cannot_perform_operation_on_api_keys':
    'Service account cannot perform operations on api keys',
  'err:service_account_api_key_expiration_invalid': 'API key expiration must be less than or equal to the Org default',
  'err:max_active_api_keys_per_service_account': 'Service accounts can only have %s active API keys',

  // Custom iptables rules errors
  'err:ipt_actor_not_found': 'Custom iptables rule actor not found',
  'err:ipt_duplicate_actor': 'Duplicated entity',
  'err:ipt_actor_must_be_managed': 'Custom iptables rule actors must be one of: Label, Workload, or All Workloads',
  'err:ipt_invalid_table_chain': 'Custom iptables rules contains an invalid table/chain combination',
  'err:ipt_invalid_token': 'Invalid token found in custom iptables rule',
  'err:ipt_invalid_parameter': 'Invalid parameter found in custom iptables rule',
  'err:ipt_invalid_custom_chain_name': 'Invalid custom chain name found in custom iptables rule',
  'err:ipt_duplicate_statement': 'Duplicate iptables statements found in same rule',

  // Scoped certificates error
  'err:ambiguous_scopes': 'Firewall Coexistence scopes and labels are ambiguous',
  'err:duplicate_cert_scope': 'There are duplicate cert scopes',

  // Supercluster errors
  'err:supercluster_required': 'Supercluster mode must be active',
  'err:invalid_active_pce_fqdn': 'Invalid active PCE FQDN',
  'err:invalid_target_pce_fqdn': 'Invalid target PCE FQDN',
  'err:pce_fqdn_too_long': 'PCE FQDN is too long',
  'err:invalid_evservice': 'Invalid event service host or port',
  'err:invalid_fqdn_port': 'Invalid fqdn or port',
  'err:supercluster_wrong_region': 'This operation is only allowed on the PCE that owns this resource',

  // Secure connect profiles
  'err:sec_connect_profile_read_only': 'System created Secure Connect Profile can not be modified or deleted',

  // Curl failures
  'err:connection_failure': 'Unable to connect to server',

  // Fileserver Cached Object
  'err:no_database_lock': 'Could not get a database lock',

  // Listen Only Mode
  'err:invalid_timestamp': 'Invalid value for timestamp',
  'err:invalid_query_hash': 'Invalid hash value for query parameter',
  'err:reject_future_timestamp': 'Timestamp cannot be in the future',
  'err:invalid_last_heartbeat_on_range': 'Last heartbeat on gte timestamp cannot be before the lte timestamp',

  // Policy Restore
  'err:method_not_allowed_when_draft_exists': 'This method is not allowed when draft policy exists',
  'err:restore_policy_deleted_labels': 'Restore failed because one or more %s refers to deleted labels: %s',
  'err:restore_policy_deleted_workload': 'Restore failed because a Rule refers to one or more deleted workload(s)',
  'err:restore_policy_deleted_network':
    'Restore failed because a Virtual Service refers to one or more deleted network(s)',

  // Firewall Coexistence
  'err:duplicate_scopes': 'There are multiple scopes that match',
  'err:firewall_coexistence_more_than_one_scope':
    'Firewall coexistence cannot have more than one setting when an empty scope is used',
  'err:firewall_coexistence_mode_disallowed': 'When an empty scope is used workload mode cannot be set',

  // Rules 2.0
  'err:ingress_service_must_be_empty':
    'If the only value for the providers resolve_labels field is "virtual_services", then ingress_services must be empty',
  'err:ingress_service_required':
    'At least one ingress service is required if destination resolve_labels_as includes workloads',
  'err:ingress_service_contains_duplicate_references': 'The same service is referenced multiple times by the rule',
  'err:ingress_service_contains_duplicate_ports':
    'The same port/protocol combination is present multiple times in the rule',
  'err:resolve_labels_required':
    'Both the providers and consumers fields must have at least one value for resolve_labels_as',
  'err:only_tcp_udp_allowed_in_rule': 'Rule ingress_service_ports only support the tcp (6) and udp (17) protocols',
  'err:stateless_rule_cannot_have_ip_list_provider': 'Stateless rules cannot have IP Lists as their destination',
  'err:egress_services_must_be_windows_egress_services': 'Only windows egress services can be set as egress services',
  'err:egress_service_virtual_service': 'Cannot use virtual service with egress services',
  'err:invalid_egress_windows_service_consumer':
    'Windows based service on source side cannot have IP Lists as a source',
  'err: cannot_set_port_or_protocol_for_windows_egress_service':
    'Cannot set port or protocol for a windows egress service',
  'err: ingress_service_cannot_be_windows_egress_service': 'Windows egress services cannot be set as ingress services',

  // Resource Type
  'err:resource_type_required': 'Resource type is required',
  'err:resource_type_too_long': 'Resource type is too long',
  'err:resource_data_required': 'Resource data is required',

  // Action Log
  'err:invalid_status': 'Invalid status: %s',
  'err:event_type_too_long': 'Event type is too long',
  'err:task_name_too_long': 'Task name is too long',

  // Notification Type
  'err:notification_type_required': 'Notification type is required',
  'err:notification_type_too_long': 'Notification type is too long',

  // SNAT
  'err:too_many_public_snat_ips': 'Cannot have more than %s public SNAT IPs in an Org',
  'err:ipv6_public_snat_ips_not_supported': 'IPv6 public SNAT IPs are not supported',

  //  VEN Software Repo
  'err:vsr_missing_required_parameters': 'Missing required parameters',
  'err:vsr_invalid_org_id': 'Invalid org id',
  'err:vsr_not_host_agent': 'Not a host VEN',
  'err:vsr_release_not_found': 'VEN release not found',
  'err:vsr_org_specific_command': 'Org specific command',
  'err:vsr_wrong_content_type': 'Wrong Content-Type',
  'err:vsr_invalid_filename': 'Invalid VEN image filename',
  'err:vsr_no_matching_image': 'No matching VEN software image',
  'err:failed_to_add_release': 'No release entry was added to the repo',
  'err:release_is_default': 'The default release cannot be removed.',
  'err:release_used_in_pairing_profile': 'Releases used in pairing profiles cannot be removed.',
  'err:duplicate_org_software_release': 'There can only be one OrgAgentSoftwareRelease object for a given VEN release.',
  'err:inconsistent_org_software_release': 'Release is not consistent with the ven type - %s.',

  'err:vsr_no_default_release': 'No default VEN release specified',
  'err:vsr_cannot_get_deploy_key': 'Unable to retrieve deploy values',
  'err:vsr_invalid_deploy_filename': 'Invalid VEN tarball filename',
  'err:vsr_invalid_sha256': 'Invalid VEN tarball SHA256',
  'err:invalid_manifest_file': 'Invalid manifest file for release',

  // VEN maintenance token generation errors
  'err:ven_maintenance_token_disabled':
    'The ven_maintenance_token_required option must be enabled in org settings prior to use.',
  'err:ven_maintenance_token_invalid_expiration': 'Requested maintenance token expiration is invalid.',
  'err:ven_maintenance_token_missing_private_key':
    'Missing private key for the org. Maintenance token cannot be generated.',

  // VEN upgrades
  'err:os_platform_not_supported': 'VEN(s) have an unsupported os platform.',
  'err:ven_downgrade_forbidden': 'VENs already have a higher version. Downgrades are not supported.',
  'err:equivalent_ven_version': 'VENs are already at the desired version.',
  'err:ven_suspended': 'VENs are suspended and cannot be upgraded.',
  'err:c_ven_not_supported': 'VENs are containerized and cannot be upgraded.',
  'err:ven_version_upgrade_unsupported': 'VENs must be manually upgraded to 18.2.0 or greater.',
  'err:unsupported_upgrade_path': 'VENs have an unsupported upgrade path.',
  'err:upgrade_path_not_found':
    'No valid upgrade paths were found for this release. Please upload the latest compatibility matrix.',
  'err:compatibility_matrix_downgrade':
    'This compatibility matrix version is lower than the version currently in the PCE.',
  'err:invalid_compatibility_matrix': 'This compatibility matrix is invalid. Please contact Illumio Support.',
  'err:unsupported_ven_release': 'VEN release %s is not supported by PCE %s.',
  'err:upgrade_ven_type_unsupported': 'Desired version does not support one or more VEN types for the selected VENs.',
  // Resource Condition
  'err:invalid_resource_condition': 'Invalid condition token: %s',

  //License
  'err:not_valid_license': 'Not valid license',

  //Container Host
  'err:container_host_rule_invalid_provider':
    'Only Workloads, Labels, or All Workloads can be used as providers if container_host is the source',
  'err:container_host_rule_single_consumer': 'container_host cannot be used with any other source',
  'err:container_host_rule_invalid_resolve_labels':
    'resolve_labels_as for providers and consumers must be "workloads" if container_host is the source',
  'err:container_host_rule_sec_connect_machine_auth':
    'SecureConnect and Machine Authentication cannot be used with container_host as the source',
  'err:container_host_rule_stateless': 'The stateless option cannot be used with container_host as the source',
  'err:container_host_rule_unscoped_consumers': 'container_host must be used with unscoped_consumers = true',
  'err:container_host_rule_no_windows_service': 'Windows services are not supported with container_host as the source',
  'err:container_host_rule_tcp_only':
    'Rule ingress_service_ports only supports tcp (6) protocol with container_host as the source',
  'err:container_host_rule_log_flow': 'The log_flow false option cannot be used with container_host as the source',
  'err:name_namespace_empty': 'One of name or namespace is required',
  'err:cannot_change_default_name_namespace': 'Cannot change default namespace or name',
  'err:namespace_key_mismatch': 'Paring key is invalid for container workload profile',
  'err:container_workload_profile_invalid_pairing_key': 'Container workload profile pairing key does not match',
  'err:container_workload_profile_invalid_managed_idle':
    'Container workload profile cannot have the option of the managed workload with the idle enforcement',
  'err:container_workload_profile_not_found': 'Container workload profile was not found',

  //Container Workload Profiles
  'err:namespace_cannot_change': 'Namespace cannot be changed if its in linked state',
  'err:cannot_delete_linked_container_workload_profile': 'Cannot delete a linked profile',
  'err:name_namespace_must_be_unique': 'Namespace and Name must be unique',
  'err:unmanaged_container_workload_profile_labels':
    'An unmanaged container workload profile cannot have assigned labels',
  'err:cannot_delete_default_container_workload_profile': 'Cannot delete a default profile',

  //Running Containers
  'err:container_id_must_be_unique': 'Container IDs must be unique for a given VEN',
  'err:unpair_disallowed': 'Un-pairing this VEN is disallowed',
  'err:container_update_disallowed': 'Containers cannot be added or updated when container mode is off',

  //Vulnerabilities
  'err:may_not_mix_empty_scope': 'Scopes may not contain both empty scopes and specified scopes',
  'err:ves_exactly_one_scope': 'Projected VES calculation requires exactly 1 scope',
  'err:ves_must_be_enabled': 'Projected VES calculation rule_set and rules must be enabled',
  'err:ves_invalid_label_combo': 'Specified rule labels must match specified scope labels',
  'err:ves_invalid_projected_actor': 'Projected VES calculation only supports a subset of providers and consumers',
  'err:ves_rule_workloads_only': 'Projected VES rules can only resolve labels to workloads, not virtual services',
  'err:invalid_vulnerability_exposure_score_on_range':
    'Vulnerability exposure score gte value cannot be less than the lte value',
  'err:ves_exclusions_unsupported': 'Projected VES calculations do not support policy exclusions in rules or rulesets',

  // LDAP config errors
  'err:ldap_address_must_be_unique': 'LDAP Server address must be unique',
  'err:ldap_timeout_must_be_integer_in_range': 'LDAP Server request timeout must be an integer between 1 and 60',
  'err:ldap_bind_dn_required': 'LDAP Server bind DN is required',
  'err:ldap_user_base_dn_required': 'LDAP Server user base DN is required',
  'err:ldap_username_attribute_required': 'LDAP Server username attribute is required',
  'err:ldap_config_count_exceeded': 'LDAP configuration count exceeded maximum allowed count',
  'err:ldap_server_verification_failure': 'LDAP Server verification failure: %s',
  'err:ldap_user_dn_pattern_invalid': 'LDAP User DN pattern must have exactly one * character in the pattern',

  // Windows Traffic Filtering and Aggregation errors
  'err:invalid_aggregation_with_target': 'Aggregation must be applied on all traffic, cannot be put on target traffic.',
  'err:invalid_aggregation_with_unicast': 'aggregation cannot be applied to unicast traffic',
  'err:duplicate_traffic_collector_setting': 'Duplicate traffic collector setting is not allowed.',
  'err:need_to_specify_target': 'Target traffic must be specified if action is "drop".',
  'err:invalid_traffic_collector_settting_proto':
    'Invalid protocol, only TCP, UDP, ICMP and ICMPv6 protocols are supported.',
  'err:incompatible_traffic_collector_setttings':
    'TCP protocol is not compatible with multicast and broadcast transmissions.',

  //Kubelink errors
  'err:pce_upgrade_required': 'Kubelink requires PCE to be upgraded',
  'err:kubernetes_connection_failed': 'Kubelink could not connect to Kubernetes API server',

  //Access restrictions
  'err:forbidden_error_access_restriction': 'Access denied. Failed access restrictions',
  'err:access_restriction_name_required': 'Access Restriction name is required',
  'err:access_restriction_ips_required': 'Access Restriction ips list is required',
  'err:access_restriction_not_found': 'Access Restriction not found',
  'err:access_restriction_invalid_org_id': 'Access Restriction has invalid org_id',
  'err:access_restriction_associated_with_users': 'Access Restriction is associated with one or more users',

  // Trusted Proxy IPs
  'err:trusted_proxy_ips_invalid_input': 'Invalid input for Trusted Proxy IPs',

  // Reporting
  'err:invalid_template_type': 'Invalid report template %s',
  'err:invalid_start_and_end_date': 'End date is earlier than start date',
  'err:invalid_report_params': 'Invalid report parameters %s for template %s',
  'err:missing_report_params': 'Missing report parameters %s for template %s',
  'err:too_many_pending_reports_queue': 'Too many pending reports waiting in queue',
  'err:day_required': 'day is required',
  'err:scheduled_at_required': 'scheduled_at is required when using "once" report frequency',
  'err:email_config_required': 'email configuration is required to use report emailing feature',
  'err:scheduled_at_validation_error':
    'scheduled_at refers to a timestamp in the past, instead it needs to be either current time or point ot a future timestamp',
  'err:unable_to_enable_explorer_report': 'Unable to enable explorer report as traffic explorer is disabled',
  'err:unsupported_report_format': 'Invalid report format %s for report type %s',
  'err:invalid_ruleset_uri': 'Invalid ruleset URI',

  //IPList
  'err:invalid_edge_admin_rule': 'In Edge, secure_connect cannot be enabled if machine_auth is disabled',

  //Groups API
  'err:not_supported_in_product': 'This API or feature is not supported in this product',
  'err:group_with_name_already_exists': 'Group with this name already exists',
  'err:group_still_administering_groups': 'Group still administering other groups',
  'err:role_label_unsupported_for_groups': 'Groups cannot be created or updated using a role label',

  'err:slb_not_found': 'SLB not found',
  'err:slb_name_ambiguous': 'More than 1 SLB found',
  'err:virtual_server_info_ip_address_required': 'Virtual server info IP address is required for discovery',

  'err:virtual_server_info_ip_address_invalid': 'Virtual server info IP address is invalid for discovery',
  'err:virtual_server_info_port_invalid': 'Virtual server info port is out of range for discovery',
  'err:virtual_server_info_port_required': 'Virtual server info port is required for discovery',
  'err:virtual_server_info_required': 'Virtual server info is required for discovery',
  'err:virtual_server_infos_invalid': 'Virtual server infos are invalid',

  //Explorer
  'err:explorer_service_account_disallowed': 'Service accounts cannot access explorer async query apis',

  // Optional features
  'err:ip_forwarding_feature_disabled': 'IP Forwarding Firewall Setting feature is disabled',
  'err:optional_feature_key_required': 'Optional feature key required. Contact Illumio Support for more details',
  'err:optional_feature_key_invalid_hmac':
    'Optional feature key invalid HMAC value. Contact Illumio Support for more details',
  'err:optional_feature_key_invalid_format':
    'Optional feature key invalid format. Contact Illumio Support for more details',
  'err:optional_feature_key_invalid_content':
    'Optional feature key invalid content at field %s. Contact Illumio Support for more details',
  'err:optional_feature_key_invalid_timestamp':
    'Optional feature key invalid timestamp. Contact Illumio Support for more details',
  'err:optional_feature_key_expired': 'Optional feature key expired. Contact Illumio Support for more details',

  // Essential Service Rules
  'err:unknown_essential_service_rule': 'Unknown essential service rule %s',
  'err:essential_service_rule_read_only': 'This essential service rule cannot be modified',
  'err:esr_ip_lists_empty': 'Essential Service Rules must have 1 or more IP lists associated with them',
  'err:esr_too_many_ip_lists': 'Essential Service Rules must have less than 20 IP lists associated with them',
  'err:esr_ip_list_not_found': 'Cannot find referenced IP list',

  // Authentication
  'err:invalid_pairing_method': 'Invalid Pairing Method',
  'err:pairing_ven_type_invalid':
    'The requested ven_type, supported ven_type or pairing profile ven_type combination is invalid. %s',
  'err:ven_auth_recovery_suspended': 'VEN Authenticated Recovery Suspend',
  'err:ven_auth_recovery_unsupported': 'VEN Authenticated Recovery Unsupported',

  // CSV upload
  'err:href_label_column_required': 'The href column and at least one label column are required',
  'err:cannot_have_duplicate_columns': 'Cannot have duplicate columns',
  'err:invalid_content_type': 'invalid content type, only text/csv is accepted for this api',
  'err:href_column_required': 'href column is required in the csv',
  'err:label_column_required':
    "at least one label column is required in the csv, label header should have ':label ' prefix",
  'err:delete_token_invalid': 'delete token should be a single string without any white spaces',
  'err:delete_token_match_existing_label':
    'the input delete token has the same name with an existing label, please use another delete token',
  'err:invalid_label_dimension_in_csv_header': 'csv header contains label dimensions not exist in PCE',
  'err:number_of_entries_greater_than_threshold':
    'the number of entries in the csv file exceeds the threshold %s for bulk import, please split into multiple files if possible',

  // Policy Exclusions
  'err:policy_exclusion_not_supported': 'Exclusions can only be used with Labels and Label Groups',
  'err:policy_exclusion_invalid':
    'Rule Actors cannot have inclusions and exclusions in the same label & label group dimensions.',

  // Active Standby PCE
  'err:pce_part_of_active_standby': 'This PCE is already part of PCE active-standby.',
  'err:cannot_run_on_standby_pce': 'Cannot finish this operation on the standby PCE in active-standby PCE',
  'err:incorrect_active_standby_replication_fqdn':
    'Incorrect or missing active_pce_fqdn for active-standby replication',

  // Override Denies
  'err:override_deny_in_rulesets_only': 'Override Deny rules can only be created as part of rulesets.',

  // Ransomware Dashboard
  'err:ransomware_dashboard_is_disabled': 'Ransomware dashboard is not available',
  'err:waiting_for_cache_initialization': 'Waiting for Cache Initialization',
  'err:unsupported_property': 'Unsupported Property',

  // corex protection_schemas
  'err:duplicate_label_group_keys_not_allowed': 'Duplicate label group keys are not allowed',
  'err:invalid_label_group_keys_for_protection_schema': 'Invalid label group keys given for protection schemas',
  'err:label_group_referenced_by_protection_schemas': 'Label group is still referenced by protection schemas',
  'err:cannot_modify_or_delete_predefined_protection_schemas':
    'Unable to modify or delete predefined protection schemas',
  'err:protection_schema_short_code_value_too_long': 'Protection Schema short code value is too long',
  'err:protection_schema_label_group_missing_short_code':
    'LabelGroups used in ProtectionSchemas must define short codes',
  'err:protection_schema_short_code_disabled': 'LabelGroup protection_schema_short_code only valid in Xpress',

  // Corporate Public Ip
  'err:cannot_rename_default_corporate_ips': 'Cannot rename Default corporate IPs object for an org',
  'err:cannot_delete_default_corporate_ips': 'Cannot delete Default corporate IPs object for an org',
  'err:only_one_default_corporate_ips': 'Cannot have more than one Default corporate IPs for an org',
  'err:corporate_ips_groups_disabled': 'This feature is not enabled. Please contact Illumio support',

  // Network
  'err:label_based_network_detection_disabled':
    'This feature must be enabled before use. Please contact Illumio support',
  'err:label_based_network_scope_collision':
    'Multiple networks with the same scope, IP version, and IP type are not allowed.',
};
