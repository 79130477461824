/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {useLayoutEffect, useCallback, useRef, Fragment} from 'react';
import SelectedResource from './SelectedResource';
import {VALUEPANEL_ID} from '../SelectorUtils';
import {useFilialPiety} from '../SelectorFormatUtils';

export default function ValuePanel(props) {
  const {
    theme,
    saveRef,
    values,
    errors,
    disabled,
    insensitive,
    allResources,
    searchBarMaxHeight,
    onSelectedValueClick,
    onRemove,
    onSetHighlighted,
    onMouseLeave,
    registerHandlers,
  } = props;
  const elementRef = useRef();
  const {saveChildRef, registerChildHandlers, setHighlightedChild, resetHighlightedChild, keyDown} = useFilialPiety();

  const saveRefCallback = useCallback(
    element => {
      elementRef.current = element;
      saveRef(VALUEPANEL_ID, element);
    },
    [saveRef],
  );

  useLayoutEffect(() => {
    if (!searchBarMaxHeight) {
      return;
    }

    elementRef.current.style.setProperty(
      '--valuePanel-maxHeight',
      `calc(${searchBarMaxHeight}px - var(--optionSelector-height) - var(--optionSelector-vertical-padding))`,
    );

    return () => {
      elementRef.current.style.removeProperty('--valuePanel-maxHeight');
    };
  }, [searchBarMaxHeight]);

  useLayoutEffect(() => {
    registerHandlers(VALUEPANEL_ID, {setHighlightedChild, resetHighlightedChild, keyDown});
  }, [registerHandlers, setHighlightedChild, resetHighlightedChild, keyDown]);

  // Called when selectedResource yield highlight,
  // The handler calls its parent highlightLeave if it cannot find next child to pass highlighted state

  return (
    <div ref={saveRefCallback} className={theme.valuePanel}>
      {Array.from(values, ([id, valuesInResource], index) => (
        <Fragment key={id}>
          {index > 0 && allResources[id].selectedProps?.resourceJoiner && (
            <div className={theme.joiner}>{allResources[id].selectedProps.resourceJoiner}</div>
          )}
          <SelectedResource
            resource={allResources[id]}
            theme={theme}
            saveRef={saveChildRef}
            disabled={disabled}
            insensitive={insensitive}
            values={values}
            valuesInResource={valuesInResource}
            errors={errors[id]}
            registerHandlers={registerChildHandlers}
            onSelectedValueClick={onSelectedValueClick}
            onMouseLeave={onMouseLeave}
            onRemove={onRemove}
            onSetHighlighted={onSetHighlighted}
          />
        </Fragment>
      ))}
    </div>
  );
}
