/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import fetcher, {type FetcherOptions, type FetcherResult, type FetchResult} from './fetcher';
import Schema, {type SchemaMethodsKey} from './schema';
import {UNAUTHORIZED, NOT_ACCEPTABLE} from 'http-status-codes';
import * as apiUtils from './apiUtils';
import {errorUtils, hrefUtils, typesUtils} from '@illumio-shared/utils';
import type {Merge} from 'type-fest';

export const apiPrefix = Schema.api_prefix;

// Any api call extends user session on backend. Backend timeout after last api call - 10min
// If there is no api calls (user just staring at the screen)
// we need to extend backend session as long as user is active, i.e is moving a mouse
// (handleMoveToLogout in App.js will logout user after 10 min of inactivity)
// This noop method will be called 5min after any api call, to extend backend session.
export const callNoop = _.debounce(_.partial(apiCall, 'noop'), 300_000);
export const defaultTimeout = 90_000;
export const defaultWarningTimeout = 7000;

export type APICallOptions = typesUtils.UnionOmit<FetcherOptions, 'method' | 'query' | 'timeout' | 'url'> & {
  /**
   * Id of organization. Not all methods needs it
   */
  orgId?: string;
  params?: Record<string, string>;
  query?:
    | Record<string, string>
    | {
        xxxkey?: string;
        xxxlabels?: string[][];
        enforcement_modes?: ('full' | 'idle' | 'selective' | 'visibility_only')[];
      };
  timeout?: number;
  hrefs?: hrefUtils.Href[];
  method?: apiUtils.APIMethod;
  url?: string;

  /**
   * Read counts from response header
   */
  readCount?: boolean;
};

export interface APICallResponse {
  data: FetchResult['data'];
  options: APICallOptions;
  response: Response;
  fetcherParams: FetcherOptions;
  headers: Response['headers'];
  statusCode: Response['status'];
  count?: {
    matched: number;
    total: number;
  };
}

export type APICallResult = Merge<
  FetcherResult,
  {
    promise: Promise<APICallResponse>;
  }
>;

/**
 * Function to call api endpoint with given options
 * Returns object with abort method and promise that can be waited on caller side
 *
 * @param {string} name      - Name of the api method, in 'class.method' notation, like 'workloads.get_instance'
 * @param {Object} [options] - Options that will be altered and passed to fetcher.js (open that file for full options list)
 * @param {number} [options.orgId] - Id of organization. Not all methods needs it
 *
 * @typedef APIReturn
 * @property {Function} abort  - Method to cancel running request
 * @property {Promise} promise - Promise that is resolved with response when request is done
 *
 * @returns {Promise.<APIReturn>}
 */
export default async function apiCall(name: SchemaMethodsKey, options: APICallOptions = {}): Promise<APICallResult> {
  const {orgId, params = {}, query = {}, data, timeout = defaultTimeout, hrefs = [], ...rest} = options;

  const headers: FetcherOptions['headers'] = {Accept: 'application/json', ...options.headers};
  const csrfCookie = await cookieStore.get('csrf_token');
  let {url, method} = options;

  // we know that schemaMethodsMap.get(methodOrKey) must exist because of the key constraint
  // so it's ok to use null assertion here to get rid of the undefined value
  method ||= apiUtils.schemaMethodsMap.get(name)!;

  // <Boolean> Read counts from response header
  const {readCount = method.methodName && method.methodName.includes('get')} = options;

  url ||= apiUtils.getMethodParameterizedPath({method, orgId, params});

  if (csrfCookie?.value) {
    headers['X-Csrf-Token'] = csrfCookie.value;
  }

  let queryToSend: FetcherOptions['query'];
  let dataToSend;

  switch (method.httpMethod) {
    case 'GET':
      queryToSend = query;

      // xxxLabels are lists of labels which require special formatting for the query string.
      // They are used by the workloads api. Other lists of labels can use the standard query encoding.
      // xxxkey is the label key used by the backend api. Most api uses 'labels', but sometimes, api uses a different key.
      // For example, 'assign_labels' is used for container workload profiles.
      if (Array.isArray(query.xxxlabels) && !_.isEmpty(query.xxxlabels)) {
        const {xxxlabels, xxxkey = 'labels', ...stripped} = query;

        queryToSend = Object.assign(stripped, apiUtils.getXXXLabelsQueryParam(xxxlabels, xxxkey));
      }

      // EYE-111722 enforcement_modes is a list of enforcement mode which require special formatting for the query string
      // they are used by the workloads api
      if (Array.isArray(query.enforcement_modes) && query.enforcement_modes.length > 0) {
        const {enforcement_modes, ...stripped} = queryToSend;

        const enforcementModesParam = JSON.stringify(enforcement_modes);

        queryToSend = Object.assign(stripped, {enforcement_modes: enforcementModesParam});
      }

      break;
    case 'PUT':
    case 'POST':
      queryToSend = query;
      headers['Content-Type'] ??= 'application/json';
      dataToSend = data || {};
      break;
    case 'DELETE':
      queryToSend = query;
      break;
    // no default
  }

  const fetcherParams = Object.assign(rest, {
    headers,
    timeout,
    data: dataToSend,
    query: queryToSend,
    method: method.httpMethod,
    url: `${apiPrefix}${url}`,
  }) as FetcherOptions;

  const request = fetcher(fetcherParams);

  return {
    ...request,
    promise: (async function () {
      try {
        const {response, data} = await request.promise;

        // If request executed with success code, postpone next noop call
        callNoop();

        const result = {
          data,
          options,
          response,
          fetcherParams,
          headers: response.headers,
          statusCode: response.status,
          ...(readCount && {
            count: {
              matched: Number(response.headers.get('x-matched-count')) || 0,
              total: Number(response.headers.get('x-total-count')) || 0,
            },
          }),
        };

        return result;
      } catch (error) {
        if (error instanceof errorUtils.RequestStatusError) {
          const {data, response, statusCode, timeout, message, details} = error as errorUtils.RequestStatusError;

          if (statusCode !== UNAUTHORIZED) {
            // Even if request executed with other than 200 code (but not 401), postpone next noop call
            callNoop();
          }

          if (statusCode === NOT_ACCEPTABLE && name.includes('delete')) {
            // we know that in this case, the data from api has a certain structure so it's safe to assert it
            throw new errorUtils.ProcessedAPIError({
              hrefs,
              data: data as errorUtils.Data,
              response,
              statusCode,
              timeout,
              message,
              ...details,
            });
          }

          throw new errorUtils.APIError({data, response, statusCode, timeout, message, ...details});
        }

        throw error;
      }
    })(),
  };
}
