/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import listReducers from './List/LabelListState';
import instanceReducers from './Edit/LabelEditState';

export default {
  label: combineReducers({
    ...listReducers,
    ...instanceReducers,
  }),
};
